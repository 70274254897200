import React, { Component } from 'react';
import styled from 'styled-components';
import { InputBase } from '@mui/material';

export default class MessageEditor extends Component {

    state = {
        text: "",
    }

    handleChange=(event) => {
        this.setState({ text : event.target.value }, () => this.props.updateText(this.state.text))
        
    }


    componentDidUpdate(prevProps) {
        if(this.props.sended !== prevProps.sended) {
            this.setState({text: ""}, () => this.props.updateText(this.state.text))
            
        }
            
    }


    render() {

        const { text } = this.state;

        return (
                <MyTextField
                    ref={this.props.myref} 
                    key={"textfield"+this.props.key} 
                    type="text" name={this.props.name} 
                    value={text ?? ""} 
                    onChange={(event) => this.handleChange(event)} 
                    placeholder={this.props.placeholder} 
                />
        )
    }
}
const MyTextField = styled(InputBase)`
    background-color: white;
    border-color: white;
    font-family: 'Karla', sans-serif;
    padding: 10px 0;
    font-size: 14px;
    border-radius: 25px;
    border-bottom: 0px;
    border-top: 0px;
    outline: none;
    width: 100%;
`