/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import {  Grid, Paper } from '@mui/material';
//import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { DesktopView, MobileView } from '../../Theme';
import {Link as ReactLink} from 'react-router-dom'
import './accompagnement.css';


class AccompagnementSection extends Component {
    render() {

        const Body = (props) => {

            const { gsm} = props;
            return (
                <div className="body-accompagnement">
                    <Grid container  spacing={gsm ? 1 : 6} style={{width:"100%", margin:"0", marginTop:gsm ? "0px" : "50px"}} >
                        
                        <Grid item md={6} xs={12} style={{paddingLeft:"0px", paddingRight:gsm ? "0px": "50px"}}>
                            <h2 className="main_h2" style={{lineHeight:"1.4"}}>
                                Se faire accompagner par une diététicienne, une solution saine pour atteindre vos objectifs
                            </h2>
                            <p >
                                Être pris en charge au niveau diététique, c'est avoir un professionel de la santé et de l'alimentation qui vous écoute selon votre mode de vie, vos habitudes alimentaires
                                et activités sportives ainsi que vos objectifs afin d'établir un plan diététique personnalisé qui vous correspond vraiment.
                            </p>
                            <br/>
                            <p>
                                Sur Food-eDiet, votre diététicien.ne vous accompagne et vous motive pour atteindre vos objectifs. Grâce à nos outils, vous aurez l'opportunité de tchatter à tout moment avec votre expert.e, visualiser des graphiques pour mesurer vos progrès et bien plus encore !
                            </p>
                            <center>
                            <div style={{paddingTop:"35px", paddingBottom:"45px"}}>
                                <ReactLink to='tarifs#what-consultation' className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative"}}>Découvrez ici le détail du suivi Food-eDiet</button></ReactLink>
                            </div>  
                            </center>                        
                        </Grid>
                        <Grid item style={{ width:"100%", lineHeight:"1.5em"}} md={6} xs={12} container direction="column" spacing={gsm ? 1 : 6} >
                            <Grid item>
                                <Paper style={{boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)" }} id="accompagnement1">
                                    <Grid container spacing={2} style={{padding:"15px", width:"100%", margin:"0"}}>
                                        <Grid item xs={4}>
                                            <center>
                                                <img src="/deadline.png" alt="prendre rendez-vous" width="50%" />
                                            </center>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h4 style={{fontSize:"18px", fontWeight:"700", lineHeight:"1.8", margin:"0", padding:"0", paddingBottom:"10px"}}>
                                                <span>Prendre rendez-vous en ligne</span>
                                            </h4>
                                            <p>
                                                Je prends rendez-vous directement en ligne selon l'horaire qui me convient le mieux
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper  style={{boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)" }} id="accompagnement2">
                                    <Grid container spacing={2} style={{padding:"15px", width:"100%", margin:"0"}}>
                                        <Grid item xs={4}>
                                            <center>
                                                <img src="/video.png" alt="Être accompagné" width="50%" />
                                            </center>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h4 style={{fontSize:"18px", fontWeight:"700", lineHeight:"1.8", margin:"0", padding:"0", paddingBottom:"10px"}}>
                                                <span>Être accompagné</span>
                                            </h4>
                                            <p>
                                                Je suis accompagné en téléconsultation par un(e) spécialiste de l'alimentation 
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper style={{boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)" }} id="accompagnement3" >
                                    <Grid container spacing={2} style={{padding:"15px", width:"100%", margin:"0"}}>
                                        <Grid item xs={4}>
                                            <center>
                                                <img src="/trophy.png" alt="atteindre ses objectifs" width="50%" />
                                            </center>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h4 style={{fontSize:"18px", fontWeight:"700", lineHeight:"1.8", margin:"0", padding:"0", paddingBottom:"10px"}}>
                                                <span>Atteindre ses objectifs</span>
                                            </h4>
                                            <p>
                                                Grâce à Food-eDiet, je suis encouragé et motivé à atteindre mes objectifs
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                        </Grid>

                    </Grid>

                </div>
            )
        }

        return (
            <div  name="decouvrez-notre-accompagnement-header" style={{position: "relative", zIndex:"1",}}>
                 <a className="anchor" id="accompagnement"></a>
                 <MobileView >
                    <div style={{padding: "30px", maxWidth:"1080px"}}>
                        <Body gsm/>
                    </div>
                </MobileView>
                <DesktopView >
                    <div style={{width:"85%", margin:"auto", maxWidth:"1080px"}}>
                        <Body/>
                    </div>
                </DesktopView>
                
            </div>
        );
    }
}

export default AccompagnementSection;