import React, { Component } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { compose } from 'recompose';
import { withAuthorization } from '../../../components/Session';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import { Grid, Select, MenuItem, FormControlLabel, Switch, FormGroup, IconButton, CircularProgress, Button } from '@mui/material';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { Background, GreenButton } from '../../../components/Theme';
import MyAppBar from '../../connected/Menu/MyAppBar';
import SimpleDialog from '../../../components/SimpleDialog';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Etape from './Etape';
import MyPictures from './MyPictures';
import withSnackBar from '../../../components/Theme/MySnackBar/withSnackBar';
import {Link as ReactLink} from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { withRouter } from '../../../App';
import {  doc, query, onSnapshot } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


class ArticleManagement extends Component {

    constructor(props) {
        super(props);
        this.stepRefs = [];
        this.ingredientsRefs = [];
    }

    state = {
        text: "",
        article: {},
        loading: true,
        open: false,
        snack_message: "",
        file: null,
        isUploading: false,
        progress: 0,
        openModalDelete: false,
        articleInitialContent: "",
    }

    deleteArticle = () => {

        this.props.firebase.firestore.collection("articles").doc(this.props.match.params.uid).delete()
        .then(() => {
            this.props.navigate("/blog");
        })
        .catch(error => {
            console.error(error)
            this.setState({ error });
        });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        const open = false;
        this.setState({ open })
      };

    onChange = event => {
        const { article } = this.state;
        article[event.target.name] = event.target.value
        this.setState({ article });
        this.saveArticle();
    };

    OnCategoryChange = event => {
        const { article } = this.state;

        article.type = event.target.value === 0 ? "Article" : "Recette"
        this.setState({ article });
        this.saveArticle();
    };

    onToggle = event => {
        const { article } = this.state;
        article[event.target.name] = event.target.checked
        this.setState({ article });
        this.saveArticle();
    };

    

    componentDidMount() {
        const { authUser } = this.props;

        if(authUser && authUser.data && authUser.data.level >= 4) {
            if(this.props.match.params.uid) {
                this.unsubCustomer = onSnapshot(
                    query(
                      doc(this.props.firebase.firestore, "articles", this.props.match.params.uid)
                    ),
                    (snap) => {
                        const article = snap.data()
                        this.setState({ article : article })
                        if(article.steps)
                        article.steps.map((element) => (
                            this.stepRefs.push(React.createRef())
                        ));
                        if(article.ingredients)
                        article.ingredients.map((element) => (
                            this.ingredientsRefs.push(React.createRef())
                        ));
                        this.setState({loading: false, articleInitialContent : article})
                    },
                    (error) => {
                      console.error(error)
                      this.setState({  loadingUser : false });
                    }
                  );
            }
            else {
                const article = {
                    uid: "",
                    title: "",
                    subtitle: "",
                    miniatureURL: "",
                    type: "Article",
                    level: 4,
                    difficulty: 0,
                    dietId: this.props.authUser.data.uid ?? "",
                    date: 0,
                    content: "",
                    statusPC: false,
                    statusAndroid: false,
                    statusIOS: false,
                    steps: [],
                    ingredients: [],
                    apports: [],
                }
                this.setState(state => ({
                    ...state,
                    loading: false,
                    article: article,
                }))
            }
           
        }
        else {
            this.props.navigate("/")
        }
    }

    componentWillUnmount() {
        if(this && this.props && this.props.base)
            this.props.base.removeBinding(this.ref)
    }

    handleEditorChange = (content, editor, name) => {
        const { article } = this.state;
        article[name] = content;
        this.setState(state => ({
            ...state,
            article: article,
        }))
        this.saveArticle(name, content);
    }

    saveArticle = (name, content) => {
        localStorage.setItem("editor-" + name, content);
    }

    uploadFile = async (file, id) => {
        const { firebase } = this.props;

        const task = firebase.storage.ref("articles/" + id + "/" + file.name).put(file);
        await task.on(
            firebase.storageUp.TaskEvent.STATE_CHANGED,
            () => {
                let url = task.snapshot.downloadURL;
                const { article } = this.state;
                article.miniatureURL = url;
                this.setState(state => ({
                    ...state,
                    article: article,
                }));
                this.saveArticle();
                return true;
            },
            error => {
                console.error("[Miniature upload] - Error " + error);
                return false;
            },
        );
    }

    submitArticle = (event) => {

        const { article, file } = this.state;

        if(article.title === "") {
            return;
        }
        article.date = Date.now();
        // Remplissage des étapes
        article.steps = []
        this.stepRefs.map((element) => (
            article.steps.push(element.current.value)
        ))
        // Remplissage des ingrédients
        article.ingredients = []
        this.ingredientsRefs.map((element) => (
            article.ingredients.push(element.current.value)
        ))

        const title = encodeURI(article.title.toLowerCase().trim()
                .replaceAll(" ", "-")
                .replaceAll("é", "e")
                .replaceAll("è", "e")
                .replaceAll("'", "-")
                .replaceAll(":", "")
                .replaceAll(",", "-")
                .replaceAll("à", "a")
                .replaceAll("ç", "c")
                .replaceAll("â", "a")
                .replaceAll("ê", "e")
                .replaceAll("ï", "i")
                .replaceAll("/", "-")
                .replaceAll('"', "-")
                .replaceAll("!", "")
        );

        article.uid = title;

        if(file) {
            this.uploadFile(file, title);
        }

        this.props.firebase.firestore.collection("articles").doc(title).set(article)
        .then(() => {
            this.props.firebase.firestore.collection("users").doc(this.props.authUser.data.uid).update({numberOfArticles : this.props.firebase.app.firestore.FieldValue.increment(1)})
            .then(() => {
                this.props.openSnackMessage("Article publié !");
                localStorage.removeItem('article');
                this.props.navigate("article/"+article.uid)
            })
        })
        .catch(error => {
            console.error(error)
            this.setState({ error });
        });
        event.preventDefault();

    };

    updateArticle = (event) => {
        event.preventDefault();
        const { article } = this.state;

        /*if(this.stepRefs.length !== article.steps.length) {
            article.steps = []
            this.stepRefs.map((element) => {
                return article.steps.push(element.current.value)
            })
        }
        if(this.ingredientsRefs.length !== article.ingredients.length) {
            article.ingredients = []
            this.ingredientsRefs.map((element) => (
                article.ingredients.push(element.current.value)
            ))
        }*/
        // Remplissage des étapes
        article.steps = []
        this.stepRefs.map((element) => (
            article.steps.push(element.current.value)
        ))
        // Remplissage des ingrédients
        article.ingredients = []
        this.ingredientsRefs.map((element) => (
            article.ingredients.push(element.current.value)
        ))


        this.props.firebase.firestore.collection("articles").doc(article.uid).update(article)
        .then((result) => {
            this.props.openSnackMessage("Article mis à jour !");
            localStorage.removeItem('article');
        })
        .catch(error => {
            this.setState({ error });
        });
    };

    handleChangeInputFile = (e) => {
        if(!e.target.files)
            return
        //this.fileUploader.startUpload( e.target.files ? e.target.files[0] : null)
        this.submitUpload.click()
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const file = e.target[0]?.files[0]
    
        if (!file) return;
    
        const storageRef = ref(this.props.firebase.storage, "articles/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(progress)
            //setProgresspercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                const { article } = this.state;
                article.miniatureURL = url;
                this.setState(state => ({
                    ...state,
                    article: article,
                }));
                this.saveArticle();
                this.props.openSnackMessage("Miniature enregistrée !");
            });
          }
        );
      }
      
    removeOne = (name) => {
        const {article } = this.state;
        article[name].pop();
        if(name === "steps")
            this.stepRefs.pop();
        if(name ==="ingredients")
            this.ingredientsRefs.pop();
        this.setState({ article })
    }

    addOne = (name, value) => {
        const {article } = this.state;
        article[name].push(value)
        if(name === "steps")
            this.stepRefs.push(React.createRef());
        if(name ==="ingredients")
            this.ingredientsRefs.push(React.createRef());
        this.setState({ article })
    }
    handleApportNutrionnels = (event, uid) => {
        const {article } = this.state;
        article.apports[uid][event.target.name] = event.target.value;
        this.setState({ article })
    }

    handlePicturesInArticles = filename => {
        const { firebase } = this.props;
        firebase
        .storage
        .ref("articles")
        .child(filename)
        .getDownloadURL()
        .then(url => {
                const { article } = this.state;
                if(!article.pictures) {
                    article.pictures = []
                }
                article.pictures.push(url)
                this.setState(state => ({
                    ...state,
                    article: article,
                }));
                this.props.openSnackMessage("Photo enregistrée !");
                this.saveArticle();
            });
    };

    render() {

        const { firebase, authUser } = this.props;
        
        if(!this.state.loading) {
            const { article, articleInitialContent } = this.state;
            const category = article.type === "Article" ? 0 : 1;
            
            const ingredients = Object.keys(article.ingredients ?? {}).map((uid, i) => 
            (<Etape key={article.ingredients[uid]} text={article.ingredients[uid]} index={i} name="ingredients" myref={this.ingredientsRefs[i]} placeholder={"Ingrédient " + (i+1)} />
            ));

            const steps = Object.keys(article.steps  ?? {}).map((uid, i) => 
            (<Etape key={article.steps[uid]} text={article.steps[uid]} index={i} name="steps" myref={this.stepRefs[i]} placeholder={"Étape " + (i+1)} />
            ));

            const apports = Object.keys(article.apports ?? {}).map((uid, i) => 
            (<div key={article.apports[uid]}>
                <div >
                    <div style={{background:"coral", borderRadius:"50%", width: "20px", textAlign:"center", color:"white", float: "left", marginTop:"10px"}}>{i+1}</div> 
                    <MyTextfield type="text" name="nom" value={article.apports[uid]["nom"] ?? ""} onChange={(event) => this.handleApportNutrionnels(event, uid)} placeholder={"Nom de l'apport " + (i+1)}/>
                    <MyTextfield type="text" name="poids" value={article.apports[uid]["poids"] ?? ""} onChange={(event) => this.handleApportNutrionnels(event, uid)} placeholder={"Quantité (1 NOMBRE SVP, SANS l'UNITE) " + (i+1)}/>
                    {/*<MyTextfield type="text" name="pourcent" value={article.apports[uid]["pourcent"] ?? ""} onChange={(event) => this.handleApportNutrionnels(event, uid)} placeholder={"% de l'apport " + (i+1)}/>*/}
                </div>
                <br/>
            </div>
            ));

            return (
                <div>
                    <Helmet>
                        <title>Article Management</title>
                        <meta name="robots" content="noindex, nofollow"/>
                    </Helmet>
                <MyAppBar authUser={authUser} title={"Création / Modification d'article"} paddingLeft="100px"/>
                <Background>
                    {/*<Prompt
                        when={true}
                        message={location =>
                        `Voulez vous vraiment quitter ? Les données non sauvegardées seront perdues`
                        }
                    />*/}
                    <ReactLink to="/admin/articles" className="nolink"><GreenButton >← Retour</GreenButton></ReactLink>
                    <br/>
                    <Grid container spacing={10}>
                        <Grid item xs={3}>
                            <h3>Miniature</h3>
                            {this.state.isUploading && <CircularProgress variant="determinate" value={this.state.progress} color="primary" />}
                            {article.miniatureURL && <img src={article.miniatureURL} alt="miniature" width="200px" height="130px"/>}
                            <br/>
                            <br/>
                            <form  onSubmit={this.handleSubmit} className='form' style={{ visibility: 'hidden', width:0, height:0}}>
                                <input type='file' ref={(fileUpload) => {this.fileUpload = fileUpload;}} onChange={this.handleChangeInputFile} />
                                <button type='submit' ref={(submitUpload) => {this.submitUpload = submitUpload;}} >Confirmer</button>
                            </form>
                            <div
                                onClick={() => this.fileUpload.click()}
                                style={{background: 'linear-gradient(to left bottom, rgb(75, 156, 75), rgb(126, 202, 202))', color: 'white', padding: 5, borderRadius: 4}}
                            >
                                Selectionner une image
                            </div>
                            <h3>Difficultée</h3>
                            <Select
                            fullWidth={true}
                            value={article.difficulty ?? 0}
                            name="difficulty"
                            onChange={this.onChange}
                            >
                                <MenuItem value={0}>Facile</MenuItem>
                                <MenuItem value={1}>Intermédiaire</MenuItem>
                                <MenuItem value={2}>Avancé</MenuItem>
                            </Select>
                            <h3>Accès</h3>
                            <Select
                            fullWidth={true}
                            value={article.level ?? 0}
                            name="level"
                            onChange={this.onChange}
                            >
                                <MenuItem value={0}>Tous les utilisateurs</MenuItem>
                                <MenuItem value={1}>Utilisateurs inscrits</MenuItem>
                                <MenuItem value={2}>Utilisateurs sous abonnement</MenuItem>
                                <MenuItem value={3}>Membres de Elysium</MenuItem>
                                <MenuItem value={4}>Dietéticiennes</MenuItem>
                                <MenuItem value={5}>Bb toum</MenuItem>
                            </Select>
                            <h3>Type d'article</h3>
                            <Select
                            id="select-type"
                            fullWidth={true}
                            value={article.type === "Recette" ? 1 : 0} //potentiellement changer si plusieurs types
                            name="category"
                            onChange={this.OnCategoryChange}
                            >
                                <MenuItem value={0}>Article</MenuItem>
                                <MenuItem value={1}>Recette</MenuItem>
                            </Select>
                            <h3>Statut</h3>
                            <FormGroup column="true">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={article.statusPC ?? false}
                                            onChange={this.onToggle}
                                            name="statusPC"
                                            color="primary"
                                        />
                                    }
                                    label="Vérification PC"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={article.statusAndroid ?? false}
                                            onChange={this.onToggle}
                                            name="statusAndroid"
                                            color="primary"
                                        />
                                    }
                                    label="Vérification Android"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={article.statusIOS ?? false}
                                            onChange={this.onToggle}
                                            name="statusIOS"
                                            color="primary"
                                        />
                                    }
                                    label="Vérification IOS"
                                />
                            </FormGroup>
                            {/*
                            <h3>Photos dans l'article</h3>
                            <CustomUploadButton
                                accept="image/*"
                                name="avatar"
                                randomizeFilename
                                storageRef={firebase.storage.ref("articles")}
                                onUploadSuccess={this.handlePicturesInArticles}
                                style={{background: 'linear-gradient(to left bottom, rgb(75, 156, 75), rgb(126, 202, 202))', color: 'white', padding: 5, borderRadius: 4}}
                            >
                                Ajouter des images
                                </CustomUploadButton>
                            <br/>
                            <br/>
                            <MyPictures pictures={this.state.article.pictures}/>
                            */}
                            <h3>Paramètres</h3>
                            { this.props.match.params.uid && 
                                <Button
                                    variant="contained"
                                    color="default"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {this.setState({openModalDelete:true})}}
                                >
                                    Supprimer l'article
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={8}>
                        <div id="login" >
                            { category === 0 && <div>
                            <h3>Titre de l'article</h3>
                            <MyTextfield type="text"name="title" value={article.title ?? ""} onChange={this.onChange} placeholder="Titre de l'article" required/>
                            <h3>Sous-Titre de l'article</h3>
                            <MyTextfield type="text" name="subtitle" value={article.subtitle ?? ""} onChange={this.onChange} placeholder="Sous titre de l'article" required/>
                            <h3>Contenu</h3>
                            <MyEditor editorId="content" onChange={(content, editor) => this.handleEditorChange(content, editor, "content")} content={article.content} initial={articleInitialContent.content} />
                            </div> }
                            { category === 1 && <div>
                            <h3>Titre de la Recette</h3>
                            <MyTextfield type="text"name="title" value={article.title ?? ""} onChange={this.onChange} placeholder="Titre de la recette" required/>
                            <h3>Sous-Titre de la recette</h3>
                            <MyTextfield type="text" name="subtitle" value={article.subtitle ?? ""} onChange={this.onChange} placeholder="Sous titre de l'recette" required/>
                            <h3>Temps de préparation</h3>
                            <MyTextfield type="text"name="preparationTime" value={article.preparationTime ?? ""} onChange={this.onChange} placeholder="ex : 1h30" required/>
                            <h3>Nombre de personnes</h3>
                            <MyTextfield type="text" name="nombreDePersonne" value={article.nombreDePersonne ?? ""} onChange={this.onChange} placeholder="ex: 2 pers." required/>
                            <h3>Ingrédients</h3>
                            <Adder name="ingredients" list={article.ingredients} removeOne={this.removeOne} addOne={this.addOne} value="" />
                            { ingredients }
                            <h3>Etapes</h3>
                            <Adder name="steps" list={article.steps} removeOne={this.removeOne} addOne={this.addOne} value=""/>
                            { steps }
                            <h3>Apports nutritionnels</h3>
                            <Adder name="apports" list={article.apports} removeOne={this.removeOne} addOne={this.addOne} value={{nom:"", poids:"", pourcent:""}} />
                            { apports }
                            <h3>Variante</h3>
                            <MyEditor editorId="variante" onChange={(content, editor) => this.handleEditorChange(content, editor, "variante")} content={article.variante} initial={articleInitialContent.variante} />
                            <h3>Point De Vue Diet</h3>
                            <MyEditor editorId="pointDeVueDiet" onChange={(content, editor) => this.handleEditorChange(content, editor, "pointDeVueDiet")} content={article.pointDeVueDiet} initial={articleInitialContent.pointDeVueDiet} />
                            </div> }
                        <br/>
                        <button  className="submit-btn" onClick={!this.props.match.params.uid ? this.submitArticle : this.updateArticle}>Publier l'article</button>
                        <div style={{height:"25px"}} />
                        <ReactLink to={"/blog/"+this.props.match.params.uid} target="_blank"><button  className="submit-btn">Voir l'article</button></ReactLink>
                        </div>
                        <br/>
                        </Grid>
                        
                    </Grid>
                    <SimpleDialog 
                        head="Supprimer ?"
                        body="Veux-tu vraiment supprimer cet article ? Cette action est définitive"
                        open={this.state.openModalDelete}
                        handleClose={() => this.setState({openModalDelete: false})}
                        handleConfirm={this.deleteArticle}
                    />
                </Background>
                </div>
            )
        }
        else {
            return (
                <div>

                </div>
            );
        }
    }
}

const MyTextfieldStyle = styled.input`
    width: 750px;
    padding: 10px 0;
    margin: 5px;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid;
    outline: none;
    font-family: 'Karla', sans-serif;
    background: transparent;
    color: black;
`;

const MyTextfield = (props) => {
    return(
        <MyTextfieldStyle key={props.key} type={props.type} name={props.name} value={props.value} onChange={props.onChange} placeholder={props.placeholder}/>
    )
}

const Adder = (props) => {
    return (
        <div style={{display:"inline"}}>
            <IconButton onClick={() => props.removeOne(props.name)}><RemoveCircleIcon fontSize="small" htmlColor="red" style={{verticalAlign: "middle"}} /></IconButton>
            {props.list ? <span> {props.list.length} </span> : <span> 0 </span> }
            <IconButton onClick={() => props.addOne(props.name, props.value)}><AddCircleIcon fontSize="small" htmlColor="blue" style={{verticalAlign: "middle"}}/></IconButton>
        </div>   
    )
}

const MyEditor = (props) => {
    return (
        <div>
        <input id="my-file" type="file" name="my-file" style={{display:"none"}}  /> 
        <Editor
            apiKey="78dhrdsl6xctbfjypq9wjovdeotnctrokmk40aajvia1atjo"
            placeholder="Contenu de l'article"
            menubar= 'file edit view insert format tools table help'
            //initialValue={props.initial}
            value={props.content}
            
            init={{
            height: 500,
            boxShadow:"0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
            fontFamily: "'Karla', sans-serif",
            fontWeight: "400",
            autosave_prefix:'editor-autosave-' + props.editorId,
            autosave_interval: '30s',
            toolbar_sticky: true,
            //image_dimensions: false,
            file_browser_callback_types: 'image',
              file_picker_callback: function (callback, value, meta) {
                if (meta.filetype === 'image') {
                    var input = document.getElementById('my-file');
                    input.click();
                    input.onchange = function () {
                        var file = input.files[0];
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            callback(e.target.result, {
                                alt: file.name
                            });
                        };
                        reader.readAsDataURL(file);
                    };
                }
            },
            paste_data_images: true,
            image_class_list: [
                {title: 'Responsive', value: 'img-responsive'}
            ],
            plugins: [
                'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons'            ],
            toolbar:
                // eslint-disable-next-line no-multi-str
                'undo redo | formatselect fontsizeselect  | bold italic underline strikethrough  | image \
                alignleft aligncenter alignright alignjustify charmap emoticons | forecolor backcolor  \
                bullist numlist outdent indent | table removeformat | preview  help restoredraft'
            }}
            onEditorChange={props.onChange}
        />
        </div>
    );
}

const condition = authUser => !!authUser; 

export default compose(withRouter, withAuthorization(condition), withSnackBar)(ArticleManagement);
export { MyEditor };
