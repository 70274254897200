import React from 'react'
import { Button, Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormControl, RadioGroup, FormControlLabel, Radio, } from '@mui/material';
//import PaidMethod from '../../Profile/ProfilePage/PaidMethod';
//import CurrentPaid from '../../Profile/ProfilePage/currentPaid';
import styled from 'styled-components';
import { Level } from '../../../../components/Theme';

const MoyenDePaiement = (props) => {

    return (
        <div style={{minHeight:"350px"}}>
            <h3>Etape 4 - Type de consultation</h3>
            <TypeConsultation type={getConsultationType(props.authUser)} />
            {props.authUser.data.level >= Level.ABONNE && <p>Nombre de consultations restantes dans votre abonnement : {props.authUser.data.freeConsultation}</p>}
            <h3>Etape 5 - Choix de la méthode de paiement*</h3>
            <h5>*Paiement sécurisé par la platforme Stripe</h5>
            <FormControl component="fieldset" style={{}} required >
                <RadioGroup name="type_paiement" value={props.type_paiement} onChange={props.onChange}>
                    <FormControlLabel value="bancontact" label="Payer par bancontact"  control={<Radio />} />
                    <FormControlLabel value="card" label="Payer par carte de credit"   control={<Radio />}  />
                </RadioGroup>
            </FormControl>
            <form onSubmit={(e) => { e.preventDefault(); props.handleClickContinue(); }} >
            <Grid container spacing={3} justifyContent="space-between">
                <Grid item>
                    <Button color="grey" variant="contained" onClick={props.handleClickPrecedent} style={{marginTop:"15px"}}  >Précédent</Button>
                </Grid>
                <Grid item>
                    {props.type_paiement !== "" && <Button color="primary" variant="contained" type="submit" style={{marginTop:"15px"}}  >Etape suivante</Button> }
                </Grid>
            </Grid>
            </form>
        </div>
    )
}
/*
<p>Il n'est actuellement possible de payer que par le moyen d'une carte de crédit (Visa, Mastercard). Vous pouvez ajouter votre carte via la platforme sécurisée.</p>
            <h5>Gérez ses moyens de paiements</h5>
            <PaidMethod authUser={props.authUser} title="Mon compte" blank/>
            <CurrentPaid authUser={props.authUser} setCard={props.setCard} />*/

export default MoyenDePaiement;

export const getConsultationType = (authUser) => {
    if(authUser.level >= Level.ABONNE) {
        if(authUser.data.freeConsultation > 0)
        return  {
            title: "Consultation incluse dans votre abonnement",
            id: 0,
            prix: "0€",
        }
        else {
            return {
                title: "Consommation hors forfait",
                id: 1,
                prix: "29€",
            }
        }
    }
    else if(authUser.consultations && authUser.consultations.length > 0) {
        return  {
            title: "Consultation de suivi",
            id: "1",
            prix: "29€",
        }
    }
    else {
        return  {
            title: "1ère Consultation",
            id: 2,
            prix: "59€",
        }
    }
}



const TypeConsultation = (props) => {

    
    return <Paper>
        <TableContainer>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        <MyCell>Type De Consultation</MyCell>
                        <MyCell>Prix</MyCell>
                    </TableRow>                           
                </TableHead>
                <TableBody>
                    <TableRow >
                        <MyCell>{props.type.title}</MyCell>
                        <MyCell>{props.type.prix}</MyCell>
                    </TableRow>
                   
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}

const MyCell = styled(TableCell)`
    font-family: 'Karla', sans-serif;
`