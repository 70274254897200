/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, InputBase, Paper } from '@mui/material';
import React, { Component } from 'react';
import MyFooter from '../../../../components/Footer';
import Header from '../../../../components/Header';
import LockIcon from '@mui/icons-material/Lock';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../../../helpers';
import Alert from '@mui/material/Alert';
import { compose } from 'recompose';
import queryString from 'query-string'
import Validator from '../../../../utils/Validator';
import { Helmet } from 'react-helmet';
import { withRouter } from '../../../../App';


class SetNewPassword extends Component {


    state = {

        // Fields
        confirm_password:"",
        password:"",

        // Errors
        error:"",
        errorConfirmPassword:"",
        errorPassword:"",
    }

    componentDidMount() {
        this.props.firebase.analytics.logEvent('setNewPassword_page_view');
    }

    checkDoublePassword = () => {

        const { password, confirm_password } = this.state;

        if (password !== confirm_password) {
            this.setState({errorConfirmPassword : "Votre mot de passe de confirmation est différent de votre mot de passe"})
            return false;
        }
        else {
            this.setState({errorConfirmPassword : ""})
            return true;
        }
    }

    confirm = () => {

        const { password } = this.state;

        // Specific checks
        var okConfirmPassword = this.checkDoublePassword();
        var okPasswordComplexity = Validator.checkPassword(password);
        this.setState({errorPassword : !okPasswordComplexity})

        // S'inscrire si pas d'erreur et ok mail/confirmPwd
        if(okConfirmPassword && okPasswordComplexity) {
            this.changePassword();
        }
    }

    changePassword = () => {

        const { password } = this.state;
        const values = queryString.parse(this.props.location.search)
        const code = values.oobCode;

        this.props.firebase.doConfirmPasswordReset(code, password).then(() => {
            this.setState({ok : true})
          })
          .catch(error => {
            this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
          });
    }

    render() {

        const { errorPassword, errorConfirmPassword, error, ok } = this.state;

        return (
            <div className="inscriptionPage karla_text" style={{backgroundColor:"rgba(250, 251, 252, 0.6)"}}>
                <Helmet>
                    <title>Reset son mot de passe</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                <Header />
                <Container maxWidth="xs">
                    { ok &&
                    <Alert severity="success">
                       <p>Votre mot de passe a bien été modifié. Vous pouvez maintenant <Link to="connexion">vous connecter</Link></p>
                    </Alert>
                     }
                    <h4>Indiquez votre nouveau mot de passe</h4>
                    <form onSubmit={(event) => {event.preventDefault(); this.confirm()}}>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <LockIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Mot de passe"
                            onChange={(event) => {this.setState({password : event.target.value})}}
                            value={this.state.password}
                            type="password"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorPassword}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                        Votre mot de passe doit contenir doit contenir au minimum 6 caractères
                        </div>
                    </Fade>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"20px"}}>
                        <LockIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Confirmer le mot de passe"
                            onChange={(event) => {this.setState({confirm_password : event.target.value})}}
                            value={this.state.confirm_password}
                            type="password"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorConfirmPassword}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px" , fontSize:"12px"}}>
                        Votre mot de passe de confirmation est différent de votre mot de passe
                        </div>
                    </Fade>
                    <center style={{paddingBottom:"25px", paddingTop:"25px"}}>
                        <Fade bottom collapse when={error}>
                            <span style={{color:"red", fontSize:"14px",  paddingBottom:"10px",}}>Attention : {error}</span>
                        </Fade>
                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} >Confirmer</button>
                    </center>
                    </form>
                    <center style={{ paddingBottom:"25px"}}>
                        <div style={{width:"100%", height:"1px", borderRadius:"25px", backgroundColor:"gainsboro", marginBottom:"15px"}} />
                        Je souhaite <Link to="connexion">me connecter</Link>
                    </center>
                </Container>
                <MyFooter/>
            </div>
        );
    }
}

export default compose(withRouter, withFirebase)(SetNewPassword);