import React from 'react'
import {  Button, Grid, Paper, InputBase} from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import HealingIcon from '@mui/icons-material/Healing';
import Autocomplete from '@mui/material/Autocomplete';
import './motif.css';

const top100Films = [
  { title: 'Perte de poids', type: "Silhouette" },
  { title: 'Prise de poids', type: "Silhouette" },
  { title: 'Maintien du poids de forme', type: "Silhouette" },
  { title: 'Bien être', type: "Silhouette" },
  { title: 'Intolérances', type: "Santé" },
  { title: 'Allergies', type: "Santé" },
  { title: 'Pathologies métaboliques (diabète, dyslipidémie, pathologie du foie, du pancréas, problème de thyroïde…) ', type: "Santé" },
  { title: 'Maladie cœliaque/Rectocolite hémorragique/Maladie de Crohn', type: "Santé" },
  { title: 'Syndrome de l’intestin ou du côlon irritable', type: "Santé" },
  { title: 'Troubles intestinaux mixtes (diarrhée/constipation)', type: "Santé" },
  { title: 'Alimentation de la femme enceinte', type: "Rééquilibrage alimentaire" },
  { title: 'Alimentation de la femme allaitante', type: "Rééquilibrage alimentaire" },
  { title: 'Alimentation végétarienne, végétalienne, végan, flexitarien ,… ', type: "Rééquilibrage alimentaire" },
  { title: 'Alimentation pédiatrique', type: "Rééquilibrage alimentaire" },
  { title: 'Alimentation gériatrique', type: "Rééquilibrage alimentaire" },
  { title: 'Alimentation du sportif en fonction de ses objectifs', type: "Sport" },
  { title: 'Loisir', type: "Sport" },
  { title: 'Performance/compétition', type: "Sport" },
  { title: 'Sport dans le cadre d’une hygiène de vie saine', type: "Sport" },
];


const getMotifCommentaires = (onMotifChange, onChange, commentary, autreMotif, motif, handleClickContinue, handleClickPrecedent) => {
    return <div className="karla_text">
        <form onSubmit={(e) => { e.preventDefault(); handleClickContinue(); }} >
        <h3>Etape 3 - Motif de la consultation</h3>
        <center>
            
            <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                <HealingIcon style={{padding: "10px"}}  />
                <Autocomplete
                    className="karla_text"
                    options={top100Films}
                    getOptionLabel={(option) => option.title}
                    style={{ width: "100%" }}
                    freeSolo
                    groupBy={(option) => option.type}
                    onInputChange={onChange}
                    onChange={onMotifChange}
                    name="motif"
                    renderInput={(params) => 
                      <div ref={params.InputProps.ref}>
                        <InputBase
                            placeholder="Saisissez votre motif de consultation"
                           
                            type="text"
                            name="motif"
                            className="karla_text"
                            style={{width:"100%"}}
                            {...params.inputProps} 
                        />
                      </div>
                    }
                  />
              </Paper>
        </center>
        <h3>(Optionnel) - Ecrire un commentaire</h3>
        <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                <MessageIcon style={{padding: "10px"}}  />
                <InputBase
                    placeholder="Commentaire, Suggestion, Point particulier, ..."
                    onChange={onChange}
                    value={commentary}
                    type="text"
                    name="commentary"
                    className="karla_text"
                    style={{width:"100%"}}
                />
              </Paper>
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Button color="grey" variant="contained" onClick={handleClickPrecedent} style={{marginTop:"15px"}}  >Précédent</Button>
            </Grid>
            <Grid item>
              {motif && motif !== '' && <Button color="primary" variant="contained" type="submit" style={{marginTop:"15px"}}  >Etape suivante</Button>}
            </Grid>
        </Grid>
        </form>
    </div>
}

export default getMotifCommentaires; 
