import { Avatar,  Grid, Paper} from '@mui/material';
import React, {  } from 'react';
import { compteStatut } from '../../connected/Profile/ProfilePanel';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
//import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

export default function InformationPatient(props) {

    const targetUser = props.targetUser;

    const body = (
        <div className="karla_text">
            <Paper style={{padding:"15px"}} elevation={2}>
                <center>
                    <Avatar 
                        src={targetUser.imageURL ?? "https://www.allsolutionslocksmiths.com.au/wp-content/uploads/2011/07/user.png"} 
                        alt={targetUser.firstname} 
                        style={{width:"125px", height:"125px", cursor:"pointer"}}
                    
                    />
                    <h3 >{targetUser.firstname} {targetUser.name}</h3>
                    <h6 style={{marginTop:"0px"}}><i>{compteStatut(targetUser.level)}</i></h6>
                    <Grid container justifyContent="space-evenly" spacing={3}>
                        <Grid item md={6} style={{width:"100%", paddingBottom:"0px"}}>
                            <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                <PersonIcon style={{padding: "10px"}}  />
                                <div
                                    className="karla_text"
                                    style={{width:"100%", marginLeft:"0", textAlign:"left"}}
                                >
                                    {targetUser.firstname}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item md={6} style={{width:"100%", paddingBottom:"0px"}}>
                            <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                <PeopleIcon style={{padding: "10px"}}  />
                                <div
                                    className="karla_text"
                                    style={{width:"100%", marginLeft:"0", textAlign:"left"}}
                                >
                                    {targetUser.name}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item md={12} style={{width:"100%", paddingBottom:"0px"}}>
                            <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                <MailIcon style={{padding: "10px"}}  />
                                <div
                                    className="karla_text"
                                    style={{width:"100%", marginLeft:"0", textAlign:"left"}}
                                >
                                    {targetUser.email}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item md={12} style={{width:"100%", paddingBottom:"0px"}}>
                            <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                <PhoneIcon style={{padding: "10px"}}  />
                                <div
                                    className="karla_text"
                                    style={{width:"100%", marginLeft:"0", textAlign:"left"}}
                                >
                                    {targetUser.telephone}
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </center>
            </Paper>
        </div>
    );

    return (
        <div>
           {body}
        </div>
        
    )
}