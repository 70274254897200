import { Avatar, Grid} from '@mui/material';
import React, { Component } from 'react';
import MyFooter from '../../../components/Footer';
import Header from '../../../components/Header';
import { DesktopView, MobileView } from '../../../components/Theme';
import { withFirebase } from '../../../helpers';
import { Helmet } from 'react-helmet';
import './newLandingStyle.css';
import { SocialIcon } from 'react-social-icons';
import {Link as ReactLink} from 'react-router-dom';
import { MyCalendar } from '../../connected/Consultation/ConsultationCreationPage';
import VisitezBlogSection from '../../../components/Landing/VisitezBlogSection';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';


class PresentationDiet extends Component {

    componentDidMount() {
        this.props.firebase.analytics.logEvent('dietPresentation_page_view');
    }

    render() {
        return (
            <div style={{backgroundColor:"rgb(250, 251, 252)"}} className="karla_text">
                    <Helmet>
                        <title>Eloïse Feryr - Diététicienne nutritionniste par téléconsultation</title>
                        <meta name="description" content="Compétences, formations et informations sur notre diététicienne. Eloïse Feryr est une passionnée par l’alimentation saine mais savoureuse. Elle vous accueille notamment pour des problèmes de pertes de poids, prises de poids, allergies et Intolérances" />
                        <link rel="canonical" href={"https://food-ediet.com/dieteticienne/eloise-feryr"} />
                        <meta property="og:url" content="https://food-ediet.com/dieteticienne/eloise-feryr" />
                    </Helmet>
                    <Header />
                    <div className="newLanding">
                        <h1 style={{fontSize:"0px"}}>Food-eDiet - Eloïse Feryr diététicienne nutritionniste : consultation en ligne </h1>
                        <div className="container" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"40px"}}>
                            <Grid container style={{width:"100%", margin:"0"}}>
                                <Grid item md={6} style={{width:"100%", margin:"0"}}>
                                    <h2 className="main_h2" style={{margin:"0", paddingBottom:"10px"}}>
                                        Nos diététiciennes
                                    </h2>
                                    <h3 className="main_h3" style={{color:"#ffc47c", margin:"0", paddingBottom:"10px"}}>
                                        Rencontrez Eloïse Feryr, Diététicienne nutritionniste
                                    </h3>
                                    <p style={{paddingBottom:"0px", lineHeight:"2em", color:"#666", fontSize:"15px", margin:"0", marginBottom:"30px"}}>
                                        Passionnée par le domaine vaste de l'alimentation et convaincue que celle-ci joue un rôle primordial pour notre santé physique et psychique, je décide en 2016 de me lancer dans les études de diététique pour en connaitre tous les moindres recoins.
                                        Arrivée en dernière année de diététique, je dois choisir un sujet de fin d’études ; 
                                        mon choix se tourne alors vers la pédiatrie.<br/>
                                        Grâce à ce travail de fin d’études, j’ai acquis de belles compétences dans le domaine de la nutrition de l’enfant. 
                                        Diplômée en 2019 et ayant eu un bon feeling avec les personnes âgées
                                        lors de mon stage en maison de repos/ service de gériatrie à l’hôpital, je me lance alors dans une spécialisation en gériatrie et psychogériatrie. 
                                        C’est ainsi que fin octobre, je décroche mon premier emploi à mi-temps en maison de repos.<br/>
                                        Etant une personne qui aime se former dans divers domaines, je me spécialise actuellement en nutrition du sportif. 
                                        Aujourd’hui, je vous propose mes services de diététique afin de vous aider à atteindre vos objectifs, dans la bonne humeur et surtout dans la bienveillance. 
                                    </p>
                                </Grid>
                                <Grid item md={1} style={{margin:"0"}} />
                                <Grid item md={5} style={{maxWidth:"100%", margin:"0"}}>
                                    <div style={{maxWidth:"100%"}}>
                                        <div style={{
                                            backgroundSize:"cover", 
                                            backgroundImage:"url(/background_dv_01.png)", 
                                            position:"relative", 
                                            backgroundRepeat:"no-repeat",
                                            backgroundPosition:"50%",
                                            textAlign:"center",
                                            marginBottom:"5.82%",
                                            lineHeight:"0",
                                            }}>
                                                <span style={{
                                                    borderRadius: "1000px 1000px 1000px 1000px",
                                                    overflow:"hidden",
                                                    boxShadow:"0px 10px 60px 0px rgb(1 33 92 / 10%)",
                                                    position:"relative",
                                                    maxWidth:"100%",
                                                    display:"inline-block"
                                                }}>
                                                    <img 
                                                        src="/elo_diet_02.jpg"
                                                        alt="eloise-feryr"
                                                        sizes="(max-width: 400px) 100vw, 400px"
                                                        width="400"
                                                        height="400"
                                                        loading="lazy"
                                                        style={{height:"auto", maxWidth:"100%", position:"relative", }}
                                                    >
                                                    </img>
                                                </span>
                                            
                                        </div>
                                    </div>
                                    <Grid container style={{width:"100%", margin:"0", marginBottom:"30px"}} justifyContent="center" spacing={3}>
                                        <Grid item>
                                            <SocialIcon url="https://www.facebook.com/FoodeDiet" style={{ height: 45, width: 45 }} bgColor="#ffc47c" />
                                        </Grid>
                                        <Grid item>
                                            <SocialIcon url="https://www.instagram.com/food_ediet/" style={{ height: 45, width: 45 }} bgColor="#ffc47c" />
                                        </Grid>
                                        <Grid item>
                                            <SocialIcon url="https://www.linkedin.com/in/elo%C3%AFse-feryr-862bb9210/" style={{ height: 45, width: 45 }} bgColor="#ffc47c" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{
                        background:"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI2dnciIHZpZXdCb3g9IjAgMCAxMjgwIDE0MCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmZmZmIj48cGF0aCBkPSJNMTI4MCAxNDBWMFM5OTMuNDYgMTQwIDY0MCAxMzkgMCAwIDAgMHYxNDB6Ii8+PC9nPjwvc3ZnPg==)",
                        backgroundSize:"100% 6vw",
                        height: "6vw", 
                        backgroundColor:"rgb(250, 251, 252)"
                        }}/>
                        <div className="AVIS" style={{backgroundColor:"white"}}>
                            <div style={{
                                backgroundImage:"url(/background_dv_02.png)", 
                                backgroundRepeat:"no-repeat",
                                backgroundPosition:"top right",
                                backgroundSize:"contain"
                            }}>
                            <div className="container" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"10vw", paddingBottom:"40px"}} >
                                <center>
                                    <div style={{width:"100%", height:"2px", backgroundColor:"rgb(230, 230, 230)", }}/>
                                    <p style={{fontWeight:"500", textAlign:"center", lineHeight:"2em", maxWidth:"800px", fontSize:"20px", paddingTop:"25px"}}>
                                        Diététicienne très à l'écoute, je me suis  vraiment senti écouté tout au long du suivi !<br/>
                                        Mon plan alimentaire ainsi que mon suivi sont totalement personnalisés à mes goûts et mes envies.
                                    </p>
                                    <Grid container justifyContent="center" spacing={3}  style={{width:"100%", margin:"0", marginBottom:"25px"}} >
                                        <Grid item xs={12} md={1}>
                                            <Avatar src="/thomas.jpg" style={{height:"60px", width:"60px"}} />
                                        </Grid>
                                        <Grid item xs={12} md={3} container direction="column" justifyContent="center" >
                                            <Grid item style={{textAlign:"left", marginTop:"12px"}}>
                                                <span ><b>Thomas Demarche</b></span>
                                            </Grid>
                                            <Grid item style={{textAlign:"left"}}>
                                                <span >Abonné sur Food-eDiet</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <div style={{width:"100%", height:"2px", backgroundColor:"rgb(230, 230, 230)", }}/>
                                </center>
                            </div>
                            </div>
                        </div>
                        <div id="prendre-rendez-vous" style={{background:"linear-gradient(white, rgb(250, 251, 252))"}}>
                            <div style={{
                                
                            }}>
                                <div  className="container" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"80px", paddingBottom:"40px"}}>
                                    <center>
                                        <h2 className="main_h2" style={{margin:"0", paddingBottom:"10px"}}>Prendre rendez-vous avec Eloïse</h2>
                                        <h3 className="main_h3" style={{color:"#ffc47c", margin:"0", paddingBottom:"50px"}}>
                                            Choisissez une date et commencez votre suivi personnalisé !
                                        </h3>
                                    </center>
                                    <MyCalendar locale="fr-BE"  style={{width:"100%", fontFamily:"'Karla', sans-serif", }}  />
                                    <div style={{paddingTop:"50px", paddingBottom:"45px"}}>
                                        <center><ReactLink to='/inscription' className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative"}}>Je prends rendez-vous !</button></ReactLink></center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="les-articles">
                            <div style={{
                                background:"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI2dnciIHZpZXdCb3g9IjAgMCAxMjgwIDE0MCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmFmYWZhIj48cGF0aCBkPSJNMzIwIDI4QzE2MCAyOCA4MCA0OSAwIDcwVjBoMTI4MHY3MGMtODAgMjEtMTYwIDQyLTMyMCA0Mi0zMjAgMC0zMjAtODQtNjQwLTg0eiIvPjwvZz48L3N2Zz4=)",
                                backgroundColor:"rgb(9, 55, 31)",
                                backgroundSize:"100% 6vw",
                                height: "6vw", 
                            }}/>
                            <div style={{backgroundColor:"rgb(9, 55, 31)", color:"white", paddingBottom:"50px"}}>
                                <div  className="container" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingBottom:"40px"}}>
                                    <h2 className="main_h2" style={{margin:"0", paddingBottom:"10px", paddingLeft:"40px"}}>Découvrir les derniers articles de Eloïse</h2>
                                    <DesktopView>
                                        <VisitezBlogSection onlyArticle />
                                    </DesktopView>
                                    <MobileView>
                                    <VisitezBlogSection onlyArticle gsm />
                                    </MobileView>
                                </div>
                            </div>
                        </div>
                        <div style={{backgroundColor:"rgb(250, 251, 252)", paddingTop:"50px", paddingBottom:"50px"}}>
                        <DesktopView>
                        <div 
                            id="row" 
                            style={{
                                paddingRight:"20px", 
                                paddingLeft:"20px", 
                                paddingBottom:"0px",
                                maxWidth:"1220px", 
                                boxShadow:"0px 10px 60px 0px rgb(1 33 92 / 10%)", 
                                marginLeft:"auto",
                                marginRight:"auto",
                                marginTop:"60px",
                                marginBottom:"60px",
                                backgroundPosition:"50%",
                                backgroundSize:"Cover",
                                backgroundRepeat:"no-repeat",
                                //backgroundColor:"#f9f9f4",
                                paddingTop:"54px",
                                position: "relative",
                            }}>
                                <div
                                    id="cadre"
                                    style={{
                                        paddingTop:"100px",
                                        paddingLeft:"110px",
                                        paddingRight:"110px",
                                        //width: "100%",
                                        maxWidth:"100%",
                                        backgroundSize:"contain",
                                        backgroundPosition:"top left",
                                        backgroundImage:"url(https://www.elegantthemes.com/layouts/wp-content/uploads/2018/09/nutritionist-15.png)",
                                        //paddingBottom:"27px", //only screen 1350
                                        backgroundRepeat:"no-repeat",
                                        margin:"0",
                                        position: "relative",
                                        

                                    }}>
                                        <div 
                                            id="cadre_textInterieur"
                                            style={{
                                                margin:"0",
                                                width:"100%",
                                                backgroundRepeat:"no-repeat",
                                            }}
                                        >
                                            <div 
                                                id="textInterieur"
                                                style={{
                                                    marginBottom:"2.75%",
                                                    marginLeft:"auto",
                                                    marginRight:"auto",
                                                    //backgroundColor:"#f9f9f4",
                                                    maxWidth:"700px",
                                                    textAlign:"center",
                                                    backgroundRepeat: "no-repeat",
                                                    position: "relative",
                                                }}
                                            >
                                                <div>
                                                    <h3 style={{fontWeight:"700", fontSize:"30px", lineHeight:"1.6em", margin:"0", paddingBottom:"10px"}}>Soyez suivi par Eloïse !</h3>
                                                    <h4 style={{fontWeight:"700", color:"#09371F", lineHeight:"1.8em", margin:"0", paddingBottom:"10px"}}>Contactez-nous pour plus de renseignements</h4>
                                                    <Grid container>
                                                        <Grid item xs={6} container justifyContent="center" spacing={3}  style={{margin:"0", marginBottom:"25px", marginTop:"35px"}} >
                                                            <Grid item xs={12} md={2}>
                                                                <Avatar style={{height:"40px", width:"40px", backgroundColor:"rgb(255, 196, 124)"}}>
                                                                <MailIcon  />
                                                                </Avatar>
                                                            </Grid>
                                                            <Grid item xs={12} md={3} container direction="column" justifyContent="center" >
                                                                <Grid item style={{textAlign:"left", marginTop:"12px"}}>
                                                                    <span ><b>Mail</b></span>
                                                                </Grid>
                                                                <Grid item style={{textAlign:"left"}}>
                                                                    <span >eloiseferyr@gmail.com</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={6} container justifyContent="center" spacing={3}  style={{margin:"0", marginBottom:"25px", marginTop:"35px"}} >
                                                            <Grid item xs={12} md={2}>
                                                                <Avatar style={{height:"40px", width:"40px", backgroundColor:"rgb(183, 221, 159)"}}>
                                                                <CallIcon  />
                                                                </Avatar>
                                                            </Grid>
                                                            <Grid item xs={12} md={3} container direction="column" justifyContent="center" >
                                                                <Grid item style={{textAlign:"left", marginTop:"12px"}}>
                                                                    <span ><b>Téléphonne</b></span>
                                                                </Grid>
                                                                <Grid item style={{textAlign:"left"}}>
                                                                    <span >+32472017623</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{paddingTop:"50px", paddingBottom:"45px"}}>
                                                        <center><ReactLink to='/inscription' className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative"}}>Réserver une consultation</button></ReactLink></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                        </div>
                    </DesktopView>
                        </div>
                    </div>
                    
                    <MyFooter/>
            </div>
        );
    }
}

export default withFirebase(PresentationDiet);
