import React, { Component } from 'react'
import { Avatar,  Grid, Paper, IconButton } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { Helmet } from 'react-helmet';
import { DesktopView, MobileView } from '../../../components/Theme';
import { BackgroundDesktopPage, BackgroundMobilePage } from '../../connected/Home';
import { withAuthorization } from '../../../components/Session';
import KitchenIcon from '@mui/icons-material/Kitchen';
import CreateArticle from './CreateArticle';
import { DocType, SaveType } from './helpers';
import CreateRecette from './CreateRecette';
import {  doc, query, onSnapshot } from "firebase/firestore";



class CreateDocument extends Component {
    
    state = {
        docType: "",
        document: null,
    }

    componentDidMount() {
        const { authUser } = this.props;

        if(authUser && authUser.data && authUser.data.level >= 4) {
            if(this.props.match.params.uid) {
                this.unsub = onSnapshot(
                    query(
                      doc(this.props.firebase.firestore, "articles", this.props.match.params.uid)
                    ),
                    (snap) => {
                        var item = snap.data()
                        this.setState({ document : item, docType: item.type ?? SaveType.Publish })
                    },
                    (error) => {
                      console.error(error)
                      this.setState({  loadingUser : false });
                    }
                  );
            }
        }
        else {
            this.props.navigate("/")
        }
    }

    render() {

        const { docType } = this.state;

        const bodyNew = (
            <div className="karla_text">
                <h2>Création d'un article</h2>
                <p>Le document créé sera enregistré dans vos brouillons. Il apparaitra sur food-ediet lorsque vous aurez l'aurez "publié"</p>
                <Grid container alignContent="center" spacing={3}>
                    <Grid item xs={12} md={3}>
                        <Paper style={{boxShadow:"rgb(1 33 92 / 10%) 0px 10px 60px 0px", padding:"25px"}}>
                            <center>
                                <div style={{}}>
                                    <h4 className="main_h4" >Créer un nouvel article</h4>
                                </div>
                                <IconButton onClick={() => { this.setState({docType: DocType.Article}) }}>
                                    <Avatar  style={{
                                            boxShadow: "rgb(36 89 208 / 47%) 0px 10px 20px 0px",
                                            backgroundColor:"rgb(167, 198, 255)",
                                            margin:"auto",
                                            width:"80px",
                                            height:"80px"
                                            }}>
                                        <DescriptionIcon style={{height:"45px", width:"45px"}} />
                                    </Avatar>
                                </IconButton>
                            </center>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper style={{boxShadow:"rgb(1 33 92 / 10%) 0px 10px 60px 0px", padding:"25px"}}>
                            <center>
                                <div style={{}}>
                                    <h4 className="main_h4" >Créer une nouvelle recette</h4>
                                </div>
                                <IconButton  onClick={() => { this.setState({docType: DocType.Recette}) }}>
                                    <Avatar  style={{
                                            boxShadow: "rgb(208 203 36 / 68%) 0px 10px 20px 0px",
                                            width: "80px",
                                            backgroundColor:"rgb(245 232 85)",
                                            margin:"auto",
                                            height:"80px",
                                            }}>
                                        <KitchenIcon style={{height:"45px", width:"45px"}} />
                                    </Avatar>
                                </IconButton>
                            </center>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );

        return (
            <div>
                <Helmet>
                    <title>Créer un article</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                 <DesktopView >
                    <BackgroundDesktopPage {...this.props}>
                        { docType === "" && bodyNew} 
                        <div >
                            { docType === DocType.Article && <CreateArticle {...this.props} document={this.state.document} />}
                            { docType === DocType.Recette && <CreateRecette {...this.props} document={this.state.document} />}
                        </div>
                    </BackgroundDesktopPage>
                </DesktopView>
                <MobileView >
                    <BackgroundMobilePage {...this.props}>
                        { docType === "" && bodyNew} 
                        <div style={{padding: "15px", maxWidth:"1080px"}}>
                            { docType === DocType.Article && <CreateArticle {...this.props} document={this.state.document} />}
                            { docType === DocType.Recette && <CreateRecette {...this.props} document={this.state.document} />}
                        </div>
                        
                    </BackgroundMobilePage>
                </MobileView>
            </div>
        )
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(CreateDocument);


