import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Component } from 'react';
import { withFirebase } from '../../../../helpers';
import { MyLabel } from '../../Document/DocumentPanel';
import './mespaiements.css';

class MesPaiements extends Component {

    state =  {
        paiements : []
    }

    componentDidMount() {
        this.getPaiementMethod();
    }

    getPaiementMethod = async () => {
        if(!this.props.authUser.customer.stripeId) {
            this.setState({loading: false })
            return
        }
        var getCustomerPaiementsDone = this.props.firebase.functions.httpsCallable(process.env.NODE_ENV === 'production' ? 'getCustomerPaiementsDone' : 'getCustomerPaiementsDoneTest');
        
        const myresult = await getCustomerPaiementsDone({customerId: this.props.authUser.customer.stripeId});
        this.setState({paiements : myresult.data ? myresult.data.data : null})
       
    };

    getLabelStatus = (paiement) => {

        if(paiement.status === "succeeded") {
            return {
                statut : 'Paiement réussi',
                color : "green"
            }
        }
        else if(paiement.status === "pending") {
            return {
                statut : 'Paiement en cours',
                color : "orange"
            }
        }
        else if(paiement.status === "failed") {
            return {
                statut : 'Paiement échoué',
                color : "red"
            }
        }
        else {
            return {
                statut : 'Erreur',
                color : "grey"
            }
        }
    }


    render() {
        const { paiements } = this.state;

        const docs = Object
        .keys(paiements)
        .map(key => (
            <TableRow key={key}>
                <TableCell>{(paiements[key].amount ?? 0) / 100}€</TableCell>
                <TableCell >{new Date(paiements[key].created * 1000).toLocaleDateString('fr-BE', options)}</TableCell>
                <TableCell><MyLabel  style={{maxWidth:"120px", margin:"0"}} color={this.getLabelStatus(paiements[key]).color}>{this.getLabelStatus(paiements[key]).statut}</MyLabel></TableCell>
            </TableRow>
    ))
    

        return (
            <div className="mespaiements" >
                <Container maxWidth="md" style={{marginLeft:"0"}} disableGutters>
                <Paper elevation={3}>
                    <TableContainer >
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell ><b>Montant</b></TableCell>
                                    <TableCell ><b>Date</b></TableCell>
                                    <TableCell ><b>Statut</b></TableCell>
                                </TableRow>                           
                            </TableHead>
                            <TableBody>
                                {docs.length === 0 && <TableRow >
                                    <TableCell >Pas de paiements disponibles!</TableCell>
                                </TableRow>}
                                { docs }      
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                </Paper>
                </Container>
            </div>
        )
    }
}

export default withFirebase(MesPaiements);

const options = {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };