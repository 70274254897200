import React from 'react';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import EuroIcon from '@mui/icons-material/Euro';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import HealingIcon from '@mui/icons-material/Healing';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { ListItem, ListItemIcon, ListItemText, Divider, Grid, Button} from '@mui/material';
import Alert from '@mui/material/Alert';
import { getConsultationType } from './moyenDePaiement';
import CircularIntegration from '../../../../components/Theme/CircularLoading';



const Resume = (props) => {
    const { slot, keySlot, confirmReservation, motif, commentary, handleClickPrecedent, authUser } = props;

    const createListItem = (icon, title, value) => (
        <ListItem>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>
                <Grid
                container
                direction="column"
                justifyContent="left"
                alignItems="left"
                >
                    <Grid item>
                        <h5 style={{marginBottom:"5px", marginTop:"5px", fontFamily:"'Karla', sans-serif"}}>{title}</h5>
                    </Grid>
                    <Grid item>
                        <span style={{fontFamily:"'Karla', sans-serif"}}>{value}</span>
                    </Grid>
                </Grid>
            </ListItemText>
        </ListItem>
    );

    return <div>
        <h3>Etape 5 - Résumé de la consultation</h3>
        <Alert severity="warning" style={{marginBottom:"10px"}} className="karla_text">
            En confirmant, vous acceptez les <a href="https://food-ediet.com/conditions-utilisation" target="_blank" rel="noopener noreferrer">conditions d'utilisation</a> de Food-eDiet.
        </Alert>
        <div style={{height: 'auto', border: "1px solid #a0a096", width:'100%', borderRadius:"10px"}}>
        {createListItem(<EventIcon/>, "Date et heure", new Date(slot.slots[keySlot].heure).toLocaleDateString('fr-BE', option))}
        <Divider/>
        {createListItem(<AccountBoxIcon/>, "Patient", authUser.data.firstname + " " + authUser.data.name + " (" + authUser.data.email + ")")}
        <Divider/>
        {createListItem(<GroupIcon/>, "Diététicienne", "Eloïse Feryr")}
        <Divider/>
        {createListItem(<EuroIcon/>, "Type de consultation", getConsultationType(authUser).title + " - " + getConsultationType(authUser).prix)}
        <Divider/>
        {createListItem(<HealingIcon/>, "Motif", motif)}
        <Divider/>
        {createListItem(<SpeakerNotesIcon/>, "Commentaire", commentary !== "" ? commentary : "/")}
        </div>
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
                <Button color="grey" variant="contained" onClick={handleClickPrecedent} style={{marginTop:"15px"}}  >Précédent</Button>
            </Grid>
            <Grid item>
                <CircularIntegration type="input" text={"Procéder au paiement"} onClick={confirmReservation}  style={{marginTop:"15px"}}  />
            </Grid>
        </Grid>
    </div>
}

export default Resume;

const option = {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};
