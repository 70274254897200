import React from 'react';
import AuthUserContext from './context';
import { compose } from 'recompose';
import { withFirebase } from '../../helpers';
import { withRouter } from '../../App';



const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
      componentDidMount() {
        this.listener = this.props.firebase.onAuthUserListener(
          authUser => {
            if (!condition(authUser)) {
              this.props.navigate("/");
            }
          },
          () => {
            if(!condition(null))
            this.props.navigate({
              pathname: '/connexion',
              state: { redirect: this.props.location.pathname }
            });
          },
        );
      }
  
      componentWillUnmount() {
        this.listener();
      }
  
      render() {
        return (
          <AuthUserContext.Consumer>
            {authUser =>
              condition(authUser) ? <Component {...this.props} authUser={authUser} /> : null
            }
          </AuthUserContext.Consumer>
        );
      }
    }
  
    return compose(
      withRouter,
      withFirebase,
    )(WithAuthorization);
  };
  
  export default withAuthorization;