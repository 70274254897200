import React, { Component } from 'react'
import { withAuthorization } from '../../../../components/Session';
import { BackgroundDesktopPage, BackgroundMobilePage } from '../../Home';
import { DesktopView, MobileView, MyColor } from '../../../../components/Theme';
import { Avatar, Container, Divider, Grid, IconButton, InputBase, Paper, Tooltip } from '@mui/material';
import { compteStatut } from '../ProfilePanel';
import Fade from 'react-reveal/Fade';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import Alert from '@mui/material/Alert';
//import FileUploader from 'react-firebase-file-uploader/lib';
import Validator from '../../../../utils/Validator';
import { Helmet } from 'react-helmet';
//import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


const Initial_State = {
    firstname:"",
    name:"",
    email:"",

    errorFirstname:"",
    errorName:"",

    editPrenom:false,
    editName:false,

    ok:false
}


class ProfilePage extends Component {
    
    state = {
        ...Initial_State
    }

    confirm = () => {

        const { firstname, name, email, ok } = this.state;
        var user = {}

        if(ok)
            this.setState({ok: false})

        if(firstname && Validator.checkInput(firstname)) 
            user["firstname"] = firstname;
        if(name && Validator.checkInput(name))
            user["name"] = name;
        if(email)
            user["email"] = email;

        if(Object.keys(user).length !== 0) {
            this.updateUser(user);
        }
    }

    handleChangeInputFile = (e) => {
        if(!e.target.files)
            return
        this.submitUpload.click()
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const file = e.target[0]?.files[0]
    
        if (!file) return;
    
        const storageRef = ref(this.props.firebase.storage, "documents/" + this.props.authUser.data.id + "/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(progress)
            //setProgresspercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                var user = {imageURL:url}
                this.updateUser(user);
            });
          }
        );
      }


    updateUser = (user) => {

        const { authUser } = this.props;
      
        this.props.firebase.firestore.collection("users").doc(authUser.data.uid).update(user)
        .then(() => {
            this.setState({ ...Initial_State, ok:true });
        })
        .catch(error => {
            this.setState({ error });
        });
            
    }

    render() {

        const { authUser } = this.props;
        const {  firstname, errorFirstname, name, errorName, email, ok } = this.state;
        

        const body = (
            <div className="karla_text">
                <Container maxWidth="md" style={{marginLeft:"0"}} disableGutters>
                    { ok &&
                    <Alert severity="success">
                       <p className="karla_text">Votre profil a correctement été mis à jour</p>
                    </Alert>
                     }
                    <h2 style={{color:MyColor.PRIMARY_GREEN}}>Mon profil</h2>
                    <Paper style={{padding:"15px"}} elevation={2}>
                        <center>
                            <Tooltip title="Changer sa photo de profil" placement="top">
                                <IconButton  onClick={() => this.fileUpload.click()}>
                                    <Avatar 
                                        src={authUser.data.imageURL ?? "https://www.allsolutionslocksmiths.com.au/wp-content/uploads/2011/07/user.png"} 
                                        alt={authUser.data.firstname} 
                                        style={{width:"125px", height:"125px", cursor:"pointer"}}
                                    
                                    />
                                    </IconButton>
                            </Tooltip>
                            <h3 >{authUser.data.firstname} {authUser.data.name}</h3>
                            <h6 style={{marginTop:"0px"}}><i>{compteStatut(authUser.data.level)}</i></h6>
                            <Grid container justifyContent="space-evenly" spacing={3}>
                                <Grid item md={6} style={{width:"100%", paddingBottom:"0px"}}>
                                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                        <PersonIcon style={{padding: "10px"}}  />
                                        {this.state.editPrenom ? <InputBase
                                            placeholder="Entrez votre prénom"
                                            onChange={(event) => {this.setState({firstname : event.target.value})}}
                                            value={firstname}
                                            type="text"
                                            autoFocus
                                            className="karla_text"
                                            style={{width:"100%"}}
                                        /> : <div
                                            className="karla_text"
                                            style={{width:"100%", marginLeft:"0", textAlign:"left"}}
                                        >
                                            {authUser.data.firstname}
                                        </div>}
                                        <Divider orientation="vertical"/>
                                        <IconButton disabled={this.state.editPrenom} onClick={() => {this.setState({editPrenom:true})}} >
                                        <EditIcon/>
                                        </IconButton>
                                    </Paper>
                                    <Fade bottom collapse when={errorFirstname}>
                                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire
                                        </div>
                                    </Fade>
                                </Grid>
                                <Grid item md={6} style={{width:"100%", paddingBottom:"0px"}}>
                                <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                        <PeopleIcon style={{padding: "10px"}}  />
                                        {this.state.editName ? <InputBase
                                            placeholder="Entrez votre nom"
                                            onChange={(event) => {this.setState({name : event.target.value})}}
                                            value={name}
                                            type="text"
                                            autoFocus
                                            className="karla_text"
                                            style={{width:"100%"}}
                                        /> : <div
                                            className="karla_text"
                                            style={{width:"100%", marginLeft:"0", textAlign:"left"}}
                                        >
                                            {authUser.data.name}
                                        </div>}
                                        <Divider orientation="vertical"/>
                                            <IconButton disabled={this.state.editName} onClick={() => {this.setState({editName:true})}}  >
                                            <EditIcon/>
                                            </IconButton>
                                    </Paper>
                                    <Fade bottom collapse when={errorName}>
                                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire
                                        </div>
                                    </Fade>
                                </Grid>
                                <Grid item md={12} style={{width:"100%", paddingBottom:"0px"}}>
                                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                        <MailIcon style={{padding: "10px"}}  />
                                        {this.state.editEmail ? <InputBase
                                            placeholder="Entrez votre adresse mail"
                                            onChange={(event) => {this.setState({email : event.target.value})}}
                                            value={email}
                                            type="mail"
                                            autoFocus
                                            className="karla_text"
                                            style={{width:"100%"}}
                                        /> : <div
                                            className="karla_text"
                                            style={{width:"100%", marginLeft:"0", textAlign:"left"}}
                                        >
                                            {authUser.data.email}
                                        </div>}
                                        <Divider orientation="vertical"/>
                                    </Paper>
                                </Grid>
                                {/*
                                <Grid item md={6} style={{width:"100%", paddingBottom:"0px"}}>
                                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                        <MailIcon style={{padding: "10px"}}  />
                                        {this.state.editPays ?  <Autocomplete
                                            className="karla_text"
                                            options={top100Films}
                                            getOptionLabel={(option) => option.countryName}
                                            style={{ width: "100%" }}
                                            groupBy={(option) => option.type}
                                            onChange={(e, value) => this.setState({
                                                pays: value.title
                                            })}
                                            name="motif"
                                            renderInput={(params) => 
                                            <div ref={params.InputProps.ref}>
                                                <InputBase
                                                    placeholder="Choisissez un pays"
                                                
                                                    type="text"
                                                    name="motif"
                                                    className="karla_text"
                                                    style={{width:"100%"}}
                                                    {...params.inputProps} 
                                                />
                                            </div>
                                            }
                                        />
                                        : <div
                                            className="karla_text"
                                            style={{width:"100%", marginLeft:"0", textAlign:"left"}}
                                        >
                                            {authUser.data.pays}
                                        </div>}
                                        <Divider orientation="vertical"/>
                                        <IconButton disabled={this.state.editName} onClick={() => {this.setState({editPays:true})}}  >
                                        <EditIcon/>
                                        </IconButton>
                                    </Paper>
                                </Grid>
                                        */}
                            </Grid>
                            <button type="button" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px", maxWidth:"300px", marginTop:"25px"}} onClick={this.confirm}>Modifier</button>
                        </center>
                        <form  onSubmit={this.handleSubmit} className='form' style={{ visibility: 'hidden', width:0, height:0}}>
                            <input type='file' ref={(fileUpload) => {this.fileUpload = fileUpload;}} onChange={this.handleChangeInputFile} />
                            <button type='submit' ref={(submitUpload) => {this.submitUpload = submitUpload;}} >Confirmer</button>
                        </form>
                    </Paper>
                </Container>
            </div>
        );

        return (
            <div>
                <Helmet>
                    <title>Mon profil</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                 <DesktopView >
                    <BackgroundDesktopPage {...this.props}>
                        {body}
                    </BackgroundDesktopPage>
                </DesktopView>
                <MobileView >
                    <BackgroundMobilePage {...this.props}>
                        {body}
                    </BackgroundMobilePage>
                </MobileView>
            </div>
        )
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProfilePage);
