import { Grid } from '@mui/material';
import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { LOGO_BLACK } from '../../../utils/Settings';
import { LinkBehavior } from '../../Theme/LinkBehaviour';

class NavBarSecondaire extends Component {
    constructor(props) {
        super(props);
        this.onScroll = this.onScroll.bind(this);
        
      }
      

    anchorRef = createRef();

    state = {
        stick:true
    }

    componentDidMount() {
        //window.addEventListener('scroll', this.onScroll);
    }

    onScroll(event) {
        const ttt  = this.anchorRef.current.getBoundingClientRect().top;
        if(ttt < 10) {
            if(!this.state.stick) {
                this.setState({stick:true})
            }
        }
        else {
            if(this.state.stick) {
                this.setState({stick:false})
            }
        }
    }

    render() {
        const { stick} = this.state;


        return (
                <nav style={stick ? 
                    { backgroundColor:"rgb(255,248,231)", position:"sticky",top:"0", left:"0", zIndex:"500", minHeight:"105px", width:"100%" } : 
                    { backgroundColor:"rgb(255,248,231)", position:"absolute", bottom:"0", left:"0", zIndex:"500", minHeight:"105px", width:"100%" }}  
                    ref={this.anchorRef}
                >
                    <Grid container justifyContent="space-between" alignItems="center" style={{width:"100%", paddingLeft:"20px", paddingRight:"5px"}}>
                        <Grid item xs={2}>
                            <LinkBehavior to="/"> <img src={LOGO_BLACK} alt="logo foodediet" width="75px" height="61px" style={{cursor: "pointer", paddingRight:"100px", paddingBottom:"15px"}}/></LinkBehavior>
                        </Grid>
                        <Grid item xs={7}>
                            <center>
                            <ul className="ulNav">
                                <li className="liNav"><a className="karla_text" href="#accompagnement">Notre accompagnement</a><div className="barspec"/></li>
                                <li className="liNav"><a className="karla_text" href="#quelque-soit-votre-objectif">Votre objectif</a><div className="barspec"/></li>
                                <li className="liNav"><a className="karla_text" href="#diets">Nos équipes</a><div className="barspec"/></li>
                                <li className="liNav"><a className="karla_text" href="#nous-contacter">Nous contacter</a><div className="barspec"/></li>
                                <li className="liNav"><a className="karla_text" href="#blog">Blog</a><div className="barspec"/></li>
                            </ul>
                            </center>
                        </Grid>
                        <Grid  item xs={3} >
                            <Link to="/inscription" className="nolink">
                            <button type="button" className="circular-espace-perso-btn" style={{width:"200px", height:"auto",}} >
                                Je m'inscris gratuitement
                            </button>
                            </Link>
                        </Grid>
                    </Grid>
                </nav>
        );
    }
}

export default NavBarSecondaire;