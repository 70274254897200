import { Container } from '@mui/material'
import React, { Component } from 'react'
import './landing.css'
import Footer from '../Footer'
import Header from '../Header';
import { DesktopView, MobileView } from '../Theme';
import EnTeteSection from './EnTeteSection';
import ObjectifsSection from './ObjectifsSection';
import AccompagnementSection from './AccompagnementSection';
import NosDietsSection from './NosDietsSection';
import VisitezBlogSection from './VisitezBlogSection';
import NavBarSecondaire from './NavBarSecondaire';
import { withFirebase } from '../../helpers';
import { Helmet } from 'react-helmet';
import PrendreRendezVous from './PrendreRendezVous';
import Newsletter from './Newsletter';
import EnCars from './EnCars';

class LandingPage extends Component {


    componentDidMount() {
        this.props.firebase.analytics.logEvent('landing_page_view');
    }

    render(){
        return(
            <div name="main_container_landing" >
                <Helmet>
                    <title>Food-eDiet - cabinet diététique en ligne</title>
                    <link rel="canonical" href="https://food-ediet.com/" />
                    <meta name="description" content="Cabinet diététique en ligne par téléconsultation. Choisissez la date de votre rendez-vous et laissez-vous accompagner par une diététicienne à votre écoute pour arriver au bout de vos objectifs" />
                </Helmet>
                <h1 style={{fontSize:"0px"}}>Food-ediet : Cabinet diététique en ligne par téléconsultation</h1>
                <DesktopView>
                    <Header/>
                    <EnTeteSection />
                    <NavBarSecondaire />
                    <AccompagnementSection />
                    <EnCars/>
                    <ObjectifsSection />
                    <PrendreRendezVous/>
                    <NosDietsSection />
                    <Newsletter />
                    <VisitezBlogSection />
                    <Footer />
                </DesktopView>
                <MobileView>
                    <Container maxWidth="xs" style={{padding:0}}>
                        <Header/>
                        <EnTeteSection gsm/>
                        <AccompagnementSection gsm/>
                        <ObjectifsSection />
                        <PrendreRendezVous/>
                        <NosDietsSection />
                        <Newsletter />
                        <VisitezBlogSection gsm/>
                        <Footer />
                    </Container>
                </MobileView>
            </div>
        )
    }
}

export default withFirebase(LandingPage);
