import React, { Component } from 'react'
import { Paper } from '@mui/material';
import styled from 'styled-components';
import {Link as ReactLink} from 'react-router-dom'
import { MyColor } from '../../../components/Theme';
import { getVideoId } from '../../../utils/Settings';
import { withRouter } from '../../../App';

class AgendaPanel extends Component {

    state = {
        visible: true,
    }

    componentDidMount() {
        console.log(this.props.authUser.consultations)
    }

    
    render() {

        if(this.props.authUser && this.props.authUser.data) {
            const {authUser, scale} = this.props;
            const consultations = authUser.consultations ? getClosestConsultation(authUser.consultations) : null;
            const oldconsultation = authUser.consultations ? getOldestConsultation(authUser.consultations) : null;

            return (
                <div>
                    <div className="PanelLabel" style={{position:"relative", zIndex:"4999"}}>
                            <div id="PanelLabel background" style={{backgroundColor:MyColor.SECONDARY_GREEN, position:"absolute", top:"10px", left:"-5px", padding:"5px"}}>
                                <span style={{fontSize:"20px", color:"white", paddingLeft:"25px", paddingRight:"10px", fontFamily:"'Karla', sans-serif" }}>Consultations</span>
                            </div>
                    </div>
                    <MyPaper visible={this.state.visible} elevation={3}>
                        <div >
                            <div className="diagram-consultation" 
                                style={{position:"relative", display:"inline-block", width:"100%", marginTop:"40px", transform: scale ? scale : ""}}>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% - 220px) ", width:"95px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% - 115px)", width:"5px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% - 105px)", width:"5px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% - 95px)", width:"5px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% - 85px)", width:"5px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% - 70px)", width:"150px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"50px", left:"calc(50% - 70px)", width:"5px", borderTop: "18px solid grey", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"50px", left:"calc(50% + 75px)", width:"5px", borderTop: "18px solid grey", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% + 90px)", width:"5px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% + 100px)", width:"5px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% + 110px)", width:"5px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% + 120px)", width:"5px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <div  style={{position:"absolute", top:"55px", left:"calc(50% + 130px)", width:"95px", borderTop: "8px solid rgb(188,224,138)", borderRadius: "5px"}}></div>
                                <i    style={{position:"absolute", top:"50px", left:"calc(50% + 220px)", borderTop: "10px solid transparent",borderBottom: "10px solid transparent", borderLeft: "18px solid rgb(188,224,138)" }}></i>
                                <span style={{position:"absolute", top:"30px", left:"calc(50% - 198px)", fontSize:"14px", color:"grey" }}>Passées</span>
                                <div style={{position:"absolute", top:"30px", left:"calc(50% + 135px)", fontSize:"14px", color:"grey", width:"80px"}}><center>A venir</center></div>
                                <span style={{position:"absolute", top:"10px", left:"calc(50% - 35px)", fontSize:"14px", color:"grey" }}><center>Prochain<br/>Rendez-vous</center></span>
                                <div style={{position:"absolute", top:"80px", left:"calc(50% - 50px)", fontSize:"14px", color:"grey", width:"110px" }}><center><b>{consultations != null && consultations.length > 0 ? new Date(parseInt(consultations[0].date)).toLocaleDateString('fr-BE', options) : "Pas de date prévue !"}</b></center></div>
                                <i    style={{position:"absolute", top:"65px", left:"calc(50% - 175px)", borderLeft: "2px solid transparent",borderRight: "2px solid transparent", borderBottom: "18px solid orange" }}></i>
                                <span    style={{position:"absolute", top:"80px", left:"calc(50% - 188px)", height:"30px", width:"30px", borderRadius:"50%", backgroundColor:"orange", paddingTop:"2px"}}><center><span style={{color:"white", paddingTop:"5px", fontSize:"16px" }}>{oldconsultation != null ? oldconsultation.length : 0}</span></center></span>
                                <i    style={{position:"absolute", top:"65px", left:"calc(50% + 175px)", borderLeft: "2px solid transparent",borderRight: "2px solid transparent", borderBottom: "18px solid orange" }}></i>
                                <span    style={{position:"absolute", top:"80px", left:"calc(50% + 162px)", height:"30px", width:"30px", borderRadius:"50%", backgroundColor:"orange", paddingTop:"2px" }}><center><span style={{color:"white", paddingTop:"5px",fontSize:'16px' }}>{consultations != null ?  consultations.length : 0}</span></center></span>
                                <ReactLink to='/home/consultation-create' className="nolink"> <div  
                                    style={{position:"absolute", top:"125px", left:"calc(50% + 143px)",   cursor:"pointer" }}
                                    
                                >
                                    <span className="circular-inscription-btn" style={{ fontSize:"0.80em", fontFamily:"'Karla', sans-serif", color:"white", padding:"5px 10px 5px 10px", width:"auto"  }}>Réserver</span>
                                </div>
                                </ReactLink>
                                {
                                    consultations != null && consultations.length > 0 ? 
                                    <ReactLink to={'/meeting/' + getVideoId(consultations[0])}><div style={{position:"absolute", top:"125px", left:"calc(50% - 80px)",   cursor:"pointer" }}>
                                        <span style={{ fontSize:"14px", fontFamily:"'Karla', sans-serif", color:"white", backgroundColor:"orange", padding:"3px", }}>Rejoindre ma consultation</span>
                                    </div></ReactLink>
                                    : <div/>
                                }
                                
                            </div>
                        </div>
                    </MyPaper>
                </div>
            )
        }
        else {
            return <div/>
        }
    }
}

export default withRouter(AgendaPanel);

export function getClosestConsultation(consultations) {
    return consultations.filter(element => element.status === "0" && ((parseInt(element.date ??"0") ) +  (60*60*1000)) > Date.now())
}

export function getOldestConsultation(consultations) {
    return consultations.filter(element => element.status === 1)
}

const options = { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };


export const MyPaper = styled(Paper)`
    height: 200px;
    transition: 0.5s;
    opacity: ${props => props.visible ? 1 : 0};
    visibility: ${props => props.visible ? "visible" : "hidden"};
    
`;
