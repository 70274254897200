import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuItem } from '@mui/material';
import { Component } from 'react';


const SimpleDialog = (props) => {
    return <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogTitle id="alert-dialog-title">{props.head}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.body}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={props.handleClose} color="primary">
                Annuler
            </Button>
            <Button onClick={props.handleConfirm} color="primary" autoFocus>
                Confirmer
            </Button>
            </DialogActions>
        </Dialog>
}

class MyDialog extends Component {

    state = {
        open:false
    }

    handleClickOpen = () => {
        this.setState({open: true})
      };
    
    handleClose = () => {
        this.setState({open: false})
      };

    render() {

        const { head, children, confirm } = this.props;
        return( 
            <Dialog open={this.state.open} onClose={this.handleClose} >
                <DialogTitle>{head}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    {children}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={confirm} color="primary" autoFocus>
                    Confirmer
                </Button>
                </DialogActions>
            </Dialog>)
    }
    
}
export { MyDialog };

class MyModal extends Component {

    state = {
        open:false
    }

    handleClickOpen = () => {
        this.setState({open: true})
      };
    
    handleClose = () => {
        this.setState({open: false})
      };

    render() {
        const { title} = this.props;
        return (
            <div className="karla_text">
                <MenuItem  onClick={this.handleClickOpen} style={{textAlign:"center", justifyContent:"center"}}><div className="karla_text" style={{color:"green", font:"normal normal bold 18px/1.4em"}}>+ Voir plus</div></MenuItem>
                <Dialog open={this.state.open} onClose={this.handleClose} scroll="paper">
                    <DialogTitle className="karla_text">{title}</DialogTitle>
                    <DialogContent dividers>
                    <DialogContentText >
                        {this.props.children}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" className="karla_text">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default SimpleDialog;
export { MyModal };
//() => this.setState({openModalDelete: false})
//Veux-tu vraiment supprimer cet article ? Cette action est définitive
//this.state.openModalDelete