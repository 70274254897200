import React from 'react'
import { Stepper, Step, StepLabel,   } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import clsx from 'clsx';
import TodayIcon from '@mui/icons-material/Today';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import EuroIcon from '@mui/icons-material/Euro';
import styled from 'styled-components';
import { MyColor } from '../../../../components/Theme';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background:
        MyColor.PRIMARY_GREEN,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background:
        MyColor.PRIMARY_GREEN,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    borderTopStyle: "unset",
  },
  }));

const MyStepper = (props) => {

    return (
        <WrapperStepper>
            <Stepper activeStep={props.activeStep} alternativeLabel connector={<QontoConnector />} >
                <Step>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>Choix d'une date et d'une heure</StepLabel>
                </Step>
                <Step >
                    <StepLabel StepIconComponent={ColorlibStepIcon}>Motif de la consultation et commentaire(s)</StepLabel>
                </Step>
                <Step>
                    <StepLabel StepIconComponent={ColorlibStepIcon} >Choix de la méthode de paiement</StepLabel>
                </Step>
                <Step>
                    <StepLabel StepIconComponent={ColorlibStepIcon} >Confirmation de la consultation et paiement</StepLabel>
                </Step>
            </Stepper>
        </WrapperStepper>
    )
}

const MyStepperMobile = (props) => {

  return (
      <WrapperStepper>
          <Stepper activeStep={props.activeStep}orientation="vertical">
              <Step>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>Choix d'une date et d'une heure</StepLabel>
              </Step>
              <Step >
                  <StepLabel StepIconComponent={ColorlibStepIcon}>Motif de la consultation et commentaire(s)</StepLabel>
              </Step>
              <Step>
                  <StepLabel StepIconComponent={ColorlibStepIcon} >Choix de la méthode de paiement</StepLabel>
              </Step>
              <Step>
                  <StepLabel StepIconComponent={ColorlibStepIcon} >Confirmation de la consultation et paiement</StepLabel>
              </Step>
          </Stepper>
      </WrapperStepper>
  )
}



export default MyStepper;
export {MyStepperMobile};

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
  
    const icons = {
      1: <TodayIcon />,
      2: <SpeakerNotesIcon />,
      3: <EuroIcon />,
      4: <CheckCircleIcon />,
    };
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
}

const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      background:MyColor.PRIMARY_GREEN,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      background:MyColor.PRIMARY_GREEN
    },
});

const WrapperStepper = styled.div`
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    
`;