import React, { Component } from 'react';
import MyFooter from '../../../components/Footer';
import Header from '../../../components/Header';
import { withFirebase } from '../../../helpers';
import { Helmet } from 'react-helmet';
import {Link as ReactLink} from 'react-router-dom';
import { MyCalendar } from '../../connected/Consultation/ConsultationCreationPage';
import {  Grid } from '@mui/material';


class PourquoiFoodeDiet extends Component {

    componentDidMount() {
        this.props.firebase.analytics.logEvent('pourquoi_food-ediet_page_view');
    }

    render() {
        return (
            <div style={{backgroundColor:"rgb(250, 251, 252)"}} className="karla_text">
                    <Helmet>
                        <title>Téléconsultation diététique avec Food-eDiet</title>
                        <meta name="description" content="Compétences, formations et informations sur notre diététicienne. Eloïse Feryr est une passionnée par l’alimentation saine mais savoureuse. Elle vous accueille notamment pour des problèmes de pertes de poids, prises de poids, allergies et Intolérances" />
                        <link rel="canonical" href={"https://food-ediet.com/pourquoi-food-ediet"} />
                        <meta property="og:url" content="https://food-ediet.com/pourquoi-food-ediet" />
                    </Helmet>
                    <Header />
                    <div className="newLanding">
                        
                        <div className="container" >
                            <center><h1 className="main_h2" >Suivi diététique en ligne : Pourquoi choisir Food-eDiet</h1></center>
                            
                            <div name="Consulter une diététicienne ?" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"40px"}}>
                                <h2 className="secondary_h2" style={{margin:"0", paddingBottom:"10px"}}>Consulter une diététicienne ?</h2>
                                <div name="Situation">
                                    <p>
                                        La Santé est le plus grand de tous les biens. 
                                        C’est cet invisible pilier qui supporte tous nos succès. 
                                        C’est le prérequis d’une vie qui vaut la peine d’être vécue !
                                    </p>
                                    <p>
                                        La nutrition en est l’un des facteurs les plus importants. 
                                        Manger, rien de plus vital et intime dans notre relation au corps. 
                                        Même lorsque nous ne consommons pas de nourriture, le fait de penser à la nourriture et d'en avoir envie joue un rôle clé dans nos vies. 
                                    </p>
                                    <p>
                                        Saviez-vous que nous prenons plus de 200 décisions alimentaires par jour 
                                        et que les désirs alimentaires représentent environ un tiers de nos désirs au cours de la journée?
                                    </p>
                                </div>
                                <div name="Problème">
                                    <p>
                                        L’évolution des habitudes alimentaires, la baisse des dépenses d’énergie, auxquelles s’ajoutent un mode de vie sédentaire,
                                        une population vieillissante – plus le tabac et l’alcool – sont les principaux facteurs 
                                        de risque responsables des maladies non transmissibles et posent un problème de santé publique de plus en plus grave.
                                    </p>
                                    <p>
                                        De plus, il devient extrêmement difficile d'identifier les aliments eux-mêmes: leur origine est parfois lointaine, étrangère; 
                                        leur histoire est désormais difficilement accessible; leur préparation échappe de plus en plus à la conscience du consommateur final. 
                                        Mais ce n'est pas tout: la technologie faisant aujourd'hui des miracles, elle permet à l'industrie de tromper les sens. 
                                        Colorants, arômes artificiels, renforçateurs de saveur, agents de texture et de sapidité, tout un arsenal d'artifices qui
                                        peuvent perturber notre équilibre. 
                                    </p>
                                    <p>
                                        Vous connaissez peut-être les dégâts occasionnés à votre précieuse voiture si vous la nourrissez avec la mauvaise essence… 
                                        Alors imaginez le résultat sur votre corps si vous en faites le plein avec les mauvais aliments !
                                    </p>
                                </div>
                                <div name="Implications">
                                    <p>
                                        Il n’a jamais été aussi important et difficile de s’alimenter convenablement. 
                                    </p>
                                </div>
                                <div name="Need-Payoff">
                                    <p>
                                        Heureusement, les diététiciens, formés aux sciences de la diététique et de la nutrition, à la santé, aux conseils 
                                        et aux stratégies de changement de comportement alimentaire, est un professionnel de santé à part entière. 
                                        Sa profession est protégée par la loi. Il est là pour vous aider à retrouver un équilibre alimentaire sain qui est adapté à votre situation et vos objectifs de santé. 
                                    </p>
                                </div>
                                <div name="Transition / Pitch / C2A">
                                    <p>
                                        Vous voulez reprendre les devants de votre santé ? Prenez contact avec l’un de nos diététiciens.
                                    </p>
                                </div>
                            </div>
                            
                           
                                <div name="Pourquoi un suivi en ligne ?" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"40px"}}>
                                    <Grid container style={{width:"100%", margin:"0"}}>
                                        <Grid item xs={12} md={4}>
                                            <img src="/background_dv_06.png" alt="" style={{width:"100%"}} />
                                        </Grid>
                                        <Grid item md={2} />
                                        <Grid item xs={12} md={6}>
                                            <h2 className="secondary_h2" style={{margin:"0", paddingBottom:"10px"}}>Pourquoi un suivi nutritionnel en ligne ?</h2>
                                            <div name="Situation">
                                                <p>
                                                    Vous avez un objectif de santé à atteindre. 
                                                    Qu’il soit né d’une obligation, consultation médical ou d’un désir personnel, 
                                                    vous êtes résolu à vous prendre en main car, au fond de vous, 
                                                    vous êtes conscient des nombreux bénéfices que peuvent vous apporter une bonne nutrition. 
                                                </p>
                                            </div>
                                            <div name="probleme">
                                                <p>
                                                    Toutefois, vous n’êtes peut-être pas un athlète de haut niveau suivi par une équipe de professionnels à tout instant. 
                                                    Bien que vous méritiez un suivi de la plus haute qualité, 
                                                    se rendre au cabinet d’un professionnel de la santé est souvent hasardeux. 
                                                </p>
                                                <p>
                                                    Téléphoner pour se renseigner auprès du secrétariat de cabinet quand ce dernier n’est pas occupé auquel cas il faut patienter, 
                                                    ensuite, convenir d’un rdv parfois lointain, dégager du temps etc. 
                                                    Le jour J: se déplacer, se garer si l’on trouve une place de parking, perdre du temps dans une salle d’attente 
                                                    et après le rdv, revenir chez soi et se rendre compte qu’on a oublié de poser une précieuse question 
                                                    et qu’il faudra attendre de revenir pour trouver réponse à ses nouvelles interrogations.
                                                </p>
                                            </div>
                                            <div name="Implication">
                                                <p>
                                                    L'Odyssée de votre remise en forme ne devrait pas être si compliquée. Le bonheur est simple.
                                                </p>
                                                <p>
                                                    Si seulement il existait un moyen d’atteindre ses objectifs de santé 
                                                    avec un service de pointe sans aucun de ces tracas logistique ? 
                                                </p>
                                                <p>
                                                    C’est exactement ce que nous avons accompli avec le cabinet de diététiciens Food-eDiet. 
                                                    Prenez rdv rapidement et facilement ici. Votre corps vous remerciera. 
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                
                                </div>
                                <div style={{
                                    backgroundImage:"url(/background_dv_02.png)", 
                                    backgroundRepeat:"no-repeat",
                                    backgroundPosition:"top right",
                                    backgroundSize:"contain"
                                }}> 
                                <div name="Pourquoi le cabinet Food-eDiet" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"40px"}}>
                                    <h2 className="secondary_h2" style={{margin:"0", paddingBottom:"10px", paddingTop:"10vw"}}>Pourquoi le cabinet diététique en ligne Food-eDiet ?</h2>
                                    <div name="Situation">
                                        <p>
                                            Vous voulez offrir à votre corps le meilleur pour votre santé? Vous êtes conscient que la nutrition
                                            est l’une de ses plus grandes variables? Vous désirez le meilleur service qui profite de ce que la technologie 
                                            a à offrir en matière d’ergonomie et de facilité de service ?
                                        </p>
                                    </div>
                                    <div name="probleme">
                                        <p>
                                            Vous ne voulez plus des tracas et manques de suivi qu’impliquent les déplacements en cabinet physique? 
                                        </p>
                                    </div>
                                    <div name="implication">
                                        <p>
                                            Le cabinet en ligne de diététiciens Food-eDiet est la solution. La plus haute qualité de suivi nutritionnel sans ces inconvénients qui incombent à votre santé.
                                        </p>
                                        <p>Food-eDiet s’articule autour de 3 missions.</p>
                                        <h3 className="main_h3">
                                            Éduquer autour des enjeux de santé publique et personnelle
                                        </h3>
                                        <p>
                                            Cette démarche d’utilité sociale est gratuite et au cœur de l’éthique du professionnel de la santé. 
                                            C’est pourquoi notre équipe scientifique se forme continuellement pour toujours rester en phase
                                            avec les dernières avancées académiques. Au travers de notre blog et formations, nous apportons 
                                            une information de qualité dont le sérieux et la démarche scientifique font notre fierté.
                                            Apprenez gratuitement au travers de nos articles, dossiers approfondis, mini-guides et recettes pour 
                                            naviguer avec confiance en consommateur éclairé et individu éclairé face aux enjeux de santé.
                                        </p>
                                        <h3 className="main_h3">
                                            Offrir un le meilleur support technologique au service de la santé et de la qualité du suivi nutritionnel.
                                        </h3>
                                        <p>
                                            Pour fournir le meilleur service au patient du marché, nous assurons la 
                                            qualité supérieure de notre suivi diététique grâce à notre plateforme intelligente. 
                                            C’est un espace personnel accessible à tous les utilisateurs inscrits qui sert de véritable tableau de bord
                                            pour l’utilisateur curieux ou les patients de notre cabinet qui en profitent sous une forme premium.
                                            Vous y retrouverez votre profil détaillé, votre espace secrétariat avec votre agenda et historique de consultation,
                                            un espace bibliothèque avec les ressources déposées pour vous par votre diététicien, 
                                            un chat ouvert 24h/24 7j/7 pour discuter avec lui, des calculateurs 
                                            et autres outils graphiques pour savoir où vous en êtes par rapport à vos objectifs 
                                            ainsi que l’espace formation avec une documentation adaptée à votre profil et notre arbre de formation. 
                                        </p>
                                        <h3 className="main_h3">
                                            Servir ses patients dans leur accompagnement nutritionnel sur mesure pour leur permettre d’atteindre leurs objectifs (médicaux, esthétiques, sportifs, holistiques…).
                                        </h3>
                                        <p>Découvrez dès maintenant l’offre qui <a href="inscription">vous correspond</a> ou consultez nos <a href="inscription">tarifs</a></p>
                                    </div>
                                    </div>
                            </div>
                        </div>
                        <div style={{
                        background:"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI2dnciIHZpZXdCb3g9IjAgMCAxMjgwIDE0MCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmZmZmIj48cGF0aCBkPSJNMTI4MCAxNDBWMFM5OTMuNDYgMTQwIDY0MCAxMzkgMCAwIDAgMHYxNDB6Ii8+PC9nPjwvc3ZnPg==)",
                        backgroundSize:"100% 6vw",
                        height: "6vw", 
                        backgroundColor:"rgb(250, 251, 252)"
                        }}/>
                       <div style={{
                                backgroundImage:"url(/background_dv_02.png)", 
                                backgroundRepeat:"no-repeat",
                                backgroundPosition:"top right",
                                backgroundSize:"contain"
                            }}>
                        <div id="prendre-rendez-vous" style={{background:"linear-gradient(white, rgb(250, 251, 252))"}}>
                            <div style={{
                                
                            }}>
                                <div  className="container" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"80px", paddingBottom:"40px"}}>
                                    <center>
                                        <h2 className="main_h2" style={{margin:"0", paddingBottom:"10px"}}>Prendre rendez-vous sur food-ediet</h2>
                                        <h3 className="main_h3" style={{color:"#ffc47c", margin:"0", paddingBottom:"50px"}}>
                                            Choisissez une date et commencez votre suivi personnalisé !
                                        </h3>
                                    </center>
                                    <MyCalendar locale="fr-BE"  style={{width:"100%", fontFamily:"'Karla', sans-serif", }}  />
                                    <div style={{paddingTop:"50px", paddingBottom:"45px"}}>
                                        <center><ReactLink to='/inscription' className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative"}}>Je prends rendez-vous !</button></ReactLink></center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <MyFooter/>
            </div>
        );
    }
}

export default withFirebase(PourquoiFoodeDiet);
