/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Checkbox, Container,  InputBase, Paper } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { Component } from 'react';
import MyFooter from '../../../components/Footer';
import Header from '../../../components/Header';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import Fade from 'react-reveal/Fade';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { withFirebase } from '../../../helpers';
import './inscription.css';
import Validator from '../../../utils/Validator';
import GoogleButton from 'react-google-button'
import { Helmet } from 'react-helmet';

class InscriptionPage extends Component {


    state = {

        // Fields
        prenom:"",
        nom:"",
        email:"",
        confirm_password:"",
        password:"",
        newsletter:false,

        // Errors
        error:"",
        errorEmail:"",
        errorPrenom:"",
        errorNom:"",
        errorConfirmPassword:"",
        errorPassword:"",
    }

    componentDidMount() {
        this.props.firebase.analytics.logEvent('inscription_page_view');
    }


    checkDoublePassword = () => {

        const { password, confirm_password } = this.state;

        if (password !== confirm_password) {
            this.setState({errorConfirmPassword : true})
            return false;
        }
        else {
            this.setState({errorConfirmPassword : false})
            return true;
        }
    }

    confirm = () => {

        const {prenom, nom, email, password} = this.state;

        var okPrenom = Validator.checkInput(prenom);
        this.setState({errorPrenom: !okPrenom})

        var okNom = Validator.checkInput(nom);
        this.setState({errorNom: !okNom})

        var okMail = Validator.checkEmail(email);
        this.setState({errorEmail: !okMail})

        var okPwd = Validator.checkPassword(password);
        this.setState({errorPassword: !okPwd})

        var okConfirmPassword = this.checkDoublePassword();

        // S'inscrire si pas d'erreur et ok mail/confirmPwd
        if(okPrenom && okNom && okMail && okPwd && okConfirmPassword) {
            this.CreateUserByMail();
        }
    }

    ContinueWithFacebook = () => {
        this.props.firebase.doSignInFacebook().then((socialAuthUser) => {
            const user = {
                imageURL: socialAuthUser.user.photoURL + "?height=200",
                firstname: socialAuthUser.additionalUserInfo.profile.first_name,
                name: socialAuthUser.additionalUserInfo.profile.last_name,
                email: socialAuthUser.user.email,
                level: 1,
                hasUnreadMessage: false,
                uid: socialAuthUser.user.uid,
                newsletter:true,
            }
            if(socialAuthUser.additionalUserInfo.isNewUser) {
                this.props.firebase.firestore.collection("users").doc(socialAuthUser.user.uid).set(user).then(() => {
                    this.props.navigate("/home");
                })
            }
            else {
                this.props.navigate("/home");
            }
          })
          .catch(error => {
            this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
          });
    }

    ContinueWithGoogle = () => {
        this.props.firebase.doSignInGoogle().then((result) => {
            const user = {
                imageURL: result.user.photoURL,
                firstname: result.additionalUserInfo.profile.given_name,
                name: result.additionalUserInfo.profile.family_name,
                email: result.additionalUserInfo.profile.email,
                level: 1,
                hasUnreadMessage: false,
                uid: result.user.uid,
                newsletter:true,
            }
            if(result.additionalUserInfo.isNewUser) {
                this.props.firebase.firestore.collection("users").doc(result.user.uid).set(user).then(() => {
                    this.props.navigate("/home");
                })
            }
            else {
                this.props.navigate("/home");
            }
          })
          .catch(error => {
            this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
          });
    }

    CreateUserByMail = () => {
        
        const { email, password, nom, prenom } = this.state;
        
        this.props.firebase.doCreateUserWithEmailAndPassword(email, password).then((MailAuthUser) => {
            const user = {
                name: nom,
                firstname: prenom,
                email: MailAuthUser.user.email,
                level: 1,
                hasUnreadMessage: false,
                uid: MailAuthUser.user.uid
            }
            this.props.firebase.firestore.collection("users").doc(MailAuthUser.user.uid).set(user).then(() => {
                this.props.firebase.doSignInWithEmailAndPassword(email, password).then(() => {
                    this.props.navigate("/home");
              })
            });
          })
          .catch(error => {
                if(error.code + "" === "auth/email-already-in-use") {
                    this.setState({ error: "l'email renseigné existe déjà."});
                }
                else {
                    this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
                }
          });
    }

    render() {

        const { errorEmail, errorNom, errorPassword, errorPrenom, errorConfirmPassword, error } = this.state;

        return (
            <div className="inscriptionPage karla_text" style={{backgroundColor:"rgba(250, 251, 252, 0.6)"}}>
                <Helmet>
                    <title>Inscription sur Food-eDiet</title>
                    <meta name="description" content="L'inscription sur Food-eDiet vous permet d'accèder à votre espace personnel et de réserver une date de consultation avec votre diététicienne" />
                </Helmet>
                <Header />
                <Container maxWidth="xs">
                    <h1 style={{fontSize:"1em", marginTop:"1em", marginBottom:"1em"}}>Inscrivez-vous gratuitement pour prendre rendez-vous et commencez dès maintenant votre suivi !</h1>
                    <div style={{paddingBottom:"25px", paddingTop:"5px"}}>
                        <GoogleButton
                            className="google-button"
                            style={{width:"100%"}}
                            label="S'inscrire avec Google"
                            onClick={this.ContinueWithGoogle}
                        />
                    </div>
                    <form onSubmit={(event) => {event.preventDefault(); this.confirm()}}>
                    <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <PersonIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Prénom"
                            onChange={(event) => {this.setState({prenom : event.target.value})}}
                            value={this.state.prenom}
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorPrenom}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                        Le champs est obligatoire ou non conforme
                        </div>
                    </Fade>
                    <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <PeopleIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Nom"
                            onChange={(event) => {this.setState({nom : event.target.value})}}
                            value={this.state.nom}
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorNom}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px" , fontSize:"12px"}}>
                        Le champs est obligatoire ou non conforme
                        </div>
                    </Fade>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <EmailIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="E-mail"
                            onChange={(event) => {this.setState({email : event.target.value})}}
                            value={this.state.email}
                            type="email"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorEmail}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                        Votre mail est vide ou non conforme
                        </div>
                    </Fade>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <LockIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Mot de passe"
                            onChange={(event) => {this.setState({password : event.target.value})}}
                            value={this.state.password}
                            type="password"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorPassword}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                        Votre mot de passe doit contenir doit contenir au minimum 6 caractères alphanumériques
                        </div>
                    </Fade>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"20px"}}>
                        <LockIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Confirmer le mot de passe"
                            onChange={(event) => {this.setState({confirm_password : event.target.value})}}
                            value={this.state.confirm_password}
                            type="password"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorConfirmPassword}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px" , fontSize:"12px"}}>
                        Votre mot de passe de confirmation est différent de votre mot de passe
                        </div>
                    </Fade>
                    <FormControlLabel
                        control={
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            
                            checked={this.state.newsletter}
                            onChange={(event) => { this.setState({newsletter : event.target.checked}) }}
                        />
                        }
                        style={{textAlign:"justify", fontSize:"12px",}}
                        label="Oui, je souhaite profiter pleinement de l'expérience Food-eDiet et recevoir des e-mails comportant des recettes diététiques, des news et des recommandations personnalisées"
                    />
                    <center style={{paddingBottom:"25px", paddingTop:"25px"}}>
                        <Fade bottom collapse when={error}>
                            <span style={{color:"red", fontSize:"14px",  paddingBottom:"10px",}}>Attention : {error}</span>
                        </Fade>
                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} >S'inscrire</button>
                    </center>
                    </form>
                    <center style={{fontSize:"11px", paddingBottom:"25px"}}>
                        En vous inscrivant, vous acceptez nos <a target="_blank" href="/conditions-utilisation#cgu">conditions générales d'utilisation</a> et notre <a target="_blank" href="/conditions-utilisation#politique-de-confidentialite">politique de confidentialité</a>.
                    </center>
                    <center style={{ paddingBottom:"25px"}}>
                        <div style={{width:"100%", height:"1px", borderRadius:"25px", backgroundColor:"gainsboro", marginBottom:"15px"}} />
                        Je possède déjà un compte <a  href="/connexion">Me connecter</a>
                    </center>
                </Container>
                <MyFooter/>
            </div>
        );
    }
}

export default withFirebase(InscriptionPage);