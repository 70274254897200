import React, { Component } from 'react'
import { withAuthorization } from '../../../components/Session';
import DocumentPanel from '../../connected/Document/DocumentPanel';
import { compose } from 'recompose';
import { withFirebase } from '../../../helpers';
import MessengerPanel from '../../connected/Messenger/MessengerPanel';
import HistoriquePanel from '../../connected/Consultation/ConsultationPage/HistoriquePanel';
//import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import styled from 'styled-components';
import CommentairePrive from './CommentairePrive';
import ApportEdition from './ApportEdition';
import { BackgroundDesktopPage } from '../../connected/Home';
import { Helmet } from 'react-helmet';
import InformationPatient from './InformationPatient';
import { withRouter } from '../../../App';
import { collection, doc, query, orderBy, onSnapshot, limit } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

class ProfilePro extends Component {

    state = {
        targetUser: null,
        loading: true,
        consultations: null,
        messages: null,
    }

    componentDidMount() {

        this.ref = onSnapshot(
            query(
              doc(this.props.firebase.firestore, "users", this.props.match.params.uid)
            ),
            (snap) => {
                console.log(snap)
                console.log(snap.data())
                const targetUser =  {data: snap.data()}
                targetUser.data.uid = this.props.match.params.uid
                targetUser.data.id = this.props.match.params.uid
                this.setState({ targetUser : targetUser, loading : false })
                this.reff = onSnapshot(
                    query(
                      collection(this.props.firebase.firestore, "users/"+targetUser.data.uid+"/consultations"), 
                      orderBy('date', 'desc')
                    ),
                    (querySnapshot) => {
                        const items = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                        this.setState({ consultations:items }, () => {
                            this.getMessages(targetUser);
                        })
                    },
                    (error) => {
                      console.error(error)
                      this.setState({  loadingUser : false });
                    }
                );
            },
            (error) => {
              console.error(error)
              this.setState({  loadingUser : false });
            }
          );
    }

    getMessages = (targetUser) => {
        const Mycollection = "users/" + this.props.authUser.data.uid + "/conversations/" + targetUser.data.uid + "/messages";
        console.log(Mycollection)
        this.unsubConsultation = onSnapshot(
            query(
              collection(this.props.firebase.firestore, Mycollection), 
              orderBy('date', 'desc'),
              limit(5)
            ),
            (querySnapshot) => {
                const items = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                this.setState({ messages:items })
            },
            (error) => {
              console.error(error)
              this.setState({  loadingUser : false });
            }
          );
    }

    handleSubmit = (e) => {
        e.preventDefault()

        const { targetUser } = this.state;
        const file = e.target[0]?.files[0]
    
        if (!file) return;
    
        const storageRef = ref(this.props.firebase.storage, "documents/" + targetUser.data.uid + "/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(progress)
            //setProgresspercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                const doc = {
                    date: Date.now(),
                    documentURL: url,
                    read: false,
                    title: file.name.split('.').slice(0, -1).join('.'),
                    sender: this.props.authUser.data.id,
                    filename: file.name,
                }
                // Create new doc entry to the user
                this.props.firebase.firestore.collection('users/' + targetUser.data.id + "/documents").doc(doc.date + "").set(doc)
            });
          }
        );
      }

      handleChangeInputFile = (e) => {
        if(!e.target.files)
            return
        //this.fileUploader.startUpload( e.target.files ? e.target.files[0] : null)
        this.submitUpload.click()
    }

    render() {

        const { targetUser } = this.state;

        return (
            <div>
                { this.state.targetUser && this.props.authUser && this.props.authUser.data && this.props.authUser.consultations &&
                <div>
                    <Helmet>
                        <title>Profile de {targetUser.data.firstname} {targetUser.data.name}</title>
                        <meta name="robots" content="noindex"/>
                    </Helmet>
                <BackgroundDesktopPage {...this.props}>
                    <h3>Informations sur le patient</h3>
                    <InformationPatient targetUser={targetUser.data} />
                    <h3>Édition de ses apports journaliers</h3>
                    <ApportEdition authUser={targetUser} />
                    <InlineDiv>
                    <h3>État de ses documents</h3>
                    <form  onSubmit={this.handleSubmit} className='form' style={{ visibility: 'hidden', width:0, height:0}}>
                            <input type='file' ref={(fileUpload) => {this.fileUpload = fileUpload;}} onChange={this.handleChangeInputFile} />
                            <button type='submit' ref={(submitUpload) => {this.submitUpload = submitUpload;}} >Confirmer</button>
                        </form>
                    <div
                        onClick={() => this.fileUpload.click()}
                        style={{background: 'linear-gradient(to left bottom, rgb(75, 156, 75), rgb(126, 202, 202))', color: 'white', padding: 5, borderRadius: 4, float: "right"}}
                    >
                        Ajouter un document
                    </div>
                    </InlineDiv>
                    <DocumentPanel authUser={targetUser} isPro/>
                    <h3>Historique de ses consultations</h3>
                    <HistoriquePanel authUser={{data:targetUser.data, consultations: this.state.consultations}} condition={(statut) => true} isPro isNext/>
                    <h3>Discuter avec le patient</h3>
                    <MessengerPanel authUser={this.props.authUser} to_user_uid={targetUser.data.uid} targetUser={targetUser} isPro/>
                    <h3>Commentaires privés non visible pour le patient</h3>
                    <CommentairePrive targetUser={targetUser.data} />
                </BackgroundDesktopPage>
                </div>
                }
           </div>
        )
    }
}

const condition = authUser => !!authUser;

export default compose(withRouter, withFirebase, withAuthorization(condition))(ProfilePro);

const InlineDiv = styled.div`
    display: inline;
    vertical-align: center;
`