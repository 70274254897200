import React, { Component } from 'react'
import { withAuthorization } from '../../../../components/Session';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { compose } from 'recompose';
import HistoriquePanel from './HistoriquePanel'
import { BackgroundDesktopPage, BackgroundMobilePage } from '../../Home';
import { DesktopView, MobileView, MyColor } from '../../../../components/Theme';
import { Helmet } from 'react-helmet';
import { withRouter } from '../../../../App';

class ConsulationPage extends Component {

    render() {

        const { authUser } = this.props;

        if(authUser && authUser.consultations){

            const body = (<div>{this.props.match && this.props.match.params && this.props.match.params.success === "success" &&
            <Alert severity="success">
                <AlertTitle><span style={{fontFamily:"'Karla', sans-serif"}}><b>Consultation planifiée</b></span></AlertTitle>
                <span style={{fontFamily:"'Karla', sans-serif"}}>Votre rendez-vous a été enregistré avec <b>succès</b>. Tous les détails concernant votre prochaine consultation vous seront envoyés par mail<br/>
                Vous pouvez dés à présent : <br/>
                <RouterLink to="/FAQ" target="_blank"><span style={{paddingLeft:"8px"}}>* Lire notre FAQ</span></RouterLink><br/>
                <br/><strong>À Bientôt !</strong></span>
            </Alert>
            }
            {this.props.match && this.props.match.params && this.props.match.params.success === "fail" &&
            <Alert severity="error">
                <AlertTitle><span style={{fontFamily:"'Karla', sans-serif"}}><b>Consultation non enregistrée</b></span></AlertTitle>
                <span style={{fontFamily:"'Karla', sans-serif"}}>Votre rendez-vous n'a pas pu être enregistré. Si le problème persiste, contactez votre diététicienne ou par mail à support@food-ediet.com
                <br/><strong>L'équipe Food-eDiet </strong></span>
            </Alert>
            }
            <h3>Consultations Prévues</h3>
            <h5>Liste des consultations à venir. Vous pouvez également modifier ou rejoindre facilement votre consultation</h5>
            <HistoriquePanel authUser={this.props.authUser} condition={(status) => status === "0"} isNext/>
            <h3>Historique Des Consultations</h3>
            <h5>Liste des consultations terminées ou annulées</h5>
            <HistoriquePanel authUser={this.props.authUser} condition={(status) => status !== "0"} isHistorique/>
            {
                //<h6>*Possibilité d'annuler votre réservation gratuitement jusqu'à 24h à l'avance. Après ce temps, il n'est plus possible d'annuler la consultation. En cas d'absence, la consultation peut être débitée.</h6>
            }</div>)

            return (
                <div className="karla_text">
                    <Helmet>
                        <title>Mes consultation</title>
                        <meta name="robots" content="noindex, nofollow"/>
                    </Helmet>
                    <DesktopView >
                        <BackgroundDesktopPage {...this.props}>
                            {body}
                        </BackgroundDesktopPage>
                    </DesktopView>
                    <MobileView >
                        <BackgroundMobilePage {...this.props}>
                            {body}
                        </BackgroundMobilePage>
                    </MobileView>
                </div>
            )

        }

        return(<div>Chargement..</div>)
    }
}

const condition = authUser => !!authUser;

export default compose(withRouter, withAuthorization(condition))(ConsulationPage);

export function getTimeConsultation(date) {
    const options = {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};
    return new Date(parseInt(date)).toLocaleDateString('fr-BE', options)
}

export const labelStatus = (status) => {
    switch(parseInt(status)) {
        case 0: return <Label color="orange">Prévue</Label>
        case 1: return <Label color={MyColor.SECONDARY_GREEN}>Terminée</Label>
        case 2: return <Label color="lightgrey">Annulée</Label>
        default: return <Label color="grey">Inconnu</Label>
    }
}

const Label = styled.div`
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  color: white;
  background: ${props => {
      return props.color !== "" ? props.color : 'grey';
  }};
  font-family: 'Noto Sans', sans-serif;
`








