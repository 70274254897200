import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../../helpers';
import { collection, doc, query, orderBy, onSnapshot } from "firebase/firestore";

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {

      state = {
        loadingUser: true,
      }

      async  getCustomClaimRole() {
        await this.props.firebase.auth.currentUser.getIdToken(true);
         await this.props.firebase.auth.currentUser.getIdTokenResult();
        //const role = decodedToken.claims.stripeRole;
      }

      rtdb_and_local_fs_presence = () => {
        // [START rtdb_and_local_fs_presence]
        // [START_EXCLUDE]
        var uid = this.props.firebase.auth.currentUser.uid;
        var userStatusDatabaseRef = this.props.firebase.realtimeDataBase.ref('/status/' + uid);
    
        var isOfflineForDatabase = {
            state: 'offline',
            last_changed: this.props.firebase.database.ServerValue.TIMESTAMP,
        };
    
        var isOnlineForDatabase = {
            state: 'online',
            last_changed: this.props.firebase.database.ServerValue.TIMESTAMP,
        };
    
        // [END_EXCLUDE]
        var userStatusFirestoreRef = this.props.firebase.firestore.doc('/status/' + uid);
    
        // Firestore uses a different server timestamp value, so we'll 
        // create two more constants for Firestore state.
        var isOfflineForFirestore = {
            state: 'offline',
            last_changed: this.props.firebase.firestoreUp.FieldValue.serverTimestamp(),
        };
    
        var isOnlineForFirestore = {
            state: 'online',
            last_changed: this.props.firebase.firestoreUp.FieldValue.serverTimestamp(),
        };
    
        this.props.firebase.realtimeDataBase.ref('.info/connected').on('value', function(snapshot) {
            if (snapshot.val() === false) {
                // Instead of simply returning, we'll also set Firestore's state
                // to 'offline'. This ensures that our Firestore cache is aware
                // of the switch to 'offline.'
                userStatusFirestoreRef.set(isOfflineForFirestore);
                return;
            };
    
            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
                userStatusDatabaseRef.set(isOnlineForDatabase);
    
                // We'll also add Firestore set here for when we come online.
                userStatusFirestoreRef.set(isOnlineForFirestore);
            });
        });
    }
   
      componentDidMount() {
        this.listener = this.props.firebase.auth.onAuthStateChanged(
          authUser => {
            if (authUser) {
              this.rtdb_and_local_fs_presence();
              
              this.setState({ authUser }, () => this.LinkToUserDB() );
            }
            else 
               this.setState({ authUser: null, loadingUser : false });
          },
        );

        
      }

      LinkToUserDB = async () => {
        if (this.state.authUser) {
          this.unsub = onSnapshot(
            doc(this.props.firebase.firestore, "users", this.state.authUser.uid), 
            (doc) => {
              console.log("[Sync user firestore]")
              var userData = doc.data();
              userData.id = doc.id;
              userData.uid = doc.id;
              this.setState({ data : userData }, () => {this.getCustomClaimRole(); this.LinkToConsultationDB()})
            },
            (error) => {
              console.error(error)
              this.setState({  loadingUser : false });
            },);
        }
        else {
        }
      }

      LinkToConsultationDB = () => {
        this.unsubConsultation = onSnapshot(
          query(
            collection(this.props.firebase.firestore, "users/"+this.state.authUser.uid+"/consultations"), 
            orderBy('date', 'desc')
          ),
          (querySnapshot) => {
              const items = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
              this.setState({ consultations:items }, () => this.getCustomer())
          },
          (error) => {
            console.error(error)
            this.setState({  loadingUser : false });
          }
        );
      }

      getCustomer = () => {

        this.unsubCustomer = onSnapshot(
          query(
            doc(this.props.firebase.firestore, "customers", this.state.authUser.uid)
          ),
          (snap) => {
            this.setState({ customer : snap.data(), loadingUser: false }, () => console.log("loading finished"))
          },
          (error) => {
            console.error(error)
            this.setState({  loadingUser : false });
          }
        );
      }
   
      componentWillUnmount() {
        this.listener();
        if(this.unsubCustomer)
          this.unsubCustomer();
        if(this.unsubConsultation)
          this.unsubConsultation();
        if(this.unsub) {
          this.unsub();
        }
      }
   
      render() {
        if(this.state.loadingUser) {
          return <div></div>
        }
        return (
          <AuthUserContext.Provider value={this.state}>
            <Component  />
          </AuthUserContext.Provider>
        );
      }
    }
   
    return withFirebase(WithAuthentication);
  };
   
  export default withAuthentication;