/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Grid, InputBase, Paper } from '@mui/material';
import React, { Component } from 'react';
import { MyColor } from '../../Theme';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';
import './newsletter.css';
import Fade from 'react-reveal/Fade';
import Validator from '../../../utils/Validator';
import { withFirebase } from '../../../helpers';
import { ref, getDownloadURL } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";


class Newsletter extends Component {

    state = {

        // Fields for qu'avez vous mangé
        email:"",
        prenom:"",
        message:"",

        // 5 astuces 
        emailPDF:"",

        // Errors
        errorSendQuestion:"",
        errorEmailPDF:"",

        // Confirmation 
        okemailPDF:"",
        okSendQuestion:"",

    }

    confirmSendAstucePDF = () => {

        const { emailPDF } = this.state;

        logEvent(getAnalytics(), 'send_astuce_PDF');


        var okMail = Validator.checkEmail(emailPDF);
        this.setState({errorEmailPDF: !okMail, okemailPDF:""})

        const obj = {
            mail:emailPDF
        }

        // S'inscrire si pas d'erreur et ok mail/confirmPwd
        if( okMail ) {
            /*this.props.firebase.storage.ref("Ebook").child("Food-eDiet - 5 Astuces pour perdre du poids.pdf").getDownloadURL()
                    .then((url) => {
                      })*/
            this.props.firebase.firestore.collection("AstucesPDF").doc(Date.now() + "").set(obj).then(() => {
                this.setState({errorEmailPDF: false, emailPDF:"", okemailPDF:true})
                this.sendPDF()
            })
        }
    }

    sendPDF = () => {
        const storageRef = ref(this.props.firebase.storage, "Ebook/Food-eDiet - 5 Astuces pour perdre du poids.pdf");
        getDownloadURL(storageRef).then((url) => {
            window.open(url, '_blank');
        })

    }

    confirmSendQuestion = () => {

        logEvent(getAnalytics(), 'send_question_diet');

        const { email, prenom, message } = this.state;

        this.setState({okSendQuestion:""})

        var okMail = Validator.checkEmail(email);


        var okPrenom = Validator.checkInput(prenom);

        var okMessage = Validator.checkArea(prenom, 500);

        const obj = {
            mail:email,
            prenom:prenom,
            message:message,
        }

        // S'inscrire si pas d'erreur et ok mail/confirmPwd
        if( okMail && okPrenom &&  okMessage) {
            this.props.firebase.firestore.collection("SendQuestion").add(obj).then(() => {
                this.setState({ okSendQuestion:true, email: "", prenom:"", message:"", errorSendQuestion:""})
            })
        }
        else {
            this.setState({errorSendQuestion:true})
        }
    }


   
    render() {

        const { errorEmailPDF, okemailPDF, okSendQuestion, errorSendQuestion  } = this.state;

        return (
            <div style={{backgroundColor:"rgb(242, 237, 219)", height:"100%"}}  >
                <div style={{
                    height:"6vw", 
                    background:"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI2dnciIHZpZXdCb3g9IjAgMCAxMjgwIDE0MCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmFmYWZhIj48cGF0aCBkPSJNMzIwIDI4QzE2MCAyOCA4MCA0OSAwIDcwVjBoMTI4MHY3MGMtODAgMjEtMTYwIDQyLTMyMCA0Mi0zMjAgMC0zMjAtODQtNjQwLTg0eiIvPjwvZz48L3N2Zz4=)",
                    backgroundColor:"rgb(9, 55, 31)",
                    backgroundSize:"100% 6vw"
                    }}/>
                <div style={{backgroundColor:"rgb(9, 55, 31)", color:"white", paddingBottom:"50px"}}>
                    <a className="anchor" id="nous-contacter" />
                        <div style={{width:"80%", margin:"auto", maxWidth:"1080px", paddingBottom:"25px", position:"relative", paddingTop:"50px"}}>
                            <Grid container spacing={6} style={{width:"100%", margin:"0", paddingBottom:"3.5vw"}}>
                                <Grid item md={6} style={{paddingLeft:"0px",}}>
                                    <h2 className="main_h2">
                                        Qui a dit qu'il fallait se priver pour garder la ligne ?
                                    </h2>
                                    <p>
                                        Sur Food-eDiet, nous n'imposons pas de restrictions alimentaires. Notre méthode repose sur une meilleure gestion des quantités 
                                        et des fréquences de consommation des différents aliments.                      
                                    </p>
                                </Grid>
                                <Grid item md={6} style={{ paddingRight:"0px", paddingLeft:"0px"}}>
                                    <Paper className="newsletter-paper" style={{
                                         minHeight:"150px", 
                                         
                                         boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)", 
                                         background:"url(background-test-2.png)", 
                                         backgroundSize:"cover", 
                                         backgroundColor:"white", 
                                         backgroundPosition:"50%"}}>
                                        <center>
                                            <h3 className="main_h4" style={{margin:"0", padding:"0"}}>Téléchargez gratuitement notre eBook</h3>
                                            <h3 className="secondary_h3" style={{marginBottom:"15px", marginTop:"5px"}}>Nos 5 meilleurs conseils pour perdre du poids !</h3>
                                            <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <EmailIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="E-mail"
                                                    onChange={(event) => {this.setState({emailPDF : event.target.value})}}
                                                    value={this.state.emailPDF}
                                                    type="email"
                                                    className="karla_text"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            <Fade bottom collapse when={errorEmailPDF}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                Votre mail est vide ou non conforme
                                                </div>
                                            </Fade>
                                            <Fade bottom collapse when={okemailPDF}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:MyColor.PRIMARY_GREEN, paddingLeft:"2px", fontSize:"12px" }}>
                                                    Votre document est maintenant disponible !
                                                </div>
                                            </Fade>
                                            <button type="button" onClick={this.confirmSendAstucePDF} className="circular-inscription-btn" style={{width:"auto", position: "relative", marginTop:"15px"}}>Confirmer</button>
                                        </center>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Paper className="newsletter-question" style={{minHeight:"250px", boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)"}}>
                                <h3 style={{fontWeight:"700", fontSize:"26px", lineHeight:"1.6em"}}>Posez nous une question diététique et nous y répondrons gratuitement !</h3>
                                <p>
                                    C'est le moment de nous poser la question diététique qui vous tourmente. Nos experts y répondrons <b>gratuitement ! </b>
                                    Par exemple, décrivez-nous votre assiette type et nous enverrons une suggestion d'amélioration 
                                </p>
                                <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                    <PersonIcon style={{padding: "10px"}}  />
                                    <InputBase
                                        placeholder="Prénom"
                                        onChange={(event) => {this.setState({prenom : event.target.value})}}
                                        value={this.state.prenom}
                                        className="karla_text"
                                        style={{width:"100%"}}
                                    />
                                </Paper>
                                <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                    <EmailIcon style={{padding: "10px"}}  />
                                    <InputBase
                                        placeholder="E-mail"
                                        onChange={(event) => {this.setState({email : event.target.value})}}
                                        value={this.state.email}
                                        type="email"
                                        className="karla_text"
                                        style={{width:"100%"}}
                                    />
                                </Paper>
                                <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                    <MessageIcon style={{padding: "10px"}}  />
                                    <InputBase
                                        placeholder="Décrivez ici votre question ! Ex : est-ce que ce produit alimentaire de mon quotidien est si diététique ? Quelle matière de cuisson utiliser ? J'essaye de manger mieux mais je ne perds pas de poids, que faire ? ..."
                                        onChange={(event) => {this.setState({message : event.target.value})}}
                                        value={this.state.message}
                                        type="text"
                                        className="karla_text"
                                        style={{width:"100%"}}
                                        multiline={true}
                                        rows="5"
                                    />
                                </Paper>
                                <Fade bottom collapse when={errorSendQuestion}>
                                    <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                        Erreur : champs vide, mail non conforme ou message trop long (max 500 caractères)
                                    </div>
                                </Fade>
                                <Fade bottom collapse when={okSendQuestion}>
                                    <div style={{ display: 'block', paddingBottom:"10px", color:MyColor.PRIMARY_GREEN, paddingLeft:"2px", fontSize:"12px" }}>
                                        Nos experts ont bien reçu votre question et vous répondrons au plus vite sur votre boite mail !
                                    </div>
                                </Fade>
                                <button type="button" className="circular-inscription-btn" onClick={this.confirmSendQuestion} style={{width:"auto", position: "relative"}}>Envoyer</button>
                            </Paper>
                        </div>
                </div>
                <div >
                    <div style={{
                        backgroundImage:"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI2dnciIHZpZXdCb3g9IjAgMCAxMjgwIDE0MCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmZmZmIj48cGF0aCBkPSJNMTI4MCAxNDBWMFM5OTMuNDYgMTQwIDY0MCAxMzkgMCAwIDAgMHYxNDB6Ii8+PC9nPjwvc3ZnPg==)",
                        backgroundSize:"100% 6vw",
                        height: "6vw",
                        backgroundColor:"rgb(9, 55, 31)"
                        }}/>
                </div>
                
            </div>
        );
    }
}

export default withFirebase(Newsletter);