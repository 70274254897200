const parseTitre = (titre) => {
    const url = encodeURI(titre.toLowerCase().trim()
        .replaceAll(" ", "-")
        .replaceAll("é", "e")
        .replaceAll("è", "e")
        .replaceAll("'", "-")
        .replaceAll(":", "")
        .replaceAll(",", "-")
        .replaceAll("à", "a")
        .replaceAll("ç", "c")
        .replaceAll("â", "a")
        .replaceAll("ê", "e")
        .replaceAll("ï", "i")
        .replaceAll("/", "-")
        .replaceAll('"', "-")
        .replaceAll("!", "")
        .replaceAll("?", "")
    );
    return url;
}

const LabelDifficulty = {
    1: 'Facile',
    2: 'Moyen',
    3: 'Difficile',
  };

const ColorDifficulty = {
    1: 'green',
    2: 'coral',
    3: 'orangered',
  };

const SaveType = {
    Draft: "brouillon",
    Publish:"publié"
}

const DocType = {
    Article: "Article",
    Recette:"Recette",
}

export { parseTitre, LabelDifficulty, ColorDifficulty, SaveType, DocType  };