import React, { Component } from 'react';
import {  Paper, Divider, Avatar, Grid, Container, Breadcrumbs, Link } from '@mui/material';
import MyFooter from '../../../components/Footer';
import EditIcon from '@mui/icons-material/Edit';
import { compose } from 'recompose';
import { Link as MyLink  } from 'react-router-dom' ;
import { withAuthorization } from '../../../components/Session';
import SimpleFab from '../components/SimpleFab';
import Recette from './recette';
import StarIcon from '@mui/icons-material/Star';
import YouLikeToo from './youLikeToo';
import { DesktopView, MobileView, MyColor } from '../../../components/Theme';
import { Helmet } from 'react-helmet';
import LoadingPanel from '../../robustness/LoadingPanel';
import './articlefull.css';

import {
    FacebookShareButton,
    FacebookIcon,
    PinterestShareButton,
    PinterestIcon,
    TwitterShareButton,
    TwitterIcon,
    FacebookMessengerShareButton, 
    FacebookMessengerIcon,
  } from "react-share";
import HeaderBlog from '../HeaderBlog';
import { withRouter } from '../../../App';

class ArticleFull extends Component {

    state = {
        text: "",
        article: {},
        loading: true,
        windowWidth: 0,
        windowHeight: 0
    }

    componentDidMount() {
        
        this.getDoc();
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        
    }
    getDoc = () => {

        const { firebase } = this.props;
        console.log(this.props)
        const id = this.props.match.params.uid.trim();
        this.props.firebase.firestore.collection("articles").doc(id).get()
        .then((doc) => {
            console.log("[Load article]")
            var article = doc.data();
            this.setState({ article : article })
            this.props.firebase.analytics.logEvent('article_page_view', {
                article_category:article.category,
                article_id: id,
            });
            this.props.firebase.firestore.collection("articles").doc(id).update({ read : firebase.app.firestore.FieldValue.increment(1)});
            /*const jsonldScript = document.createElement('script');
            jsonldScript.setAttribute('type', 'application/ld+json');
            const structuredData = {
                "@context": "https://schema.org/",
                "@type": "Recipe",
                "name": article.title,
                "image": [
                    article.miniatureURL
                ],
                "datePublished":"",
                "recipeIngredient":[

                ],
                "recipeInstructions": [
                    {
                        "@type":"HowToStep",
                        "text":"Préchauffer le four à 210°C (th 7)."
                    },
                ],
                "author":"Eloïse Feryr",
                "keywords":"",
                "recipeCuisine":"Entrée",
            };
            jsonldScript.textContent = structuredData;
            document.head.appendChild(jsonldScript);*/
            this.setState({ loading: false })
        })
        .catch((error) => {
            console.log("Error getting document:", error);
            this.setState({  loading : false });
        });
    }

  
    componentDidUpdate(newProps) {
        if(this.props.match.params.uid !== newProps.match.params.uid) {
            this.getDoc();
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        if(this.unsub) {
            this.unsub();
        }
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
    
        this.setState({ windowWidth, windowHeight });
      }

    getDifficulty = (difficulty) => {
        if(difficulty === 0) {
            return <div>Facile  <StarIcon style={{verticalAlign: "middle", marginLeft:"5px"}} htmlColor="rgb(188, 224, 138)"/><StarIcon style={{verticalAlign: "middle"}}/><StarIcon style={{verticalAlign: "middle"}}/></div> 
        }
        if(difficulty === 1) {
            return <div>Intermédiaire <StarIcon style={{verticalAlign: "middle", marginLeft:"5px"}} htmlColor="coral"/><StarIcon style={{verticalAlign: "middle"}} htmlColor="coral"/><StarIcon style={{verticalAlign: "middle"}}/></div> 
        }
        if(difficulty === 2) {
            return <div>Expérimenté <StarIcon style={{verticalAlign: "middle", marginLeft:"5px"}} htmlColor="orangered"/><StarIcon style={{verticalAlign: "middle"}} htmlColor="orangered"/><StarIcon style={{verticalAlign: "middle"}} htmlColor="orangered" /></div> 
        }
    }


    render() {

        const {article, loading, windowWidth} = this.state;
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const {authUser} = this.props;

        

        const description = <span><b>Eloïse Feryr</b> est une diététicienne passionnée par l'alimentation saine et savoureuse. 
        Elle vous aide à atteindre vos objectifs sur Food-ediet dans la bonne humeur et dans la bienveillance grâce à un suivi régulier et personnalisé.</span>
       

        if(loading) {
            return <LoadingPanel/>
        }

        return (
            <div className="articleFull" > 
                <Helmet>
                    <title>{article.title}</title>
                    <meta name="description" content={article.subtitle} />
                    <meta property="og:title" content={article.title} />
                    <meta property="og:url" content={"https://food-ediet.com/blog/" + article.uid} />
                    <meta property="og:type" content="article" />
                    <meta property="og:description" content={article.subtitle} />
                    <meta property="og:image" content={article.miniatureURL} />
                    <link rel="canonical" href={"https://food-ediet.com/blog/" + article.uid} />
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4559264347139943"
     crossorigin="anonymous"></script>
                   
                </Helmet>
                <HeaderBlog/>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", backgroundColor:"rgba(241,241,241,0.8)"}}>
                <Grid container style={{width:"100%", margin:"0",}}>
                    <Grid item xs={3}>
                        <DesktopView>
                            <div style={{position:"fixed", left:"8%", top:"25%",}}>
                                <h4>Par Eloïse Feryr,</h4>
                                <p>Diététicienne passionnée<br/> par l'alimentation saine <br/>et savoureuse.</p>
                                <MyLink to="/dieteticienne/eloise-feryr" className="nolink">
                                    <button type="button" className="circular-inscription-btn" style={{ paddingTop:"8px", paddingBottom:"8px", width:"150px"}} >Découvrir</button>
                                </MyLink>
                            </div>
                        </DesktopView>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <DesktopView>
                        <Breadcrumbs style= {{marginTop:"10px", paddingTop:"5px", paddingLeft:"5px", }} >
                        <Link color="inherit" href="/blog">
                        Blog
                        </Link>
                        <Link color="inherit" href={"/blog/categorie/" + article.type.toLowerCase()}>
                        {article.type}
                        </Link>
                        <span>{truncate(article.title, 50)}</span>
                        </Breadcrumbs>
                    </DesktopView>
                    <Paper elevation={3} style={{padding: windowWidth > 600 ? 30 : 10, paddingTop:windowWidth > 600 ? 30 : 0, marginTop:windowWidth > 600 ? "15px" : "2px", marginBottom:"10px", display:"flex", flexDirection:"column"}}>
                        {article.type !== "Recette" && <div>
                            <DesktopView>
                                <h1 style={{color: MyColor.PRIMARY_GREEN, paddingBottom:"35px"}}>{article.title}</h1>
                            </DesktopView>
                            <MobileView>
                                <h1 style={{color: MyColor.PRIMARY_GREEN}}><span>{article.title}</span></h1>
                            </MobileView>
                        <Grid  container style={{paddingBottom:"10px", margin:"0", width:"100%"}} justifyContent="space-between"  alignItems="center">
                            <Grid item xs={5} md={8} container style={{paddingBottom:"10px"}} >
                                <DesktopView>
                                    <Grid item>
                                        <Avatar alt="Eloïse Feryr" src="https://firebasestorage.googleapis.com/v0/b/hello-diet.appspot.com/o/elo_diet_cadr%C3%A9e.jpg?alt=media&token=4c8621d4-cf6e-4b26-8644-83661a2f7f99" style={{
                                            width: '30px',
                                            height: '30px',
                                            marginRight: '10px',
                                            verticalAlign: "center"
                                        }}/>
                                    </Grid>
                                </DesktopView>
                                <Grid item>
                                    <span >Par <a style={{textDecoration:"none", color:MyColor.PRIMARY_GREEN}} href="/dieteticienne/eloise-feryr">Eloïse Feryr</a>, le {new Date(article.date).toLocaleDateString('fr-BE', options)}</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} md={2}>

                            </Grid>
                            <Grid item xs={5} md={2} >
                               <div >{this.getDifficulty(article.difficulty)}</div> 
                            </Grid>
                        </Grid>
                        <Divider/>
                        <br/>
                        <div style={{textAlign: "justify", fontStyle: "italic", paddingTop:"5px", paddingBottom:"15px"}} dangerouslySetInnerHTML={{__html: article.subtitle}}>

                        </div>
                        <div className="articlefull-content" dangerouslySetInnerHTML={{__html: article.content}}></div> </div> }
                        {article.type === "Recette" && <Recette recette={article} />}
                        {
                            authUser && authUser.data && authUser.data.level >= 4 && 
                            <SimpleFab
                                tooltip="Modifier l'article"
                                onClick={() => {this.props.navigate("/admin/article/" + article.uid)}}
                                icon={<EditIcon htmlColor="white"  />}
                            />
                        }
                        <div style={{padding:"5px"}}>
                            <h5>Si cet article vous a plu, n'hésitez pas à le partager !</h5>
                            <div style={{display:"inline"}}>
                                <FacebookShareButton url={window.location.origin + this.props.location.pathname}>
                                    <FacebookIcon size={25} round={true} style={{paddingRight:"5px"}} />
                                </FacebookShareButton>
                                <FacebookMessengerShareButton url={window.location.origin + this.props.location.pathname} appId="2555598378013261">
                                    <FacebookMessengerIcon size={25} round={true} style={{paddingRight:"5px"}} />
                                </FacebookMessengerShareButton>
                                <TwitterShareButton url={window.location.origin + this.props.location.pathname}>
                                    <TwitterIcon size={25} round={true} style={{paddingRight:"5px"}}/>
                                </TwitterShareButton>
                                <PinterestShareButton url={window.location.origin + this.props.location.pathname} media={article.miniatureURL}>
                                    <PinterestIcon size={25} round={true} style={{paddingRight:"5px"}} />
                                </PinterestShareButton>
                            </div>
                        </div>
                    </Paper>
                    <Paper style={{padding:"15px", marginBottom:"25px",}}>
                        <Grid container style={{width:"100%", margin:"0"}} alignItems="center" justifyContent="center" spacing={2}>
                            <Grid item  xs={12} md={3}>
                                <center>
                                    <div style={{position:"relative", width:"100%"}}>
                                        <img 
                                            alt="Eloïse Feryr" 
                                            src="https://firebasestorage.googleapis.com/v0/b/hello-diet.appspot.com/o/elo_diet_cadr%C3%A9e.jpg?alt=media&token=4c8621d4-cf6e-4b26-8644-83661a2f7f99" 
                                            style={{  borderRadius:"5px", width:"100%", maxWidth:"150px"}}  
                                        />
                                    </div>
                                </center>
                            </Grid>
                            <Grid container item xs={8} direction="column" justifyContent="space-evenly" style={{width:"100%", margin:"0"}} spacing={2}>
                                <Grid item>
                                    <DesktopView>
                                        <p >
                                            {description}
                                        </p>
                                    </DesktopView>
                                    <MobileView>
                                        <p style={{textAlign:"center"}}>
                                            {description}
                                        </p>
                                    </MobileView>
                                </Grid>
                                <Grid item container justifyContent="space-around" style={{width:"100%", margin:"0"}} spacing={2}>
                                    <Grid item>
                                        <MyLink to="/dieteticienne/eloise-feryr" className="nolink">
                                            <button type="button" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px", width:"150px"}} >En savoir plus</button>
                                        </MyLink>
                                    </Grid>
                                    <Grid item>
                                        <MyLink to="/inscription" className="nolink">
                                            <button type="button" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px", width:"250px"}} >Réserver une consultation</button>
                                        </MyLink>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <MobileView>
                        <Breadcrumbs style= {{paddingTop:"5px", paddingLeft:"5px", paddingBottom:"20px" }} >
                            <Link color="inherit" href="/blog">
                            Blog
                            </Link>
                            <Link color="inherit" href={"/blog/categorie/" + article.type.toLowerCase()}>
                            {article.type}
                            </Link>
                            <span>{truncate(article.title, 50)}</span>
                        </Breadcrumbs>
                    </MobileView>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>
                </div>
                <Container maxWidth="md">
                <div >
                    {article.type && <YouLikeToo uid={article.uid} category={article.type} level={(authUser && authUser.data && authUser.data.level) ? authUser.data.level : 0}/> }
                </div>
                </Container>
                <MyFooter/>
            </div>
        )
    }
}

export default compose(withRouter, withAuthorization((authUser) => true))(ArticleFull);



function truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
  };