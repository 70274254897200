import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { ListItemAvatar, Avatar, ListItemSecondaryAction } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default class MyPictures extends Component {

    state = {
        open: false,
    }

    handleClickOpen = () => {
        this.setState({open: true})
      };
    
    handleClose = () => {
        this.setState({open: false})
    };

    render() {

        const { open } = this.state; 
        const { pictures } = this.props;

        const picturesTiles = Object.keys(pictures  ?? {}).map((uid, i) => 
            (<ListItem key={pictures[uid]} button>
                <ListItemAvatar>
                    <Avatar alt="" src={pictures[uid]} />
                </ListItemAvatar>
                <ListItemText>
                    {pictures[uid]}
                </ListItemText>
                <ListItemSecondaryAction>
                    <IconButton onClick={() => {navigator.clipboard.writeText(pictures[uid])}}>
                        <FileCopyIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        ));
        
        return (
            <div>
                <Button style={{background: 'linear-gradient(to left bottom, rgb(75, 156, 75), rgb(126, 202, 202))', color: 'white', padding: 5, borderRadius: 4}} onClick={this.handleClickOpen}>
                    Voir mes photos
                </Button>
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar style={{position: 'relative', marginTop: "75px"}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" style={{flex: 1, }}>
                            Mes photos de l'article
                        </Typography>
                    </Toolbar>
                    </AppBar>
                    <List>
                        {picturesTiles}
                    <Divider />
                    </List>
                </Dialog>
            </div>
        )
    }
}