import React from "react";
import "./meeting.css";
import { AGORA_APP_ID } from "../../../../agora.config";
import AgoraVideoCall from '../AgoraVideoCall'
import { compose } from "recompose";
import { withFirebase } from "../../../../helpers";
import MessengerPanel from "../../Messenger/MessengerPanel"
import { withAuthorization } from "../../../../components/Session";
import { LoadingPage } from "../../../robustness/LoadingPanel";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Collapse, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ELO_UID, LOGO_WHITE } from "../../../../utils/Settings";
import { DesktopView, MobileView } from "../../../../components/Theme";
import AgoraRTC from "agora-rtc-sdk";
import { Helmet } from "react-helmet";
import { withRouter } from "../../../../App";

class Meeting extends React.Component {
  constructor(props) {
    super(props);
    this.videoProfile =  "480p_4";
    this.transcode =  "interop";
    this.attendeeMode =  "video";
    this.baseMode =  "avc";
    this.appId = AGORA_APP_ID;
    if (!this.appId) {
      return alert("Get App ID first!");
    }
    this.uid = undefined;
  }

  state = {
    open : true,
    errorDevice: false,
    noShowWarning: false,
  }

  componentDidMount() {
    if(!AgoraRTC.checkSystemRequirements()) {
      this.setState({errorDevice : true})
    }

    if(localStorage.getItem('warningclosed')) {
      this.setState({noShowWarning : true})
    }
    
    
  }

  render() {

    const channel = this.props.match.params.channel;

    const { open, noShowWarning } = this.state;
    const { authUser } = this.props;

    if(!(authUser && authUser.data))
            return (LoadingPage);

    return (
      <div style={{backgroundColor:"#474747"}}>
        <Helmet>
            <title>Meeting</title>
            <meta name="robots" content="noindex, nofollow"/>
        </Helmet>
        <DesktopView >
        {
          this.state.errorDevice && 
          <Alert severity="error">
            Votre navigateur n'est pas compatible.
            <ul className="karla_text">
                    <li>Si possible, merci de passer sur Google Chrome</li>
                    <li>Si vous êtes sur téléphonne, tentez de passer sur un ordinateur</li>
                    <li>Si le problème persiste, contactez-nous directement via le tchat, le bouton d'assistance, ou l'addresse mail support@food-ediet.com</li>
                  </ul>

          </Alert>
        }
        { !noShowWarning &&
          <Collapse in={open}>
              <div style={{paddingLeft: "10%", paddingRight:"10%", paddingBottom: "15px", paddingTop:"15px"}}>
                <Alert severity="warning" action={
              <IconButton
                size="small"
                onClick={() => {
                  this.setState({ open : false}); localStorage.setItem('warningclosed', true);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
                  <AlertTitle>Attention</AlertTitle>
                  <ul className="karla_text">
                    
                    <li>Lorsque demandé, acceptez l'option "utiliser la caméra" et "utiliser le microphone"</li>
                    <li>Si votre caméra ne s'active pas :</li>
                    <ul className="karla_text">
                      <li>Changez de navigateur (ex : Google Chrome)</li>
                      <li>Accèder à votre consultation depuis un ordinateur</li>
                      <li>Vérifiez que votre webcam est accessible(ex : depuis l'application windows "Caméra")</li>
                      <li>Rafraichissez la page (ctrl+f5)</li>
                      <li>Si le problème n'est toujours pas résolu, contactez votre diététicienne au plus vite</li>
                    </ul>
                  </ul>
                </Alert>
              </div>
          </Collapse>
        }
         
        <div className="wrapper meeting">
          <div className="ag-main">
            <div className="ag-container">
              <AgoraVideoCall
                  videoProfile={this.videoProfile}
                  channel={channel}
                  transcode={this.transcode}
                  attendeeMode={this.attendeeMode}
                  baseMode={this.baseMode}
                  appId={this.appId}
                  uid={this.uid}
                />
            </div>
          </div>
          <div className="ag-footer">
            <div className="imglogo">
              <img src={LOGO_WHITE} alt="logo food-ediet" width="59px" height="45px" style={{paddingRight:"100px", paddingTop:"7px", paddingBottom:"7px"}}/>
            </div>
            <div>
              
            </div>
          </div>
        </div>
        <div id="Tchat" style={{paddingLeft: "10%", paddingRight:"10%", paddingBottom:"15px"}}>
          { authUser.data.uid === ELO_UID ? <span style={{color:"white"}}>ELO, ouvre un onglet de l'accueil pro avec le tchat stp</span> : 
          <MessengerPanel authUser={authUser} id={ELO_UID} messages={authUser.messages}/>
          }
        </div>
        </DesktopView>
        <MobileView >
          <div style={{height:"100vh", }}>
            <AgoraVideoCall
              videoProfile={this.videoProfile}
              channel={channel}
              transcode={this.transcode}
              attendeeMode={this.attendeeMode}
              baseMode={this.baseMode}
              appId={this.appId}
              uid={this.uid}
              mobile
            />
          </div>
          <MessengerPanel authUser={authUser} id={ELO_UID} messages={authUser.messages}/>
        </MobileView>
        
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withFirebase, withAuthorization(condition), withRouter)(Meeting);

/*

*/