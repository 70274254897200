import React, { Component, createRef } from 'react'
import { AppBar, Toolbar, Typography, IconButton, /*Badge,*/ Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, ListItemIcon, Divider } from '@mui/material';
import styled from 'styled-components';
//import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { compose } from 'recompose';
import { withFirebase } from '../../../../helpers';
import { LOGO_BLACK } from '../../../../utils/Settings';
import SettingsIcon from '@mui/icons-material/Settings';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { LinkBehavior } from '../../../../components/Theme/LinkBehaviour';
import {Link as ReactLink} from 'react-router-dom'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { withRouter } from '../../../../App';

class MyAppBar extends Component {

    state = {
        open: false,
    }

    anchorRef = createRef()

    handleClose = () => {
        this.setState({ open : false});
    }

    handleOpen = () => {
        this.setState({ open : true});
    }

    handleSignOut = () => {
        const { firebase } = this.props;
        firebase.doSignOut();
        //this.props.navigate("/")
        window.location.href = "/";
    }

    //<IconButton><MyBadge badgeContent={4} color="error"><NotificationsActiveIcon htmlColor="gold" fontSize="default"/></MyBadge></IconButton>

    render() {
        const {authUser, position} = this.props;
        const { open } =  this.state;

        return (
            <div className="myAppBar" style={{display:"flex"}}>
                <ThemeAppBar position={position ?? "fixed"} color="transparent" elevation={0}>
                    <Toolbar>
                        <ReactLink to='/'>
                            <img src={LOGO_BLACK} alt="logo food-ediet" width="59px" height="45px" style={{padding:"5px", paddingTop:"15px", paddingRight:"100px", }}/>
                        </ReactLink>
                    <Typography variant="h6" style={{paddingLeft:this.props.paddingLeft}} >
                
                    </Typography>
                    <ActionButton >
                        
                        <IconButton ref={this.anchorRef} onClick={this.handleOpen} color="inherit">{authUser.data && <SettingsIcon htmlColor="rgb(94,94,94)"/>}</IconButton>
                    </ActionButton>
                    <Popper open={open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" >
                                    <h3 style={{paddingLeft:"10px"}}>{authUser.data.firstname ?? "Vous.."}</h3>
                                    <MenuItem component={LinkBehavior} to="/home/profil" >
                                        <ListItemIcon>
                                            <AccountBoxIcon fontSize="small" htmlColor="#474747"/>
                                        </ListItemIcon>
                                        Mon profil
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem component={LinkBehavior} to="/home/mensurations" >
                                        <ListItemIcon>
                                            <FitnessCenterIcon fontSize="small" htmlColor="#474747"/>
                                        </ListItemIcon>
                                        Mes mensurations
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem component={LinkBehavior} to="/home/compte" >
                                        <ListItemIcon>
                                            <SettingsIcon fontSize="small" htmlColor="#474747"/>
                                        </ListItemIcon>
                                        Mon compte
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem component={LinkBehavior} to="/FAQ" >
                                        <ListItemIcon>
                                            <LiveHelpIcon fontSize="small" htmlColor="#474747"/>
                                        </ListItemIcon>
                                        FAQ
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem component={LinkBehavior} to="/">
                                        <ListItemIcon>
                                            <ArrowBackIcon fontSize="small" htmlColor="#474747"/>
                                        </ListItemIcon>
                                        Retourner sur la page d'accueil
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem onClick={this.handleSignOut}>
                                        <ListItemIcon>
                                            <ExitToAppIcon fontSize="small" htmlColor="#474747"/>
                                        </ListItemIcon>
                                        Se déconnecter
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                        </Grow>
                    )}
                    </Popper>
                    </Toolbar>
                </ThemeAppBar>
            </div> 
        )
    }
}

export default compose(withFirebase, withRouter)(MyAppBar)

/*const MyBadge = styled(Badge)`

`;*/

const ThemeAppBar = styled(AppBar)`
    padding-left: ${props => {
      return props.paddingLeft ?? '10px';
    }};
    //background: rgb(1,1,1,0);
    //box-shadow:"";
`;

const ActionButton = styled.div`
    margin-left: auto;
`;
