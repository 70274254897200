import { Grid, InputBase, Paper, Box, FormControl, RadioGroup, FormControlLabel, Checkbox} from '@mui/material';
import React, { Component } from 'react';
import MyFooter from '../../../components/Footer';
import Header from '../../../components/Header';
import { withFirebase } from '../../../helpers';
import { Helmet } from 'react-helmet';
import PersonIcon from '@mui/icons-material/Person';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import { compose } from 'recompose';
import MailIcon from '@mui/icons-material/Mail';
import HeightIcon from '@mui/icons-material/Height';
import './questionnaire.css';
import StyledRadio from './RadioButton';
import LockIcon from '@mui/icons-material/Lock';
import Validator from '../../../utils/Validator';


class QuestionnairePage extends Component {

    componentDidMount() {
        this.props.firebase.analytics.logEvent('Page view - Questionnaire');
    }

    state = {
        prenom:"",
        nom:"",
        etape:0,
        naissance:"",
        emailConfirm:"",
        email:"",
        gender: "",
        objectif :"",
        input_allergie:"",
        taille:"",
        poids:"",
        dejeuner:"",
        dejeunerQuoi:"",
        dejeunerPourquoi:"",
        midi:"",
        gouter:"",
        soir:"",
        boisson:"",

        // Errors
        error:"",
        errorEmail:"",
        errorEmailConfirm:"",
        errorPrenom:"",
        errorNom:"",
        errorGender:"",
        errorNaissance:"",
        errorObjectif:"",
        errorTaille:"",
        errorPoids:"",
        errorDejeuner:"",
        errorQuoi:"",
        errorPourquoi:"",
        errorMidi:"",
        errorGouter:"",
        errorSoir:"",
        errorBoisson:"",

        // CheckBow 
        tailleChecked: false,
        poidsChecked: false,

        // compte
        password:"",
        errorPassword:"",

    }

    checkStep1 = () => {

        const { prenom, nom, etape } = this.state;

        var okPrenom = Validator.checkInput(prenom);
        this.setState({errorPrenom: !okPrenom})

        var okNom = Validator.checkInput(nom);
        this.setState({errorNom: !okNom})

        if(okPrenom && okNom) {
            this.props.firebase.analytics.logEvent('Questionnaire - 2');
            this.setState({etape:etape + 1})
        }
    }

    checkStep2 = () => {

        const { gender, naissance, etape } = this.state;

        var okGender = Validator.isNonEmpty(gender)
        this.setState({errorGender: !okGender})

        var okNaissance = Validator.isNonEmpty(naissance);
        this.setState({errorNaissance: !okNaissance})

        if(okGender && okNaissance) {
            this.props.firebase.analytics.logEvent('Questionnaire - 3');
            this.setState({etape:etape + 1})
        }
    }

    checkStep3 = () => {

        const { objectif, etape } = this.state;

        var okObjectif = Validator.isNonEmpty(objectif)
        this.setState({errorObjectif: !okObjectif})

        if(okObjectif) {
            this.props.firebase.analytics.logEvent('Questionnaire - 4');
            this.setState({etape:etape + 1})
        }
    }

    checkStep4 = () => {

        const { taille, tailleChecked, poids, poidsChecked, etape } = this.state;

        var okTaille = Validator.validNumber(taille)
        if(!tailleChecked)
            this.setState({errorTaille: !okTaille})

        var okPoids = Validator.validNumber(poids)
        if(!poidsChecked)
            this.setState({errorPoids: !okPoids})

        if((okTaille || tailleChecked) && (okPoids || poidsChecked) ) {
            this.props.firebase.analytics.logEvent('Questionnaire - 5');
            this.setState({etape:etape + 1})
        }
    }

    checkStep5 = () => {

        const { dejeuner, dejeunerPourquoi, dejeunerQuoi, midi, gouter, soir, boisson, etape } = this.state;

        var okDejeuner = Validator.isNonEmpty(dejeuner)
        this.setState({errorDejeuner: !okDejeuner})

        var okMidi = Validator.isNonEmpty(midi)
        this.setState({errorMidi: !okMidi})

        var okGouter = Validator.isNonEmpty(gouter)
        this.setState({errorGouter: !okGouter})

        var okSoir = Validator.isNonEmpty(soir)
        this.setState({errorSoir: !okSoir})

        var okBoisson = Validator.isNonEmpty(boisson)
        this.setState({errorBoisson: !okBoisson})

        var okQuoi = true;
        if(dejeuner === "oui") {
            okQuoi = Validator.isNonEmpty(dejeunerQuoi)
            this.setState({errorQuoi: !okQuoi})
        }

        var okPourquoi = true;
        if(dejeuner === "non") {
            okPourquoi = Validator.isNonEmpty(dejeunerPourquoi)
            this.setState({errorPourquoi: !okPourquoi})
        }

        if(okDejeuner && okQuoi && okPourquoi && okMidi && okGouter && okSoir && okBoisson) {
            this.props.firebase.analytics.logEvent('Questionnaire - 6');
            this.setState({etape:etape + 1})
        }
    }

    checkStep6 = () => {

        const { email, etape } = this.state;

        var okMail = Validator.checkEmail(email);
        this.setState({errorEmail: !okMail})

        var okConfirMail = this.checkDoubleMail();


        if(okMail && okConfirMail) {
            this.props.firebase.analytics.logEvent('Questionnaire - 7');
            this.setState({etape:etape + 1});
            this.SaveQuestionnaire()
        }
    }

    SaveQuestionnaire = () => {
        
            const { email, 
                nom, 
                prenom, 
                naissance, 
                gender, 
                objectif, 
                input_allergie, taille, poids, dejeuner, dejeunerQuoi, dejeunerPourquoi, midi, gouter, soir, boisson } = this.state;

            const user = {
                name: nom,
                firstname: prenom,
                email: email,
                naissance:naissance,
                gender: gender,
                objectif :objectif,
                input_allergie:input_allergie,
                taille:taille,
                poids:poids,
                dejeuner:dejeuner,
                dejeunerQuoi:dejeunerQuoi,
                dejeunerPourquoi:dejeunerPourquoi,
                midi:midi,
                gouter:gouter,
                soir:soir,
                boisson:boisson,
            }
            this.props.firebase.firestore.collection("questionnaire").doc(Date.now() + "").set(user)
            .then(() => {
                this.props.firebase.analytics.logEvent('Questionnaire - saved')
            })
            .catch(error => {
                console.error(error)
                this.props.firebase.analytics.logEvent('Questionnaire - error');
             });
    }

    checkDoubleMail = () => {

        const { email, emailConfirm } = this.state;

        if (email !== emailConfirm) {
            this.setState({errorEmailConfirm : true})
            return false;
        }
        else {
            this.setState({errorEmailConfirm : false})
            return true;
        }
    }


    checkStep7 = () => {

        const { password } = this.state;

        var okPwd = Validator.checkPassword(password);
        this.setState({errorPassword: !okPwd})


        if(okPwd) {
            this.CreateUserByMail()
        }
    }

    CreateUserByMail = () => {
        
        const { email, 
            password, 
            nom, 
            prenom, 
            naissance, 
            gender, 
            objectif, 
            input_allergie, taille, dejeuner, dejeunerQuoi, dejeunerPourquoi, midi, gouter, soir, boisson } = this.state;
        
        this.props.firebase.doCreateUserWithEmailAndPassword(email, password).then((MailAuthUser) => {
            const user = {
                name: nom,
                firstname: prenom,
                email: MailAuthUser.user.email,
                level: 1,
                hasUnreadMessage: false,
                uid: MailAuthUser.user.uid,
                naissance:naissance,
                gender: gender,
                objectif :objectif,
                input_allergie:input_allergie,
                taille:taille,
                dejeuner:dejeuner,
                dejeunerQuoi:dejeunerQuoi,
                dejeunerPourquoi:dejeunerPourquoi,
                midi:midi,
                gouter:gouter,
                soir:soir,
                boisson:boisson,
            }
            this.props.firebase.firestore.collection("users").doc(MailAuthUser.user.uid).set(user).then(() => {
                this.props.firebase.analytics.logEvent('Questionnaire - user sign in');
                this.props.firebase.doSignInWithEmailAndPassword(email, password).then(() => {
                    this.props.navigate("/home");
                })
            })
          })
          .catch(error => {
                if(error.code + "" === "auth/email-already-in-use") {
                    this.setState({ error: "l'email renseigné existe déjà."});
                }
                else {
                    this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
                }
          });
    }


    render() {

        const { etape, 
            errorNom, errorPrenom, 
            gender, errorGender, naissance, errorNaissance, 
            objectif, errorObjectif, 
            tailleChecked, taille, errorTaille, poidsChecked, poids, errorPoids,
            dejeuner, dejeunerQuoi, dejeunerPourquoi, midi, gouter, soir, boisson, errorMidi, errorGouter, errorSoir, errorBoisson, errorDejeuner, errorPourquoi, errorQuoi,
            email, emailConfirm, errorEmail, errorEmailConfirm,
            errorPassword, error } = this.state;

        return (
            <div className="karla_text" style={{backgroundColor:"rgb(250, 251, 252)"}}>
                <Helmet>
                        <title>Food-eDiet - Questionnaire en ligne</title>
                        <meta name="description" content="Répondez à ce court questionnaire afin de mieux connaitre vos habitudes alimentaires et recevoir des conseils personnalisés ainsi qu'une formule qui vous correspond" />
                </Helmet>
                <Header />
                    <Grid container  >
                        <Box component={Grid} item xs={12} md={4} display={{ xs: "none", lg: "block" }}> 
                            <img src="food-vert.jpg" alt="food-ediet" style={{  display: 'block', width: 'auto', maxWidth:"100%",  height: '95vh', opacity:"0.3"}}/>
                        </Box>
                        <Grid container item xs={12} md={5} direction="column" justifyContent="center" alignItems="flex-start" >
                            {
                                etape === 0 &&
                                <Zoom>
                                    <Paper style={{padding:"35px", margin:"8%"}} elevation={3} className="questionnaire-bloc-question">
                                        <Grid item style={{paddingBottom:"15px"}}>
                                        <h2>Un plan diététique personnalisé, ça vous tente ?</h2>
                                        </Grid>
                                        <Grid item style={{paddingBottom:"35px"}}>
                                        <p>Bienvenue sur le questionnaire Food-eDiet !</p>
                                        <p> Nous allons vous poser quelques questions sur vos objectifs de santé et vos habitudes de vie afin de vous fournir les meilleurs conseils personnalisés.</p>
                                        </Grid>
                                        <Grid item>
                                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} onClick={() => {this.props.firebase.analytics.logEvent('Questionnaire - 1'); this.setState({etape:etape + 1})}} >Commencer</button>
                                        </Grid>
                                    </Paper>
                                </Zoom>
                            }
                            {
                                etape === 1 &&
                                <Fade right>
                                    <Paper style={{padding:"35px", margin:"8%"}} elevation={3} className="questionnaire-bloc-question">
                                        <Grid item style={{paddingBottom:"15px"}}>
                                        <h4>Pour commencer, comment vous appellez-vous ?</h4>
                                        </Grid>
                                        <Grid item style={{paddingBottom:"35px"}}>
                                            <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <PersonIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="Prénom"
                                                    onChange={(event) => {this.setState({prenom : event.target.value})}}
                                                    value={this.state.prenom}
                                                    className="karla_text"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            <Fade bottom collapse when={errorPrenom}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                Le champs est vide ou non conforme
                                                </div>
                                            </Fade>
                                            <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <PeopleIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="Nom"
                                                    onChange={(event) => {this.setState({nom : event.target.value})}}
                                                    value={this.state.nom}
                                                    className="karla_text"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            <Fade bottom collapse when={errorNom}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                Le champs est vide ou non conforme
                                                </div>
                                            </Fade>
                                        </Grid>
                                        <Grid item>
                                        <button 
                                            type="submit" 
                                            className="circular-inscription-btn" 
                                            style={{ paddingTop:"12px", paddingBottom:"12px"}} 
                                            onClick={this.checkStep1} >Continuer</button>
                                        </Grid>
                                    </Paper>
                                </Fade>
                            }
                            {
                                etape === 2 &&
                                <Fade right>
                                    <Paper style={{padding:"35px", margin:"8%"}} elevation="3" className="questionnaire-bloc-question">
                                        <Grid item style={{paddingBottom:"5px"}}>
                                        <h4>Enchanté {this.state.prenom} ! Faisons plus ample connaissance. </h4>
                                        </Grid>
                                        <h5>Vous êtes ?</h5>
                                        <FormControl component="fieldset" style={{paddingBottom:"10px"}}>
                                            <RadioGroup value={gender} onChange={(event) => this.setState({gender : event.target.value})} >
                                                <FormControlLabel value="female" control={<StyledRadio />} label="Une femme" />
                                                <FormControlLabel value="male" control={<StyledRadio />} label="Un homme" />
                                                <FormControlLabel value="other" control={<StyledRadio />} label="Autre" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Fade bottom collapse when={errorGender}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                Aucune option n'a été choisie
                                            </div>
                                        </Fade>
                                        <h5>Quelle est votre date de naissance ?</h5>
                                        <Grid item style={{paddingBottom:"35px"}}>
                                            <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <EventIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="Date de naissance"
                                                    type="date"
                                                    onChange={(event) => {this.setState({naissance : event.target.value})}}
                                                    value={naissance}
                                                    className="karla_text"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            <Fade bottom collapse when={errorNaissance}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                    Le champs est vide ou non conforme
                                                </div>
                                            </Fade>
                                        </Grid>
                                        <Grid item>
                                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} onClick={this.checkStep2} >Continuer</button>
                                        </Grid>
                                    </Paper>
                                </Fade>
                            }
                            {
                                etape === 3 &&
                                <Fade right>
                                    <Paper style={{padding:"35px", margin:"8%"}} elevation="3" className="questionnaire-bloc-question">
                                        <Grid item style={{paddingBottom:"0px"}}>
                                        <h4>Quel est votre objectif santé principal ?</h4>
                                        </Grid>
                                        <Grid item style={{paddingBottom:"35px"}}>
                                            <FormControl component="fieldset" style={{paddingBottom:"10px"}}>
                                                <RadioGroup value={objectif} onChange={(event) => this.setState({objectif : event.target.value})} >
                                                    <FormControlLabel value="perte_poids" control={<StyledRadio />} label="Je veux perdre du poids" />
                                                    <FormControlLabel value="sport" control={<StyledRadio />} label="Je suis sportif.ve et souhaite adapter mon alimentation" />
                                                    <FormControlLabel value="prise_poids" control={<StyledRadio />} label="Je veux prendre du poids" />
                                                    <FormControlLabel value="allergie" control={<StyledRadio />} label="J'ai une allergie ou une intolérance alimentaire" />
                                                    <FormControlLabel value="reequilibrage" control={<StyledRadio />} label="Je souhaite un rééquilibrage alimentaire" />
                                                    <FormControlLabel value="regime" control={<StyledRadio />} label="Je suis un régime particulier et souhaite être accompagné dans ma transition" />
                                                    <FormControlLabel value="pathologie" control={<StyledRadio />} label="J’ai des pathologies médicales" />
                                                    <FormControlLabel value="autre" control={<StyledRadio />} label="Autre" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Fade bottom collapse when={errorObjectif}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                    Aucune option n'a été choisie
                                                </div>
                                            </Fade>
                                            <Fade bottom collapse when={objectif === "allergie"}>
                                                <h4>Laquelle ?</h4>
                                                <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <PersonIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="Indiquez votre allergie / intolérace"
                                                    onChange={(event) => {this.setState({input_allergie : event.target.value})}}
                                                    value={this.state.input_allergie}
                                                    className="karla_text"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            </Fade>
                                        </Grid>
                                        <Grid item>
                                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} onClick={this.checkStep3} >Continuer</button>
                                        </Grid>
                                    </Paper>
                                </Fade>
                            }
                            {
                                etape === 4 &&
                                <Fade right>
                                    <Paper style={{padding:"35px", margin:"8%"}} elevation="3" className="questionnaire-bloc-question">
                                        <Grid item style={{paddingBottom:"35px"}}>
                                            <h4>Combien mesurez-vous ?</h4>
                                            <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <HeightIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="Taille (en cm)"
                                                    onChange={(event) => {this.setState({taille : event.target.value})}}
                                                    value={taille}
                                                    type="number"
                                                    className="karla_text"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            <Fade bottom collapse when={errorTaille}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                    Le champs est vide ou non conforme
                                                </div>
                                            </Fade>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={tailleChecked}
                                                    onChange={(event) => this.setState({tailleChecked:event.target.checked}) }
                                                    color="primary"
                                                />
                                                }
                                                label="Je ne connais pas ma taille"
                                            />
                                            <h4>Combien pesez-vous ?</h4>
                                            <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <PersonIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="Poids (en Kg)"
                                                    onChange={(event) => {this.setState({poids : event.target.value})}}
                                                    value={poids}
                                                    className="karla_text"
                                                    type="number"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            <Fade bottom collapse when={errorPoids}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                    Le champs est vide ou non conforme
                                                </div>
                                            </Fade>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={poidsChecked}
                                                    onChange={(event) => this.setState({poidsChecked:event.target.checked}) }
                                                    color="primary"
                                                />
                                                }
                                                label="Je ne connais pas mon poids"
                                            />
                                        </Grid>
                                        <Grid item>
                                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} onClick={this.checkStep4} >Continuer</button>
                                        </Grid>
                                    </Paper>
                                </Fade>
                            }
                            {
                                etape === 5 &&
                                <Fade right>
                                    <Paper style={{padding:"35px", margin:"8%", marginTop:"15px"}} elevation={3} className="questionnaire-bloc-question">
                                        <Grid item style={{paddingBottom:"35px"}}>
                                            <h4>Déjeunez-vous le matin ?</h4>
                                            <FormControl component="fieldset">
                                                <RadioGroup value={dejeuner} onChange={(event) => this.setState({dejeuner : event.target.value})} >
                                                    <FormControlLabel value="oui" control={<StyledRadio />} label="Oui" />
                                                    <FormControlLabel value="non" control={<StyledRadio />} label="Non" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Fade bottom collapse when={errorDejeuner}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                    Aucune option n'a été choisie
                                                </div>
                                            </Fade>
                                            <Fade bottom collapse when={dejeuner === "oui"}>
                                                <h5 style={{marginTop:"10px", marginBottom:"10px"}}>Quel genre de déjeuner ? (Le plus habituellement) </h5>
                                                <FormControl component="fieldset">
                                                    <RadioGroup value={dejeunerQuoi} onChange={(event) => this.setState({dejeunerQuoi : event.target.value})} >
                                                        <FormControlLabel value="sucre" control={<StyledRadio />} label="Sucré (ex : céréales, tartines de confitures, biscuits, …)" />
                                                        <FormControlLabel value="sale" control={<StyledRadio />} label="Salé (ex : oeufs, tartine fromage/charcuterie, …)" />
                                                    </RadioGroup>
                                                </FormControl>
                                                <Fade bottom collapse when={errorQuoi}>
                                                    <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                        Aucune option n'a été choisie
                                                    </div>
                                                </Fade>
                                            </Fade>
                                            <Fade bottom collapse when={dejeuner === "non"}>
                                                <h5 style={{marginTop:"10px", marginBottom:"10px"}}>Pourquoi ?</h5>
                                                <FormControl component="fieldset">
                                                    <RadioGroup value={dejeunerPourquoi} onChange={(event) => this.setState({dejeunerPourquoi : event.target.value})} >
                                                        <FormControlLabel value="faim" control={<StyledRadio />} label="Je n’ai pas faim" />
                                                        <FormControlLabel value="temps" control={<StyledRadio />} label="Par manque de temps" />
                                                    </RadioGroup>
                                                </FormControl>
                                                <Fade bottom collapse when={errorPourquoi}>
                                                    <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                        Aucune option n'a été choisie
                                                    </div>
                                                </Fade>
                                            </Fade>
                                            <h4>De quoi se compose habituellement votre repas de midi ?</h4>
                                            <FormControl component="fieldset">
                                                <RadioGroup value={midi} onChange={(event) => this.setState({midi : event.target.value})} >
                                                    <FormControlLabel value="tartine" control={<StyledRadio />} label="Tartines, sandwichs, ..." />
                                                    <FormControlLabel value="repas_chaud" control={<StyledRadio />} label="Repas chauds maison" />
                                                    <FormControlLabel value="repas_autre" control={<StyledRadio />} label="Repas achetés à l’extérieur" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Fade bottom collapse when={errorMidi}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                    Aucune option n'a été choisie
                                                </div>
                                            </Fade>
                                            <h4>Prenez-vous un goûter ?</h4>
                                            <FormControl component="fieldset">
                                                <RadioGroup value={gouter} onChange={(event) => this.setState({gouter : event.target.value})} >
                                                    <FormControlLabel value="oui" control={<StyledRadio />} label="Oui" />
                                                    <FormControlLabel value="non" control={<StyledRadio />} label="Non" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Fade bottom collapse when={errorGouter}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                    Aucune option n'a été choisie
                                                </div>
                                            </Fade>
                                            <h4>De quoi se compose habituellement votre repas du soir ? </h4>
                                            <FormControl component="fieldset">
                                                <RadioGroup value={soir} onChange={(event) => this.setState({soir : event.target.value})} >
                                                    <FormControlLabel value="tartine" control={<StyledRadio />} label="Tartines, sandwichs, ..." />
                                                    <FormControlLabel value="repas_chaud" control={<StyledRadio />} label="Repas chauds maison" />
                                                    <FormControlLabel value="repas_autre" control={<StyledRadio />} label="Repas achetés à l’extérieur" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Fade bottom collapse when={errorSoir}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                    Aucune option n'a été choisie
                                                </div>
                                            </Fade>
                                            <h4>Que buvez-vous pendant la journée ?</h4>
                                            <FormControl component="fieldset">
                                                <RadioGroup value={boisson} onChange={(event) => this.setState({boisson : event.target.value})} >
                                                    <FormControlLabel value="eau" control={<StyledRadio />} label="Eau" />
                                                    <FormControlLabel value="soda" control={<StyledRadio />} label="Soda (ex : coca, fanta, ...)" />
                                                    <FormControlLabel value="jus" control={<StyledRadio />} label="Jus de fruits" />
                                                    <FormControlLabel value="boisson_chaude" control={<StyledRadio />} label="Boisson chaude" />
                                                    <FormControlLabel value="autre" control={<StyledRadio />} label="Autre" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Fade bottom collapse when={errorBoisson}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                    Aucune option n'a été choisie
                                                </div>
                                            </Fade>
                                        </Grid>
                                        <Grid item>
                                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} onClick={this.checkStep5} >Continuer</button>
                                        </Grid>
                                    </Paper>
                                </Fade>
                            }
                            {
                                etape ===  6 &&
                                <Fade right>
                                    <Paper style={{padding:"35px", margin:"8%"}} elevation={3} className="questionnaire-bloc-question">
                                        <Grid item style={{paddingBottom:"15px"}}>
                                        <h4>Quelle est votre adresse e-mail ? Nous en avons besoin afin de vous envoyer votre profil nutritionnel. Nous ne l’utiliserons pas pour vous spammer.</h4>
                                        </Grid>
                                        <Grid item style={{paddingBottom:"35px"}}>
                                            <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <MailIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="Email"
                                                    onChange={(event) => {this.setState({email : event.target.value})}}
                                                    value={email}
                                                    className="karla_text"
                                                    type="email"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            <Fade bottom collapse when={errorEmail}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                Le champs est vide ou non conforme
                                                </div>
                                            </Fade>
                                            <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <MailIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="Confirmez votre email"
                                                    onChange={(event) => {this.setState({emailConfirm : event.target.value})}}
                                                    value={emailConfirm}
                                                    className="karla_text"
                                                    type="email"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            <Fade bottom collapse when={errorEmailConfirm}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                Le champs est vide ou non conforme
                                                </div>
                                            </Fade>
                                        </Grid>
                                        <Grid item>
                                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} onClick={this.checkStep6} >Découvrir les résultats</button>
                                        </Grid>
                                    </Paper>
                                </Fade>
                            }
                            {
                                etape ===  7 &&
                                <Fade right>
                                    <Paper style={{padding:"35px", margin:"8%"}} elevation={3} className="questionnaire-bloc-question">
                                        <Grid item style={{paddingBottom:"15px"}}>
                                            <h2>Votre plan vous attends !</h2>
                                            <p>Merci pour vos réponses.</p>
                                            <p>Notre équipe diététique a analysé votre questionnaire et nous pouvons, ensemble, vous aider à atteindre vos objectifs.</p>
                                            <p>Nous vous avons envoyé par mail un plan diététique correspondant à votre profil à l'adresse :</p>
                                            <p><b>{ email }</b></p>
                                            <p>Pour aller plus loin, et pour répondre à vos besoins à long terme, le mieux est d'être suivi par un professionnel de la santé. Sur Food-ediet, vous serez accompagné et suivi dans l'atteinte de vos objectifs. </p>
                                            <h4>Plus qu'une étape pour activer votre compte !</h4>
                                            <p>Inscrivez-vous pour profiter votre tableau de bord contenant outils & graphiques pour faciliter votre suivi. Vous aurez également la possibilité de réserver une consultation en ligne et contacter votre diététicien.ne à tout moment.</p>
                                            <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                                <LockIcon style={{padding: "10px"}}  />
                                                <InputBase
                                                    placeholder="Mot de passe"
                                                    onChange={(event) => {this.setState({password : event.target.value})}}
                                                    value={this.state.password}
                                                    type="password"
                                                    className="karla_text"
                                                    style={{width:"100%"}}
                                                />
                                            </Paper>
                                            <Fade bottom collapse when={errorPassword}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                Votre mot de passe doit contenir doit contenir au minimum 6 caractères alphanumériques
                                                </div>
                                            </Fade>
                                        </Grid>
                                        <Grid item>
                                            <Fade bottom collapse when={error}>
                                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                                {error}
                                                </div>
                                            </Fade>
                                            <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} onClick={this.checkStep7} >Finaliser mon inscription</button>
                                        </Grid>
                                    </Paper>
                                </Fade>
                            }
                        </Grid>
                    </Grid>
                <MyFooter/>
            </div>
        );
    }
}


export default compose(withFirebase)(QuestionnairePage);