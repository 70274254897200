import React from 'react'
import { Helmet } from 'react-helmet'

const EmptyPage = () => {
    return (
    <div>
        <Helmet>
            <meta name="prerender-status-code" content="404"/>
        </Helmet>
        <h2>Erreur 404 - Cette page n'existe pas!</h2>
    </div> )
};

export default EmptyPage;