import { Button, Grid, Paper, TextField } from '@mui/material';
import React, { Component, createRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Header from '../../../components/Header';
//import { MyColor } from '../../../components/Theme';
import './headerBlog.css';
import { compose } from 'recompose';
import { withAuthorization } from '../../../components/Session';
import { DesktopView, MobileView } from '../../../components/Theme';
import { withRouter } from '../../../App';

class HeaderBlog extends Component {

    anchorRef = createRef();

    state = {
        search:""
    }

    confirm = () => {
        const { search } = this.state;
        if(search)
            window.location.href = "/blog/recherche/" + encodeURI(search)
    }
    
    render() {

        return (
            <div>
                <Header />
                <DesktopView>
                    <div style={{ backgroundColor:"white", position:"relative",top:"0", left:"0", zIndex:"500", width:"100%",marginTop:"5px"}} ref={this.anchorRef}>
                        <Paper style={{backgroundColor:"rgb(255, 248, 231)", width:"100%", boxShadow:"0px 3px 3px -2px rgb(0 0 0 / 20%)" }}  square={false} elevation={3}>
                            <Grid container spacing={2} justifyContent="center" alignItems="center" direction="row" style={{width:"100%"}}>
                                <Grid item style={{padding:"6px 8px"}}>
                                    <Button href="/blog/categorie/article" startIcon={<img src="/articles.png" alt="articles" width="30" height="30" />} style={{color:"#09371F", textTransform:"none", marginRight:"35px", fontSize:"16px"}} >
                                        Articles diététiques
                                    </Button>
                                </Grid>
                                <Grid item style={{padding:"6px 8px"}}>
                                    <Button href="/blog/categorie/recette" startIcon={<img src="/recettes.png" alt="recettes" width="30" height="30"/>} style={{color:"#09371F", textTransform:"none", fontSize:"16px",marginRight:"35px",}} >
                                        Recettes
                                    </Button>
                                </Grid>
                                <Grid item style={{padding:"6px 8px"}}>
                                    <Button href="/blog" startIcon={<img src="/apprendre.png" alt="apprendre" width="30" height="30"/>} style={{color:"#09371F", textTransform:"none", fontSize:"16px"}} >
                                        Apprendre
                                    </Button>
                                </Grid>
                            </Grid>
                            <div style={{position:"absolute", right:"10px", top:"10px"}}>
                                    <Grid item container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <SearchIcon />
                                        </Grid>
                                        <Grid item>
                                            <form onSubmit={(event) => {event.preventDefault(); this.confirm()}}>
                                            <TextField 
                                                variant="standard"
                                                type="search"  
                                                onChange={(event) => {this.setState({search : event.target.value})}}
                                                placeholder="Rechercher un article, recette, ..." 
                                                style={{width:"250px"}} 
                                            />
                                            </form>
                                        </Grid>
                                    </Grid>
                            </div>
                        </Paper>
                    </div>
                </DesktopView>
                <MobileView>
                    <Paper style={{backgroundColor:"rgb(255, 248, 231)", width:"100%", boxShadow:"0px 3px 3px -2px rgb(0 0 0 / 20%)", paddingLeft:"5px" }}  square={false} elevation={3}>
                        <Grid item container style={{width:"100%"}} spacing={1} alignItems="flex-end">
                            <Grid item>
                                <SearchIcon />
                            </Grid>
                            <Grid item>
                                <form onSubmit={(event) => {event.preventDefault(); this.confirm()}}>
                                <TextField 
                                    type="search"  
                                    variant="standard"
                                    onChange={(event) => {this.setState({search : event.target.value})}}
                                    placeholder="Rechercher un article, recette, ..." 
                                    style={{width:"250px"}} 
                                    fullWidth 
                                />
                                </form>
                            </Grid>
                        </Grid>
                    </Paper>
                </MobileView>
                
            </div>
        );
    }
}

export default compose(withRouter, withAuthorization((authUser) => true))(HeaderBlog);