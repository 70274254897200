import React, { Component } from 'react'
import { DesktopView, MobileView } from '../../../../components/Theme';
import { withAuthorization } from '../../../../components/Session';
import { Paper, CircularProgress, Button, Container} from '@mui/material';
import styled from 'styled-components';


import Calendar from 'react-calendar';
import './consultationCreationPage.css';
import getMotifCommentaires from './motif';

import { compose } from 'recompose';
import LoadingPanel from '../../../robustness/LoadingPanel';
import MyStepper, { MyStepperMobile } from './myStepper';
import Resume from './resume';
import { ELO_UID, stripePromise } from '../../../../utils/Settings';
import MoyenDePaiement, { getConsultationType } from './moyenDePaiement';
import { BackgroundDesktopPage, BackgroundMobilePage } from '../../Home';
import { Helmet } from 'react-helmet';
import { withRouter } from '../../../../App';
import {  doc, onSnapshot } from "firebase/firestore";


class ConsultationCreationPage extends Component {

    state = {
        activeStep: 0,
        steps: [],
        dateSelected: null,
        firstSlot: false,
        slot: null,
        loadingSlots: false,
        consultation: {},
        motif: "",
        autreMotif: "",
        commentary: "",
        card: null,
        keySlot: null,
        dateInMilliseconds: null,
        type_paiement: "",
    }

    getContent = (index) => {
        switch(index) {
            case 0: return this.getChoixDate();
            case 1: return getMotifCommentaires(this.onSelectMotif, this.onChange, this.state.commentary, this.state.autreMotif, this.state.motif, this.handleClickContinue, () => {this.setState({ activeStep: this.state.activeStep -1 })});
            case 2: 
                return MoyenDePaiement({
                    authUser:this.props.authUser,
                    handleClickPrecedent:() => {this.setState({ activeStep: this.state.activeStep -1})},
                    setCard:(card) => this.setState({card}),
                    handleClickContinue:this.handleClickContinue,
                    card:this.state.card,
                    type_paiement:this.state.type_paiement,
                    onChange:this.onChange,
                });
            case 3: 
                return <Resume 
                    slot={this.state.slot} 
                    keySlot={this.state.keySlot} 
                    confirmReservation={this.confirmReservation}
                    motif={this.state.motif}
                    commentary={this.state.commentary}
                    authUser={this.props.authUser}
                    handleClickPrecedent={() => {this.setState({ activeStep: this.state.activeStep -1})}}
                />;
            default: return this.getChoixDate();
        }
    }

    getChoixDate = () => {

        return <div>
            <h3>Etape 1 - Choisir une date</h3>
            <MyCalendar locale="fr-BE" onChange={this.onDateChange} value={this.state.dateSelected} />
            <h3>Etape 2 - Choisir une heure disponible</h3>
            <div style={{height: '150px', border: "1px solid #a0a096", background:'white'/*background: 'rgba(241,241,241,0.8)'*/, overflowY: 'scroll', width:'100%'}}>
                {this.state.loadingSlots && <CircularProgress/>}
                {!this.state.loadingSlots && this.state.firstSlot && 
                <div>
                    {this.getSlots()}
                </div>}
            </div>
            <br/>
        </div>
    }

    slotClicked = (key) => {
        const active = this.state.activeStep + 1;
        this.setState({ activeStep:active, keySlot: key })
    }

    handleClickContinue = () => {
        if(this.state.motif === "Autre")
            this.setState({ motif:this.state.autreMotif })
        const active = this.state.activeStep + 1; 
        this.setState({ activeStep:active })
    }

    // Create a list of slot items 
    getSlots = () => {
        const { slot } = this.state;
        if(!slot || Object.keys(slot).length === 0) {
            return <div><center><i>Pas de date prévue pour ce jour !</i></center></div>
        }
        if(slot.date <= Date.now()) {
            return <div><center><i>Pas de date prévue pour ce jour !</i></center></div>
        }
        if(slot && slot.slots) {
            slot.slots.sort(function(a, b) {
                return a.heure - b.heure;
            });
        }
        return Object
        .keys((slot && slot.slots) ? slot.slots : {})
        .map(key => {
            if(slot.slots[key].heure <= Date.now())
                return(<div></div>)
            if(slot.slots[key].statut === 1)
            return (<MyButton key={key} onClick={() => this.slotClicked(key)}>
                {new Date(slot.slots[key].heure).toLocaleTimeString('fr-BE', options)} - 1 Place Libre
            </MyButton>);
            return (<MyButton key={key} disabled>
                {new Date(slot.slots[key].heure).toLocaleTimeString('fr-BE', options)} - 0 Place Libre
            </MyButton>);
        })
    };

    // Date selected in Calendar
    // Store the date as value for the calendar
    // Get the slot of the date if exists & slot is in the futur
    onDateChange = (date) => {
        this.setState({ dateSelected: date, loadingSlots: true, slot: null})
        var dateInMilliseconds =  new Date(date).getTime()

        this.ref = onSnapshot(
            doc(this.props.firebase.firestore, "slots", dateInMilliseconds + ""), 
            (doc) => {
                if(doc.exists) {
                    var item = doc.data();
                    if(item) {
                        item.id = doc.id;
                        this.setState({ firstSlot: true, slot : item, loadingSlots: false, dateInMilliseconds })
                    }
                    else {
                        this.setState({ firstSlot: true, slot : null, loadingSlots: false, dateInMilliseconds })
                    }
                }
            },
            (error) => {
              console.error(error)
            }
        );
    }

    handleChangeCommentary = (e) => {
        this.setState({ motif: e.target.value })
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onSelectMotif = (e, value) => {
        this.setState({
            motif: value.title
        })
    }

    // Faire payer la personne. Si ça réussi, faire tout ça sur le serveur 
    // Add consultation in patient consultation list, 
    // Add consultation in diet consultation list
    // Update the slot to set it 'used'
    confirmReservation = async (circularSuccess, circularFail) => {

        const { slot, dateInMilliseconds, keySlot, type_paiement } = this.state;

        const typeConsultation = getConsultationType(this.props.authUser);

        const consultationString = {
            commentary: String(this.state.commentary),
            date: String(slot.slots[keySlot].heure),
            dateString: new Date(slot.slots[keySlot].heure).toLocaleDateString('fr-BE', {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}),
            dietId: String(ELO_UID),
            dietName: "Eloïse Feryr",
            motif: String(this.state.motif),
            patientId: String(this.props.authUser.data.uid),
            patientName: String(this.props.authUser.data.firstname + " " + this.props.authUser.data.name),
            status: String(0),
            slotId: "" + dateInMilliseconds,
            keyslot: this.state.keySlot + "",
            currency: "eur",
            customerId: this.props.authUser.customer.stripeId+ "",
            typeConsultation: typeConsultation.id+ "",
        }

        // Paiement 
        var payByBancontact = this.props.firebase.functions.httpsCallable(process.env.NODE_ENV === 'production' ? 'payByBancontact' : 'payByBancontactTest');
        const myresult = await payByBancontact({
            customerId: this.props.authUser.customer.stripeId, 
            return_url: window.location.origin + "/home/consultation", 
            consultation: consultationString,
            authUser: {
                firstname : this.props.authUser.data.firstname,
                name:this.props.authUser.data.name,
                email:this.props.authUser.data.email,
            },
            payment_type : type_paiement});
        circularSuccess();
        const stripe = await stripePromise;
         await stripe.redirectToCheckout({
            sessionId: myresult.data.id,
          });
    }


    render() {

        const { activeStep } = this.state;

        if(!this.props.authUser || !this.props.authUser.data || !this.props.authUser.data.email)
            return <LoadingPanel/>

        const body = (<div>
                <Container maxWidth="md" style={{marginLeft:"0px"}}>
                    <h3>Plannification d'une consultation</h3>
                    <Paper elevation={3}>
                        <MyStepper activeStep={activeStep}/>
                        <WrapperContent>
                            {this.getContent(activeStep)}
                        </WrapperContent>
                    </Paper>
                </Container>
        </div>)

        const bodyMobile = (<div>
            <Container maxWidth="md" style={{marginLeft:"0px", padding:"0px"}}>
                <h3>Plannification d'une consultation</h3>
                <Paper elevation={3}>
                    <MyStepperMobile activeStep={activeStep}/>
                    <div style={{padding:"10px"}}>
                        {this.getContent(activeStep)}
                    </div>
                </Paper>
            </Container>
        </div>)

        return (
            <div>
                <Helmet>
                    <title>Réserver une consultation</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                 <DesktopView >
                        <BackgroundDesktopPage {...this.props}>
                            {body}
                        </BackgroundDesktopPage>
                    </DesktopView>
                    <MobileView >
                        <BackgroundMobilePage {...this.props}>
                            {bodyMobile}
                        </BackgroundMobilePage>
                    </MobileView>
            </div>
        )
    }
}

const condition = authUser => !!authUser;

export default compose(withRouter, withAuthorization(condition))(ConsultationCreationPage);

const options = {  hour: 'numeric', minute: 'numeric'};


const WrapperContent = styled.div`
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
`;

export const MyCalendar = styled(Calendar)`
    width: 100%;
    font-family: 'Karla', sans-serif;

`

const MyButton = styled(Button)`
    width: 100%;
    background-color: white;
    color: black;
    font-family: 'Karla', sans-serif;
`;

