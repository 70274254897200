/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import {  Grid } from '@mui/material';
import React, { Component } from 'react';
import {Link as ReactLink} from 'react-router-dom';
import { withRouter } from '../../../App';
import { MyCalendar } from '../../../pages/connected/Consultation/ConsultationCreationPage';
import './prendrerendezvous.css';

class PrendreRendezVous extends Component {
    render() {
        return (
            <div style={{
                backgroundImage:"url(/background_dv_02.png)", 
                backgroundRepeat:"no-repeat",
                backgroundPosition:"top right",
                backgroundSize:"contain"
            }}>
                <div className="container" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"10vw", paddingBottom:"40px"}} >
                    <a className="anchor" id="rendezvous" ></a>
                    <center style={{paddingTop:"25px", paddingBottom:"25px"}}>
                        <h2  style={{font:"normal normal normal 71px/1.4em 'rozha one',serif", fontSize:"calc(10px + 3vw)", lineHeight:"1.2em", color:"#09371F", marginTop:"0px", paddingTop:"15px" }}>Prendre rendez-vous en ligne</h2>
                    </center>
                
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <p>Sur Food-eDiet, la prise de rendez-vous est facile, rapide et se fait entierement <b>en ligne.</b></p>
                            <ul>
                                <li style={{paddingBottom:"10px"}}>Choississez la date et l'heure selon votre disponibilité.</li>
                                <li style={{paddingBottom:"10px"}}>Expliquez-nous le motif de votre consultation ainsi que des détails si nécessaire. Ainsi, nous serons en mesure de préparer au mieux un rendez-vous et un suivi qui vous correspond vraiment.</li>
                                <li style={{paddingBottom:"10px"}}>Après avoir procédé au paiement, vous recevrez par mail une confirmation ainsi qu'un lien vers votre téléconsultation.</li>
                            </ul>
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={6}>
                            <MyCalendar locale="fr-BE"  style={{width:"100%", fontFamily:"'Karla', sans-serif"}}  />
                        </Grid>
                    </Grid>
                    <div style={{paddingTop:"50px", paddingBottom:"45px"}}>
                        <center><ReactLink to='inscription' className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative"}}>Je prends rendez-vous !</button></ReactLink></center>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PrendreRendezVous);