/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react';
import { Button, ClickAwayListener, Divider, Grow, IconButton, MenuItem, MenuList, Paper, Popper} from '@mui/material';
import { LOGO_BLACK } from '../../utils/Settings';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import MenuIcon from '@mui/icons-material/Menu';
import { DesktopView, MobileView, MyColor } from '../Theme';
import { LinkBehavior } from '../Theme/LinkBehaviour';
import { withRouter } from '../../App';

class Header extends Component {

    state = {
        open : false,
    }

    anchorRef = createRef()

    render() {

        const { authUser, navigate } = this.props;
        const { open } = this.state;

        return (
        <div  className="karla_text" name="header" style={{width:"100%", backgroundColor:"grey", display:"run-in", }} >
            <div style={{width:"100%",  backgroundColor:MyColor.SECONDARY_GREEN}}>
                <center>
                    
                    <a href="/#nous-contacter" style={{
                        textAlign:"center", 
                        paddingTop: "11px", 
                        paddingBottom: "11px", 
                        font:"normal normal normal 13px/1.4em avenir-lt-w01_85-heavy1475544,sans-serif",
                        fontWeight: "bold",
                        color:"#09371F",
                        cursor: "pointer",
                        //textDecoration:"none",
                        display:"block",
                        
                        }}>
                        Cliquer ici pour recevoir notre ebook gratuit : "Nos 5 meilleurs conseils pour perdre du poids"
                    </a>
                </center>
            </div>
            <div name="appBar" >
                <div style={{backgroundColor:"white", display: "flex", paddingRight: "50px", paddingLeft: "35px"}}>
                    <div style={{paddingRight:"100px", position:"relative"}}>
                        <div style={{/*width:"100px", height:"100px", backgroundColor:"white", position:"absolute", top:"0",*/ }}>
                            <a href="/"  onClick={() => this.props.firebase.analytics.logEvent('accueil_header_logo_click')}  >
                                <img src={LOGO_BLACK} alt="logo foodediet" width="59px" height="45px" style={{cursor: "pointer",  paddingBottom:"10px", paddingTop:"10px"}}/>
                            </a>
                        </div>
                        
                    </div>
                    <div style={{flexGrow: 1}} />
                    <DesktopView>
                        <Button href="/"  onClick={() => this.props.firebase.analytics.logEvent('accueil_header_menu_click')}  className="karla_text" style={{color:"#09371F", textTransform:"none", padding:"6px 8px"}}>Accueil</Button>
                        <Button href="/pourquoi-food-ediet" onClick={() => this.props.firebase.analytics.logEvent('pourquoi_food-ediet_header_menu_click')} className="karla_text" style={{color:"#09371F", textTransform:"none", padding:"6px 8px"}}>Pourquoi Food-eDiet ?</Button>
                        <Button href="/dieteticienne/eloise-feryr" onClick={() => this.props.firebase.analytics.logEvent('je_suis_dieteticienne_header_menu_click')} className="karla_text" style={{color:"#09371F", textTransform:"none", padding:"6px 8px"}}>Notre équipe</Button>
                        <Button  href="/tarifs" onClick={() => this.props.firebase.analytics.logEvent('forfait_tarifs_header_menu_click')}  className="karla_text" style={{color:"#09371F", textTransform:"none", padding:"6px 8px"}}>Forfaits et tarifs</Button>
                        <Button href="/ebooks" onClick={() => this.props.firebase.analytics.logEvent('je_suis_dieteticienne_header_menu_click')} className="karla_text" style={{color:"#09371F", textTransform:"none", padding:"6px 8px"}}>Ebooks</Button>
                        <Button  className="karla_text" href="/blog" onClick={() => this.props.firebase.analytics.logEvent('blog_header_menu_click')}  style={{color:"#09371F", textTransform:"none", padding:"6px 8px"}}>Blog</Button>
                        <button 
                        type="button" className="circular-espace-perso-btn" style={{width:"auto", height:"auto"}} 
                        onClick={() => authUser  && authUser.data ? (authUser.data.level >=4 ? navigate("/admin") : navigate("/home")) : navigate("/connexion") }>
                            {authUser && authUser.data  ? "Mon espace personnel" : "Se connecter"} 
                        </button>
                    </DesktopView>
                    <MobileView>
                        <IconButton ref={this.anchorRef} onClick={() => {this.setState({open : true})}}><MenuIcon/></IconButton>
                        <Popper open={open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 5000}}>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={() => this.setState({open: false})}>
                                            <MenuList autoFocusItem={open} id="menu-list-grow" >
                                                <MenuItem component={LinkBehavior} to="/"  onClick={() => this.props.firebase.analytics.logEvent('accueil_header_menu_click')}  >
                                                    Accueil
                                                </MenuItem>
                                                <Divider/>
                                                <MenuItem component={LinkBehavior} to="/pourquoi-food-ediet"  onClick={() => this.props.firebase.analytics.logEvent('pourquoi_food-ediet_header_menu_click')}  >
                                                    Pourquoi food-ediet ?
                                                </MenuItem>
                                                <Divider/>
                                                <MenuItem component={LinkBehavior} to="/dieteticienne/eloise-feryr" onClick={() => this.props.firebase.analytics.logEvent('je_suis_dieteticienne_header_menu_click')}>
                                                    Notre équipe
                                                </MenuItem>
                                                <Divider/>
                                                <MenuItem component={LinkBehavior} to="/tarifs"  onClick={() => this.props.firebase.analytics.logEvent('forfait_tarifs_header_menu_click')} >
                                                    Forfaits et tarifs
                                                </MenuItem>
                                                <Divider/>
                                                <MenuItem component={LinkBehavior} to="/ebooks"  onClick={() => this.props.firebase.analytics.logEvent('ebooks_header_menu_click')} >
                                                    Ebooks
                                                </MenuItem>
                                                <Divider/>
                                                <MenuItem component={LinkBehavior} to="/blog" onClick={() => this.props.firebase.analytics.logEvent('blog_header_menu_click')}>
                                                    Blog
                                                </MenuItem>
                                                <Divider/>
                                                <MenuItem onClick={() => authUser  && authUser.data ? navigate("/home") : navigate("/connexion") }>
                                                {authUser && authUser.data  ? "Mon espace personnel" : "Se connecter"} 
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </MobileView>
                </div>
            </div>
        </div>)
    }
}

/*const PhoneAppBar = (props) => {
    const { open, anchorRef, handleClose } = props;
    return 
}*/

export default compose(withRouter, withAuthorization((authUser) => true))(Header);