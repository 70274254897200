import {  Paper, Grid,  InputBase } from '@mui/material';
import React, { Component } from 'react'
import { withFirebase } from '../../../helpers';
import { compose } from 'recompose';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import Fade from 'react-reveal/Fade';
import PhoneIcon from '@mui/icons-material/Phone';
import Validator from '../../../utils/Validator';
import { Level } from '../../../components/Theme';
import { withRouter } from '../../../App';

class NouveauPatientPanel extends Component {

    state = {
        // Fields
        prenom:"",
        nom:"",
        email:"",
        confirm_password:"",
        telephone:"",

        // Errors
        error:"",
        errorEmail:"",
        errorPrenom:"",
        errorNom:"",
        errorTelephone:"",
    }

    confirm = () => {

        const {prenom, nom, email, telephone} = this.state;

        var okPrenom = Validator.checkInput(prenom);
        this.setState({errorPrenom: !okPrenom})

        var okNom = Validator.checkInput(nom);
        this.setState({errorNom: !okNom})

        if(email) {
            var okMail = Validator.checkEmail(email);
            this.setState({errorEmail: !okMail})
        }
        else okMail = true
        
        if(telephone) {
            var okTelephone = Validator.checkInput(telephone);
            this.setState({errorTelephone: !okTelephone})
        } 
        else okTelephone = true
        
        // S'inscrire si pas d'erreur et ok mail/confirmPwd
        if(okPrenom && okNom && okMail && okTelephone ) {
            this.AddPatient();
        }
    }

    AddPatient = () => {

        const { email, telephone, nom, prenom } = this.state;

        const user = {
            name: nom,
            firstname: prenom,
            email: email,
            telephone: telephone,
            level: Level.NON_INSCRIT,
            dietId:  this.props.authUser.data.uid,
            //uid: MailAuthUser.user.uid
        }

        this.props.firebase.firestore.collection("users").add(user)
        .then((docRef) => {
            this.props.firebase.firestore.collection("users").doc(this.props.authUser.data.uid).update({
                numberOfPatients : this.props.firebase.app.firestore.FieldValue.increment(1)
            }).then((result) => {
                this.props.firebase.firestore.collection("users").doc(docRef.id).update({uid : docRef.id}).then((result) => {
                    this.props.navigate("/admin/profil/" + docRef.id);
                });
            });
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
        
    }


    render() {

        const { errorEmail, errorNom, errorTelephone, errorPrenom,  error } = this.state;

        return (
            <div className="inscriptionPage karla_text" style={{backgroundColor:"rgba(250, 251, 252, 0.6)"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Paper style={{padding:"10px 10px 10px", marginBottom:"10px"}}>
                            <h1 style={{fontSize:"1em", marginTop:"1em", marginBottom:"1em"}}>
                                Encoder un nouveau patient
                            </h1>
                            <form onSubmit={(event) => {event.preventDefault(); this.confirm()}}>
                                <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                    <PersonIcon style={{padding: "10px"}}  />
                                    <InputBase
                                        placeholder="Prénom"
                                        onChange={(event) => {this.setState({prenom : event.target.value})}}
                                        value={this.state.prenom}
                                        className="karla_text"
                                        style={{width:"100%"}}
                                    />
                                </Paper>
                                <Fade bottom collapse when={errorPrenom}>
                                    <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                    Le champs est obligatoire ou non conforme
                                    </div>
                                </Fade>
                                <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                    <PeopleIcon style={{padding: "10px"}}  />
                                    <InputBase
                                        placeholder="Nom"
                                        onChange={(event) => {this.setState({nom : event.target.value})}}
                                        value={this.state.nom}
                                        className="karla_text"
                                        style={{width:"100%"}}
                                    />
                                </Paper>
                                <Fade bottom collapse when={errorNom}>
                                    <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px" , fontSize:"12px"}}>
                                    Le champs est obligatoire ou non conforme
                                    </div>
                                </Fade>
                                <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                    <PhoneIcon style={{padding: "10px"}}  />
                                    <InputBase
                                        placeholder="Numéro de téléphone"
                                        onChange={(event) => {this.setState({telephone : event.target.value})}}
                                        value={this.state.telephone}
                                        type="text"
                                        className="karla_text"
                                        style={{width:"100%"}}
                                    />
                                </Paper>
                                <Fade bottom collapse when={errorTelephone}>
                                    <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                    Le numéro de téléphone indiqué n'est pas conforme
                                    </div>
                                </Fade>
                                <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                    <EmailIcon style={{padding: "10px"}}  />
                                    <InputBase
                                        placeholder="E-mail"
                                        onChange={(event) => {this.setState({email : event.target.value})}}
                                        value={this.state.email}
                                        type="email"
                                        className="karla_text"
                                        style={{width:"100%"}}
                                    />
                                </Paper>
                                <Fade bottom collapse when={errorEmail}>
                                    <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                    L'adresse mail indiquée est non conforme
                                    </div>
                                </Fade>
                                <center style={{paddingBottom:"25px", paddingTop:"25px"}}>
                                    <Fade bottom collapse when={error}>
                                        <span style={{color:"red", fontSize:"14px",  paddingBottom:"10px",}}>Attention : {error}</span>
                                    </Fade>
                                    <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} >Encoder le patient</button>
                                </center>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper style={{padding:"10px 10px 10px", marginBottom:"10px"}}>
                            <h2>Statistiques</h2>
                            <p>Nombre de patients : {this.props.authUser.data.numberOfPatients ?? 0}</p>
                            <p>Limite du nombre de patients : {50}</p>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default compose(withFirebase, withRouter)(NouveauPatientPanel);