import { Avatar, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { Component } from 'react';
import { DesktopView, MobileView, MyColor } from '../../components/Theme';
import './home.css';
import { withAuthorization } from '../../components/Session'
import { compose } from 'recompose';
import { withFirebase } from '../../helpers';
import LoadingPanel from '../robustness/LoadingPanel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import SideBar from './Menu/SideBar';
import MyAppBar from './Menu/MyAppBar';

import DocumentPanel from './Document/DocumentPanel';
import ConsultationPanel from './Consultation/ConsultationPanel';
import MessengerPanel from './Messenger/MessengerPanel';
import { ELO_UID, } from '../../utils/Settings';
import LabelBottomNavigation from './Menu/LabelBottomNavigation';
import {Link as ReactLink} from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import ApportPanel from './Apports/ApportPanel';
import Assistance from './Assistance';
import { Helmet } from 'react-helmet';
import FutureConsultation from './FutureConsultation';


export const BackgroundDesktopPage = (props) => {
    const {authUser} = props;
    return (
        <div style={{backgroundColor: 'rgba(241,241,241,0.8)', height:"100vh"}} className="newhome">
                   
                    <div style={{backgroundColor:MyColor.SECONDARY_GREEN,height:"100%", padding:"10px", paddingLeft:"0px", width:"100%", marginRight:"auto", marginLeft:"auto"}}>
                        <div style={{position:"relative"}}>
                            <MyAppBar authUser={authUser} position="fixed" />
                            <div style={{height:"75px", width:"100%", backgroundColor:MyColor.SECONDARY_GREEN, top:"0px", position:"fixed"}}/>
                        </div>
                        <div>
                            <div style={{height:"100%"}} className="myMenu">
                            <div style={{position:"fixed", top:"50px", width:"300px"}}>
                                <div style={{paddingTop:"40px", height:"100%",  }}>
                                    <div className="documentWrapper" style={{backgroundColor:"rgb(235,235,235)", height:"calc(100vh - 85px)", minHeight:"calc(100vh - 85px)",  overflowY:"auto", justifyContent:"space-between"}}>
                                        <div style={{marginTop:"10px",}}>
                                            <div>
                                                <div className="MyName" style={{padding:"20px"}}>
                                                    <center>
                                                        <ReactLink to='/home/profil' >
                                                            <Avatar src={authUser.data.imageURL ?? "https://www.allsolutionslocksmiths.com.au/wp-content/uploads/2011/07/user.png"} alt={authUser.data.firstname} style={{width:"150px", height:"150px"}}/>
                                                        </ReactLink>
                                                        <h3>{authUser.data.firstname ?? ""} {authUser.data.name ?? "Visiteur"}</h3>
                                                    </center>
                                                </div>
                                                <SideBar />
                                            </div>
                                            <div >
                                                <List>
                                                    <Assistance>
                                                    <ListItem button key={"Assistance"} >
                                                        <ListItemIcon>
                                                            <HelpOutlineIcon/>
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <h3 style={{marginTop:"5px", marginBottom:"5px"}}>
                                                                Assistance
                                                            </h3>
                                                        </ListItemText>
                                                    </ListItem>
                                                    </Assistance>

                                                </List>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div> 
                            <div style={{marginLeft:"300px"}}>
                                <div style={{height:"80px"}} />
                                <div style={{backgroundColor:"white", height:"calc(100vh - 90px)", padding:"8px"}}>
                                    <div style={{ backgroundColor:"rgb(235,235,235)", padding:"10px", minHeight:"calc(100vh - 120px)"}}>
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
    )

}

export const BackgroundMobilePage = (props) => {
    const {authUser} = props;
    return (
    <div style={{backgroundColor: 'rgba(241,241,241,0.8)',}}>
        <MyAppBar authUser={authUser} />
        <div style={{height:"75px", width:"100%", backgroundColor:MyColor.SECONDARY_GREEN, top:"0px", position:"fixed"}}/>
        <div style={{height:"75px", width:"100%", backgroundColor:MyColor.SECONDARY_GREEN, }}/>
        <Container maxWidth="xs" style={{padding:"10px"}}>
            {props.children}
        </Container>
        <div style={{height:"75px"}}></div>
        <LabelBottomNavigation/>
    </div>);
}


class Home extends Component {

    componentDidMount() {
        this.props.firebase.analytics.logEvent('home_page_view');
    }


    render() {

        const {authUser} = this.props;


        if(!authUser.data)
            return (<LoadingPanel />)

        return (
            <div>
                <Helmet>
                    <title>Espace personnel</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
              
                 <DesktopView >
                     
                    <BackgroundDesktopPage {...this.props}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FutureConsultation authUser={authUser} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Fade bottom>
                                    <ConsultationPanel authUser={authUser} /> 
                                </Fade>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Fade bottom delay={100}>
                                    <DocumentPanel authUser={authUser} />
                                </Fade>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Fade bottom delay={200}>
                                    <MessengerPanel authUser={authUser} to_user_uid={ELO_UID}/>
                                </Fade>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Fade bottom delay={300}>
                                    <ApportPanel authUser={authUser}/>
                                </Fade>
                            </Grid>
                           
                        </Grid>
                    </BackgroundDesktopPage>
                </DesktopView>
                <MobileView>
                    <BackgroundMobilePage {...this.props}>
                    <Grid container spacing={3} style={{margin:"0", width:"100%"}}>
                        <Grid item xs={12}>
                            <FutureConsultation authUser={authUser} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}  style={{margin:"0"}}>
                            <ConsultationPanel authUser={authUser} scale="scale(0.7)"/> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} style={{margin:"0"}}>
                            <DocumentPanel authUser={authUser} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{margin:"0"}}>
                            <MessengerPanel authUser={authUser} id={ELO_UID}  to_user_uid={ELO_UID} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{margin:"0"}}>
                            <ApportPanel authUser={authUser}/>
                        </Grid>
                        
                    </Grid>
                    </BackgroundMobilePage>
                </MobileView>
            </div>
        );
    }
}

const condition = authUser => !!authUser;

export default compose(withFirebase, withAuthorization(condition))(Home);


