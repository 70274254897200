import styled from 'styled-components';
import { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';

const GreenButton = styled.button`
    padding: 5px 10px;
    cursor: pointer;
    position: ${props => props.position ?? "absolute"};
    margin-left: ${props => props.marginLeft ?? "0px"};
    margin-right: ${props => props.marginRight ?? "0px"};
    margin-bottom: ${props => props.marginBottom ?? "0px"};
    margin-top: ${props => props.marginTop ?? "0px"};
    display: ${props => props.display ?? "block"};
    /*background: linear-gradient(to left bottom, rgb(65,209,204),rgb(224,255,255));*/
    background: #09371F;
    outline: none;
    color: white;
    font-size: ${props => props.fontSize ?? "14px"};
    border: 0;
    font-family: 'Karla', sans-serif;
    border-radius: 30px;
    &:hover {
        background: #09371F;
        opacity: 0.6;
        color: white;
    }
    width: ${props => props.width ?? "auto"};
`;

const Background = styled.div`
    background: rgba(241, 241, 241, 0.8);
    //background: rgba(255,248,231, 0.4);
    padding: 10px;
    padding-left: ${props => props.paddingLeft ?? "10px"};
    font-family: 'Karla', sans-serif; 
    min-height: ${props => props.minHeight ?? "400px"};
    margin-left:  ${props => props.marginLeft ?? 0};
    flex-grow: 1;
    margin-top: ${props => props.marginTop ?? "65px"};
    width: auto;
    min-width: ${props => props.minWidth ?? "auto"};
`

export { Background} ;
export { GreenButton };


export function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}

export const Level = {
  NON_INSCRIT : 0,
  INSCRIT : 1,
  ABONNE : 2,
  ELYSIUM : 3,
  DIET : 4,
  ADMIN : 5,
}

export const MyColor = {
  PRIMARY_GREEN : '#09371F',
  SECONDARY_GREEN : '#bce08a',
  PRIMARY_ORANGE : '#ffc47c',
}

export const MyFontFamily = {
  PRIMARY_KARLA : "'Karla', sans-serif",
  SECONDARY_ROHZA : "'rozha one',serif"
}


 
export const DesktopView = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 })
  return isDesktop ? children : null
}
/*const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}*/
export const MobileView = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1223 })
  return isMobile ? children : null
}