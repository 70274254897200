/* eslint-disable no-loop-func */
import React, { Component } from 'react'
import Calendar from 'react-calendar'
import { Paper, IconButton, Tooltip, List, ListItem, ListItemText, ListItemSecondaryAction, MenuItem, Select } from '@mui/material'
import styled from 'styled-components'
import TimePicker from 'react-time-picker'
import AddIcon from '@mui/icons-material/Add';
import { compose } from 'recompose'
import { withFirebase } from '../../../helpers'
import withSnackBar from '../../../components/Theme/MySnackBar/withSnackBar'
import DeleteIcon from '@mui/icons-material/Delete';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {  doc, query, onSnapshot } from "firebase/firestore";
import {  getDoc } from "firebase/firestore";


// Horaire Panel pour le tableau de bord
class Horaire extends Component {

    state = {
        dateSelected : null, // format de react-calendar, sert de value au calendar
        timeSelected: null, // format de react-time, sert de value au time-picker
        totalTimeMillisecond : null, // milliseconds cumulée -> date du jour + temps choisis
        dateTimeMillisecond: null, // millisecondes du jour, sert d'id pour firestore
        slot: null, // slot réccupéré identifié par une date du jour, contient des slot items
        slots: [], // liste de ListItem générée lorsqu'on a lu le slot
        loaded: true, // sert de refresh pour la page
        datesArraySelected : null, //pour le range date => Lorsqu'on change de date dans le calendrier, on override
        timeType:0, // 0 = unique, 1 = simple

    }

    // Click on new date on calendar 
    // Store the date and update the list of available slots
    onDateChange = (date) => {
        this.setState({ 
            dateSelected: new Date(date), 
            totalTimeMillisecond : new Date(date).getTime(), 
            dateTimeMillisecond: new Date(date).getTime(), 
            loaded : false,
            datesArraySelected: [new Date(date), new Date(date)],
        }, () => this.getSlotsAtSelectedTime())
    }

    getTimeInMillisecond = (date) => {
        const  {timeSelected} = this.state;
        var totalTimeMillisecond = new Date(date).getTime();
        var timeParts = timeSelected.split(":");
        var getTime = (+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000);
        return totalTimeMillisecond + getTime;
    }

    // Save the slot in firestore
    // Create a new slot or get back the existing one and add new slot item
    addSlot = async () => {
        const { datesArraySelected } = this.state;

        var i = 0;
        for (var iDate = new Date(datesArraySelected[0]); iDate <= datesArraySelected[1]; iDate.setDate(iDate.getDate() + 1)) {
            if(i++ === 30) {
                return
            }
            if(iDate != null) {
                var newslot = {}
                iDate.setHours(0,0,0,0);
                var totalTimeMillisecond = this.getTimeInMillisecond(iDate)
                const newSlotItem = {
                    heure: totalTimeMillisecond,
                    statut: 1,
                }
                const date = iDate.getTime();

                // Get the slot info
                var slot = null;

                const docRef = doc(this.props.firebase.firestore, "slots", ""+ date);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    slot = docSnap.data()
                } 

                // Searching the slot current info (already exists ?)
                var pass=false;
                if(slot && slot.slots && slot.slots.length > 0) {
                    newslot = slot;
                    // check if slots does not contains already 
                    for (const slotItem of newslot.slots) {
                        if(slotItem.heure === totalTimeMillisecond)
                        pass=true;
                    }
                    newslot.slots.push(newSlotItem)
                }
                else {
                    newslot = {
                        date: new Date(iDate).getTime(),
                        slots: [
                            newSlotItem
                        ]
                    }
                }
                if(!pass) {

                    // Adding the slot (or update..)
                    this.props.firebase.firestore.collection("slots").doc(""+new Date(iDate).getTime()).set(newslot)
                    .then((result) => {
                        this.props.openSnackMessage("Slot ajouté !");
                        this.setState({loaded: false, timeSelected: null}, () => this.getSlotsAtSelectedTime())
                        //if(date.getTime() === this.state.dateTimeMillisecond) {
                        
                        //}
                    })
                    .catch(error => {
                        this.setState({ error });
                    });
                }
            }
        }
    }

    // Update the list of available slots
    // Creates the list of slot items
    getSlotsAtSelectedTime = () => {
        const { dateTimeMillisecond } = this.state;
        console.log(dateTimeMillisecond)
        this.ref = onSnapshot(
            query(
              doc(this.props.firebase.firestore, "slots", dateTimeMillisecond + "")
            ),
            (snap) => {
                const slot = snap.data()
                console.log(slot)
                if(slot && slot.slots) {
                    slot.slots.sort(function(a, b) {
                        return a.heure - b.heure;
                    });
                }
                const slots = Object.keys((slot && slot.slots) ? slot.slots : {}).map((key, i) => 
                {
                    return <ListItem button key={key} >
                        <ListItemText primary={new Date(slot.slots[key].heure).toLocaleTimeString('fr-BE', options)} secondary={slot.slots[key].statut === 1 ? "Libre" : "Non libre"} />
                        { slot.slots[key].statut === 1 && <ListItemSecondaryAction>
                        <Tooltip title={"Supprimer le slot"}  placement="top">
                            <IconButton onClick={() => this.removeSlot(i)} >
                                <DeleteIcon htmlColor="red" />
                            </IconButton>
                        </Tooltip>
                        </ListItemSecondaryAction>}
                    </ListItem>    
                });
                this.setState({ slots, loaded: true })
            },
            (error) => {
              console.error(error)
              this.setState({  loadingUser : false });
            }
          );
    }


    // Click on delete button (only if not empty)
    // Remove the slot items and update in firestore
    // todo : accès concurent, vérifier lors de la suppression que c'était effectivement libre
    // todo : sinon, y aurait consultation, tant pis 
    removeSlot = (index) => {
        const { slot, dateTimeMillisecond  } = this.state;
        slot.slots.splice(index, 1);
        this.props.firebase.firestore.collection("slots").doc(dateTimeMillisecond + "").update(slot)
        .then((result) => {
            this.props.openSnackMessage("Slot supprimé !");
            this.setState({loaded: false}, () => this.getSlotsAtSelectedTime())
        })
        .catch(error => {
            this.setState({ error });
        });
    }

    render() {
        const { totalTimeMillisecond, timeSelected, slots, loaded, timeType, dateSelected } = this.state;

        return (
            <div>
                <Paper elevation={3} style={{minHeight: "510px", paddingLeft: "10px", paddingRight: "10px" }}>
                    <h2 style={{paddingLeft: "10px", paddingTop: "10px"}}>Organiser mon horaire</h2>
                    <h5 style={{paddingLeft: "10px"}}>Ajoutez, supprimez et organisez vos disponibilités. Selectionner une date sur le calendrier pour gérer vos disponibilités.</h5>
                    <MyCalendar locale="fr-BE" onClickDay={this.onDateChange} value={dateSelected}/>
                    {loaded && totalTimeMillisecond && 
                    <div>
                        <center>
                            <Select
                            value={timeType}
                            onChange={(event) => {this.setState({timeType: event.target.value, datesArraySelected: [new Date(dateSelected), new Date(dateSelected)] })}}
                            >
                                <MenuItem value={0}>Unique</MenuItem>
                                <MenuItem value={1}>Multiple</MenuItem>
                            </Select>
                            {timeType ?
                            <div>
                                <h3>Du - Au</h3>
                                <DateRangePicker
                                    onChange={(dates) => { this.setState({datesArraySelected: dates})}}
                                    value={this.state.datesArraySelected}
                                    minDate={dateSelected}
                                />
                            </div> : <div/>
                            }
                            <h3 style={{marginTop:"5px", marginBottom:"5px"}}>Ajouter un slot</h3>
                            <MyTimePicker
                                onChange={(timeSelected) => this.setState({ timeSelected })}
                                value={this.state.timeSelected}
                            /> 
                            { timeSelected && <Tooltip  title="Ajouter un slot" placement="top" ><IconButton onClick={this.addSlot}><AddIcon htmlColor="black"/></IconButton></Tooltip> }
                            <h3 style={{marginTop:"5px", marginBottom:"5px"}}>Slots existants</h3>
                            <List style={{overflow: 'auto', maxHeight: '150px',}} className="documentWrapper" subheader={<li />}>
                                { slots }
                                { slots.length === 0 && <div>Pas de slots existants</div>}
                            </List>
                            
                        </center>
                    </div>
                    }
                </Paper>
            </div>
        )
    }
}

const options = {  hour: 'numeric', minute: 'numeric'};

export default compose(withFirebase, withSnackBar)(Horaire);

const MyCalendar = styled(Calendar)`
    width: 100%;
`

const MyTimePicker = styled(TimePicker)`
    margin-left: "10px";
    display: "inline";
`
