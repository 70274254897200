import React, { Component, createRef } from 'react'
import { Paper, Divider , IconButton, Tooltip } from '@mui/material'
import { withFirebase } from '../../../../helpers';
import { compose } from 'recompose';
import styled from 'styled-components';
import '../MessengerPanel/messengerPanel.css';
import SendIcon from '@mui/icons-material/Send';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
//import FileUploader from 'react-firebase-file-uploader/lib';
import MessageEditor from './messageEditor';
import { Link } from 'react-router-dom';
import { MyColor } from '../../../../components/Theme';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";



class MessengerPanel extends Component {

    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
    }

    state = {
        disableButtonSend: false,
        file: null,
        messageSended: false,
        messageText: "",
        messages : [],

        //Infinite list
        has_more: true,
        last:"",
    }

    messagesRef = createRef()
    uploadButton = createRef()

    componentDidMount() {
        this.getMessages();
    }

    reloadScroll = () => {
        if(this.state.messages && this.state.messages.length > 0) {
            const ref = this.messagesRef.current
            ref.scrollTop = ref.scrollHeight
        }
    }

    getMessages = () => {
        const { firebase } = this.props;
        const collection = "/users/" + this.props.authUser.data.uid + "/conversations/" + this.props.to_user_uid + "/messages";
        let set = this

        // Listener for last message 
        firebase.firestore.collection(collection).orderBy("date", "desc")
        .onSnapshot((querySnapshot) => {
            if(querySnapshot.docs.length > 0) {
                var doc = (querySnapshot.docs[0]).data();
                const newArray = [doc].concat(this.state.messages)
                this.setState({ messages : newArray})
                this.reloadScroll();
            }
        });
    
        //initiate first set
        var first  = firebase.firestore.collection(collection).orderBy("date", "desc").limit(5);
        first.get().then(function (documentSnapshots) {
            var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
            const list = [];
            documentSnapshots.forEach(function(doc) {
                list.push(doc.data());
            });
            set.setState({ messages: list, last: lastVisible, has_more: (list.length > 0) });
            set.reloadScroll();
        });
    }

    fetchMoreData = () => {
        let last = this.state.last;
        let set = this;
        const collection = "/users/" + this.props.authUser.data.uid + "/conversations/" +  this.props.to_user_uid + "/messages";

        var next = this.props.firebase.firestore.collection(collection).orderBy("date", "desc").startAfter(last).limit(5);
        next.get().then(function (documentSnapshots) {
            var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
            const list = [];
            documentSnapshots.forEach(function(doc) {
                const message = doc.data();
                list.push(message);
            });
            let updated_list = set.state.messages.concat(list);
            set.setState({ messages: updated_list, last: lastVisible, has_more: (list.length > 0) });
        });
    };

    handleSubmit = (e) => {
        e.preventDefault()
        const file = e.target[0]?.files[0]
    
        if (!file) return;
    
        const storageRef = ref(this.props.firebase.storage, "messages/" + this.props.authUser.data.id + "/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(progress)
            //setProgresspercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                const filename = file.name
                this.sendPhoto(url)
            });
          }
        );
      }

    sendSubmit = (e) => {
        e.preventDefault();
        this.sendMessage()
    }

    handleChangeInputFile = (e) => {
        if(!e.target.files)
            return
        //this.fileUploader.startUpload( e.target.files ? e.target.files[0] : null)
        this.submitUpload.click()
    }

    send = (imageUrl, text)  => {
        const date = Date.now();
        const data = {
            date: date,
            from: this.props.authUser.data.uid,
            to: this.props.to_user_uid,
            imageUrl: imageUrl,
            text: text,
        }
        this.firebaseSend(data, date + "")
    }

    sendPhoto = (url) => {

        if(url === "" || !url)
            return;

        this.setState({ disableButtonSend: true})
        this.send(url, null)
    }

    sendMessage = () => {

        const text = this.state.messageText;
        if(text === "")
            return;

        this.setState({ disableButtonSend: true, messageSended: !this.state.messageSended, messageText: ""})
        this.send(null, text)
    }

    firebaseSend = (data, date) => {
        const { to_user_uid } = this.props;

        const collectionMe = "users/" + this.props.authUser.data.uid + "/conversations/" + to_user_uid + "/messages";
        const collectionTo = "users/" + to_user_uid + "/conversations/" + this.props.authUser.data.uid + "/messages";
        console.log(collectionMe)
        this.props.firebase.firestore.collection(collectionMe).doc(date + "").set(data)
        .then(() => {
            this.props.firebase.firestore.collection(collectionTo).doc(date + "").set(data)
            .then(() => {
                this.setState({ disableButtonSend: false})
            }).catch(err => {
                console.error(err);
                this.setState({ disableButtonSend: false})
            })
        })
        .catch(error => {
            console.error(error)
            this.setState({ error });
        });
    }

    render() {
        if(this.props.authUser && this.props.authUser.data) {
            const { targetUser } = this.props;
            const { messages } = this.state

            const Message = ({ message, isUser }) => {

                if (isUser) {
                    return (
                    <p className='user-message'>
                        <span style={{fontSize:"9px", marginLeft: "60%", display:"block"}}><i>{new Date(message.date).toLocaleDateString('fr-BE', options)}</i></span>
                        {message.text ? <span>{message.text}</span> : <img src={message.imageUrl} onLoad={this.reloadScroll} alt="" className="messengerImg" style={{paddingTop:"2px"}}/>}
                    </p>
                    )
                } else {
                    return (
                        <div>
                            <Myimg src={targetUser ? targetUser.imageURL : "https://firebasestorage.googleapis.com/v0/b/hello-diet.appspot.com/o/137514025_195215995555635_43087321747305297_n.jpg?alt=media&token=f7136b8e-c4c1-4a81-9c2b-5ace046452db"} alt="logo food-ediet" />
                            <p className='not-user-message'>
                                {message.date && <span style={{fontSize:"9px", marginLeft: "60%", display:"block"}}><i>{new Date(message.date).toLocaleDateString('fr-BE', options)}</i></span>}
                                {message.text ? <span>{message.text}</span> : <img src={message.imageUrl} onLoad={this.reloadScroll} alt="" className="messengerImg"/>}
                            </p>
                        </div>
                    )
                }
            }

            const listMessages = 
                <InfiniteScroll 
                    dataLength={messages.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.has_more}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }} 
                    inverse={true} 
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                >
                {messages.map((single, key) => (
                    <Message key={key} message={messages[key]} isUser={messages[key].from !== this.props.to_user_uid}/>
                ))}
            </InfiniteScroll>
            

            return (
                <Wrapper>
                    <MyPaper elevation={3}>
                        <div className="PanelLabel" style={{position:"relative", zIndex:"4999"}}>
                                <div id="PanelLabel background" style={{backgroundColor:MyColor.SECONDARY_GREEN, position:"absolute", top:"5px", left:"-5px", padding:"5px"}}>
                                    <span style={{fontSize:"20px", color:"white", paddingLeft:"25px", paddingRight:"10px", fontFamily:"'Karla', sans-serif" }}>Messages</span>
                                </div>
                        </div>
                        <Title>Di</Title>
                        <Divider/>
                        <form  onSubmit={this.handleSubmit} className='form' style={{ visibility: 'hidden', width:0, height:0}}>
                            <input type='file' ref={(fileUpload) => {this.fileUpload = fileUpload;}} onChange={this.handleChangeInputFile} />
                            <button type='submit' ref={(submitUpload) => {this.submitUpload = submitUpload;}} >Confirmer</button>
                        </form>
                        <div id="scrollableDiv" className="messages" ref={this.messagesRef}>
                            { messages.length === 0 && 
                                <div>
                                    <Myimg src={targetUser ? targetUser.imageURL : "https://firebasestorage.googleapis.com/v0/b/hello-diet.appspot.com/o/137514025_195215995555635_43087321747305297_n.jpg?alt=media&token=f7136b8e-c4c1-4a81-9c2b-5ace046452db"} alt="" />
                                    <p className={this.props.isPro ? 'user-message' : 'not-user-message'}>
                                        <span>
                                            Bonjour {targetUser ? targetUser.firstname : this.props.authUser.data.firstname} ! Bienvenue sur Food-eDiet.<br/><br/>
                                            Vous êtes ici sur votre espace personnel. Depuis ce tchat, nous pouvons discuter à tout moment.<br/><br/>
                                            Vous pouvez également <Link to="/home/consultation-create">réserver votre consultation</Link>.<br/><br/>
                                            Votre agenda et documents sont également affichés sur cette page. <br/><br/>
                                            Contactez-moi pour toutes questions ! À bientôt !<br/>
                                        </span>
                                    </p>
                                </div>
                            }
                            { listMessages }
                        </div>
                        <WrapperToolBar>
                            <PaperSubmit component="form" onSubmit={this.sendSubmit}>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <Tooltip title="Envoyer une photo" placement="top">
                                        <IconButton onClick={() => this.fileUpload.click()}>
                                                <PhotoLibraryIcon htmlColor="#474747"/>
                                            </IconButton>
                                    </Tooltip>
                                    <MessageEditor placeholder="Ecrire un message" myref={this.editorRef} sended={this.state.messageSended} updateText={(text) => this.setState({messageText: text})} />
                                    <Tooltip title="Envoyer" placement="top">
                                        <IconButton onClick={this.sendMessage} disabled={this.state.disableButtonSend}>
                                            <SendIcon htmlColor="#474747"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </PaperSubmit>
                        </WrapperToolBar>
                    </MyPaper>
                </Wrapper>
            )
        }
        return <div></div>
    }
}


export default  compose(withFirebase)(MessengerPanel);


const options = {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};

const Myimg = styled.img`
    border-radius: 50%;
    width: 50px;
    height: 50px;
`

const PaperSubmit = styled(Paper)`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
`

const Title = styled.div`
    font-size: 1.17em;
    margin-block-start: 0px;
    margin-block-end: 10px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    padding-left: 100px;
    padding-top: 10px;
    color: white;
`;

const Wrapper = styled.div`
    overflow: 'hidden';
`;

const MyPaper = styled(Paper)`
    height: auto;
    min-width: 100%;
    overflow: 'hidden';
`

const WrapperToolBar = styled.div`
    width: 100%;
    height: auto;
    background-color: lightgray;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
