// TRUE = pas de problème
// FALSE = J'AI UN SOUCI


const Validator =  {
    minLength(value, length) {
      return value.length >= length
    },
    maxLength(value, length) {
      return value.length <= length
    },
    validChar(value) {
      return new RegExp(/^[0-9a-zA-Z _-àáâãäåçèéêëìíîïðòóôõöùúûüýÿ]+?$/i).test(value)
    },
    validNumber(value) {
      return new RegExp(/^[0-9]+?$/i).test(value)
    },
    isNonEmpty(value) {
      return value !== ''
    },
    checkEmail(value) {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      return pattern.test(value)
    },
    checkInput(value) {
      return this.validChar(value) && this.minLength(value, 1) && this.maxLength(value, 20)
    },
    checkArea(value, max) {
      return this.validChar(value) && this.minLength(value, 1) && this.maxLength(value, max ?? 250)
    },
    checkPassword(value) {
      return this.minLength(value, 6) && this.maxLength(value, 30)
    }
  }

  export default Validator;