/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Grid } from '@mui/material';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../../helpers';
import Zoom from 'react-reveal/Zoom';
import {Link as ReactLink} from 'react-router-dom'
import Carousel from 'react-multi-carousel';
import ArticleTile from '../../../pages/blog/components/ArticleTile/ArticleTile';
import { withRouter } from '../../../App';
import { collection, where, limit, query, orderBy, onSnapshot } from "firebase/firestore";


class VisitezBlogSection extends Component {

    state = {
        articles: [],
    };

    componentDidMount() {

        this.unsub = onSnapshot(
            query(
              collection(this.props.firebase.firestore, "articles"),
              where('level', "==", 0),
              orderBy('date', "desc"),
              limit(3)
            ),
            (snap) => {
                const items = snap.docs.map(docSnapshot => docSnapshot.data());
                this.setState({ articles : items, loading: false })
            },
            (error) => {
              console.error(error)
              this.setState({  loadingUser : false });
            }
          );
    }

    componentWillUnmount() {
        if(this.unsub) {
            this.unsub();
        }
    }



    render() {

        const { articles } = this.state;
        const { navigate } = this.props;

        const list = Object.keys(articles).map((uid, i) => 
            (<Grid item  key={"item" + articles[uid].uid}><Zoom delay={i * 100}><ArticleTile 
                onClick={() => navigate("/blog/"+articles[uid].uid)} 
                article={articles[uid]}
                modify={() => navigate("/blog/"+articles[uid].uid)}
            /></Zoom></Grid>
        ));

        const listGSM = Object.keys(articles).map((uid, i) => 
            (<div key={"item" + articles[uid].uid} style={{paddingLeft:"50px"}}><ArticleTile 
                onClick={() => navigate("/blog/"+articles[uid].uid)} 
                article={articles[uid]}
                modify={() => navigate("/blog/"+articles[uid].uid)}
            />
            </div>
        ));

        const BodyDesktop = () => {
            return (
                <Grid container spacing={3} justifyContent="center" style={{
                    margin: 0,
                    width: '100%',
                    paddingBottom:"15px"
                }}>{list}</Grid>
            )
        }

        const BodyGSM = () => {
            return (
                <div style={{paddingBottom:"15px"}} >
                <Carousel
                showDots={true}
                responsive={responsive}
                infinite={true}
                keyBoardControl={true}
                transitionDuration={500}
                deviceType="desktop"
                >
                    {listGSM}
                </Carousel>
                </div>
            )
        }



        return (
            <div>
                { !this.props.onlyArticle ?
            <div name="visitez-notre-blog"  style={{background:"linear-gradient(white, rgb(250, 251, 252))", paddingBottom:"45px"}}>
                    <a className="anchor" id="blog" ></a>
                    <center><h2 className="main_h2"  style={{color:"#09371F", marginTop:"0px", paddingTop:"35px"}}>Visitez notre blog</h2></center>
                    <center><h3 className="main_h3" style={{color:"#ffc47c", margin:"0", paddingBottom:"10px"}}>
                                        Des articles, astuces et recettes chaque semaines !
                                    </h3></center>
                    {this.props.gsm ? <BodyGSM/>: <BodyDesktop/>}
                <center><ReactLink to='blog' className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative"}}>Découvrir d'autres articles</button></ReactLink></center>
            </div>
            :
            <div>{this.props.gsm ? <BodyGSM/>: <BodyDesktop/>}</div> }
            </div>
        );
    }
}

export default compose(withRouter, withFirebase)(VisitezBlogSection);

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1700 },
      items: 1,
    },
    short: {
        breakpoint: { max: 1700, min: 1024 },
        items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };