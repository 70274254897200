import { Container, InputBase, Paper } from '@mui/material';
import React, { Component } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ApportRecJourn } from '../../../utils/Settings';
import withSnackBar from '../../../components/Theme/MySnackBar/withSnackBar';
import { withFirebase } from '../../../helpers';
import { compose } from 'recompose';

class ApportEdition extends Component {

    state = {
        apports : {
            "Cal" : ApportRecJourn['Cal'],
            "Prot" : ApportRecJourn['Prot'],
            "Glu" : ApportRecJourn['Glu'],
            "Lip" : ApportRecJourn['Lip'],
            "Ca" : ApportRecJourn['Ca'],
            "Fe" : ApportRecJourn['Fe'],
            "Vit C" : ApportRecJourn['Vit C'],
            "Mg" : ApportRecJourn['Mg'],
            "Fibres" : ApportRecJourn['Fibres'],
        }
    }

    componentDidMount() {

        const {authUser } = this.props;

        if(authUser.data.apports) {
            this.setState({apports : authUser.data.apports})
        }
    }

    updateApport = (key, value) => {
        var apports = this.state.apports;
        apports[key] = parseInt(value);
        this.setState({apports})
    }


    saveApports = () => {
        const { targetUser } = this.props;
        //todo: ne pas remplir le champs dans le user patient, mais dans une collection de la diet privée 
        this.props.firebase.firestore.collection("users").doc(targetUser.uid).update(this.state)
        .then(() => {
            this.props.openSnackMessage("apports mis à jour !");
        })
        .catch(error => {
            this.setState({ error });
        });
    }

    render() {

        const ApportItem = (props) => {
            return (
                
                <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"20px"}}>
                <AccountCircle style={{padding: "10px"}}  />
                <InputBase
                    placeholder={props.label}
                    onChange={(event) => {this.updateApport(props.enum, event.target.value)}}
                    value={this.state.apports[props.enum]}
                    type="text"
                    className="karla_text"
                    style={{width:"100%"}}
                />
                <span>{props.unit}</span>
                </Paper>
            );
        }


        return (
            <div>
                <Paper style={{padding:"15px"}}>
                    <Container maxWidth="md">
                        {ApportItem({unit:"kcal", label:"Cal.", enum:"Cal"})}
                        {ApportItem({unit:"g", label:"Prot.", enum:"Prot"})}
                        {ApportItem({unit:"g", label:"Glu.", enum:"Glu"})}
                        {ApportItem({unit:"g", label:"Lip.", enum:"Lip"})}
                        {ApportItem({unit:"mg", label:"Ca", enum:"Ca"})}
                        {ApportItem({unit:"mg", label:"Fe", enum:"Fe"})}
                        {ApportItem({unit:"mg", label:"Vit C", enum:"Vit C"})}
                        {ApportItem({unit:"mg", label:"Mg", enum:"Mg"})}
                        {ApportItem({unit:"mg", label:"Fibres", enum:"Fibres"})}
                        <button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative", marginTop:"15px"}} onClick={this.saveApports} >Sauvegarder</button>
                    </Container>
                </Paper>
                
            </div>
        );
    }
}

export default compose(withFirebase, withSnackBar)(ApportEdition);