import React from 'react';
import { makeStyles } from '@mui/styles';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import styled from 'styled-components';
import LinesEllipsis from 'react-lines-ellipsis'
import { Paper } from '@mui/material';
import './ArticleTile.css';


const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    transition: '0.5s ease',
  },
  media: {
    height: 200,
  },
});

const MyCard = styled(Paper)`
  transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-top:30px;
  width: 300px;
  height: 350px;
  display: flex;
  box-shadow: rgb(1 33 92 / 10%) 0px 10px 60px 0px;
  flex-direction: column;
  font-family: 'Karla', sans-serif;
  &:hover {
    transform: translateY(-20px);
    box-shadow: 5px 5px 15px rgba(0,0,0,0.6);
  }
`

const LabelContainer = styled.div`
  float: right;
  top: 5px;
  right: 10px;
  z-index: 5;
  padding: 10px 10px;
  font-family: 'Noto Sans', sans-serif;
`

const Label = styled.div`
  float: right;
  border-radius: 25px;
  text-align: center;
  z-index: 6;
  line-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
  min-width: 100px;
  color: white;
  background-color: ${props => {
        var colors = getColor(props.type);
        return "rgba("+colors[0]+","+colors[1]+","+colors[2]+",0.85)";
  }};
  font-family: 'Noto Sans', sans-serif;
`

const Title = styled(LinesEllipsis)`
  font-size: 20px;
  font-family: 'Noto Sans', sans-serif;
  text-overflow: ellipsis;
`

const MyDate = styled.span`
  margin-top: 10px;
  font-size: 11px;
  font-style: italic;
`


const getColor = (type) => {
  if(type === "Article")
    return [255, 140, 0];
  if(type === "Recette")
    return [100, 149, 237];
  return [255, 140, 0];
}

export default function ArticleTile(props) {

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const classes = useStyles();

  const {article} = props;

  return (
      <div>
    <MyCard>
      <div className="mycard">
      <CardActionArea 
        href={"/blog/" + article.uid} 
        style={{height:"100%"}}
       >
         <LabelContainer><Label className="type" type={article.type}>{article.type ?? "Article"}</Label></LabelContainer>
        <CardMedia
          className={classes.media}
          image={article.miniatureURL}
          title={article.title}
          
        />
        <CardContent>
        <Title
            text={article.title}
            maxLine='2'
            ellipsis='...'
            trimRight
            basedOn='letters'
          />
          <Title>
            
          </Title>
          <MyDate>
            {new Date(article.date).toLocaleDateString('fr-BE', options)}
          </MyDate>
        </CardContent>
      <CardActions>
        <button className="articletilebtn toggle-btn" onClick={props.modify}>
          → Lire
        </button>
      </CardActions>
      </CardActionArea>
      </div>
    </MyCard>
    <br/><br/>
    </div>
  );
}