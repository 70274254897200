import React, { Component } from 'react'
import { withFirebase } from '../../../../helpers';
import { compose } from 'recompose';
import { Paper, Tooltip as MatTooltip } from '@mui/material';
import { Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, LabelList,  ComposedChart, ReferenceLine, Label} from 'recharts';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { ApportRecJourn } from '../../../../utils/Settings';
import './apports.css';
import { DesktopView, MobileView, MyColor } from '../../../../components/Theme';
import LockIcon from '@mui/icons-material/Lock';

/*const calc = (apports, key) => {
  const val = Math.round((apports[key] ?? ApportRecJourn[key]) / ApportRecJourn[key] * 100)
  return val;
}*/

class ApportPanel extends Component {


    state = {
        loading: true,
        documents: {},
        error: null,
    }

    componentDidMount() {

      const  apports  = this.props.authUser.data.apports ?? [];

      this.setState({
        data : [
          {
            name: 'Cal.',
            pourcent: 0,//calc(apports, 'Cal'),
            label: (apports['Cal'] ?? ApportRecJourn['Cal']) + "kcal",
            default: 100,
          },
          {
            name: 'Prot.',
            pourcent: 0,//calc(apports, 'Prot'),
            label: (apports['Prot'] ?? ApportRecJourn['Prot']) + "g",
            default: 100,
          },
          {
            name: 'Glu.',
            pourcent: 0,//calc(apports, 'Glu'),
            label: (apports['Glu'] ?? ApportRecJourn['Glu']) + "g",
            default: 100,
          },
          {
            name: 'Lip.',
            pourcent: 0,//calc(apports, 'Lip'),
            label: (apports['Lip'] ?? ApportRecJourn['Lip']) + "g",
            default: 100,
          },
          {
            name: 'Ca',
            pourcent: 0,//calc(apports, 'Ca'),
            label: (apports['Ca'] ?? ApportRecJourn['Ca']) + "mg",
            default: 100,
          },
          {
            name: 'Fe',
            pourcent: 0,//calc(apports, 'Fe'),
            label: (apports['Fe'] ?? ApportRecJourn['Fe']) + "mg",
            default: 100,
          },
          {
            name: 'Vit C',
            pourcent: 0,//calc(apports, 'Vit C'),
            label: (apports['Vit C'] ?? ApportRecJourn['Vit C']) + "mg",
            default: 100,
          },
          {
              name: 'Mg',
              pourcent: 0,//calc(apports, 'Mg'),
              label: (apports['Mg'] ?? ApportRecJourn['Mg']) + "mg",
              default: 100,
          },
          {
              name: 'Fibres',
              pourcent: 0,//calc(apports, 'Fibres'),
              label: (apports['Fibres'] ?? ApportRecJourn['Fibres']) + "g",
              default: 100,
          },
        ]
      })
    }

    render() {

      const { data } = this.state;

      const MyIcon = React.forwardRef(function MyComponent(props, ref) {
        //  Spread the props to the underlying DOM element.
        return <div  {...props} ref={ref}><HelpOutlineOutlinedIcon htmlColor="grey" size="small" /></div>
      });

      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
            </div>
          );
        }
      
        return null;
      };
      
      
       
        return (
            <div style={{position:"relative"}} >
                <div className="PanelLabel" style={{position:"relative", zIndex:"4999"}}>
                    <div id="PanelLabel background" style={{backgroundColor:MyColor.SECONDARY_GREEN, position:"absolute", top:"8px", left:"-8px", padding:"5px"}}>
                        <span style={{fontSize:"20px", color:"white", paddingLeft:"25px", paddingRight:"10px", fontFamily:"'Karla', sans-serif" }}>Mes Apports</span>
                    </div>
                </div>
                <div style={{position:"absolute", top:"4px", right:"4px"}}>
                    <MatTooltip title={
                      <React.Fragment>
                        <p>Cette section affiche vos propres besoins nutritionnels journaliers</p>
                        <p>Par défaut, ceux-ci correspondent aux apports journaliers recommandés d'une femme lambda de 60kg mesurant 1m70. Il s'agit donc d'une moyenne.</p>
                        <p>Après votre 1ère consultation, votre diététicienne encodera vos besoins spécifiques. Ceux-ci permettront d'ajuster les recettes à vos besoins</p>
                      </React.Fragment>
                      }>
                      <MyIcon/>
                    </MatTooltip>
                </div>
                {this.props.authUser.data.apports ?
                <Paper elevation={3} style={{height: "360px", padding:"5px"}}>
                <div style={{height:"40px"}} />
                      
                      <ResponsiveContainer  height={320}>
                      <ComposedChart data={data} layout="vertical" >
                        {<Tooltip  content={<CustomTooltip />} />}
                        <XAxis type="number" hide domain={[0, 'dataMax + 25']} />
                        <YAxis dataKey="name" type="category"  />
                        <Bar dataKey="pourcent" fill="rgb(167, 198, 255)" animationBegin={800}>
                            <LabelList dataKey="label" position="inside" />
                        </Bar>
                        <ReferenceLine x={100}  stroke="coral" strokeDasharray="3 3" isFront>
                        <Label value="AJR" position="top"/>
                        </ReferenceLine>
                    </ComposedChart>
                    </ResponsiveContainer>
                </Paper>
                :
                <Paper elevation={3} style={{height: "360px", padding:"5px", position:"relative"}}>
                  <div style={{height:"40px"}} />
                  <ResponsiveContainer  height={320}>
                      <ComposedChart data={data} layout="vertical" >
                        {<Tooltip  content={<CustomTooltip />} />}
                        <XAxis type="number" hide domain={[0, 'dataMax + 25']} />
                        <YAxis dataKey="name" type="category"  />
                        <Bar dataKey="pourcent" fill="rgb(167, 198, 255)" animationBegin={800}>
                        </Bar>
                        <ReferenceLine x={100}  stroke="coral" strokeDasharray="3 3" isFront>
                        <Label value="AJR" position="top"/>
                        </ReferenceLine>
                    </ComposedChart>
                  </ResponsiveContainer>
                  <div style={{position:"absolute", top:"50%", left:"50px", width:"100%", maxWidth:"100%"}}>
                  <center><LockIcon style={{width:"50px", height:"50px"}} /><br/></center>
                    <center>
                        <h3 className="main_h3" >
                          <DesktopView>
                          Disponible après votre 1ère consultation
                          </DesktopView>
                          <MobileView>
                          Bientôt disponible
                          </MobileView>
                        </h3>
                      </center>
                  </div>
                </Paper>
              }
            </div>
        )
    }
}

export default  compose(withFirebase)(ApportPanel);
