/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Avatar, Card, CardActionArea, Container, Grid, List, ListItem, ListItemAvatar } from '@mui/material';
import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { MyColor } from '../../Theme';
import {Link as ReactLink} from 'react-router-dom'
import { withRouter } from '../../../App';


const data =[
    {"name":"Eloïse Feryr", "img": "elo3.jpg", "ecole": "Diététicienne - HEPL", "specia1": "Diététique sportive", "specia2": "Gériatrie" }, 
    {"name":"Bientôt !", "img": "diet_bientot.jpg", "ecole": "Diététicien(ne)s à votre écoute", "specia1": "Spécialisations pour un suivi optimal", "specia2": "Et bien plus encore !" }
];


class NosDietsSection extends Component {
    render() {

        const d = data[0];

        return (
            <div style={{backgroundImage:"linear-gradient(white, rgb(250, 251, 252))", paddingBottom:"35px"}}>
                <a className="anchor" id="diets" ></a>
                <center>
                    <h2  style={{font:"normal normal normal 71px/1.4em 'rozha one',serif", fontSize:"calc(12px + 3.4vw)", lineHeight:"1.2em", color:"#09371F", marginTop:"0px", paddingTop:"15px" }}>Nos équipes</h2>
                </center>
                <Container maxWidth="md" >
                <h3 className="main_h3" style={{color:"#ffc47c", margin:"0", paddingBottom:"10px"}}>
                C'est quoi une diététicienne ?
                                    </h3>
                    <p style={{textAlign:"justify"}}>
                    Le diététicien est le seul spécialiste de l’alimentation reconnu (avec le médecin nutritionniste). 
                    Il possède un numéro d’INAMI, ce qui lui attribue le rôle de professionnel de la santé. 
                    Etant agréé par l’INAMI, les consultations diététiques bénéficient d’un remboursement mutuel. 
                    De plus, ce numéro atteste que le diététicien a bien suivi un bachelier de 3 ans comme l’oblige l’arrêté royal de 1997 qui protège le métier de diététicien. 
                    Pour garder cet agrément, le diététicien doit continuer de se former chaque année afin d’être au courant des dernières avancées scientifiques.
                    </p>
                    <br/>
                    <Grid container style={{width:"100%", margin:"0"}}  spacing={2}>
                        <Grid item lg={8} >
                            <div className="karla_text">
                            <h3 className="main_h3" style={{color:"#ffc47c", margin:"0", paddingBottom:"10px"}}>
                                Le contrat de confiance Food-eDiet
                            </h3>
                            <p>C'est la garantie d'être pris en main par des diététiciennes répondant à nos 3 checks d'expertise et de qualité :</p>
                            <List>
                                <Fade left>
                                    <ListItem style={{paddingBottom:"15px"}}>
                                        <ListItemAvatar>
                                            <Avatar src="objectifs.png" style={{ height: '35px', width: '35px' }}/>
                                        </ListItemAvatar>
                                        Diététicienne diplômée et reconnue par l'UPDLF
                                    </ListItem>
                                </Fade>
                                <Fade left delay={100}>
                                    <ListItem style={{paddingBottom:"15px"}}>
                                        <ListItemAvatar>
                                            <Avatar src="objectifs.png" style={{ height: '35px', width: '35px' }}/>
                                        </ListItemAvatar>
                                        Connaissances régulièrement vérifiées et au fait des dernières avancées scientifiques
                                    </ListItem>
                                </Fade>
                                <Fade left delay={200}>
                                    <ListItem style={{paddingBottom:"15px"}}>
                                        <ListItemAvatar>
                                            <Avatar src="objectifs.png" style={{ height: '35px', width: '35px' }}/>
                                        </ListItemAvatar>
                                        Professionnelle ayant passé une série d'entretiens en téléconsultation et un background check rigoureux
                                    </ListItem>
                                </Fade>
                            </List>
                            </div>

                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <div style={{position:"relative", minHeight:"400px",}}>
                                <Card style={{width:"270px", minHeight:"400px", position:"absolute", top:"0", left:"80px", zIndex:"1", boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)" }} elevation={6}>
                                    <img src="accompagnement_photo3.jpg"  width="220px" height="200px" alt="accompagnement sur food-ediet" style={{padding:"25px", paddingBottom:"10px"}} />
                                </Card>
                                <Card style={{width:"270px", minHeight:"400px", position:"absolute", top:"0", left:"40px", zIndex:"2", boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)" }} elevation={6}>
                                    <img src="diet_bientot.jpg"  width="220px" height="200px" alt="diététicienne" style={{padding:"25px", paddingBottom:"10px"}} />
                                </Card>
                                <Card style={{width:"270px", zIndex:"3", position:"absolute", top:"0", left:"0px", minHeight:"400px", boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)" }} elevation={3}>
                                    <CardActionArea href="/dieteticienne/eloise-feryr" >
                                    <img src={d.img}  width="220px" height="200px" alt="Eloïse Feryr Diététicienne" style={{padding:"25px", paddingBottom:"10px"}} />
                                    <center style={{paddingBottom:"10px"}}>
                                        <p style={{font:"normal normal normal 20px/1.4em 'rozha one',serif", color:MyColor.PRIMARY_GREEN, margin:"5px", marginTop:"0px"}}>{d.name}</p>
                                        <p className="karla_text" style={{fontSize:"14px", color:"grey", margin:"5px"}}><i>{d.ecole}</i></p>
                                        <h5 style={{margin:"10px", marginTop:"15px"}}>Spécialisations : </h5>
                                        <p className="karla_text" style={{fontSize:"14px", color:"grey", margin:"5px"}}>{d.specia1}</p>
                                        <p className="karla_text" style={{fontSize:"14px", color:"grey", margin:"5px", paddingBottom:"15px"}}>{d.specia2}</p>
                                    </center>
                                    </CardActionArea>
                                </Card>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{paddingTop:"50px", paddingBottom:"45px"}}>
                        <center><ReactLink to='dieteticienne/eloise-feryr' className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative"}}>Découvrir nos diététiciennes</button></ReactLink></center>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withRouter(NosDietsSection);