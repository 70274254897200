import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { Component } from 'react';
import { withFirebase } from '../../../helpers';
import { compose } from 'recompose';
import withSnackBar from '../../../components/Theme/MySnackBar/withSnackBar';

class Assistance extends Component {
    state = {
        open:false,
        problem:""
    }

    handleClickOpen = () => {
        this.setState({open: true})
      };
    
    handleClose = () => {
        this.setState({open: false})
      };

    componentDidMount() {
        
    }

    confirm = () => {
        var problem = {
            userUid:this.props.firebase.user.uid,
            problem:this.state.problem,
            date:this.props.firebase.getTime(),
            
        }

        if(this.props.consultation)
            problem["consultation"] = this.props.consultation

        this.props.firebase.firestore.collection("assistance").add(problem).then(() => {
            this.props.openSnackMessage("Message envoyé")
            this.handleClose();
        })
    }

    render() {
        return (
            <div className="karla_text">
                <div onClick={this.handleClickOpen}>
                    {this.props.children}
                </div>
                <Dialog open={this.state.open} onClose={this.handleClose} scroll="paper" >
                    <DialogTitle className="karla_text">Comment pouvons-nous vous aider ?</DialogTitle>
                    <DialogContent dividers>
                    <DialogContentText >
                    <TextField
                        placeholder="Décrivez votre problème, nous vous contacterons dès que possible..."
                        multiline
                        variant="outlined"
                        style={{width:"100%"}}
                        rows={4}
                        value={this.state.problem}
                        onChange={(event) => {this.setState({problem:event.target.value})}}
                    />
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.confirm} color="primary" className="karla_text">
                            Envoyer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(withSnackBar, withFirebase)(Assistance);
