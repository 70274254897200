import React, { Component } from 'react'
import { DesktopView, MobileView } from '../../../../components/Theme';
import { InputBase, Paper } from '@mui/material';
import Fade from 'react-reveal/Fade';
import LockIcon from '@mui/icons-material/Lock';
import Validator from '../../../../utils/Validator';


class ChangePassword extends Component {  

    state = {
        confirm_password:"",
        password:"",
        actual_password:"",

        error:"",
        errorConfirmPassword:"",
        errorPassword:"",
        errorActualPassword:"",
    }

    checkDoublePassword = () => {

        const { password, confirm_password } = this.state;

        if (password !== confirm_password) {
            this.setState({errorConfirmPassword : "Votre mot de passe de confirmation est différent de votre mot de passe"})
            return false;
        }
        else {
            this.setState({errorConfirmPassword : ""})
            return true;
        }
    }


    confirm = () => {

        const { actual_password, password } = this.state;

        var okActualPassword = Validator.isNonEmpty(actual_password);
        this.setState({errorActualPassword: !okActualPassword})

        var okPasswordComplexity = Validator.checkPassword(password);
        this.setState({errorPassword : !okPasswordComplexity})

        // Specific checks
        var okConfirmPassword = this.checkDoublePassword();

        if(okActualPassword && okConfirmPassword && okPasswordComplexity) {
            this.updatePassword();
        }
    }

    updatePassword = () => {
        const { actual_password, password} = this.state;
        const { authUser } = this.props;
        this.props.firebase.reauthenticateWithCredential(authUser.data.email, actual_password).then(() => {
            this.props.firebase.doUpdatePassword(password).then(() => {
                this.props.setSuccess("Votre mot de passe a correctement été mis à jour");     
                this.setState({actual_password:"", password:"", confirm_password:"", error:""})          
              })
              .catch((error) => {
                    console.error(error)
                    this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
              });
        })
        .catch((error) => {
            console.error(error)
            this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
        });
    }

    render() {

        const { error, actual_password, password, confirm_password, errorConfirmPassword, errorPassword, errorActualPassword} = this.state;

        const body = (
            <div className="karla_text">
                <h3>Modifier votre mot de passe</h3>
                <p>Votre mot de passe doit contenir au minimum 6 caractères alphanumériques</p>
                <form onSubmit={(event) => {event.preventDefault(); this.confirm()}}>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <LockIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Saisissez votre mot de passe actuel"
                            onChange={(event) => {this.setState({actual_password : event.target.value})}}
                            value={actual_password}
                            type="password"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorActualPassword}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                            Ce champs est obligatoire
                        </div>
                    </Fade>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <LockIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Saisissez votre nouveau mot de passe"
                            onChange={(event) => {this.setState({password : event.target.value})}}
                            value={password}
                            type="password"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorPassword}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                            Votre mot de passe doit contenir doit contenir au minimum 6 caractères
                        </div>
                    </Fade>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <LockIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Confirmez votre nouveau mot de passe"
                            onChange={(event) => {this.setState({confirm_password : event.target.value})}}
                            value={confirm_password}
                            type="password"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorConfirmPassword}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                        Votre mot de passe de confirmation est différent de votre mot de passe
                        </div>
                    </Fade>
                    <center style={{paddingBottom:"25px", paddingTop:"25px"}}>
                    <Fade bottom collapse when={error}>
                        <span style={{color:"red", fontSize:"14px",  paddingBottom:"10px",}}>Attention : {error}</span>
                    </Fade>
                    <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px", maxWidth:"300px"}}>Modifier</button>
                    </center>
                </form>
            </div>
        );

        return (
            <div>
                 <DesktopView >
                        {body}
                </DesktopView>
                <MobileView >
                        {body}
                </MobileView>
            </div>
        )
    }
}


export default (ChangePassword);
