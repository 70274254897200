import Footer from 'rc-footer';
import 'rc-footer/assets/index.css'; // import 'rc-footer/asssets/index.less';
import CategoryIcon from '@mui/icons-material/Category';
import GroupIcon from '@mui/icons-material/Group';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
 
import React, { Component } from 'react'



export default class MyFooter extends Component {

    render() {
        const site = "https://food-ediet.com/";

        return (
            <div className="karla_text">
                <Footer
                    backgroundColor="#09371F"
                    columns={[
                    {
                        title: 'Catégories',
                        icon: (
                            <CategoryIcon/>
                        ),
                        items: [
                            {
                                title: (<div>Offres et tarifs</div>),
                                url:site   + "tarifs",
                            },
                            {
                                title: (<div>Blog</div>),
                                url: site + "blog",
                            },
                            {
                                title: (<div>Mon Espace Personnel</div>),
                                url:site   + "home",
                            },
                        ],
                    },
                    {
                        icon: (<GroupIcon/>),
                        title: 'Suivez-nous !',
                        items: [
                            {
                                icon: <FacebookIcon/>,
                                title: 'Facebook',
                                url: "https://www.facebook.com/FoodeDiet",
                                openExternal: true,
                            },
                            {
                                icon: <InstagramIcon/>,
                                title: 'Instagram',
                                url: "https://www.instagram.com/food_ediet/?hl=fr",
                                openExternal: true,
                            },
                        ],
                    },
                    {
                        icon: (<ContactSupportIcon/>),
                        title: 'Mentions légales',
                        items: [
                            {
                                title: 'Politique de confidentialité',
                                url: site + "conditions-utilisation#politique-de-confidentialite",
                                openExternal: true,
                            },
                            {
                                title: 'Mentions légales',
                                url: site + "conditions-utilisation#cgu",
                                openExternal: true,
                            },
                        ],
                    },

                    ]}
                    bottom="Copyright © Demarche Dyn. 2020"
                    //backgroundColor='LightSlateGrey'
                    
                />
            </div>
        )
    }
}