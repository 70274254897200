import { Avatar, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import React, { Component } from 'react';
import MyFooter from '../../../components/Footer';
import Header from '../../../components/Header';
import { MyColor, MyFontFamily } from '../../../components/Theme';
import Zoom from 'react-reveal/Zoom';
import CheckIcon from '@mui/icons-material/Check';
import './pricing.css';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../../helpers';
import { Helmet } from 'react-helmet';
import { Tooltip as MatTooltip } from '@mui/material';

const MyListItem = (props) => {
    return (
        <ListItem style={{paddingBottom:props.paddingBottom ?? "0px"}}>
            <ListItemIcon>
                <CheckIcon />
            </ListItemIcon>
            <ListItemText >
                <span className="karla_text" style={{lineHeight:"normal"}}>{props.children}</span>
            </ListItemText>
        </ListItem>
    )
}

class Pricing extends Component {

    componentDidMount() {
        this.props.firebase.analytics.logEvent('pricing_page_view');
    }

    render() {
        return (
            <div className="pricing karla_text">
                <Helmet>
                    <title>Tarifs</title>
                    <meta name="description" content="Prix et tarifs sur Food-eDiet. La consultation et bilan diététique est à 59 euros (€). La consultation de suivi à 29 euros (€}" />
                    <link rel="canonical" href={"https://food-ediet.com/tarifs"} />
                    <meta property="og:url" content="https://food-ediet.com/tarifs" />
                </Helmet>
                <Header />
                <div style={{
                    backgroundImage:"url(/background_legume.jpg),linear-gradient(180deg,#437a88 0%,rgba(67,122,136,0.6) 100%)",
                    backgroundPosition:"bottom right",
                    backgroundBlendMode:"multiply"
                    }}> 
                    <h1 style={{fontSize:"0px"}}>Consultation en ligne : forfait, tarifs et prix sur Food-ediet</h1>
                    <Grid container style={{ paddingBottom:"25px", paddingTop:"15px", margin:"0", width:"100%"}} spacing={2} direction="column" alignItems="center" alignContent="center" justifyContent="center">
                        <Grid item container alignItems="center" alignContent="center" justifyContent="center" spacing={4} style={{margin:"0", width:"100%"}}>
                            <Grid item>
                                <Zoom>
                                    <Paper style={{maxWidth:"330px", padding:"15px"}} elevation={9}>
                                        <div style={{minHeight:"600px"}}>
                                        <center>
                                            <h2  style={{fontFamily:MyFontFamily.SECONDARY_ROHZA,  color:MyColor.PRIMARY_GREEN, fontSize:"2em" }}>Formule<br/> Gratuite</h2>
                                            <b><span style={{fontSize:"68px", color:MyColor.SECONDARY_GREEN}}>0</span></b>
                                            <span style={{fontSize:"24px", color:MyColor.PRIMARY_GREEN}}>€</span>
                                            <div style={{height:"25px"}}/>
                                            <List component="p">
                                            <MyListItem>
                                                Accès au blog sans publicité
                                            </MyListItem>
                                            <MyListItem>
                                                <span>Accès plateforme version </span>
                                                <MatTooltip title="La version basic vous permet de suivre votre évolution : graphiques, courbe de poids, outils pour calculer votre IMC, ..." arrow> 
                                                    <span style={{fontWeight:"bold", textDecoration: "underline dotted"}} >basic</span>
                                                </MatTooltip>
                                            </MyListItem>
                                            <MyListItem>
                                                Des conseils et astuces chaque mois dans votre boite mail
                                            </MyListItem>
                                            <MyListItem>
                                                Réductions 
                                            </MyListItem>
                                            <MyListItem>
                                                Newsletter
                                            </MyListItem>
                                            </List>
                                        </center>
                                        </div>
                                        <center ><Link to="/inscription" className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"220px", paddingTop:"12px", paddingBottom:"12px"}} >S'inscrire gratuitement</button></Link></center>
                                    </Paper>
                                </Zoom>
                            </Grid>
                            <Grid item>
                                <Zoom>
                                    <Paper style={{maxWidth:"330px", padding:"15px"}} elevation={9}>
                                        <div style={{minHeight:"600px"}}>
                                            <center>
                                                <h2  style={{fontFamily:MyFontFamily.SECONDARY_ROHZA,  color:MyColor.PRIMARY_GREEN, fontSize:"2em" }}>Formule<br/> Formations</h2>
                                                <b><span style={{fontSize:"68px", color:MyColor.SECONDARY_GREEN}}>3</span></b>
                                                <span style={{fontSize:"24px", color:MyColor.PRIMARY_GREEN}}>€ / mois</span>
                                                <div style={{height:"25px"}}/>
                                                <List component="p">
                                                    <MyListItem>
                                                        <b>Prolonge vos avantages de la formule gratuite</b>
                                                    </MyListItem>
                                                    <MyListItem>
                                                        <span>Accès plateforme version </span>
                                                        <MatTooltip title="La version premium vous permet d'accèder à l'intégralité des fonctionnalités de la plateforme Food-eDiet. Vous pourrez à tout instant tchatter avec votre diététicienne. Vous aurez également la possibilité de remplir un journal alimentaire afin d'optimiser votre suivi diététique" arrow> 
                                                            <span style={{fontWeight:"bold", textDecoration: "underline dotted"}} >premium</span>
                                                        </MatTooltip>
                                                    </MyListItem>
                                                    <MyListItem>
                                                        Un livre/formation personnalisé selon votre profil
                                                    </MyListItem>
                                                </List>
                                            </center>
                                        </div>
                                        <center ><Link to="/inscription" className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"220px", paddingTop:"12px", paddingBottom:"12px"}} >Choisir cette offre</button></Link></center>
                                    </Paper>
                                </Zoom>
                            </Grid>
                            <Grid item>
                                <Zoom>
                                <Paper style={{ maxWidth:"330px", padding:"15px"}} elevation={9}>
                                    <div style={{minHeight:"600px"}}>
                                    <center>
                                        <h2  style={{fontFamily:MyFontFamily.SECONDARY_ROHZA,  color:MyColor.PRIMARY_GREEN, fontSize:"2em"}}>Formule<br/> 1<sup>ère</sup> Consultation</h2>
                                        <b><span style={{fontSize:"68px", color:MyColor.SECONDARY_GREEN}}>59</span></b>
                                        <span style={{fontSize:"24px", color:MyColor.PRIMARY_GREEN}}>€</span><br/>
                                        <div style={{height:"25px"}}/>
                                        <List component="p">
                                            <MyListItem>
                                                <b>Prolonge vos avantages de la formule gratuite</b>
                                            </MyListItem>
                                            <MyListItem>
                                                    <span>Accès plateforme version </span>
                                                    <MatTooltip title="La version premium vous permet d'accèder à l'intégralité des fonctionnalités de la plateforme Food-eDiet. Vous pourrez à tout instant tchatter avec votre diététicienne. Vous aurez également la possibilité de remplir un journal alimentaire afin d'optimiser votre suivi diététique" arrow> 
                                                        <span style={{fontWeight:"bold", textDecoration: "underline dotted"}} >premium</span>
                                                    </MatTooltip>
                                                    </MyListItem>
                                            <MyListItem>
                                                1 séance par téléconsultation d'environ 1h
                                            </MyListItem>
                                            <MyListItem paddingBottom="15px">
                                                Bilan nutritionnel
                                            </MyListItem>
                                            <MyListItem paddingBottom="5px">
                                                Définition des objectifs
                                            </MyListItem>
                                            <MyListItem paddingBottom="5px">
                                                Plan alimentaire personnalisé 
                                            </MyListItem>
                                            <MyListItem>
                                                Suivi régulier de votre progression
                                            </MyListItem>
                                        </List>
                                    </center>
                                    </div>
                                    <center ><Link to="/inscription" className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"220px", paddingTop:"12px", paddingBottom:"12px"}} >Choisir cette offre</button></Link></center>
                                </Paper>
                                </Zoom>
                            </Grid>
                            <Grid item>
                                <Zoom>
                                <Paper style={{ maxWidth:"330px", padding:"15px"}} elevation={9}>
                                    <div style={{minHeight:"600px"}}>
                                    <center>
                                        <h2  style={{fontFamily:MyFontFamily.SECONDARY_ROHZA,  color:MyColor.PRIMARY_GREEN, fontSize:"2em"}}>Formule<br/> Consultation de suivi</h2>
                                        <b><span style={{fontSize:"68px", color:MyColor.SECONDARY_GREEN}}>29</span></b>
                                        <span style={{fontSize:"24px", color:MyColor.PRIMARY_GREEN}}>€</span><br/>
                                        <div style={{height:"25px"}}/>
                                        <List component="p">
                                            <MyListItem>
                                                <b>Pour les consultations suivantes !</b>
                                            </MyListItem>
                                            <MyListItem>
                                                    <span>Accès plateforme version </span>
                                                    <MatTooltip title="La version premium vous permet d'accèder à l'intégralité des fonctionnalités de la plateforme Food-eDiet. Vous pourrez à tout instant tchatter avec votre diététicienne. Vous aurez également la possibilité de remplir un journal alimentaire afin d'optimiser votre suivi diététique" arrow> 
                                                        <span style={{fontWeight:"bold", textDecoration: "underline dotted"}} >premium</span>
                                                    </MatTooltip>
                                                    </MyListItem>
                                            <MyListItem>
                                                1 séance par téléconsultation d'environ 30mins
                                            </MyListItem>
                                            <MyListItem paddingBottom="15px">
                                                Révision des objectifs
                                            </MyListItem>
                                            <MyListItem paddingBottom="5px">
                                                Echange sur vos ressentis
                                            </MyListItem>
                                            <MyListItem paddingBottom="5px">
                                                Réadaptation du plan 
                                            </MyListItem>
                                            <MyListItem>
                                                Motivation et encouragement
                                            </MyListItem>
                                        </List>
                                    </center>
                                    </div>

                                    <center >
                                        <div>
                                            <Link to="/inscription" className="nolink">
                                                <MatTooltip title="Une consultation de suivi commence après votre 1ère consultation et bilan" arrow> 
                                                <button type="button" className="circular-inscription-btn" style={{width:"220px", paddingTop:"12px", paddingBottom:"12px", backgroundColor:"grey"}} >Pour les suivantes !</button>
                                                </MatTooltip>
                                            </Link>
                                        </div>
                                    </center>
                                </Paper>
                                </Zoom>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div >
                        <div style={{
                            backgroundImage:"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI2dnciIHZpZXdCb3g9IjAgMCAxMjgwIDE0MCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmZmZmIj48cGF0aCBkPSJNMTI4MCAxNDBWMFM5OTMuNDYgMTQwIDY0MCAxMzkgMCAwIDAgMHYxNDB6Ii8+PC9nPjwvc3ZnPg==)",
                            backgroundSize:"100% 6vw",
                            height: "6vw",
                            }}/>
                    </div>
                </div>
                <div style={{background:"linear-gradient(white, rgb(250, 251, 252))"}}>
                    <div id="what-consultation">
                        <div className="container" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"6vw"}}>
                            <Grid container style={{width:"100%", margin:"0"}}>
                                <Grid item xs={12} md={6}>
                                    <h2 className="secondary_h3" style={{marginTop:"0"}}>
                                        Bilan diététique : Déroulement de la 1ère séance (Durée : ~1h)
                                    </h2>
                                    <p style={{lineHeight:"2em", wordWrap:"break-word", }}>
                                    Lors de la 1ère consultation, nous apprenons à nous connaitre à travers un échange bienveillant et dans la bonne humeur.
                                    Les questions sont ciblées en fonction de votre mode de vie, de vos goûts et de vos habitudes alimentaires, selon votre personnalité.
                                    Nous discutons ensemble de vos objectifs et de ce qui est envisageable ou non pour la création de votre plan alimentaire.
                                    </p>
                                </Grid>
                                <Grid item md={1}/>
                                <Grid item xs={12} md={5}>
                                    <img style={{maxWidth:"100%", height:"auto"}} src="background_dv_04.png" alt="background"/>
                                    <h4 className="main_h4" style={{color:MyColor.PRIMARY_ORANGE}}>Ce suivi diététique est-il fait pour moi ?</h4> 
                                    <ul style={{fontWeight:"700"}}>
                                        <li style={{marginBottom:"5px"}}>Suite au 1er échange, vous recevrez un plan alimentaire personnalisé dans les 3 jours.</li>
                                        <li style={{marginBottom:"5px"}}>En fonction de vos objectifs, vous recevrez des documents et conseils créés pour vous.</li>
                                        <li style={{marginBottom:"5px"}}>Le suivi ne s'arrête pas au bilan diététique, vous êtes accompagné et encouragé régulièrement</li>
                                    </ul>
                                </Grid>
                            </Grid>
                            <Grid container style={{width:"100%", margin:"0", paddingTop:"20px"}} justifyContent="space-between" >
                                <Grid item md={3} style={{paddingBottom:"15px"}}>
                                    <Paper style={{boxShadow:"rgb(1 33 92 / 10%) 0px 10px 60px 0px", padding:"25px"}}>
                                        <Avatar  style={{
                                            boxShadow: "rgb(83 152 31 / 62%) 0px 10px 20px 0px",
                                            width: "80px",
                                            backgroundColor:MyColor.SECONDARY_GREEN,
                                            margin:"auto",
                                            height:"80px",
                                        }}>
                                            <img src="/video.png" alt="téléconsultation" width="40" height="40"/>
                                        </Avatar>
                                        <center>
                                        <div style={{}}>
                                            <h4 className="main_h4" >Téléconsultation</h4>
                                        </div>
                                        <p style={{lineHeight:"2em"}}>
                                        Depuis la platforme sur votre ordinateur ou votre téléphone, participez à votre consultation en vidéo conférence dans la bonne humeur et dans la bienveillance
                                        </p>
                                        </center>
                                    </Paper>
                                </Grid>
                                <Grid item md={3}  style={{paddingBottom:"15px", paddingTop:"40px"}}>
                                <Paper style={{boxShadow:"rgb(1 33 92 / 10%) 0px 10px 60px 0px", padding:"25px"}}>
                                        <Avatar  style={{
                                            boxShadow: "rgb(208 139 36 / 61%) 0px 10px 20px 0px",
                                            width: "80px",
                                            backgroundColor:MyColor.PRIMARY_ORANGE,
                                            margin:"auto",
                                            height:"80px",
                                        }}>
                                            <img src="/questionnaire.png" alt="Bilan nutritionnel" width="40" height="40"/>
                                        </Avatar>
                                        <center>
                                        <div style={{}}>
                                            <h4 className="main_h4" >Bilan nutritionnel</h4>
                                        </div>
                                        <p style={{lineHeight:"2em"}}>
                                        Nous discuterons de vos motivations, des objectifs que vous souhaitez atteindre, de vos habitudes de vie et alimentaire afin de construire un plan alimentaire pour vous et avec vous
                                        </p>
                                        </center>
                                    </Paper>
                                </Grid>
                                <Grid item md={3}  style={{paddingBottom:"15px"}}>
                                <Paper style={{boxShadow:"rgb(1 33 92 / 10%) 0px 10px 60px 0px", padding:"25px"}}>
                                        <Avatar  style={{
                                                boxShadow: "rgb(208 203 36 / 68%) 0px 10px 20px 0px",
                                                width: "80px",
                                                backgroundColor:"rgb(245 232 85)",
                                                margin:"auto",
                                                height:"80px",
                                                }}>
                                            <img src="/plan.png" alt="Plan alimentaire" width="40" height="40"/>
                                        </Avatar>
                                        <center>
                                        <div style={{}}>
                                            <h4 className="main_h4" >Plan alimentaire</h4>
                                        </div>
                                        <p style={{lineHeight:"2em"}}>
                                        Vous recevrez un plan alimentaire, personnalisé, adapté à vos besoins et à votre mode de vie ainsi que des conseils personnalisés, des listes d'aliments conseillés et des recettes
                                        </p>
                                        </center>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div id="quelle_est_l_offre" >
                        <div className="container" style={{width:"80%", maxWidth:"1080px", margin:"auto", paddingTop:"6vw"}}>
                            <Grid container style={{width:"100%", margin:"0"}}>
                                <Grid item xs={12} md={5}>
                                    <h2 className="main_h2">
                                       L'accompagnement chez Food-ediet ? Une vision sur le long terme
                                    </h2>
                                    <p style={{lineHeight:"2em", wordWrap:"break-word", paddingBottom:"15px" }}>
                                    Fini le temps des régimes, place au rééquilibrage alimentaire. 
                                    Parce que nous sommes tous différents, notre alimentation doit l'être aussi. 
                                    Nos diététiciennes sont là pour vous accompagner afin de trouver votre propre équilibre,
                                    celui qui vous permettra d'atteindre vos objectifs.<br/><br/>

                                    Cet équilibre vous permettra d'adopter de nouvelles habitudes alimentaires qui 
                                    feront ainsi partie de votre mode de vie.
                                    </p>
                                </Grid>
                                <Grid item md={1}/>
                                <Grid item xs={12} md={6}>
                                    <img style={{maxWidth:"100%", height:"auto"}} src="background_dv_03.png" alt="">
                                    </img>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div className="quoi-food-ediet" >
                        <div style={{
                            paddingTop:"3vw",
                            paddingBottom:"3vw",
                        }}>
                            <div 
                                className="container" 
                                style={{
                                    width:"80%", 
                                    maxWidth:"1220px", 
                                    margin:"auto", 
                                    paddingTop:"3vw", 
                                    paddingBottom:"40px", 
                                    backgroundColor:"#f9f9f4",
                                    backgroundImage:"url(/background_dv_05.png),radial-gradient(circle at center,#f9f9f4 80%,#ffffff 100%)", 
                                    backgroundRepeat:"no-repeat",
                                    backgroundPosition:"top left",
                                    //boxShadow:"rgb(1 33 92 / 10%) 0px 10px 60px 0px",
                                    backgroundSize:"contain"}} >

                                <div style={{maxWidth:"500px", margin:"auto", textAlign:"center", paddingBottom:"15px"}}>
                                    <h2 className="main_h2">Suivi en ligne sur Food-eDiet</h2>
                                    <p>Plus qu'un simple suivi diététique, Food-ediet vous offre des outils et services afin de faciliter l'avancée vers vos objectifs</p>
                                </div>
                                <div style={{width:"100%", margin:"auto"}}>
                                    <Grid container style={{padding:"0", margin:"auto", width:"80%", maxWidth:"1080px", }}>
                                        <Grid item md={6} container style={{padding:"0", margin:"0", width:"100%"}} >
                                            <Grid item xs={4}>
                                                <Avatar  style={{
                                                boxShadow: "rgb(208 139 36 / 61%) 0px 10px 20px 0px",
                                                width: "80px",
                                                backgroundColor:MyColor.PRIMARY_ORANGE,
                                                margin:"auto",
                                                height:"80px",
                                                }}>
                                                    <img src="/platforme.png" alt="Espace personnel" width="40" height="40"/>
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <h4 className="main_h4">Espace Personnel & Outils</h4>
                                                <p>L'accès à votre espace personnel présente de nombreux avantages : une prise de rendez-vous directement en ligne, des outils et graphiques 
                                                    vous permettant de suivre votre évolution, et votre plan et documents disponibles en 1 clic
                                                </p>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6} container style={{padding:"0", margin:"0", width:"100%"}} >
                                            <Grid item xs={4}>
                                                <Avatar  style={{
                                                boxShadow: "rgb(83 152 31 / 62%) 0px 10px 20px 0px",
                                                width: "80px",
                                                backgroundColor:MyColor.SECONDARY_GREEN,
                                                margin:"auto",
                                                height:"80px",
                                                }}>
                                                    <img src="/speech-bubble.png" alt="Tchat en ligne" width="40" height="40"/>
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <h4 className="main_h4">Tchat en ligne</h4>
                                                <p>Discutez à tout moment avec votre diététicienne, posez-lui toutes les questions qui vous passent par la tête, 
                                                    que vous soyez en train de faire vos courses ou en train de préparer le diner, le tchat est à portée de main
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{padding:"0", margin:"auto", width:"80%", maxWidth:"1080px",}}>
                                        <Grid item md={6} container style={{padding:"0", margin:"0", width:"100%"}} >
                                            <Grid item xs={4}>
                                                <Avatar  style={{
                                                boxShadow: "rgb(36 89 208 / 47%) 0px 10px 20px 0px",
                                                width: "80px",
                                                backgroundColor:"rgb(167, 198, 255)",
                                                margin:"auto",
                                                height:"80px",
                                                }}>
                                                    <img src="/talk.png" alt="Suivi régulier" width="40" height="40"/>
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <h4 className="main_h4">Suivi régulier</h4>
                                                <p>Grâce aux services en ligne Food-eDiet, soyez sûr d'être suivi régulièrement dans l'atteinte de vos objectifs
                                                </p>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6} container style={{padding:"0", margin:"0", width:"100%"}} >
                                            <Grid item xs={4}>
                                                <Avatar  style={{
                                                boxShadow: "rgb(208 203 36 / 68%) 0px 10px 20px 0px",
                                                width: "80px",
                                                backgroundColor:"rgb(245 232 85)",
                                                margin:"auto",
                                                height:"80px",
                                                }}>
                                                    <img src="/trophy.png" alt="Objectifs" width="40" height="40"/>
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <h4 className="main_h4">Objectifs</h4>
                                                <p>
                                                    Grâce aux outils que propose la platforme Food-eDiet, vous serez d'autant plus motivé pour atteindre vos objectifs
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    height:"6vw", 
                    background:"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI2dnciIHZpZXdCb3g9IjAgMCAxMjgwIDE0MCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmFmYWZhIj48cGF0aCBkPSJNMzIwIDI4QzE2MCAyOCA4MCA0OSAwIDcwVjBoMTI4MHY3MGMtODAgMjEtMTYwIDQyLTMyMCA0Mi0zMjAgMC0zMjAtODQtNjQwLTg0eiIvPjwvZz48L3N2Zz4=)",
                    backgroundColor:"rgb(242, 237, 219)"
                    }}/>
                <div style={{backgroundColor:"rgb(242, 237, 219)", paddingBottom:"15px", paddingRight:"10px"}}>
                <center>
                <h3 style={{fontWeight:"700", fontSize:"36px", lineHeight:"1.6em", marginBottom:"15px", fontFamily:MyFontFamily.SECONDARY_ROHZA,  color:MyColor.PRIMARY_GREEN,marginTop:"0px", paddingTop:"15px"}}>Questions fréquemment posées</h3>
                </center> 
                <Container maxWidth="xs" >
                    <center>
                        <p>
                            Pour toute question supplémentaire, n'hésitez pas à nous contacter à l'adresse mail : support@food-ediet.com
                        </p>
                    </center> 
                </Container>
                <Container maxWidth="md" >
                <Grid container justifyContent="center" spacing={5} style={{backgroudColor:"grey", margin:"0"}}>
                    <Grid item md={6} xs={12}>
                        <div style={{paddingBottom:"15px"}} >
                            <h3>Qu'est-ce que l'accompagnement Food-eDiet ?</h3>
                            <p style={{textAlign:"justify"}}>Food-eDiet est un cabinet diététique en ligne de téléconsultation. Grâce à ses services, soyez certain de bénéficier d'un suivi régulier et personnalisé afin d'atteindre vos objectifs</p>
                        </div>
                        <div style={{paddingBottom:"15px"}}>
                            <h3>Qu'est-ce que la platforme ?</h3>
                            <p style={{textAlign:"justify"}}>Après votre inscription, vous aurez accès à votre espace personnel sécurisé en ligne vous permettant de tchatter, lire vos documents, choisir une date de rendez-vous, ... Autrement dit, tous les outils nécessaires pour un accompagnement de qualité, disponible à tout instant</p>
                        </div>
                        <div style={{paddingBottom:"15px"}}>
                            <h3>Comment accéder à ma consultation ? </h3>
                            <p style={{textAlign:"justify"}}>Depuis votre espace personnel, vous pouvez facilement rejoindre votre consultation. Un mail contenant un lien vers votre consultation vous sera également envoyé</p>
                        </div>
                        <div style={{paddingBottom:"15px"}}>
                            <h3>Mes données sont-elles traitées confidentiellement ? </h3>
                            <p style={{textAlign:"justify"}}>Oui ! Vos données sur Food-eDiet sont privées et confidentielles. Elles ne sont consultables que par vous et votre diététicienne</p>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{paddingBottom:"15px"}}>
                            <h3>A qui convient Food-eDiet ? </h3>
                            <p style={{textAlign:"justify"}}>A toute personne ayant un objectif nutritionnel, que vous ayez une pathologie, une intolérance ou ayez tout simplement envie de « manger mieux » grâce à un rééquilibrage alimentaire, Food-eDiet vous accompagne tout au long de ce voyage </p>
                        </div>
                        <div style={{paddingBottom:"15px"}}>
                            <h3>Comment prendre rendez-vous ?</h3>
                            <p style={{textAlign:"justify"}}>Une fois inscrit, rien de plus simple. Depuis la platforme en ligne, suivez les instructions et choisissez le créneau qui vous arrange le mieux</p>
                        </div>
                        <div style={{paddingBottom:"15px"}}>
                            <h3>Quels sont les moyens de paiements autorisés ? </h3>
                            <p style={{textAlign:"justify"}}>Actuellement, 2 moyens de paiements sont autorisés : carte de crédit et Bancontact.</p>
                        </div>
                        <div style={{paddingBottom:"15px"}}>
                            <h3>Puis-je annuler une consultation ? </h3>
                            <p style={{textAlign:"justify"}}>Il est possible d'annuler ou de reporter une consultation jusqu'à 24h à l'avance</p>
                        </div>
                    </Grid>
                    
                </Grid>
                </Container>
                <Container maxWidth="md" style={{backgroundColor:"rgba(250, 251, 252, 0.6)", borderRadius:"30px", paddingTop:"15px", paddingBottom:"15px", }}>
                <Grid container justifyContent="space-evenly"  style={{paddingTop:"15px",paddingBottom:"15px", margin:"0" }} spacing={3}>
                    <Grid item>
                        <h2 style={{marginTop:"5px", marginBottom:"0px"}}>Prêt(e) à commencer ?</h2>
                    </Grid>
                    <Grid item >
                        <Link to="/inscription" className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"200px", paddingTop:"12px", paddingBottom:"12px"}}>Je me lance !</button></Link>
                    </Grid>
                </Grid>
                </Container> 
                <div style={{height:"25px"}} />
                </div>   
                <MyFooter/>
            </div>
        );
    }
}

export default withFirebase(Pricing);