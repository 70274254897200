import React, { Component } from 'react';
import styled from 'styled-components';

export default class Etape extends Component {

    state = {
        text: "",
        index: 0,
        
    }

    handleChange=(event) => {
        this.setState({ text : event.target.value })
        //this.props.update(event, this.state.index)
    }


    componentDidMount() {
        this.setState({text: this.props.text, index: this.props.index})
    }


    render() {

        const { text, index } = this.state;

        return (
            <div key={this.props.myref} >
                <div style={{background:"coral", borderRadius:"50%", width: "20px", textAlign:"center", color:"white", float: "left", marginTop:"10px"}}>{index+1}</div> 
                <MyTextfieldStyle ref={this.props.myref} key={"textfield"+this.props.key} type="text" name={this.props.name} value={text ?? ""} onChange={(event) => this.handleChange(event)} placeholder={this.props.placeholder} />
                <br/>
            </div>
        )
    }
}

const MyTextfieldStyle = styled.input`
    width: 750px;
    padding: 10px 0;
    margin: 5px;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid;
    outline: none;
    font-family: 'Karla', sans-serif;
    background: transparent;
    color: black;
`;