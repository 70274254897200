/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper } from '@mui/material';
import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';
import { MyModal } from '../../SimpleDialog';
import { DesktopView, MobileView, MyColor } from '../../Theme';
import CheckIcon from '@mui/icons-material/Check';
import {Link as ReactLink} from 'react-router-dom';
import './objectifs.css';

const ItemItem = (props) => {
    return (
        <p style={{marginTop:"5px", marginBottom:"5px"}}>{props.children}</p>
    )
};

const ModalMotifItem = (props) => {
    return (
        <ListItem>
             <ListItemIcon>
                <CheckIcon htmlColor={MyColor.PRIMARY_GREEN} />
            </ListItemIcon>
            <ListItemText primary={props.children} />
        </ListItem>
    )
}

class ObjectifsSection extends Component {

    state = {
        openModal:false,
    }

    render() {


        const MotifItem = (props) => {
            return (
                <Grid item xs={12} md={6} lg={3} style={{paddingLeft:"0px", paddingRight:"48px"}}>
                    <Zoom delay={props.delay}>
                        <Paper elevation={4} style={{boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)" }} >
                            <Grid container direction="column" justifyContent="space-between" style={{height:"430px", padding:"15px"}}>
                                <Grid item>
                                    <center >
                                        <img src={props.img} width="80px" height="80px" alt={props.title} style={{marginTop:"30px"}}/>
                                        <h2 style={{font:"normal normal normal 35px/1.4em 'rozha one',serif", fontSize:"calc(8px + 1.5vw)", color: MyColor.PRIMARY_GREEN}}>{props.title}</h2>
                                        <div className="karla_text" style={{margin: "auto", fontSize:"18px"}}>
                                        {props.children}
                                        </div>
                                    </center>
                                </Grid>
                                <Grid item>
                                    <MyModal title="Motifs de consultation">
                                        <List subheader={<li />}>
                                            { props.silhouette && <div>
                                                <ListSubheader style={{backgroudColor:"white"}} disableSticky>Silhouette</ListSubheader>
                                                <ModalMotifItem>
                                                    Perte de poids 
                                                </ModalMotifItem>
                                                <ModalMotifItem>
                                                    Prise de poids 
                                                </ModalMotifItem>
                                                <ModalMotifItem>
                                                    Maintien du poids de forme
                                                </ModalMotifItem>
                                                <ModalMotifItem>
                                                    Bien être
                                                </ModalMotifItem>
                                                </div>
                                            }
                                            { props.sante && <div>
                                                <ListSubheader  disableSticky>Santé</ListSubheader>
                                                <ModalMotifItem>
                                                    Intolérances
                                                </ModalMotifItem>
                                                <ModalMotifItem>
                                                    Allergies 
                                                </ModalMotifItem>
                                                <ModalMotifItem>
                                                    Pathologies métaboliques (diabète, dyslipidémie, pathologie du foie, du pancréas, problème de thyroïde…) 
                                                </ModalMotifItem>
                                                <ModalMotifItem>
                                                    Maladie cœliaque/Rectocolite hémorragique/Maladie de Crohn 
                                                </ModalMotifItem>
                                                <ModalMotifItem>
                                                    Syndrome de l’intestin ou du côlon irritable 
                                                </ModalMotifItem>
                                                <ModalMotifItem>
                                                    Troubles intestinaux mixtes (diarrhée/constipation)
                                                </ModalMotifItem>
                                                </div>
                                            }
                                             { props.reequilibrage && <div>
                                            <ListSubheader disableSticky>Rééquilibrage alimentaire</ListSubheader>
                                            <ModalMotifItem>
                                                Alimentation de la femme enceinte 
                                            </ModalMotifItem>
                                            <ModalMotifItem>
                                                Alimentation de la femme allaitante 
                                            </ModalMotifItem>
                                            <ModalMotifItem>
                                                Alimentation végétarienne, végétalienne, végan, flexitarien ,… 
                                            </ModalMotifItem>
                                            <ModalMotifItem>
                                                Alimentation pédiatrique 
                                            </ModalMotifItem>
                                            <ModalMotifItem>
                                                Alimentation gériatrique  
                                            </ModalMotifItem>
                                            </div> 
                                        }
                                         { props.sport && <div>
                                            <ListSubheader disableSticky>Sport</ListSubheader>
                                            <ModalMotifItem>
                                                Alimentation du sportif en fonction de ses objectifs
                                            </ModalMotifItem>
                                            <ModalMotifItem>
                                                Loisir 
                                            </ModalMotifItem>
                                            <ModalMotifItem>
                                                Performance/compétition
                                            </ModalMotifItem>
                                            <ModalMotifItem>
                                                Sport dans le cadre d’une hygiène de vie saine
                                            </ModalMotifItem>
                                            </div>
                                        }
                                        </List>
                                    </MyModal>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Zoom>
                </Grid>
            )
        };

        const GridItems = (props) => {
            return (
                    <Grid container spacing={props.gsm ? 2 : 6}style={{
                        margin: 0,
                        width: '100%',
                    }}> 
                    <MotifItem {...props} title="Silhouette" img="poids2.png" delay={0} silhouette>
                        <ItemItem>Perte de poids</ItemItem>
                        <ItemItem>Prise de poids</ItemItem>
                        <ItemItem>Poids de forme</ItemItem>
                        <ItemItem>Bien être</ItemItem>
                    </MotifItem>
                    <MotifItem {...props}  title="Sport" img="poids.png" delay={200} sport>
                        <ItemItem>Objectifs</ItemItem>
                        <ItemItem>Performance</ItemItem>
                        <ItemItem>Compétition</ItemItem>
                        <ItemItem>Loisir</ItemItem>
                    </MotifItem>
                    <MotifItem {...props} title="Santé" img="santé.png" delay={400} sante>
                        <ItemItem>Intolérances</ItemItem>
                        <ItemItem>Allergies</ItemItem>
                        <ItemItem>Diabète</ItemItem>
                        <ItemItem>Maladie cœliaque</ItemItem>
                    </MotifItem>
                    <MotifItem {...props} title= "Équilibre" img="balanced-diet.png" delay={600} reequilibrage>
                        <ItemItem>Végétarisme</ItemItem>
                        <ItemItem>Végétalisme</ItemItem>
                        <ItemItem>Pour tout âge</ItemItem>
                        <ItemItem>Femme enceinte </ItemItem>
                    </MotifItem>
                </Grid>
            )
        }

        const Titre = () => {
            return (
                <div>
                    <a className="anchor" id="list-objectifs"></a>
                    <div >
                        <h2 className="main_h2" >
                            Un suivi adapté à vos objectifs
                        </h2>
                        <p>
                            L'alimentation varie selon l'objectif et le résultat attendu. Le définir clairement est donc essentiel afin d'obtenir de <b>bons résultats</b>.
                        </p>
                    </div>
                </div>
            )
        }

        const MotifsDesktop = () => { 
            return (
            <div id="list-objectifs" style={{width: "100%",paddingTop:"50px", paddingBottom:"50px",}}>
                <div style={{width:"80%", margin:"auto", maxWidth:"1080px"}}>
                    <Titre/>
                    <GridItems/>
                </div>
            </div>
        )};

        const MotifsGSM = () => { 
            return (
            <div id="list-objectifs" style={{width: "100%",  paddingBottom:"50px",}}>
               <Titre/>
                <GridItems gsm/>
            </div>
        )};

        return (
            <div>
                <a className="anchor" id="quelque-soit-votre-objectif" ></a>
                <DesktopView>
                    <MotifsDesktop />
                </DesktopView>

                <MobileView>
                    <div style={{paddingLeft:"25px", paddingRight:"5px"}}>
                        <MotifsGSM/>
                    </div>
                </MobileView>
                <div style={{paddingTop:"25px", paddingBottom:"45px"}}>
                    <center>
                        <ReactLink to='tarifs' className="nolink">
                            <button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative"}}>
                                Définir mes objectifs
                            </button>
                        </ReactLink>
                    </center>
                </div>     
            </div>
        );
    }
}

export default ObjectifsSection;