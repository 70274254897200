import app from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/functions';

// Initialize Firebase
var prodConfig = {
    apiKey: "AIzaSyCph7Muh_jnNol2thHewFhcjemGq1rBgkg",
    authDomain: "hello-diet.firebaseapp.com",
    databaseURL: "https://hello-diet.firebaseio.com",
    projectId: "hello-diet",
    storageBucket: "hello-diet.appspot.com",
    messagingSenderId: "747141083403",
    appId: "1:747141083403:web:6c3211a2cd8de813b6b20e",
    measurementId: "G-807J1L1CZK"
};

/*var devConfig =  {
  apiKey: "AIzaSyDuoBxaTi0yFSEI0AGRcZUKOeN_7JK8wzM",
  authDomain: "food-ediet-dev.firebaseapp.com",
  projectId: "food-ediet-dev",
  storageBucket: "food-ediet-dev.appspot.com",
  messagingSenderId: "862857779786",
  appId: "1:862857779786:web:b5955e99ea3e169394a4b3",
  measurementId: "G-5SH811FVK7"
};*/

const config = process.env.NODE_ENV === 'production' ? prodConfig : prodConfig;

class Firebase {
    constructor() {
        this.fireapp = app.initializeApp(config);
        this.auth = app.auth();
        this.analytics = app.analytics();
        this.firestore = app.firestore();
        this.firestoreUp = app.firestore;
        this.realtimeDataBase = app.database();
        this.database = app.database;
        this.storage = app.storage();
        this.storageUp = app.storage;
        this.functions = this.fireapp.functions('europe-west1');
        //this.base = Rebase.createClass(this.fireapp.firestore());
        this.googleProvider = new app.auth.GoogleAuthProvider();
        this.facebookProvider = new app.auth.FacebookAuthProvider();
        this.twitterProvider = new app.auth.TwitterAuthProvider();
        this.emailProvider = new app.auth.EmailAuthProvider();
        this.user = null;
        this.app = app;

        
    }

    // Firestore 
    getTime = () => {
      return app.firestore.FieldValue.serverTimestamp()
    }

    // Authentication API 
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSendEmailForgotPassword = (email) =>
        this.auth.sendPasswordResetEmail(email);
 
    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doConfirmPasswordReset = (code, password) =>
        this.auth.confirmPasswordReset(code, password);

    doUpdateMail = (email) =>
        this.auth.currentUser.updateEmail(email);

    doUpdatePassword = (password) =>
        this.auth.currentUser.updatePassword(password);

    getProviderId = () => this.auth.currentUser?.providerData;

    reauthenticateWithCredential = (email, password) => 
      this.auth.currentUser.reauthenticateWithCredential(app.auth.EmailAuthProvider.credential(email, password));

    reauthenticateWithCredentialFacebook = () => 
      this.auth.currentUser.reauthenticateWithCredential();
    

    doSignInAnonymously = () => this.auth.signInAnonymously();

    doSignOut = async () => await this.auth.signOut();

    doSignInFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

    doSignInGoogle = () => {
      this.auth.languageCode = 'fr';
      return this.auth.signInWithPopup(this.googleProvider);
    } 

    onFireStoreUserLister = (next, fallback) => 
    this.firestore.collection("users").doc(this.user.uid).onSnapshot(firestoreUser => {
      if(firestoreUser) {
        next(firestoreUser);
      }
      else {
        fallback();
      }
    });

    ////IMPORTANT, peut être la solution pour quand on se connecte avoir tout chargé !!!
    onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
          if(authUser.isAnonymous) {
          }
          else {
            this.firestore.collection("users").doc(authUser.uid).get().then(value => {
              const user = {
                uid: authUser.uid,
                ...value.data(),
              }
              this.user = user;
            })
          }
          next(authUser);
      } else {
        fallback();
      }
    });

  }
   
export default Firebase;

/*// Initialize Analytics
app.analytics();

// Initialize firestore
var firestore = app.firestore();
const base = Rebase.createClass(firestore);

// Firebase auth


export {firebaseapp};
export default base;*/
