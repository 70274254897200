import React, { Component } from 'react'
import {  Grid, Paper, IconButton, InputBase, Tooltip, Button, Link, Breadcrumbs } from '@mui/material';
import { withAuthorization } from '../../../components/Session';
import Fade from 'react-reveal/Fade';
import HttpIcon from '@mui/icons-material/Http';
import TitleIcon from '@mui/icons-material/Title';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import { MyEditor } from '../ArticleManagement';
import "./CreateDocument.css";
//import FileUploader from 'react-firebase-file-uploader/lib';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import Rating from '@mui/material/Rating';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import { ColorDifficulty, DocType, LabelDifficulty, parseTitre, SaveType } from './helpers';
import SimpleDialog from '../../../components/SimpleDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Level } from '../../../components/Theme';
import { ELO_UID } from '../../../utils/Settings';
import NoteIcon from '@mui/icons-material/Note';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";



const NewArticle = {
    // Common
    url: "",
    title:"",
    subtitle:"",
    date:null,

    // Article
    content: "",
    miniatureURL:"",
    difficulty:"",
}


class CreateArticle extends Component {
    
    state = {
        ...NewArticle,
        submitSuccess: "",
        /*text: "",
        article: {},
        loading: true,
        file: null,
        isUploading: false,
        progress: 0,
        articleInitialContent: "",*/

        // error
        error:"",
        errorUrl:"",
        errorTitre:"",
        errorSubtitle:"",
        errorDifficulty:"",

         // other
        openModalDelete: false,
    }

    componentDidMount() {
        if(this.props.match.params.uid) {
            this.setState({...this.props.document, url : this.props.match.params.uid })
        }
        else {
            this.setState({...NewArticle})
        }
    }

    onEditorChange = (content, editor, name) => {
        this.setState({[name] : content})
        localStorage.setItem("editor-" + name, content);
    }

    handleChangeInputFile = (e) => {
        if(!e.target.files)
            return
        this.submitUpload.click()
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const file = e.target[0]?.files[0]
    
        if (!file) return;
    
        const storageRef = ref(this.props.firebase.storage, "articles/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(progress)
            //setProgresspercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                this.setState({miniatureURL: url})
            });
          }
        );
      }

    deleteArticle = () => {
        this.props.firebase.firestore.collection("articles").doc(this.props.match.params.uid).delete()
        .then(() => {
            this.props.navigate("/admin/articles");
        }).catch(err => {
            this.setState({ error : err });
            console.error(err);
        });
    }

    saveDocument = () => {

        const { url } = this.state;

        const date = Date.now()

        const document = {
            // Common
            saveType: this.state.saveType,
            url: this.state.url,
            title:this.state.title,
            subtitle:this.state.subtitle,

            // Article
            content: this.state.content,
            miniatureURL:this.state.miniatureURL,
            difficulty:this.state.difficulty,

            level: Level.ABONNE,
        }

        if(this.props.match.params.uid) {
            // Update document

            const modif_document = {
                ...document,
                dateModif: date,
            }
            
            this.props.firebase.firestore.collection("articles").doc(url).update(modif_document)
            .then((result) => {
                this.setState({submitSuccess:"Votre article a bien été mis à jour !", ...modif_document, warningLeavingFlag: true})
            })
            .catch(error => {
                console.error(error)
                this.setState({ error });
            });
        }
        else {

            const add_document = {
                ...document,
                date: date,
                dateModif: date,
                dietId: ELO_UID,//this.props.authUser.data.uid,
                id: url,
                level: Level.ABONNE,
                pictures:[],
                type:DocType.Article,
                uid:url,
            }


            this.props.firebase.firestore.collection("articles").doc(url).set(add_document)
            .then((result) => {
                this.props.firebase.firestore.collection("users").doc(this.props.authUser.data.uid).update({numberOfArticles : this.props.firebase.app.firestore.FieldValue.increment(1)})
                .then((result) => {
                    this.setState({submitSuccess:"Votre article a bien été publié !", ...add_document, warningLeavingFlag : true})
                    this.props.navigate(url)
                });
            })
            .catch(error => {
                console.error(error)
                this.setState({ error });
            });
        }

    }

    render() {

        const { error, difficulty, errorDifficulty, errorUrl, url, title, errorTitre, subtitle, errorSubtitle, content, miniatureURL,
            submitSuccess, saveType, date, dateModif,  } = this.state;
        return (
            <div>
                <Fade collapse when={error}>
                    <Alert severity="error" onClose={() => {this.setState({error:""})}}> {error}</Alert>
                </Fade>
                <Fade collapse when={submitSuccess}>
                    <Alert severity="success" onClose={() => {this.setState({submitSuccess:""})}}> {submitSuccess}</Alert>
                </Fade>
                <Breadcrumbs style= {{marginTop:"10px", paddingTop:"5px", paddingLeft:"5px", marginBottom:"10px"}} >
                    <Link color="inherit" href="/admin">
                    Espace Pro
                    </Link>
                    <Link color="inherit" href="/admin/articles">
                    Mes Articles
                    </Link>
                    <span>Créer un article</span>
                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <Paper style={{padding:"15px"}} elevation={2}>
                            <h1 className="secondary_h2">Création d'un article</h1>
                            <Grid container style={{width:"100%", margin:0}}>
                                <Grid item xs={12} md={3} style={{paddingLeft:"0px", paddingBottom:"0px"}}>
                                    <div id="article miniature">
                                        <h2 className="main_h3">Photo de l'article</h2>
                                        <Tooltip title="Changer l'image de l'article" placement="bottom">
                                            <div>
                                            {miniatureURL === "" && <Paper style={{height:"100px", width:"100%"}} elevation={3}><center><IconButton onClick={() => this.fileUpload.click()}  style={{marginTop:"25px"}}><CloudUploadIcon fontSize="large"  /></IconButton></center></Paper> }
                                            {miniatureURL !== "" && <img onClick={() => this.fileUpload.click()} src={miniatureURL}  alt=""   style={{maxWidth:"100%", cursor:"pointer"}}/>}
                                            </div>
                                        </Tooltip>
                                        <form  onSubmit={this.handleSubmit} className='form' style={{ visibility: 'hidden', width:0, height:0}}>
                                            <input type='file' ref={(fileUpload) => {this.fileUpload = fileUpload;}} onChange={this.handleChangeInputFile} />
                                            <button type='submit' ref={(submitUpload) => {this.submitUpload = submitUpload;}} >Confirmer</button>
                                        </form>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={1} />
                                <Grid item xs={12} md={8} style={{paddingLeft:"0px", paddingBottom:"0px"}}>
                                    <div id="article titre">
                                        <h2 className="main_h3">Titre</h2>
                                        <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                            <TitleIcon style={{padding: "10px"}}  />
                                            <InputBase
                                                placeholder="Titre de l'article"
                                                onChange={
                                                    (event) => {
                                                        if(!this.props.match.params.uid) {
                                                            this.setState({title : event.target.value, url: parseTitre(event.target.value)})
                                                        }
                                                        else {
                                                            this.setState({title : event.target.value})
                                                        }
                                                    }}
                                                value={title}
                                                className="karla_text"
                                                style={{width:"100%"}}
                                            />
                                        </Paper>
                                        <Fade bottom collapse when={errorTitre}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire ou non conforme
                                            </div>
                                        </Fade>
                                    </div>
                                    <div id="article url">
                                        <h2 className="main_h3">Id de l'article (qui servira d'url pour y accèder depuis le blog)</h2>
                                        <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                            <HttpIcon style={{padding: "10px"}}  />
                                            <InputBase
                                                placeholder="Url de l'article"
                                                onChange={(event) => { if(!this.props.match.params.uid) {this.setState({url : event.target.value})} }}
                                                value={this.props.match.params.uid ?? url}
                                                disabled={this.props.match.params.uid}
                                                className="karla_text"
                                                style={{width:"100%"}}
                                            />
                                        </Paper>
                                        <ul style={{marginBottom:"0px"}}><li><i>https://food-ediet.com/blog/{this.props.match.params.uid ?? url}</i></li></ul>
                                        <Fade bottom collapse when={errorUrl}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire ou non conforme
                                            </div>
                                        </Fade>
                                    </div>
                                    <div id="article sous titre">
                                        <h2 className="main_h3">Sous-titre</h2>
                                        <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                            <SubtitlesIcon style={{padding: "10px"}}  />
                                            <InputBase
                                                placeholder="Sous-titre de l'article"
                                                onChange={(event) => {this.setState({subtitle : event.target.value})}}
                                                value={subtitle}
                                                className="karla_text"
                                                style={{width:"100%"}}
                                            />
                                        </Paper>
                                        <Fade bottom collapse when={errorSubtitle}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire ou non conforme
                                            </div>
                                        </Fade>
                                    </div>
                                </Grid>
                        
                        </Grid>
                        <div id="article contenu">
                            <h2 className="main_h3">Contenu</h2>
                            <MyEditor 
                                editorId="content" 
                                onChange={(content, editor) => this.onEditorChange(content, editor, "content")} 
                                content={content} 
                            />
                        </div>
                        </Paper>
                    </Grid> 
                    <Grid item xs={12} lg={4}>
                        <Paper style={{padding:"10px 10px 10px", marginBottom:"10px", position:"static"}}>
                            <h2 className="main_h3"><SettingsIcon/> Paramètres</h2>
                            <h4 style={{marginBottom:"10px"}}  >Difficulté de l'article</h4>
                            <div style={{display:"flex"}}>
                                <Rating
                                    value={difficulty}
                                    max={3}
                                    size="large"
                                    onChange={(event, newValue) => {
                                        this.setState({difficulty:newValue})
                                    }}
                                    classes={{
                                        iconFilled: ColorDifficulty[difficulty],
                                        iconHover: ColorDifficulty[difficulty],
                                    }}
                                    
                                />
                                <span style={{marginTop:"8px"}}>{difficulty !== "" && difficulty !== null ? "- " + LabelDifficulty[difficulty] : ""}</span>
                            </div>
                            <Fade bottom collapse when={errorDifficulty}>
                                <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                Le champs est obligatoire ou non conforme
                                </div>
                            </Fade>
                           
                            <h4>Sauvegarde</h4>
                            <Button
                                    variant="contained"
                                    startIcon={<NoteIcon />}
                                    onClick={() => {this.setState({ saveType: SaveType.Draft}, this.saveDocument)}}
                                    style={{marginRight:"10px", marginBottom:"10px"}}
                                >
                                {this.props.match.params.uid && saveType === SaveType.Draft ? "Modifier le brouillon" : ( saveType === SaveType.Publish ? "Transferer vers mes brouillons" : "Enregistrer comme brouillon" ) } 
                            </Button>
                            <Button
                                    variant="contained"
                                    startIcon={<PublishIcon />}
                                    onClick={() => {this.setState({ saveType: SaveType.Publish}, this.saveDocument)}}
                                    style={{backgroundColor:"rgb(188, 224, 138)", marginBottom:"10px"}}
                                >
                                {this.props.match.params.uid && saveType === SaveType.Publish ? "Publier les modifications" : "Publier l'article" }
                            </Button>
                            { this.props.match.params.uid && 
                                <div>
                                    <h4>Gestion de l'article</h4>
                                    <p>Statut : <b>{saveType ?? SaveType.Publish}</b> </p>
                                    <p>Créé le : <i>{date ? new Date(date).toLocaleDateString('fr-BE', option) : "/"}</i> </p>
                                    <p>Dernière modification le : <i>{dateModif ? new Date(dateModif).toLocaleDateString('fr-BE', option) : "/"}</i> </p>
                                    <Grid container justifyContent="space-evenly" alignItems="center" style={{width:"100%", margin:"0"}}>
                                        <Grid item style={{marginRight:"10px"}}>
                                            <Button
                                            target="_blank"
                                            href={"/blog/"+this.props.match.params.uid}
                                            variant="contained"
                                            style={{backgroundColor:"rgb(167, 198, 255)"}}
                                            startIcon={<VisibilityIcon />}
                                            >
                                                Visualiser l'article
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                            variant="contained"
                                            style={{backgroundColor:"rgb(255, 77, 77)"}}
                                            startIcon={<DeleteIcon />}
                                            onClick={() => {this.setState({openModalDelete:true})}}
                                            >
                                                Supprimer l'article
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        </Paper>
                        <SimpleDialog 
                            head="Supprimer ?"
                            body="Veux-tu vraiment supprimer cet article ? Cette action est définitive"
                            open={this.state.openModalDelete}
                            handleClose={() => this.setState({openModalDelete: false})}
                            handleConfirm={this.deleteArticle}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(CreateArticle);

const option = {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'};