import React, { Component } from 'react'
import "react-multi-carousel/lib/styles.css";
import { compose } from 'recompose';
//import GridRecette from './GridRecette';
import { Helmet } from 'react-helmet';
import { Container, Grid, Paper } from '@mui/material';
import ArticleEntry from '../components/ArticleEntry';
import BlogSuivezNous from '../components/BlogSuivezNous';
import TopArticles from '../components/TopArticles';
import { DesktopView, MobileView } from '../../../components/Theme';
import MyFooter from '../../../components/Footer';
import { withAuthorization } from '../../../components/Session';
import HeaderBlog from '../HeaderBlog';
import { SaveType } from '../../diet/CreateDocument/helpers';
import { withRouter } from '../../../App';
import { collection, where, limit, query, orderBy, onSnapshot } from "firebase/firestore";


class ArticleCategory extends Component {

    state = {
        loading: true,
        articles: [],
    }

    componentDidMount() {
        this.bindArticles();
        this.props.firebase.analytics.logEvent('article_blog_page_view');
    }

    componentWillUnmount() {
        if(this.unsub) {
            this.unsub()
        }
    }

    bindArticles = () => {

        this.unsub = onSnapshot(
            query(
              collection(this.props.firebase.firestore, "articles"),
              where('type', "==", "Article"),
              where('saveType', "==", SaveType.Publish),
              orderBy('date', "desc"),
              limit(10)
            ),
            (snap) => {
                const items = snap.docs.map(docSnapshot => docSnapshot.data());
                this.setState({ articles : items, loading: false })
            },
            (error) => {
              console.error(error)
              this.setState({  loading : false });
            }
        );
    }

    render() {
        const { authUser } = this.props;

        const { articles } = this.state;

        const articleList = articles.filter(article => (article.level ?? 0) <= ((authUser && authUser.data) ? authUser.data.level : 0));

        const entries  = Object
        .keys(articleList)
        .map((key, i) => (
            <ArticleEntry key={key} article={articleList[key]}/>
        ));

        const BodyDesktop = (props) => { return(
                <Container maxWidth="lg">
                    <Paper style={{marginTop:"35px", marginBottom:"20px"}}elevation={7}>
                        <Grid container style={{width:"100%", padding:"15px", paddingTop:"0px"}} >
                            <Grid item md={8} xs={12}>
                                <center style={{paddingTop:"15px", paddingBottom:"15px"}}>
                                    <h2 >Articles diététiques</h2>
                                    <p>Découvrez et comprenez comment être en meilleure santé grâce à nos articles diététiques sur Food-eDiet</p>
                                </center>
                                {entries}
                            </Grid>
                            <Grid item md={1} />
                            <Grid item md={3} xs={12}>
                                <BlogSuivezNous/>
                                <TopArticles/>
                            </Grid>
                        </Grid>
                    </Paper>
                    

                </Container>
        )}

        const BodyGSM = (props) => { return(
            <Container maxWidth="xs" style={{margin:"0", padding:"0"}}>
                <Paper style={{marginTop:"15px", padding:"15px" }}elevation={7}>
                    <Grid container style={{width:"100%"}} >
                        <Grid item md={9} xs={12}>
                            <center>
                            <h2 style={{marginBottom:"5px"}}>Articles diététiques</h2>
                            <p>Découvrez et comprenez comment être en meilleure santé grâce à nos articles diététiques sur Food-eDiet</p>
                            </center>
                            {entries}
                        </Grid>
                        <Grid item md={3} xs={12}>
                        <BlogSuivezNous/>
                                <TopArticles/>
                        </Grid>

                    </Grid>
                </Paper>
                

            </Container>
    )}
        


        return (
            <div style={{backgroundColor:"#F9F9F9"}}>
                <Helmet>
                        <title>Blog - Articles Diététiques</title>
                        <meta name="description" content="Découvrez et comprenez comment être en meilleure santé grâce à nos articles diététiques sur Food-eDiet" />
                        
                        <link rel="canonical" href="https://food-ediet.com/blog/categorie/article" />

                </Helmet>
                <HeaderBlog/>
                <h1 style={{fontSize:"0px"}}>Food-eDiet - Article magazine diététique et nutrition tout apprendre sur l'alimentation </h1>
                <DesktopView>
                    <BodyDesktop {...this.props} />
                </DesktopView>
                <MobileView>
                    <BodyGSM {...this.props} />
                </MobileView>
                <MyFooter/>
            </div>
        )
    }
}

export default compose(withAuthorization((authUser) => true), withRouter)(ArticleCategory);
