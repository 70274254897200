import { Container,  InputBase, Paper } from '@mui/material';
import React, { Component } from 'react';
import MyFooter from '../../../components/Footer';
import Header from '../../../components/Header';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../../helpers';
import Validator from '../../../utils/Validator';
import { Helmet } from 'react-helmet';
import Alert from '@mui/material/Alert';
import GoogleButton from 'react-google-button'
import { compose } from 'recompose';
import { withRouter } from '../../../App';

class ConnexionPage extends Component {


    state = {
        mail:"",
        password:"",
        error: "",
        errorMail:"", 
        errorPassword:"",
    }

    componentDidMount() {
        this.props.firebase.analytics.logEvent('connexion_page_view');
        console.log(this.props)
    }

    confirm = () => {

        const { mail, password } = this.state;

        var okMail = Validator.checkEmail(mail);
        this.setState({errorMail:!okMail})

        var okPassword = Validator.isNonEmpty(password);
        this.setState({errorPassword:!okPassword})

        if(okMail && okPassword) {
            this.ConnectUserByMail();
        }
    }

    rtdb_and_local_fs_presence = () => {
        // [START rtdb_and_local_fs_presence]
        // [START_EXCLUDE]
        var uid = this.props.firebase.auth.currentUser.uid;
        var userStatusDatabaseRef = this.props.firebase.realtimeDataBase.ref('/status/' + uid);
    
        var isOfflineForDatabase = {
            state: 'offline',
            last_changed: this.props.firebase.database.ServerValue.TIMESTAMP,
        };
    
        var isOnlineForDatabase = {
            state: 'online',
            last_changed: this.props.firebase.database.ServerValue.TIMESTAMP,
        };
    
        // [END_EXCLUDE]
        var userStatusFirestoreRef = this.props.firebase.firestore.doc('/status/' + uid);
    
        // Firestore uses a different server timestamp value, so we'll 
        // create two more constants for Firestore state.
        var isOfflineForFirestore = {
            state: 'offline',
            last_changed: this.props.firebase.firestoreUp.FieldValue.serverTimestamp(),
        };
    
        var isOnlineForFirestore = {
            state: 'online',
            last_changed: this.props.firebase.firestoreUp.FieldValue.serverTimestamp(),
        };
    
        this.props.firebase.realtimeDataBase.ref('.info/connected').on('value', function(snapshot) {
            if (snapshot.val() === false) {
                // Instead of simply returning, we'll also set Firestore's state
                // to 'offline'. This ensures that our Firestore cache is aware
                // of the switch to 'offline.'
                userStatusFirestoreRef.set(isOfflineForFirestore);
                return;
            };
    
            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
                userStatusDatabaseRef.set(isOnlineForDatabase);
    
                // We'll also add Firestore set here for when we come online.
                userStatusFirestoreRef.set(isOnlineForFirestore);
            });
        });
    }

    ContinueWithGoogle = () => {
        this.props.firebase.doSignInGoogle().then((result) => {
            const user = {
                imageURL: result.user.photoURL,
                firstname: result.additionalUserInfo.profile.given_name,
                name: result.additionalUserInfo.profile.family_name,
                email: result.additionalUserInfo.profile.email,
                level: 1,
                hasUnreadMessage: false,
                uid: result.user.uid
            }
            if(result.additionalUserInfo.isNewUser) {
                this.props.firebase.firestore.collection("users").doc(result.user.uid).set(user).then(() => {
                    this.props.navigate("/home");
                })
            }
            else {
                this.props.navigate("/home");
            }
          })
          .catch(error => {
            this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
          });
    }

    ConnectUserByMail = () => {
        const { mail, password } = this.state;
        
        this.props.firebase.doSignInWithEmailAndPassword(mail, password).then(() => {
            this.rtdb_and_local_fs_presence();
            this.props.navigate(this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : "/home");
          })
          .catch(error => {
            if(error.code + "" === "auth/user-not-found") {
                this.setState({ error: "mail et/ou mot de passe introuvable"});
            }
            else if(error.code + "" === "auth/wrong-password") {
                this.setState({ error: "mail et/ou mot de passe introuvable"});
            }
            else {
                this.setState({ error: "une erreur s'est produite - Vérifiez votre mail/mot de passe ou créez un compte "});
                console.error(error)
            }
          });
    }

    ContinueWithFacebook = () => {
        this.props.firebase.doSignInFacebook().then((socialAuthUser) => {
            const user = {
                imageURL: socialAuthUser.user.photoURL + "?height=200",
                firstname: socialAuthUser.additionalUserInfo.profile.first_name,
                name: socialAuthUser.additionalUserInfo.profile.last_name,
                email: socialAuthUser.user.email,
                level: 1,
                hasUnreadMessage: false,
                uid: socialAuthUser.user.uid
            }
            if(socialAuthUser.additionalUserInfo.isNewUser) {
                this.props.firebase.firestore.collection("users").doc(socialAuthUser.user.uid).set(user).then(() => {
                    this.props.navigate("/home");
                })
            }
            else {
                this.props.navigate("/home");
            }
          })
          .catch(error => {
            this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
          });
    }



    render() {

        const { error, errorMail, errorPassword } = this.state;

        return (
            <div className="inscriptionPage karla_text" style={{backgroundColor:"rgba(250, 251, 252, 0.6)"}}>
                <Helmet>
                    <title>Connexion à votre espace personnel</title>
                    <meta name="description" content="Se connecter sur Food-eDiet vous permet d'intéragir sur la platforme et d'accèder à vos documents, plans et conseils ainsi qu'aux recettes adaptés à vos besoins" />
                </Helmet>
                <Header />
                <Container maxWidth="xs">
                    {
                        this.props.location.state && this.props.location.state.redirect && <div>
                             <Helmet>
                    <title>Espace personnel</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                            <Alert severity="error">Vous devez être connecté pour afficher cette page.</Alert>
                            </div>
                    }
                    <h1 style={{fontSize:"1em", marginTop:"1em", marginBottom:"1em"}}>Connectez-vous à votre compte Food-eDiet</h1>
                    <div style={{paddingBottom:"25px", paddingTop:"5px"}}>
                        <GoogleButton
                            className="google-button"
                            style={{width:"100%"}}
                            label="Se connecter avec Google"
                            onClick={this.ContinueWithGoogle}
                        />
                    </div>
                    <form onSubmit={(event) => {event.preventDefault(); this.confirm()}}>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <EmailIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="E-mail"
                            onChange={(event) => {this.setState({mail : event.target.value})}}
                            value={this.state.mail}
                            type="email"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorMail}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                            Ce champs est obligatoire ou non conforme
                        </div>
                    </Fade>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <LockIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Mot de passe"
                            onChange={(event) => {this.setState({password : event.target.value})}}
                            value={this.state.password}
                            type="password"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorPassword}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                            Ce champs est obligatoire
                        </div>
                    </Fade>
                  
                   
                    <center style={{paddingBottom:"25px", paddingTop:"25px"}}>
                        <Fade bottom collapse when={error}>
                            <span style={{color:"red", fontSize:"14px",  paddingBottom:"10px",}}>Attention : {error}</span>
                        </Fade>
                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}}>Se connecter</button>
                    </center>
                    <center style={{ paddingBottom:"25px"}}>
                        J'ai oublié <Link to="reset_password">mon mot de passe</Link>
                    </center>
                    <center style={{ paddingBottom:"60px"}}>
                        <div style={{width:"100%", height:"1px", borderRadius:"25px", backgroundColor:"gainsboro", marginBottom:"15px"}} />
                        Je n'ai pas encore de compte <Link to="/inscription">S'inscrire</Link>
                    </center>
                    </form>
                </Container>
                <MyFooter/>
            </div>
        );
    }
}

export default compose(withRouter, withFirebase)(ConnexionPage);