import { Avatar, Divider, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MyColor } from '../../../../components/Theme';
import { withFirebase } from '../../../../helpers';

/*
* Top 5 articles, on the right of the /blog page (may be static for SEO ? )
*
*/
class TopArticles extends Component {

    state = {
        loading:true,
        articles:[],
    }

    componentDidMount() {
        this.bindArticles();
    }

    bindArticles = () => {
        this.props.firebase.firestore.collection("articles").orderBy('read', 'desc').limit(5)
        .get()
        .then((querySnapshot) => {
            const data = querySnapshot.docs.map(docSnapshot => docSnapshot.data())
            this.setState({loading: false, articles: data})
        })
        .catch((error) => {
            console.error("Error getting documents: ", error);
        });
    }


    render() {

        const { articles, loading } = this.state;

        const listArticle  = Object
        .keys(articles)
        .map((key, i) => (
            <div key={key}>
                <Divider orientation="horizontal" variant="fullWidth" />
                <Grid container style={{width:"100%", marginTop:"5px", marginBottom:"5px"}} alignItems="center">
                    <Grid item xs={3} >
                        <div style={{position:"relative"}}>
                            <Link to={"/blog/" + articles[key].uid} className="nolink">
                                <Avatar src={articles[key].miniatureURL} style={{ height: '65px', width: '65px'}}/>
                            </Link>
                            <div style={{background:MyColor.PRIMARY_GREEN, borderRadius:"50%", width: "20px", textAlign:"center", color:"white", position:"absolute", top:"0", left:"0"}}>{i+1}</div> 
                        </div>
                        

                    </Grid>
                    <Grid item xs={9}>
                        <Link to={"/blog/" + articles[key].uid} className="nolink">
                            <h4 style={{textDecoration:"none", color:MyColor.PRIMARY_GREEN}}>{articles[key].title}</h4>
                        </Link>
                    </Grid>
                </Grid>

            </div>
        ));

        const SkeletonLoading = () => <div >
            <Divider orientation="horizontal" variant="fullWidth" />
                <Grid container style={{width:"100%", marginTop:"5px", marginBottom:"5px"}} alignItems="center">
                    <Grid item xs={3} >
                        <Skeleton animation="wave" variant="circle" width={65} height={65} />
                    </Grid>
                    <Grid item xs={9}>
                        <Skeleton animation="wave" variant="text" />
                    </Grid>
                </Grid>
            
            

        </div>


        return (
            <div>
                <h2>Top Articles</h2>
                {!loading ? listArticle : 
                <div>
                    <SkeletonLoading/>
                    <SkeletonLoading/>
                    <SkeletonLoading/>
                    <SkeletonLoading/>
                    <SkeletonLoading/>
                </div>
                }
            </div>
        );
    }
}

export default withFirebase(TopArticles);