import React from 'react';
import {  Fab, Tooltip } from '@mui/material';


const SimpleFab = (props) => {
    return <Tooltip title={props.tooltip} placement="top">
        <Fab 
        aria-label="edit" 
        style={{position:"fixed", bottom:"15px", right:"15px", backgroundColor:"coral"}}
        onClick={props.onClick}
        >
            {props.icon}
        </Fab>
    </Tooltip>
}

export default SimpleFab;

//<EditIcon htmlColor="white" />
//() => {this.props.navigate("/admin/article/" + article.uid)}
//Modifier l'article