import React from 'react'
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import HomeIcon from '@mui/icons-material/Home';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { LinkBehavior } from '../../../../components/Theme/LinkBehaviour';
import './bottomNavigation.css'
import { MyColor } from '../../../../components/Theme';

export default function LabelBottomNavigation() {
    const [value, setValue] = React.useState('home');
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
      localStorage.setItem("value", newValue);
    };
  
    return (
      <BottomNavigation value={localStorage.getItem("value", value)} onChange={handleChange} style={{bottom: 0, position:"fixed", width:"100%",  backgroundColor:MyColor.SECONDARY_GREEN, zIndex:"5001"}} showLabels >
        <BottomNavigationAction component={LinkBehavior} to="/home" label="Accueil" value="home" icon={<HomeIcon />} />
        <BottomNavigationAction component={LinkBehavior} to="/home/consultation-create" label="Réserver" value="home/consultation-create" icon={<AddBoxIcon />} />
        <BottomNavigationAction component={LinkBehavior} to="/home/consultation" label="Consultations" value="consultations" icon={<InsertInvitationIcon />} />
      </BottomNavigation>
    );
  }