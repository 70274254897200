import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './helpers'
import CookieConsent from "react-cookie-consent";
import { Helmet } from 'react-helmet';


ReactDOM.render(
  
  <FirebaseContext.Provider value={new Firebase()}>
      <React.StrictMode>
        <CookieConsent buttonText="Accepter" declineButtonText="Refuser" style={{ zIndex:"9999" }} enableDeclineButton>Ce site utilise des cookies pour améliorer votre expérience utilisateur</CookieConsent>
        <Helmet>
            <meta property="og:title"  data-react-helmet="true" content="Food-eDiet - Cabinet diététique en ligne par téléconsultation" />
            <meta property="og:image" data-react-helmet="true" content="https://firebasestorage.googleapis.com/v0/b/hello-diet.appspot.com/o/imageOG2.png?alt=media&token=074a14cb-7889-4889-9f25-bc0f967004aa" />
            <meta property="og:url"  data-react-helmet="true" content="https://food-ediet.com/" />
            <meta property="og:type" data-react-helmet="true" content="website" />
        </Helmet>
        
        <App />   
        { /* <div>Site en cours de maintenance, merci de réessayer plus tard</div> */ }
      </React.StrictMode>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
