import React, { Component } from 'react'
import "react-multi-carousel/lib/styles.css";
import { compose } from 'recompose';
//import GridRecette from './GridRecette';
import MyFooter from '../../components/Footer';
import { withAuthorization } from '../../components/Session';
import SimpleFab from './components/SimpleFab';
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet';
import HeaderBlog from './HeaderBlog';
import { Container, Grid, Paper } from '@mui/material';
import ArticleEntry, { ArticleEntrySkeleton, ArticleEntryUne, ArticleEntryUneSkeleton } from './components/ArticleEntry';
import { DesktopView, MobileView } from '../../components/Theme';
import BlogSuivezNous from './components/BlogSuivezNous';
import TopArticles from './components/TopArticles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SaveType } from '../diet/CreateDocument/helpers';
import { withRouter } from '../../App';

class Blog extends Component {

    state = {
        loading: true,
        articles: [],
        last: null,
        has_more: true,
    }

    componentDidMount() {
        this.getArticles();
        this.props.firebase.analytics.logEvent('blog_page_view');
    }

    getArticles = () => {
        const { firebase } = this.props;
        let set = this

        //initiate first set
        var first;
        first  = firebase.firestore.collection("articles").where("saveType", "==", SaveType.Publish).orderBy('date', 'desc').limit(10);
        first.get().then(function (documentSnapshots) {
            var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
            const list = [];
            documentSnapshots.forEach(function(doc) {
                const articles = doc.data();

                list.push(articles);
            });
            set.setState({ loading: false, articles : list,  last: lastVisible, has_more: (list.length > 0) });
          });
    }

    fetchMoreData = () => {
        const { firebase } = this.props;
        let {last} = this.state;
        let set = this;

        if(last == null)
            return
        
        var next = firebase.firestore.collection("articles").where("saveType", "==", SaveType.Publish).orderBy('date', 'desc').startAfter(last).limit(5);
        next.get().then(function (documentSnapshots) {
            var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
            const list = [];
            documentSnapshots.forEach(function(doc) {
                const articles = doc.data();
                list.push(articles);
            });
            let updated_list = set.state.articles.concat(list);
            set.setState({ articles: updated_list, last: lastVisible, has_more: (list.length > 0) });
        });
    };

    render() {
        const { authUser } = this.props;

        // Render all articles tiles
        const { articles, loading, has_more } = this.state;

        //const articleList = articles.filter(article => (article.level ?? 0) <= ((authUser && authUser.data) ? authUser.data.level : 0));

        const entries  = 
        <InfiniteScroll 
            dataLength={articles.length}
            next={this.fetchMoreData}
            hasMore={has_more}
            loader={
                <div>
                    <ArticleEntrySkeleton/>
                    <ArticleEntrySkeleton/>
                    <ArticleEntrySkeleton/>
                    <ArticleEntrySkeleton/>
                    <ArticleEntrySkeleton/>
                </div>
            }
        >
            {articles.map((single, key) => (
                key ? <ArticleEntry key={key} article={articles[key]}/> : <div></div>
            ))}
        </InfiniteScroll>

        const BodyDesktop = (props) => { return(
                <Container maxWidth="lg" style={{maxWidth:"1280px"}}>
                    <Paper style={{marginTop:"35px", marginBottom:"20px"}}elevation={7}>
                        <Grid container style={{width:"100%", padding:"15px", paddingTop:"0px"}} >
                            <Grid item md={8} xs={12}>
                                {loading ? <ArticleEntryUneSkeleton/> :<ArticleEntryUne article={articles[0]} /> }
                                
                                {entries}
                            </Grid>
                            <Grid item md={1} />
                            <Grid item md={3} xs={12}>
                                <BlogSuivezNous/>
                                <TopArticles/>
                            </Grid>
                        </Grid>
                    </Paper>
                    

                </Container>
        )}

        const BodyGSM = (props) => { return(
            <Container maxWidth="xs" style={{margin:"0", padding:"0"}}>
                <Paper style={{marginTop:"15px", padding:"15px" }}elevation={7}>
                    <Grid container style={{width:"100%"}} >
                        <Grid item md={9} xs={12}>
                            {loading ? <ArticleEntryUneSkeleton/> :<ArticleEntryUne article={articles[0]} /> }
                            {entries}
                        </Grid>
                        <Grid item md={3} xs={12}>
                             <BlogSuivezNous/>
                             <TopArticles/>
                        </Grid>

                    </Grid>
                </Paper>
                

            </Container>
    )}
        


        return (
            <div style={{backgroundColor:"#F9F9F9"}}>
                <Helmet>
                        <title>Blog diététique - Article et recettes pour apprendre la nutrition</title>
                        <meta name="description" content="Blog diététique comportant des articles, recettes, conseils et quizz sur l'alimentation. Apprenez-en davantage sur la bonne nutrition en vous amusant" />
                        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4559264347139943"
     crossorigin="anonymous"></script>
                </Helmet>
                {
                    authUser && authUser.data && authUser.data.level >= 4 && 
                    <SimpleFab
                        tooltip="Créer un article"
                        onClick={() => {this.props.navigate("/admin/article/")}}
                        icon={<AddIcon htmlColor="white" />}
                    />
                }
                <HeaderBlog/>
                <h1 style={{fontSize:"0px"}}>Food-ediet Blog diététique - Conseils et articles pour apprendre la nutrition</h1>
                <DesktopView>
                    <BodyDesktop {...this.props} />
                </DesktopView>
                <MobileView>
                    <BodyGSM {...this.props} />
                </MobileView>
                <MyFooter/>
            </div>
        )
    }
}

//const condition = authUser => !!authUser;

export default compose(withAuthorization((authUser) => true), withRouter)(Blog);
