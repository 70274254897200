import Alert from '@mui/material/Alert';
import React, { Component } from 'react';

class FutureConsultation extends Component {

    state = {
        show:false
    }

    componentDidMount(){
        const {authUser} = this.props;

        if(!authUser.data.lastConsultation) {
            return;
        }
        else {
            const yourDateMilliseconds = parseInt(authUser.data.lastConsultation);
            const actualTimeMilliseconds = new Date().getTime()
            const diff = actualTimeMilliseconds - yourDateMilliseconds
            if (diff > 2678400000) {
                this.setState({show:true})
            }
        }   
    }


    render() {

        const {authUser} = this.props;
        const {show} = this.state;

        return (

            <div>
                {show &&
                    <Alert severity="info" style={{textAlign:"justify"}}>
                        <p>Bonjour <b>{authUser.data.firstname} !</b></p> 
                        <p>Nous espérons que tout se passe bien pour toi dans l'<b>avancée de tes objectifs !</b></p>
                        <p>Il est important de discuter de <b>vive voix</b> après 1 mois de changement pour évaluer les choses positives et modifier au besoin ton plan alimentaire afin qu'il te corresponde au mieux.</p>
                        <p>Pour prendre un <b>rendez-vous en ligne</b>, il te suffit de rejoindre la page <a href="/home/consultation-create">Réserver une date</a></p>
                        <p>Au plaisir d'échanger avec toi !</p>
                    </Alert>
                }
            </div>
        );
    }
}

export default FutureConsultation;