import React, { Component } from 'react'
import { withFirebase } from '../../../helpers';
import CarouselArticle from '../components/CarouselArticle';
import { compose } from 'recompose';

class YouLikeToo extends Component {

    state = {
        articles: null,
    }

    componentDidMount() {
        const { firebase, category, level, uid } = this.props;

        firebase.firestore.collection("articles")
        .where('type', '==', category)
        .where('level', '<=', level)
        .orderBy('level')
        .orderBy('date', "desc")
        .limit(6)
        .get()
        .then((querySnapshot) => {
            const data = querySnapshot.docs.map(docSnapshot => docSnapshot.data())
            this.setState({loading: false, articles: data.filter(element => element.uid !== uid)})
        })
        .catch((error) => {
            console.error("Error getting documents: ", error);
        });
    }

    render() {
        return (
            <div>
                <h2 style={{ fontFamily: "'Karla', sans-serif"}}>Vous pourriez également aimer</h2>
                { this.state.articles && 
                    <CarouselArticle  articles={this.state.articles} />
                }
            </div>
        )
    }
}

export default compose(withFirebase)(YouLikeToo);
