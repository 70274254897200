/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {  Container,    InputBase, Paper } from '@mui/material';
import React, { Component } from 'react';
import MyFooter from '../../../components/Footer';
import Header from '../../../components/Header';
import EmailIcon from '@mui/icons-material/Email';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../../helpers';
import Alert from '@mui/material/Alert';
import Validator from '../../../utils/Validator';
import { Helmet } from 'react-helmet';

class PasswordReset extends Component {


    state = {

        // Fields
        email:"",
        ok:false, // email sent

        // Errors
        error:"",
        errorEmail:"",
    }

    componentDidMount() {
        this.props.firebase.analytics.logEvent('passwordReset_page_view');
    }

    confirm = () => {

        const { email } = this.state;

        var okEmail = Validator.checkEmail(email);
        this.setState({errorEmail : !okEmail})

        if(okEmail) {
            this.resetPassword();
        }
    }

    resetPassword = () => {

        const { email } = this.state;

        this.props.firebase.doSendEmailForgotPassword(email).then((result) => {
            this.setState({ok : true})
        })
        .catch(error => {
            console.error(error)
            this.setState({ok : true})
        });
    }


    render() {

        const { errorEmail, error, ok } = this.state;

        return (
            <div className="inscriptionPage karla_text" style={{backgroundColor:"rgba(250, 251, 252, 0.6)"}}>
                <Helmet>
                    <title>Mot de passe perdu</title>
                    <meta name="description" content="Si vous avez perdu votre mot de passe, suivez la procédure et vous pourrez indiquer un nouveau mot de passe" />
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                <Header />
                <Container maxWidth="xs">
                    { ok &&
                    <Alert severity="success">
                       <p>Un mail vous a été envoyé à l'adresse mail renseignée. Cliquez sur le lien dans le mail pour poursuivre la procédure</p>
                    </Alert>
                     }
                    <h1 className="main_h4">Indiquez votre adresse mail pour réinitialiser votre mot de passe</h1>
                    <form onSubmit={(event) => {event.preventDefault(); this.confirm()}}>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <EmailIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="E-mail"
                            onChange={(event) => {this.setState({email : event.target.value})}}
                            value={this.state.email}
                            type="email"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorEmail}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                        Le mail est vide ou non conforme
                        </div>
                    </Fade>
                    <center style={{paddingBottom:"25px", paddingTop:"25px"}}>
                        <Fade bottom collapse when={error}>
                            <span style={{color:"red", fontSize:"14px",  paddingBottom:"10px",}}>Attention : {error}</span>
                        </Fade>
                        <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px"}} >Envoyer</button>
                    </center>
                    </form>
                    <center style={{ paddingBottom:"225px"}}>
                        <div style={{width:"100%", height:"1px", borderRadius:"25px", backgroundColor:"gainsboro", marginBottom:"15px"}} />
                        Je ne possède pas <Link to="/inscription">de compte</Link>
                    </center>
                </Container>
                <MyFooter/>
            </div>
        );
    }
}

export default withFirebase(PasswordReset);