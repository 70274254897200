import React, { Component } from 'react'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PeopleIcon from '@mui/icons-material/People';
import StarIcon from '@mui/icons-material/Star';
import { Paper, Tabs, Tab, Box, CircularProgress, Tooltip, Divider, Hidden } from '@mui/material';
import './recette.css';
import { ApportRecJourn, ApportRecJournSigle } from '../../../utils/Settings';
import { DesktopView, MobileView, MyColor } from '../../../components/Theme';
import { Helmet } from 'react-helmet';


export default class Recette extends Component {

    state = {
        index: 0,
    }

    handleChangeTab = (event, newValue) => {
        this.setState({index : newValue});
    } 

    getDifficulty = (difficulty) => {
        if(difficulty === 0) {
            return <div>Facile <StarIcon style={{verticalAlign: "middle", marginLeft:"5px"}} htmlColor="green"/><StarIcon style={{verticalAlign: "middle"}}/><StarIcon style={{verticalAlign: "middle"}}/></div> 
        }
        if(difficulty === 1) {
            return <div>Intermédiaire <StarIcon style={{verticalAlign: "middle", marginLeft:"5px"}} htmlColor="coral"/><StarIcon style={{verticalAlign: "middle"}} htmlColor="coral"/><StarIcon style={{verticalAlign: "middle"}}/></div> 
        }
        if(difficulty === 2) {
            return <div>Expérimenté <StarIcon style={{verticalAlign: "middle", marginLeft:"5px"}} htmlColor="orangered"/><StarIcon style={{verticalAlign: "middle"}} htmlColor="orangered"/><StarIcon style={{verticalAlign: "middle"}} htmlColor="orangered" /></div> 
        }
    }

    componentDidMount() {
        const {recette} = this.props;


        const jsonldScript = document.createElement('script');
        jsonldScript.setAttribute('type', 'application/ld+json');

        const structuredData = {
            "@context": "https://schema.org/",
            "@type": "Recipe",
            "name": recette.title,
            "image": [
                recette.miniatureURL
            ],
            "datePublished":"",
            "recipeIngredient":[

            ],
            "recipeInstructions": [
                {
                    "@type":"HowToStep",
                    "text":"Préchauffer le four à 210°C (th 7)."
                },
            ],
            "author":"Eloïse Feryr",
            "keywords":"",
            "recipeCuisine":"Entrée",
            
        };
        
        jsonldScript.textContent = structuredData;
        document.head.appendChild(jsonldScript);
    }

    render() {

        const {recette} = this.props;
        const { index } = this.state;
        const list = Object.keys(recette.apports).map((uid) => 
            (<CircularProgressWithLabel 
                nom={recette.apports[uid].nom} 
                poids={recette.apports[uid].poids} 
                pourcent={recette.apports[uid].pourcent} 
                key={uid}  
            />
        ));

        const ingredients = Object.keys(recette.ingredients).map((uid, i) => 
            (<div key={recette.ingredients[uid]}>
                <div >
                    <div style={{background:MyColor.PRIMARY_GREEN, borderRadius:"50%", width: "20px", textAlign:"center", color:"white", float: "left"}}>{i+1}</div> 
                    <p style={{display: "inline", marginLeft: "15px"}}>{recette.ingredients[uid]}</p>
                </div>
               <br/>
            </div>
        ));

        const steps = Object.keys(recette.steps).map((uid, i) => 
            (<div key={recette.steps[uid]}>
                <div >
                    <div style={{background:MyColor.PRIMARY_GREEN, borderRadius:"50%", width: "20px", textAlign:"center", color:"white", float: "left"}}>{i+1}</div> 
                    <p style={{display: "inline", marginLeft: "15px"}}>{recette.steps[uid]}</p>
                </div>
                <br/>
            </div>
        ));

        
          

        return (
            <div className="karla_text">
                <Helmet>
                
                    </Helmet>
                <DesktopView>
                    <center>
                        <div style={{ marginLeft:"130px", marginRight:"130px", height:"450px", backgroundImage:"url("+recette.miniatureURL+")",  backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"  }}   />
                    </center>
                    <center style={{marginTop:"25px"}} ><h1>{recette.title}</h1></center>
                    <div className="recetteRow" style={{marginTop:"45px"}}>
                        <div className="recetteColumn"><center><HourglassEmptyIcon style={{verticalAlign: "middle", marginRight:"5px"}} />{recette.preparationTime}</center></div>
                        <div className="recetteColumn"><center><PeopleIcon style={{verticalAlign: "middle", marginRight:"5px"}} />{recette.nombreDePersonne}</center></div>
                        <div className="recetteColumnLeft" ><center>{this.getDifficulty(recette.difficulty)}</center></div>
                    </div>
                </DesktopView>
                <MobileView>
                    <center>
                        <div style={{ opacity:"0.6", height:"300px", backgroundImage:"url("+recette.miniatureURL+")",  backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"  }}   />
                    </center>
                    <center style={{marginTop:"25px"}}  ><h1>{recette.title}</h1></center>
                    <div className="recetteRow" style={{marginTop:"25px"}}>
                        <div className="recetteColumn"><center><HourglassEmptyIcon style={{verticalAlign: "middle", marginRight:"5px"}} />{recette.preparationTime}</center></div>
                        <div className="recetteColumn"><center><PeopleIcon style={{verticalAlign: "middle", marginRight:"5px"}} />{recette.nombreDePersonne}</center></div>
                        <div className="recetteColumnLeft" ><center>{this.getDifficulty(recette.difficulty)}</center></div>
                    </div>
                </MobileView>
                <br/>
                <Divider/>
                <center><h2>Apports Nutritionnels (1 pers) </h2></center>
                <br/>
                <MobileView>
                    <div style={{display:"flex", overflow: "-moz-scrollbars-horizonal", overflowX:"scroll"}}>{list}</div>
                </MobileView>
                <DesktopView>
                    <div style={{justifyContent: "space-evenly", display:"flex",  overflowX:"auto"}}>{list}</div>
                </DesktopView>
                
                <br/>
                <Hidden smUp>
                    <Paper style={{padding:"15px"}}>
                    <center><h2>Ingrédients</h2></center>
                    {ingredients}</Paper>
                    <Paper style={{padding:"15px"}}><center><h2>Étapes de préparation</h2></center>
                    {steps}</Paper>
                    <Paper style={{padding:"15px"}}><center><h2>Variantes de la recette</h2></center>
                    <div dangerouslySetInnerHTML={{__html: recette.variante}}></div></Paper>
                    <Paper style={{padding:"15px"}}><center><h2>Conseils et astuces diététiques</h2></center>
                    <div dangerouslySetInnerHTML={{__html: recette.pointDeVueDiet}}></div></Paper>
                </Hidden>
                <Hidden xsDown>
                    <Paper square>
                        <Tabs
                            value={index}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleChangeTab}
                            variant="fullWidth"
                        >
                            <Tab className="karla_text" label="Ingrédients et préparation"  />
                            <Tab  className="karla_text" label="Variantes" />
                            <Tab className="karla_text" label="Point de vue diet" />
                        </Tabs>
                    </Paper>
                    <TabPanel value={index} index={0} >
                        <Paper style={{padding:"15px"}} elevation={4}>
                        <h3>Liste des ingrédients</h3>
                        {ingredients}
                        <h3>Étapes de préparation</h3>
                        {steps}
                        <center>
                        <img className="img-responsive" src={recette.miniatureURL} alt={recette.title} width="350px" />

                        </center>
                        </Paper>
                    </TabPanel>
                    <TabPanel value={index} index={1} >
                        <Paper style={{padding:"15px"}} elevation={4}>
                            <h3>Variantes de la recette</h3>
                            <div dangerouslySetInnerHTML={{__html: recette.variante}} className="articlefull"></div>
                        </Paper>
                    </TabPanel>
                    <TabPanel value={index} index={2} >
                        <Paper style={{padding:"15px"}} elevation={4}>
                            <h3>Conseils et astuces diététiques</h3>
                            <div dangerouslySetInnerHTML={{__html: recette.pointDeVueDiet}} className="articlefull"></div>
                        </Paper>
                    </TabPanel>
                    
                </Hidden>
            </div>
        )
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  function CircularProgressWithLabel(props) {
    //var regex = "/[+-]?\d+(\.\d+)?/g";
   // var floats = str.match(regex).map(function(v) { return parseFloat(v); });
   var nom = props.nom.trim()
    if(nom.endsWith('.')) {
        nom = nom.substring(0, nom.length - 1);
    }
   const ValRec = ApportRecJourn[nom];
   const pourcent = (parseFloat(props.poids)/ValRec)*100;
    return (
        <Tooltip title={"Sur " + ValRec + ApportRecJournSigle[nom] + " soit " +  Math.round(pourcent) + "% des valeurs recommandées"} placement="top">
      <Box position="relative" display="inline-flex" style={{paddingLeft:"5px"}}>
        
        
        <CircularProgress 
            variant="static" 
            size={90} 
            disableShrink
            {...props} 
            value={pourcent} 
            thickness={2}
            style={{color: '#3CB371'}}
        />
        
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
            <div>
          <center><span>{props.nom}</span></center>
          <span>{props.poids}{ApportRecJournSigle[nom]}</span>
          </div>
        </Box>
      </Box>
      </Tooltip>
    );
  }