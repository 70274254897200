import React from 'react';
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

 
//<input type="checkbox" className="check-box" required><span>Se souvenir de moi</span></input>

const withSnackBar = Component => {
    
    class withSnackBar extends React.Component {
        constructor(props) {
            super(props);
            
            this.state = {
                open: false,
                snack_message: "",
            };
        }

        handleClose = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            const open = false;
            this.setState({ open })
        };

        openSnackMessage = (message) => {
            this.setState({ open: true, snack_message : message})
        }

    render() {

        return (
            <div>
                 <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    message={this.state.snack_message}
                    action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                        <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                    }
                />
                <Component {...this.props} openSnackMessage={this.openSnackMessage}  />
            </div>
          );
        }
    }

    return withSnackBar;
};
   
export default withSnackBar;
