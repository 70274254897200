import { Hidden, Grid } from '@mui/material';
import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { LOGO_BLACK } from '../../../utils/Settings';
import { MyColor } from '../../Theme';
import "./entete.css"

class EnTeteSection extends Component {

    
    render() {

        const BodyDesktop = () => {
            return (
                <div name="video-en-tete" style={{position:"relative"}}>
                    <video 
                    autoPlay 
                    loop 
                    id="bgvid" 
                    muted="muted"  
                    style={{backgroundSize: "cover", width: "100%", height: "auto", maxHeight: "calc(100vh - 200px)", zIndex: "-1000", objectFit: "cover"}}
                    >
                        <source src="prod8.mp4" type="video/mp4" />
                    </video>
                    <div 
                    name="cadre-inscrivez-vous" 
                    style={{
                        position: "absolute", 
                        top:"100px", 
                        height:"auto", 
                        width:"40%", 
                        paddingLeft: "3%", 
                        paddingBottom:"50px", 
                        paddingRight:"25px",
                        backgroundColor:"rgba(255, 255, 255, 0.8)"
                    }}>
                        <Grid container style={{margin:0, width:"100%", paddingTop:"25px", paddingBottom:"15px"}}>
                            <Grid item xs={3}>
                                <img src={LOGO_BLACK} alt="logo foodediet"   style={{maxWidth:"100%", marginTop:"15px"}} />
                            </Grid>
                            <Grid item xs={9}>
                                <div style={{ paddingLeft:"15px"}}>
                                    <h2 style={{font:"calc(13px + 2.6vw) / 1.2em 'rozha one', serif", marginBlockStart:"0px", color: MyColor.PRIMARY_GREEN, marginTop:"0px"}}>
                                        <Fade left delay={200} >
                                            Votre cabinet diététique en ligne
                                        </Fade>
                                    </h2>
                                </div>
                               
                            </Grid>
                        </Grid>
                        <div style={{font:"normal normal normal 25px/1.4em 'Karla', sans-serif", fontSize:"calc(6px + 1.1vw)", color: MyColor.PRIMARY_GREEN}}>
                            <Fade left delay={550}>Téléconsultation - Suivi Personnalisé -  Motivation</Fade>
                        </div>
                        <Hidden xsDown>
                            <div style={{height:"75px"}} />
                        </Hidden>
                        <Fade left delay={800} >
                            <Link to="/inscription" className="nolink"><button type="button" className="circular-inscription-btn" style={{width:"auto", position: "relative"}} >S'inscrire gratuitement sur Food-eDiet</button></Link>
                        </Fade>
                    </div>
                    <div 
                    className="newsletter-paper warning-bubble"
                    style={{
                        position: "absolute", 
                        top:"100px", 
                        right:"15px",
                        height:"auto", 
                        width:"40%", 
                        paddingLeft: "3%", 
                        paddingBottom:"50px", 
                        paddingRight:"25px",
                        background: "url('background-test-2.png') 50% center / cover white",
                        boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)",
                        backgroundColor:"rgb(255, 248, 231)",
                        borderRadius: "5px",
                    }}>
                        <center>
                            <div style={{ paddingTop:"1px"}}>
                                <h2  className="main_h2" >Marre de ne pas perdre du poids ?</h2>
                                <h2 >Répondez à notre questionnaire</h2>
                                <h2>Et recevez un <u>plan personnalisé gratuit </u> écrit par des <u>diététiciennes !</u></h2>
                            </div>
                            <Link to="/questionnaire" className="nolink">
                                <button type="button" className="circular-inscription-btn" style={{position: "relative", marginTop:"50px"}} >
                                    Commencer le questionnaire !
                                </button>
                            </Link>
                        </center>
                    </div>
                </div>
            )
        }

        const BodyGSM = () => {
            return (
                <div name="video-en-tete" style={{position:"relative"}}>
                    <div 
                    name="cadre-inscrivez-vous" 
                    style={{
                        height:"250px", 
                        width:"100%", 
                        background: " linear-gradient(rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)), url(accompagnement_photo.jpg)",
                        backgroundSize: "cover",
                        backgroundColor:"rgba(255, 255, 255, 0.65)",
                    }}>
                        <center style={{justifyContent: "space-evenly", display:"flex", flexDirection:"column", height:"100%"}}>
                            <div style={{ paddingTop:"1px"}}>
                                <h2  className="main_h2" style={{color: MyColor.PRIMARY_GREEN, marginBottom:"0px", marginTop:"0px"}}><Fade left delay={200} >Cabinet diététique en ligne</Fade></h2>
                            </div>
                            <div style={{font:"normal normal normal 25px/1.4em 'Karla', sans-serif", fontSize:"calc(10px + 1.3vw)", color: MyColor.PRIMARY_GREEN, padding:"10px"}}><Fade left delay={550}>Téléconsultation - Suivi Personnalisé -  Motivation</Fade></div>
                            <center style={{marginTop:"10px"}}>
                            <Fade left delay={800} duration={1250} >
                                <Link to="/inscription" className="nolink"><button type="button" className="circular-inscription-btn" style={{paddingTop:"10px", paddingBottom:"10px"}}>S'inscrire gratuitement sur Food-eDiet</button></Link>
                            </Fade>
                            </center>
                        </center>
                       
                    </div>
                    <div 
                    className=""
                    style={{
                        height:"auto", 
                        paddingLeft: "3%", 
                        paddingBottom:"50px", 
                        paddingRight:"25px",
                        background: "url('background-test-2.png') 50% center / cover white",
                        boxShadow: "0px 10px 60px 0px rgb(1 33 92 / 10%)",
                        backgroundColor:"rgb(255, 248, 231)",
                        borderRadius: "5px",
                    }}>
                        <center>
                            <div style={{ paddingTop:"35px", paddingRight:""}}>
                                <h2  className="main_h2" >Marre de ne pas perdre du poids ?</h2>
                                <h2 className="main_h2" >Répondez à notre questionnaire</h2>
                                <h3 className="main_h2" >Et recevez un <u>plan personnalisé gratuit </u> écrit par des <u>diététiciennes !</u></h3>
                            </div>
                            <Link to="/questionnaire" className="nolink">
                                <button type="button" className="circular-inscription-btn" style={{position: "relative", marginTop:"50px"}} >
                                    Commencer le questionnaire !
                                </button>
                            </Link>
                        </center>
                    </div>
                </div>
            )
        } 

        return (
            <div>
                {this.props.gsm ?  <BodyGSM {...this.props}/> : <BodyDesktop {...this.props}/>}
                
            </div>
        );
    }
}

export default (EnTeteSection);