import React, { Component } from 'react'
import "react-multi-carousel/lib/styles.css";
import { compose } from 'recompose';
//import GridRecette from './GridRecette';
import { Helmet } from 'react-helmet';
import { Container, Grid, Paper } from '@mui/material';
import { DesktopView, MobileView } from '../../../../components/Theme';
import MyFooter from '../../../../components/Footer';
import { withAuthorization } from '../../../../components/Session';
import { withRouter } from '../../../../App';
import Header from '../../../../components/Header';
import { collection, query, onSnapshot } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import "./ebooks.css";

class EbooksPage extends Component {

    state = {
        loading: true,
        ebooks: [],
    }

    componentDidMount() {
        this.bindArticles();
        this.props.firebase.analytics.logEvent('ebooks_page_view');
    }

    componentWillUnmount() {
        if(this.unsub) {
            this.unsub()
        }
    }

    bindArticles = () => {
        this.unsub = onSnapshot(
            query(
              collection(this.props.firebase.firestore, "ebooks"),
            ),
            (snap) => {
                const items = snap.docs.map(docSnapshot => ({...docSnapshot.data(), id: docSnapshot.id}));
                console.log(items)
                this.setState({ ebooks : items, loading: false })
            },
            (error) => {
              console.error(error)
              this.setState({  loading : false });
            }
        );
    }

    sendPDF = (props) => {
        this.props.firebase.analytics.logEvent('ebooks_download', { titre: props.titre, id:props.id});
        const storageRef = ref(this.props.firebase.storage, "Ebook/" + props.id + "/" + props.fileTitle);
        getDownloadURL(storageRef).then((url) => {
            window.open(url, '_blank');
        })

    }

    render() {

        const { ebooks } = this.state;

        const EbookEntry = (props) => { return(
            <Grid item md={3} xs={9} key={"ebook-" + props.id}>
                <div className="ebook-container">
                    <img className="ebook-image" src={props.miniatureURL} alt={props.titre} style={{width:"100%", height:"355px", borderRadius:"4px 4px 0px 0px",  paddingBottom:"20px"}}/>
                    <div className="ebook-middle">
                        <div className="ebook-text" style={{cursor:"pointer"}} onClick={() => this.sendPDF(props)}  >Télécharger</div>
                    </div>
                    <span onClick={() => this.sendPDF(props)}  style={{cursor:"pointer", color:"#282828", fontWeight:"700", fontSize:"15px", textDecoration:"none"}} >{props.titre}</span><br/>
                    <p style={{fontSize:"14px", marginTop:"5px"}}>Gratuit !</p>
                </div>
            </Grid>
        )}

        const entries  = Object
        .keys(ebooks)
        .map((key, i) => (
            <EbookEntry key={"entry-" + i} {...ebooks[key]} />
        ));

        

        const BodyDesktop = (props) => { return(
                <Container maxWidth="lg">
                    <Paper style={{marginTop:"35px", marginBottom:"20px",
                backgroundImage: "url(/background_dv_02.png)", 
                backgroundRepeat: "no-repeat", 
                backgroundPosition: "right top", 
                backgroundSize: "contain"}}elevation={7}>
                        <Grid container style={{width:"100%", padding:"15px", paddingTop:"0px"}} >
                            <Grid item md={12} xs={12}>
                                <div style={{paddingTop:"15px", paddingBottom:"15px"}}>
                                    <h3 className="main_h3" style={{color:"#ffc47c", margin:"0", paddingBottom:"10px"}}>Les E-Books de Food-eDiet</h3>
                                    <p style={{maxWidth:"80%"}} >Dans cet espace, vous découvrirez un vaste choix de ebooks centrés sur la diététique et la nutrition.<br/> 
                                        Ceux-ci ont été rédigés par des diététiciens afin de vous donner toutes les clés pour atteindre vos objectifs. </p>
                                </div>
                                <Grid container spacing={3}>
                                    {entries}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
        )}

        const BodyGSM = (props) => { return(
            <Container maxWidth="xs" style={{margin:"0", padding:"0"}}>
                <Paper style={{
                    marginTop:"15px", 
                    padding:"15px", 
                    backgroundImage: "url(/background_dv_02.png)", 
                    backgroundRepeat: "no-repeat", 
                    backgroundPosition: "right top", 
                    backgroundSize: "contain"}} elevation={7}>
                    <center>
                        <h2 style={{marginBottom:"5px"}}>Les E-Books de Food-eDiet</h2>
                        <p>Dans cet espace, vous découvrirez un vaste choix de ebooks centrés sur la diététique et la nutrition.<br/> 
                        Ceux-ci ont été rédigés par des diététiciens afin de vous donner toutes les clés pour atteindre vos objectifs.</p>
                    </center>
                    <Grid container style={{width:"100%"}} spacing={3} justifyContent="center" >
                        {entries}
                    </Grid>
                </Paper>
            </Container>
        )}

        return (
            <div style={{backgroundColor:"#F9F9F9"}}>
                <Helmet>
                        <title>Ebook - diététique et nutrition</title>
                        <meta name="description" content="choix de ebooks centrés sur la diététique et la nutrition rédigés par des diététiciens pour atteindre vos objectifs" />
                        
                        <link rel="canonical" href="https://food-ediet.com/blog/categorie/article" />

                </Helmet>
                <Header/>
                <h1 style={{fontSize:"0px"}}>Food-eDiet EBook diététique et nutrition </h1>
                <DesktopView>
                    <BodyDesktop {...this.props} />
                </DesktopView>
                <MobileView>
                    <BodyGSM {...this.props} />
                </MobileView>
                <MyFooter/>
            </div>
        )
    }
}

export default compose(withAuthorization((authUser) => true), withRouter)(EbooksPage);
