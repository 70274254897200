import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import { BackgroundDesktopPage, BackgroundMobilePage } from '../../Home';
import { DesktopView, MobileView } from '../../../../components/Theme';
import { withAuthorization } from '../../../../components/Session';
import DocumentPanel from '../DocumentPanel';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
//import FileUploader from 'react-firebase-file-uploader/lib';
import Fade from 'react-reveal/Fade';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


class DocumentPage extends Component {

    state = {
        error:"",
        file: null,
        upload_success: false
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const file = e.target[0]?.files[0]
    
        if (!file) return;
    
        const storageRef = ref(this.props.firebase.storage, "documents/" + this.props.authUser.data.id + "/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(progress)
            //setProgresspercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                const filename = file.name
                const doc = {
                    date: Date.now(),
                    documentURL: url,
                    read: false,
                    title: filename.split('.').slice(0, -1).join('.'),
                    sender: this.props.authUser.data.id,
                    filename: filename,
                }
                // Create new doc entry to the user
                this.props.firebase.firestore.collection("users").doc(this.props.authUser.data.id).collection("documents").add(doc).then(() => {
                    this.fileUploader.value = ""
                    this.setState({ upload_success: true, file: null})
                })
                .catch(error => {
                    this.setState({ error });
                });
            });
          }
        );
      }

    render() {
        const { upload_success } = this.state

        const body = (<div>
            
            <h3>Vos documents reçus</h3>
            <p>Les documents envoyés par votre diététicien(ne)</p>
            <DocumentPanel authUser={this.props.authUser} />

            
            <h3>Partager un nouveau document</h3>
            <p>Seul(e) votre diététicien(ne) aura accès à ces documents</p>
            <form onSubmit={this.handleSubmit} className='form'>
                <input type='file' />
                <button type='submit'>Confirmer</button>
            </form>
            <Fade bottom collapse when={upload_success}>
                <Alert severity="success" style={{marginTop:"15px"}}>
                    <AlertTitle><span style={{fontFamily:"'Karla', sans-serif"}}><b>Document ajouté</b></span></AlertTitle>
                    <span style={{fontFamily:"'Karla', sans-serif"}}>
                        Le document à bien été ajouté à votre compte. 
                    </span>
                </Alert>
            </Fade>
            <h3>Vos documents partagés</h3>
            <p>Visualisez les documents que vous avez uploadé</p>
            <DocumentPanel authUser={this.props.authUser} uploader />
        </div>)

        return (
            <div>
                <Helmet>
                    <title>Gérez mes mensurations</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                 <DesktopView >
                    <BackgroundDesktopPage {...this.props}>
                        {body} 
                    </BackgroundDesktopPage>
                </DesktopView>
                <MobileView >
                    <BackgroundMobilePage {...this.props}>
                        {body} 
                    </BackgroundMobilePage>
                </MobileView>
            </div>
        )
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(DocumentPage);


