import React, { Component } from 'react'
import { Collapse, List, ListItem, ListItemText } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { compose } from 'recompose';
import { withAuthorization } from '../../../../components/Session';
import { LinkBehavior } from '../../../../components/Theme/LinkBehaviour';
import { withRouter } from '../../../../App';


class SideBar extends Component {
    state = {
        // Patient
        openCompte: true,
        openConsultation: true,
        openBlog: false,
        openSuivi: true,

        // Diet
        openPlanning: true,
        openPatient: true,
        openContenu: true,
    }
    
    render() {
        const { openCompte, openConsultation, openPatient, openBlog, openSuivi, openPlanning, openContenu } = this.state;

        const Item = (props) => <ListItemText  style={{paddingLeft:"10%", }} >{props.children}</ListItemText>

        return (
            <div>
                <List>
                    {
                    this.props.authUser && this.props.authUser.data && this.props.authUser.data.level < 4 &&
                    <List>
                        <ListItem button key={"DashBoard"} component={LinkBehavior} to="/home"  >
                            <ListItemText><h3 style={{marginTop:"5px", marginBottom:"5px"}}>Tableau De Bord</h3></ListItemText>
                        </ListItem>
                        <ListItem button key={"Consultation"}  onClick={() => {this.setState({openConsultation: !openConsultation})}}>
                            <ListItemText><h3 style={{marginTop:"5px", marginBottom:"5px"}}>Consultations</h3></ListItemText>
                            {openConsultation ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openConsultation} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} button key={"ee"} component={LinkBehavior} to="/home/consultation">
                                    <Item>Mes consultations</Item>
                                </ListItem>
                                <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} button key={"feef"} component={LinkBehavior} to="/home/consultation-create">
                                    <Item>Réserver une date</Item>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button key={"Suivi"} onClick={() => {this.setState({openSuivi: !openSuivi})}}>
                            <ListItemText><h3 style={{marginTop:"5px", marginBottom:"5px"}}>Mon Suivi</h3></ListItemText>
                            {openSuivi ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openSuivi} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} component={LinkBehavior} button key={"suivi"} to="/home/mensurations">
                                    <Item key="suivi-mensurations">Mes mensurations</Item>
                                </ListItem>
                                <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} component={LinkBehavior} button key={"suivi"} to="/home/documents">
                                    <Item key="suivi-mensurations">Mes documents</Item>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button key={"Compte"} onClick={() => {this.setState({openCompte: !openCompte})}}>
                            <ListItemText><h3 style={{marginTop:"5px", marginBottom:"5px"}}>Mon Compte</h3></ListItemText>
                            {openCompte ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openCompte} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} component={LinkBehavior} button key={"profil-item"} to="/home/profil">
                                    <Item key="profil">Mon profil</Item>
                                </ListItem>
                                <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} button key={"Mes paiement"} component={LinkBehavior} to="/home/compte">
                                    <Item >Paramètres</Item>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button key={"Blog"} onClick={() => {this.setState({openBlog: !openBlog})}}>
                            <ListItemText><h3 style={{marginTop:"5px", marginBottom:"5px"}}>Blog</h3></ListItemText>
                            {openBlog ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openBlog} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} component={LinkBehavior} button key={"articles"} to="/blog/categorie/article">
                                    <Item>Articles</Item>
                                </ListItem>
                                <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} button key={"recettes"} component={LinkBehavior} to="/blog/categorie/recette">
                                    <Item >Recettes</Item>
                                </ListItem>
                            </List>
                        </Collapse>
                    </List>
                    }
                    {
                        this.props.authUser && this.props.authUser.data && this.props.authUser.data.level >= 4 &&
                        <List>
                            <ListItem button key={"DashBoard-Diet"} component={LinkBehavior} to="/admin"  >
                                <ListItemText><h3 style={{marginTop:"5px", marginBottom:"5px"}}>Tableau De Bord</h3></ListItemText>
                            </ListItem>
                            <ListItem button key={"Accès Pro"} onClick={() => {this.setState({openPlanning: !openPlanning})}} >
                                <ListItemText><h3 style={{marginTop:"5px", marginBottom:"5px"}}>Planning</h3></ListItemText>
                                {openPlanning ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openPlanning} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} button key={"mespatients"} component={LinkBehavior} to="/admin/patients">
                                        <Item>Mon agenda</Item>
                                    </ListItem>
                                    <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} button key={"mesarticles"} component={LinkBehavior} to="/admin/articles">
                                        <Item>Organiser mon horaire</Item>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button key={"Accès Pro"} onClick={() => {this.setState({openPatient: !openPatient})}} >
                                <ListItemText><h3 style={{marginTop:"5px", marginBottom:"5px"}}>Patientèle</h3></ListItemText>
                                {openPatient ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openPatient} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} button key={"mespatients"} component={LinkBehavior} to="/admin/patients">
                                        <Item>Mes Patients</Item>
                                    </ListItem>
                                    <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} button key={"nouveau-patient"} component={LinkBehavior} to="/admin/patients/nouveau">
                                        <Item>Encoder un patient</Item>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button key={"Accès Pro"} onClick={() => {this.setState({openContenu: !openContenu})}} >
                                <ListItemText><h3 style={{marginTop:"5px", marginBottom:"5px"}}>Contenu</h3></ListItemText>
                                {openContenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openContenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem style={{paddingTop:"2px", paddingBottom:"2px"}} button key={"mesarticles"} component={LinkBehavior} to="/admin/articles">
                                        <Item>Mes Articles</Item>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                        
                    }
                </List>
            </div>
        );
    }
}

export default compose(withAuthorization((authUser) => true), withRouter)(SideBar);
