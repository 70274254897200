import React, { Component } from 'react'
import { Button, Grid, InputBase, Paper } from '@mui/material';
import { Helmet } from 'react-helmet';
import { BackgroundDesktopPage, BackgroundMobilePage } from '../../Home';
import { DesktopView, MobileView } from '../../../../components/Theme';
import { withAuthorization } from '../../../../components/Session';
import Fade from 'react-reveal/Fade';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import HeightIcon from '@mui/icons-material/Height';
import { collection, onSnapshot, query, orderBy, limit } from 'firebase/firestore';


class MensurationsPage extends Component {

    default = {
        poids: "",
        tour_de_cou: "",
        tour_de_poitrine:"",
        tour_de_ceinture:"",
        tour_de_taille:"",
        tour_de_bassin:"",

        upload_success: false,
    }

    state = {
        ...this.default,

        upload_success: false,
        data: [],
    }

    componentDidMount() {
        this.firebaseLinkMensurations()
    }

    firebaseLinkMensurations = () => {
        if (this.props.authUser) {
            this.unsubConsultation = onSnapshot(
                query(
                collection(this.props.firebase.firestore, "users/"+this.props.authUser.data.uid+"/mensurations"), 
                orderBy('date', 'desc'),
                limit(5)
                ),
                (querySnapshot) => {
                    const items = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                    this.setState({ data:items })
                    console.log(items)
                },
                (error) => {
                console.error(error)
                }
            );
        }
        else {
        }
    }

    AddMensuration = () => {
        const { poids, tour_de_cou, tour_de_bassin, tour_de_ceinture, tour_de_poitrine, tour_de_taille} = this.state

        const date = Date.now()

        const doc = {
            date: new Date(date).toLocaleDateString('fr-BE', options),
            poids: poids,
            tour_de_cou: tour_de_cou,
            tour_de_poitrine: tour_de_poitrine,
            tour_de_ceinture: tour_de_ceinture,
            tour_de_taille: tour_de_taille,
            tour_de_bassin: tour_de_bassin,
        }

        this.props.firebase.firestore.collection('users/' + this.props.authUser.data.id + "/mensurations").doc(date + "").set(doc)
        .then(() => {
            this.setState({ ...this.default, upload_success: true})
        })
        .catch(error => {
            console.error(error)
            this.setState({ error });
        });
    }

    render() {

        const { tour_de_cou, tour_de_poitrine, tour_de_ceinture, tour_de_taille, tour_de_bassin, upload_success, data } = this.state;

        const graph = (myid, myText ) => {
            return <Grid item xs={12} md={6} >
            <Paper elevation={3} style={{height: "380px", padding:"5px"}}>
                <h4>{myText}</h4>
                <ResponsiveContainer  height={320}>
                    <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date"  />
                        <YAxis domain={['auto', 'dataMax + 3']} />
                        <Tooltip  />
                        <Legend />
                        <Line type="monotone" dataKey={myid} stroke="#82ca9d" />
                    </LineChart>
                </ResponsiveContainer>
            </Paper>
        </Grid>
        }
        
        const body = (
            <div className="karla_text">
                <h2>Mes mensurations (beta)</h2>
                <p>Il est recommandé de ne pas se peser tous les jours. Nous conseillons de remplir ces données 1x par mois en moyenne</p>
                <Fade bottom collapse when={upload_success}>
                    <Alert severity="success" style={{marginTop:"15px", marginBottom:"25px"}}>
                        <AlertTitle><span style={{fontFamily:"'Karla', sans-serif"}}><b>Document ajouté</b></span></AlertTitle>
                        <span style={{fontFamily:"'Karla', sans-serif"}}>
                            Le document à bien été ajouté à votre compte. 
                        </span>
                    </Alert>
                </Fade>
                <Grid container alignItems="stretch" style={{width:"100%", margin:"0"}} spacing={3}>
                    
                    <Grid item xs={12} md={8} style={{paddingLeft:"0px"}}>
                        <Paper style={{boxShadow:"rgb(1 33 92 / 10%) 0px 10px 60px 0px", padding:"25px"}}>
                            <h3>Ajouter mes mensurations</h3>
                            <p>Remplissez vos mensurations selon les données que vous possédez à la date d'aujourd'hui et confirmez</p>
                            <Grid container style={{width:"100%"}} spacing={3}>
                                <Grid item xs={6} >
                                    <div id="poids">
                                        <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                            <FitnessCenterIcon style={{padding: "10px"}}  />
                                            <InputBase
                                                placeholder="Poids"
                                                onChange={(event) => {this.setState({poids : event.target.value})}}
                                                value={this.state.poids}
                                                className="karla_text"
                                                type="number"
                                                style={{width:"100%"}}
                                            />
                                            kg
                                        </Paper>
                                        <Fade bottom collapse when={false}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire ou non conforme
                                            </div>
                                        </Fade>
                                    </div>
                                </Grid>
                                <Grid item xs={6} >
                                    <div id="tour_de_cou">
                                        <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                            <HeightIcon style={{padding: "10px"}}  />
                                            <InputBase
                                                placeholder="Tour de cou"
                                                onChange={(event) => {this.setState({tour_de_cou : event.target.value})}}
                                                value={tour_de_cou}
                                                className="karla_text"
                                                type="number"
                                                style={{width:"100%"}}
                                            />
                                            cm
                                        </Paper>
                                        <Fade bottom collapse when={false}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire ou non conforme
                                            </div>
                                        </Fade>
                                    </div>
                                </Grid>
                                <Grid item xs={6} >
                                    <div id="tour_de_poitrine">
                                        <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                            <HeightIcon style={{padding: "10px"}}  />
                                            <InputBase
                                                placeholder="Tour de poitrine"
                                                onChange={(event) => {this.setState({tour_de_poitrine : event.target.value})}}
                                                value={tour_de_poitrine}
                                                className="karla_text"
                                                type="number"
                                                style={{width:"100%"}}
                                            />
                                            cm
                                        </Paper>
                                        <Fade bottom collapse when={false}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire ou non conforme
                                            </div>
                                        </Fade>
                                    </div>
                                </Grid>
                                <Grid item xs={6} >
                                    <div id="tour_de_ceinture">
                                        <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                            <HeightIcon style={{padding: "10px"}}  />
                                            <InputBase
                                                placeholder="tour de ceinture"
                                                onChange={(event) => {this.setState({tour_de_ceinture : event.target.value})}}
                                                value={tour_de_ceinture}
                                                className="karla_text"
                                                type="number"
                                                style={{width:"100%"}}
                                            />
                                            cm
                                        </Paper>
                                        <Fade bottom collapse when={false}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire ou non conforme
                                            </div>
                                        </Fade>
                                    </div>
                                </Grid>
                                <Grid item xs={6} >
                                    <div id="tour_de_taille">
                                        <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                            <HeightIcon style={{padding: "10px"}}  />
                                            <InputBase
                                                placeholder="tour de taille"
                                                onChange={(event) => {this.setState({tour_de_taille : event.target.value})}}
                                                value={tour_de_taille}
                                                className="karla_text"
                                                type="number"
                                                style={{width:"100%"}}
                                            />
                                            cm
                                        </Paper>
                                        <Fade bottom collapse when={false}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire ou non conforme
                                            </div>
                                        </Fade>
                                    </div>
                                </Grid>
                                <Grid item xs={6} >
                                    <div id="tour_de_bassin">
                                        <Paper  elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                                            <HeightIcon style={{padding: "10px"}}  />
                                            <InputBase
                                                placeholder="tour de bassin"
                                                onChange={(event) => {this.setState({tour_de_bassin : event.target.value})}}
                                                value={tour_de_bassin}
                                                className="karla_text"
                                                type="number"
                                                style={{width:"100%"}}
                                            />
                                            cm
                                        </Paper>
                                        <Fade bottom collapse when={false}>
                                            <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                                            Le champs est obligatoire ou non conforme
                                            </div>
                                        </Fade>
                                    </div>
                                </Grid>
                            </Grid>
                            <Button color="primary" onClick={this.AddMensuration}>
                                Confirmer
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper style={{boxShadow:"rgb(1 33 92 / 10%) 0px 10px 60px 0px", padding:"25px"}}>
                            <img src="/mensurations.png" alt="" width="100%" />
                        </Paper>
                    </Grid>
                </Grid>
                <h3>Suivi de mon évolution</h3>
                <Grid container alignContent="center" style={{width:"100%"}} spacing={3}>
                    {graph("poids", "Poids")}
                    {graph("tour_de_bassin", "Tour de bassin")}
                    {graph("tour_de_cou", "Tour de cou")}
                    {graph("tour_de_poitrine", "Tour de poitrine")}
                    {graph("tour_de_ceinture", "Tour de ceinture")}
                    {graph("tour_de_taille", "Tour de taille")}
                </Grid>
                
            </div>
        );

        return (
            <div>
                <Helmet>
                    <title>Gérez mes mensurations</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                 <DesktopView >
                    <BackgroundDesktopPage {...this.props}>
                        {body} 
                    </BackgroundDesktopPage>
                </DesktopView>
                <MobileView >
                    <BackgroundMobilePage {...this.props}>
                        {body} 
                    </BackgroundMobilePage>
                </MobileView>
            </div>
        )
    }
}

const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

const condition = authUser => !!authUser;

export default withAuthorization(condition)(MensurationsPage);


