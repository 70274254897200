/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { DesktopView, MyColor } from '../../Theme';

class EnCars extends Component {

   
    render() {

        return (
            <div  >
                <div >
                    <DesktopView>
                        <div 
                            id="row" 
                            style={{
                                paddingRight:"20px", 
                                paddingLeft:"20px", 
                                paddingBottom:"0px",
                                maxWidth:"1220px", 
                                boxShadow:"inset 0px 0px 0px 50px #ffffff", 
                                marginLeft:"auto",
                                marginRight:"auto",
                                marginTop:"60px",
                                marginBottom:"60px",
                                backgroundPosition:"50%",
                                backgroundSize:"Cover",
                                backgroundRepeat:"no-repeat",
                                backgroundColor:"#f9f9f4",
                                paddingTop:"54px",
                                position: "relative",
                            }}>
                                <div
                                    id="cadre"
                                    style={{
                                        paddingTop:"130px",
                                        paddingLeft:"110px",
                                        paddingRight:"110px",
                                        //width: "100%",
                                        maxWidth:"100%",
                                        backgroundSize:"contain",
                                        backgroundPosition:"top left",
                                        backgroundImage:"url(background-test-3.png)",
                                        paddingBottom:"27px", //only screen 1350
                                        backgroundRepeat:"no-repeat",
                                        margin:"0",
                                        position: "relative",
                                        

                                    }}>
                                        <div 
                                            id="cadre_textInterieur"
                                            style={{
                                                margin:"0",
                                                width:"100%",
                                                backgroundRepeat:"no-repeat",
                                            }}
                                        >
                                            <div 
                                                id="textInterieur"
                                                style={{
                                                    marginBottom:"2.75%",
                                                    marginLeft:"auto",
                                                    marginRight:"auto",
                                                    backgroundColor:"#f9f9f4",
                                                    maxWidth:"700px",
                                                    textAlign:"center",
                                                    backgroundRepeat: "no-repeat",
                                                    position: "relative",
                                                }}
                                            >
                                                <div>
                                                    <h3 style={{fontWeight:"700", fontSize:"30px", lineHeight:"1.6em", margin:"0", paddingBottom:"10px"}}>Vous n'avez pas encore trouvé votre équilibre alimentaire ?</h3>
                                                    <h4 style={{fontWeight:"700", color:"#09371F", lineHeight:"1.8em", margin:"0", paddingBottom:"10px"}}>Vous n'êtes pas seul et nous sommes là pour vous aider !</h4>
                                                    <p style={{textAlign:"center", margin:"0", lineHeight:"1.6em"}}>
                                                        Manger équilibré, c'est vous donner toutes les chances de rester en bonne santé. Entre "fausses croyances" et désinformations, il est 
                                                        souvent difficile de savoir comment s'alimenter correctement. Sur Food-eDiet, plus qu'un simple suivi diététique, nous mettons en place un plan diététique sur
                                                        le long terme, notamment grâce à notre <a href="/blog" style={{color:MyColor.PRIMARY_GREEN}}>blog diététique gratuit</a>, afin que vous puissiez y trouver votre équilibre alimentaire.
                                                    </p>
                                                </div>
                                            </div>
                                            <div 
                                                id="button_contact"
                                                style={{
                                                    textAlign:"center",
                                                    marginTop:"50px",
                                                }}
                                            >
                                                <a 
                                                    href="#nous-contacter"
                                                    style={{
                                                        color:"#ffffff",
                                                        borderWidth:"0px",
                                                        borderColor:"rgba(0,0,0,0)",
                                                        borderRadius:"100px",
                                                        letterSpacing:"2px",
                                                        fontSize:"13px",
                                                        fontWeight:"500",
                                                        backgroundColor:"#09371F",
                                                        paddingTop:"16px",
                                                        paddingRight:"30px",
                                                        paddingLeft:"30px",
                                                        paddingBottom:"16px",
                                                        textDecoration:"none",
                                                        boxShadow:"0px 10px 20px 0px rgb(9 55 31 / 30%)",
                                                        lineHeight:"1.7em",
                                                    }}>
                                                        <b>CONTACTEZ NOUS</b>
                                                        
                                                </a>

                                            </div>

                                        </div>
                                </div>
                        </div>
                    </DesktopView>
                   
                </div>
            </div>
        );
    }
}


export default EnCars;