import React from 'react';
import './App.css';
import Blog from './pages/blog/Blog';
import EmptyPage from './pages/robustness/EmptyPage';
import DietPanel from './pages/diet/DietPanel';
import { withAuthentication } from './components/Session';
import LandingPage from './components/Landing';
import ArticleFull from './pages/blog/ArticleFull';
import Meeting from './pages/connected/Consultation/Meeting';
import ConsultationPage from './pages/connected/Consultation/ConsultationPage';
import ConsultationCreationPage from './pages/connected/Consultation/ConsultationCreationPage';
import ProfilePage from './pages/connected/Profile/ProfilePage';
import ConditionUtilisation from './pages/robustness/ConditionUtilisation';
import ProfilePro from './pages/diet/ProfilePro';
import MesArticles from './pages/diet/MesArticles';
import MesPatients from './pages/diet/MesPatients';
import PresentationDiet from './pages/landing/PresentationDiet';
import Pricing from './pages/landing/Pricing';
import InscriptionPage from './pages/landing/Inscription';
import ConnexionPage from './pages/landing/Connexion';
import PasswordReset from './pages/landing/PasswordReset';
import SetNewPassword from './pages/landing/PasswordReset/SetNewPassword';
import ComptePage from './pages/connected/Profile/ComptePage';
import Faq from './pages/robustness/Faq';
import QuestionnairePage from './pages/landing/Questionnaire';
import RechercheBlog from './pages/blog/RechercheBlog';
import ArticleCategory from './pages/blog/ArticleCategory';
import RecetteCategory from './pages/blog/RecetteCategory/RecetteCategory';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PourquoiFoodeDiet from './pages/landing/PourquoiFoodeDiet';
import CreateDocument from './pages/diet/CreateDocument';
import MensurationsPage from './pages/connected/Mensurations/MensurationsPage';
import NouveauPatient from './pages/diet/NouveauPatient';
import DocumentPage from './pages/connected/Document/DocumentPage';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams
} from "react-router-dom";
import Home from './pages/connected/Home';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EbooksPage from './pages/blog/Ebooks/EbooksPage';


const THEME = createTheme({
  typography: {
   "fontFamily": `'Karla', sans-serif`,
  }
});


export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

export function withRouter( Child ) {
  return ( props ) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const match = {
      params: params
    }
    return <Child { ...props } navigate={ navigate } location={ location } match={match} />;
  }
}

const MyHome = () => (
  <div>
    <Helmet>
      <title>Espace personnel</title>
      <meta name="robots" content="noindex, nofollow"/>
  </Helmet>
  <Home/>
  </div>
)

const App = () => (
  <ThemeProvider theme={THEME}>
  <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      <Route exact path="/" element={<LandingPage />}></Route>
      <Route exact path="/tarifs" element={<Pricing/>}></Route>
      <Route exact path="/dieteticienne/eloise-feryr" element={<PresentationDiet/>}></Route>
      <Route exact path="/pourquoi-food-ediet" element={<PourquoiFoodeDiet/>}></Route>
      <Route exact path="/questionnaire" element={<QuestionnairePage/>}></Route>

      <Route exact path="/conditions-utilisation" element={<ConditionUtilisation/>}></Route>
      <Route exact path="/FAQ" element={<Faq/>}></Route>
      
      <Route exact path="/inscription" element={<InscriptionPage/>}></Route>
      <Route exact path="/questionnaire-en-ligne" element={<QuestionnairePage />}></Route>
      <Route exact path="/connexion" element={<ConnexionPage/>}></Route>
      <Route exact path="/reset_password" element={<PasswordReset/>}></Route>
      <Route exact path="/action" element={<SetNewPassword/>}></Route>

      <Route exact path="/blog" element={<Blog/>}></Route>
      <Route exact path="/blog/recherche" element={<RechercheBlog/>}></Route>
      <Route path="/blog/recherche/:search" element={<RechercheBlog/>}></Route>
      <Route exact path="/blog/categorie/article" element={<ArticleCategory/>}></Route>
      <Route exact path="/blog/categorie/recette" element={<RecetteCategory/>}></Route>
      <Route exact path="/blog/:uid" element={<ArticleFull/>}></Route>
      
      <Route exact path="/ebooks" element={<EbooksPage/>}></Route>

      <Route exact path="/home" element={<MyHome/>}></Route>
      <Route exact path="/home/mensurations" element={<MensurationsPage/>}></Route>
      <Route exact path="/home/consultation" element={<ConsultationPage/>}></Route>
      <Route path="/home/consultation/:success" element={<ConsultationPage/>}></Route>
      <Route exact path="/home/consultation-create" element={<ConsultationCreationPage/>}></Route>
      <Route exact path="/home/profil" element={<ProfilePage/>}></Route>
      <Route exact path="/home/documents" element={<DocumentPage/>}></Route>
      <Route exact path="/home/compte" element={<ComptePage/>}></Route>
      <Route path="/home/profil/:success" element={<ProfilePage/>}></Route>
      <Route exact path="/meeting/:channel" element={<Meeting/>}></Route>
      
      <Route path="/admin/article/:uid" element={<CreateDocument/>}></Route>
      <Route path="/admin/profil/:uid" element={<ProfilePro/>}></Route>
      <Route path="/admin/article" element={<CreateDocument/>}></Route>
      <Route exact path="/admin" element={<DietPanel/>}></Route>
      <Route exact path="/admin/articles" element={<MesArticles/>}></Route>
      <Route exact path="/admin/patients" element={<MesPatients/>}></Route>
      <Route exact path="/admin/patients/nouveau" element={<NouveauPatient/>}></Route>
      <Route element={<EmptyPage/>}></Route> 
    </Routes>
</BrowserRouter>
</ThemeProvider>
)


export default withAuthentication(App);
