import { Grid, Paper } from '@mui/material';
import React from 'react';
import { SocialIcon } from 'react-social-icons';


export default function BlogSuivezNous(props) {
    return(
        <div>
            <Paper variant="outlined" style={{padding:"15px", marginTop:"25px"}}>
                <img src="/accompagnement_photo.jpg" alt="Suivez-nous !" width="100%" style={{opacity:"0.7"}} />
                <h4>suivez nous sur les réseaux sociaux !</h4>
                <Grid container style={{width:"100%"}} justifyContent="space-evenly">
                    <Grid item>
                        <SocialIcon url="https://www.facebook.com/FoodeDiet" style={{ height: 45, width: 45 }} />
                    </Grid>
                    <Grid item>
                        <SocialIcon url="https://www.instagram.com/food_ediet/" style={{ height: 45, width: 45 }} />
                    </Grid>
                </Grid>
                

            </Paper>
        </div>
    )
    
}