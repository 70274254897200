import React from 'react'
import Carousel from 'react-multi-carousel';
import ArticleTile from '../ArticleTile/ArticleTile';
//import { useHistory } from 'react-router-dom';

export default function CarouselArticle(props) {

    const articles = props.articles;

    //const history = useHistory();
    //to refactor

    const list = Object.keys(articles).map((uid) => 
     (<div key={uid}><ArticleTile 
                key={uid}
                //onClick={() => navigate("/blog/"+articles[uid].id)} 
                article={articles[uid]}
                //modify={() => navigate("/blog/"+articles[uid].id)}
            /></div>
    ));

    return (
        <div>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                //infinite={true}
                //autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                transitionDuration={500}
                //containerclassName="carousel-container"
                //removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType="desktop"
                dotListclassName="custom-dot-list-style"
            >
                {list}
            </Carousel>
        </div>
    )
}

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1700 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
    short: {
        breakpoint: { max: 1700, min: 1024 },
        items: 3,
        slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

