import React, { Component } from 'react'
import "react-multi-carousel/lib/styles.css";
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { Container, Grid, Paper } from '@mui/material';
import ArticleEntry from '../components/ArticleEntry';
import HeaderBlog from '../HeaderBlog';
import { DesktopView, MobileView } from '../../../components/Theme';
import MyFooter from '../../../components/Footer';
import { withAuthorization } from '../../../components/Session';
import algoliasearch from 'algoliasearch';
import { withRouter } from '../../../App';
const searchClient = algoliasearch('5VLFOU1HSS', '53fab587d37f770223ccf8e28ed1d30a');
const index = searchClient.initIndex("Articles");


class RechercheBlog extends Component {

    state = {
        loading: true,
        articles: [],
        nbResult: 0
    }

    componentDidMount() {
        //this.bindArticles();
        this.setState({search : decodeURI(this.props.match.params.search ?? "")}, () => {
            this.searchText(this.state.search);
        })
        this.props.firebase.analytics.logEvent('recherche_blog_page_view');
    }

    searchText = (name) => {
        index
        .search(name)
        .then((resp) => {
            this.setState({articles: resp.hits, nbResult : resp.nbHits})
        })
        .catch(err => {
            console.error(err);
        });
       
    }


    render() {
        const { authUser } = this.props;

        const { articles, search, nbResult } = this.state;

        const articleList = articles.filter(article => (article.level ?? 0) <= ((authUser && authUser.data) ? authUser.data.level : 0));

        const entries  = Object
        .keys(articleList)
        .map((key, i) => (
            <ArticleEntry key={key} article={articleList[key]}/>
        ));

        const BodyDesktop = (props) => { return(
                <Container maxWidth="lg">
                    <Paper style={{marginTop:"35px", marginBottom:"20px"}}elevation={7}>
                        <Grid container style={{width:"100%", padding:"15px", paddingTop:"0px"}} >
                            <Grid item md={12} xs={12}>
                                <h1 style={{marginBottom:"5px"}}>Résultats pour votre recherche - {search} </h1>
                                <p>Nombre d'articles trouvés : {nbResult}</p>
                                {entries}
                            </Grid>
                            <Grid item md={1} />
                            <Grid item md={3} xs={12}>
                            </Grid>
                        </Grid>
                    </Paper>
                    

                </Container>
        )}

        const BodyGSM = (props) => { return(
            <Container maxWidth="xs" style={{margin:"0", padding:"0"}}>
                <Paper style={{marginTop:"15px", padding:"15px" }}elevation={7}>
                    <Grid container style={{width:"100%"}} >
                        <Grid item md={9} xs={12}>
                            <h1 style={{marginBottom:"5px"}}>Résultats pour votre recherche - {search} </h1>
                            <p>Nombre d'articles trouvés : {nbResult}</p>
                            {entries}
                        </Grid>
                        <Grid item md={3} xs={12}>
                        </Grid>

                    </Grid>
                </Paper>
                

            </Container>
    )}
        


        return (
            <div style={{backgroundColor:"#F9F9F9"}}>
                <Helmet>
                        <title>Blog - Recherche</title>
                        <meta name="description" content="Recherchez un article, une recette ou un ingrédient, un conseil" />
                        <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                <HeaderBlog/>
                <DesktopView>
                    <BodyDesktop {...this.props} />
                </DesktopView>
                <MobileView>
                    <BodyGSM {...this.props} />
                </MobileView>
                <MyFooter/>
            </div>
        )
    }
}

export default compose(withAuthorization((authUser) => true), withRouter)(RechercheBlog);
