import {  Button, Grid, IconButton, Paper, Tooltip, TextField } from '@mui/material';
import React, { Component } from 'react'
import { withFirebase } from '../../../helpers';
import { ELO_UID } from '../../../utils/Settings';
//import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ArchiveIcon from '@mui/icons-material/Archive';
import AddIcon from '@mui/icons-material/Add';
import { compose } from 'recompose';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchIcon from '@mui/icons-material/Search';
import LoadingPanel from '../../robustness/LoadingPanel';
import algoliasearch from 'algoliasearch/lite';
import { SaveType } from '../CreateDocument/helpers';
import { withRouter } from '../../../App';
const searchClient = algoliasearch('5VLFOU1HSS', '53fab587d37f770223ccf8e28ed1d30a');
const index = searchClient.initIndex("Articles");


class MesArticlesPanel extends Component {

    state = {
        articles: [],
        has_more: true,
        searchText: "",
        typing: false,
        typingTimeout: 0,
    }

    componentDidMount() {
        this.getArticles();
    }
    changeName = (event) => {
        const self = this;
    
        if (self.state.typingTimeout) {
           clearTimeout(self.state.typingTimeout);
        }
    
        self.setState({
           name: event.target.value,
           typing: false,
           typingTimeout: setTimeout(function () {
               self.searchText(self.state.name);
             }, 300)
        });
    }

    searchText = (name) => {
        index
        .search(name)
        .then(({ hits }) => {
            this.setState({articles: hits})
        })
        .catch(err => {
            console.error(err);
        });
       
    }

    getArticles = (title) => {
        const { firebase } = this.props;
        let set = this

        var first;
        if(title === "" || title == null)
            first  = firebase.firestore.collection("articles").where('dietId', '==', ELO_UID).orderBy("date", "desc").limit(8);
        else 
            first  = firebase.firestore.collection("articles").where('dietId', '==', ELO_UID).orderBy("date", "desc").limit(8);
        first.get().then(function (documentSnapshots) {
          var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
          const list = [];
          documentSnapshots.forEach(function(doc) {
              const articles = doc.data();
              list.push(articles);
              encodeURI(articles.title.toLowerCase().replace(" ", "-"));
          });
              set.setState({ articles: list, last: lastVisible, has_more: (list.length > 0) });
          });
    }

    fetchMoreData = () => {
        //get last state we added from getUsers()
        let last = this.state.last;
        let set = this;
        const { firebase, /*authUser*/ } = this.props;
        var next = firebase.firestore.collection("articles").where('dietId', '==', ELO_UID).orderBy("date", "desc").startAfter(last).limit(4);
        next.get().then(function (documentSnapshots) {
        var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        const list = [];
        documentSnapshots.forEach(function(doc) {
          const articles = doc.data();
          list.push(articles);
        });
        let updated_list = set.state.articles.concat(list);
        set.setState({ articles: updated_list, last: lastVisible, has_more: (list.length > 0) });
        });
    };


    visualizeArticle = (key) => {
        const url = window.location.origin + "/blog/" + this.state.articles[key].uid
        var win = window.open(url, '_blank');
        win.focus();
    }

    
 
    render() {

        const { articles, has_more } = this.state;

        const {authUser} = this.props;

        if(!authUser.data)
            return (<LoadingPanel />)

        const articlesList = 
        <InfiniteScroll 
            dataLength={articles.length}
            next={this.fetchMoreData}
            hasMore={has_more}
        >
            {articles.map((single, key) => (
            <Paper key={key} style={{padding:"5px 10px 5px 10px", marginBottom:"10px",}}>
            <Grid container spacing={1} >
                <Grid item>
                    <img style={{cursor: "pointer"}} src={articles[key].miniatureURL} alt="" width="150" height="100" onClick={() => this.visualizeArticle(key)}/>
                </Grid>
                <Grid item xs={8} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <h4>{articles[key].title}</h4>
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs={4}>
                                Créé le <i>{new Date(articles[key].date).toLocaleDateString('fr-BE', options)}</i>
                            </Grid>
                            <Grid item xs={4}>
                                Type - <i>{articles[key].type}</i>
                            </Grid>
                            <Grid item xs={4}>
                                Statut - <i>{articles[key].saveType ?? SaveType.Publish}</i>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} container alignContent="center">
                        <Grid item >
                            <Tooltip title="Éditer" placement="top">
                                <IconButton component="span" onClick={() => {this.props.navigate(({
                                    pathname: "/admin/article/" + articles[key].uid,
                                    state: { return_url: this.props.location.pathname }
                                  }))}}>
                                    <EditIcon htmlColor="#4169E1" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Visualiser" placement="top">
                                <IconButton component="span" onClick={() => this.visualizeArticle(key)}>
                                    <FindInPageIcon  htmlColor="grey" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Archiver" placement="top">
                                <IconButton component="span" disabled>
                                    <ArchiveIcon htmlColor="red" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            ))}
        </InfiniteScroll>

        const filter = 
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
                <SearchIcon />
            </Grid>
            <Grid item md>
                <TextField  onChange={this.changeName} placeholder="Rechercher un article par titre" fullWidth/>
            </Grid>
        </Grid>

        /*const Hit = ({ hit }) => (
            <p>
                {hit.title}
            </p>
        );*/

       /* const filter = 
        <InstantSearch searchClient={searchClient} indexName="Articles">
      <SearchBox />
      <Hits hitComponent={Hit} />
    </InstantSearch>*/




        return (
            <div>
                <Grid container style={{marginBottom:"10px"}}>
                    <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {this.props.navigate(({
                            pathname: "/admin/article/",
                            state: { return_url: this.props.location.pathname }
                            }))}}
                    >
                        Ajouter un article
                    </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        
                        <Paper style={{padding:"10px 10px 10px", marginBottom:"10px"}}>
                            {filter}
                        </Paper>
                        {articlesList}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper style={{padding:"10px 10px 10px", marginBottom:"10px"}}>
                            <h2>Statistiques</h2>
                            Nombre d'articles écrits : {this.props.authUser.data.numberOfArticles ?? 0}
                        </Paper>
                    </Grid>
                </Grid>
                
            </div>
        )
    }
}

export default compose(withFirebase, withRouter)(MesArticlesPanel);

const options = {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};