import React from 'react';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';


const LoadingPanel = () => {
    return (
        <div>
            <MyPaper>
                chargement..
            </MyPaper>
        </div>
    );
}

const LoadingPage = (title) => {
    return (
        <div>
            {title ?? ""}
        </div>
    )
}

const MyPaper = styled(Paper)`
    min-height: 200px;
    width: 100%;
    margin-top: 7px;
`
export default LoadingPanel;
export { LoadingPage };