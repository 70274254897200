import React, { Component } from 'react'
import { withFirebase } from '../../../../helpers';
import { compose } from 'recompose';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, ListItemIcon } from '@mui/material';
import styled from 'styled-components';
import GetAppIcon from '@mui/icons-material/GetApp';
import '../DocumentPanel/documentpanel.css';
import { DesktopView, MobileView, MyColor } from '../../../../components/Theme';
//import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import { collection, where, query, orderBy, onSnapshot } from "firebase/firestore";


class DocumentPanel extends Component {


    state = {
        loading: true,
        documents: {},
        error: null,
    }

    /// uploader tag : show only files uploaded by connected user
    componentDidMount() {
        const {  authUser, uploader } = this.props;

        const coll = "/users/" + authUser.data.uid + "/documents";

        if(uploader) {
            this.ref = onSnapshot(
                query(
                  collection(this.props.firebase.firestore, coll), 
                  where('sender', "==", authUser.data.id),
                  orderBy('date', 'desc')
                ),
                (querySnapshot) => {
                    const items = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                    this.setState({ documents:items, loading: false })
                },
                (error) => {
                  console.error(error)
                  this.setState({  loading : false });
                }
            );
        }
        else {

            this.ref = onSnapshot(
                query(
                  collection(this.props.firebase.firestore, coll), 
                  where('sender', "!=", authUser.data.id),
                  orderBy("sender"),
                  orderBy('date', 'desc')
                ),
                (querySnapshot) => {
                    const items = querySnapshot.docs.map(docSnapshot => ({...docSnapshot.data(), id: docSnapshot.id}));
                    this.setState({ documents:items, loading: false })
                },
                (error) => {
                  console.error(error)
                  this.setState({  loading : false });
                }
            );
        }
        
    }

    updateDocument = (uid, document) => {
        if(!document.read) {
            const { authUser } = this.props;
            document.read = true;
            this.props.firebase.firestore.collection("users/" + authUser.data.uid + "/documents").doc(uid).update(document);
        }
    };

    render() {

        const { documents } = this.state;

        const docs = Object
        .keys(documents)
        .map(key => (
            <TableRow key={documents[key].title}>
                <MyCell>{documents[key].title}</MyCell>
                <MyCell align="center">{new Date(documents[key].date).toLocaleDateString('fr-BE', options)}</MyCell>
                <MyCell align="center"><MyLabel color={documents[key].read ? "grey" : "orange"}>{documents[key].read ? "Lu" : "Nouveau"}</MyLabel></MyCell>
                <MyCell align="right"><IconButton onClick={() => {if(!this.props.isPro) {this.updateDocument(documents[key].id, documents[key]); } window.open(documents[key].documentURL, '_blank');}}><GetAppIcon htmlColor="#f474747"/></IconButton></MyCell>
            </TableRow>
        ));

        const listDocs = Object.keys(documents).map(key => (
            <div key={"item-" + documents[key].title}>
                <ListItem  alignItems="flex-start">
                    <ListItemIcon>
                        <DescriptionIcon fontSize="small" htmlColor={documents[key].read ? "grey" : "orange"}  />
                    </ListItemIcon>
                    <ListItemText primary={documents[key].title.replace("_", " ")} secondary={new Date(documents[key].date).toLocaleDateString('fr-BE', options)} />
                    <ListItemSecondaryAction>
                        <IconButton onClick={() => {if(!this.props.isPro) {this.updateDocument(documents[key].id, documents[key]); } window.open(documents[key].documentURL, '_blank');}}>
                            <GetAppIcon htmlColor="#f474747"/>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
            </div>
        ))
    

        return (
            <div >
                <Paper elevation={3}>
                    <div className="PanelLabel" style={{position:"relative", zIndex:"4999"}}>
                            <div id="PanelLabel background" style={{backgroundColor:MyColor.SECONDARY_GREEN, position:"absolute", top:"10px", left:"-11px", padding:"5px"}}>
                                <span style={{fontSize:"20px", color:"white", paddingLeft:"25px", paddingRight:"10px", fontFamily:"'Karla', sans-serif" }}>Documents</span>

                            </div>
                    </div>
                    <DesktopView>
                        <MyPaper className="documentWrapper">
                            <Table stickyHeader >
                                <TableHead>
                                    <TableRow>
                                        <MyCell><b></b></MyCell>
                                        <MyCell align="center"><b>{this.props.uploader ? "Envoyé le " : "Reçu le"}</b></MyCell>
                                        <MyCell align="center"><b>Statut</b></MyCell>
                                        <MyCell align="right"><b>Action</b></MyCell>
                                    </TableRow>                           
                                </TableHead>
                                <TableBody>
                                    {docs.length === 0 && <TableRow >
                                        <MyCell>Pas de document disponible!</MyCell>
                                        <MyCell></MyCell>
                                        <MyCell></MyCell>
                                        <MyCell></MyCell>
                                    </TableRow>}
                                    { docs }      
                                </TableBody>
                            </Table>
                        </MyPaper>
                    </DesktopView>
                    <MobileView>
                    <div style={{height:"50px"}}></div>
                        <List className="documentWrapper" style={{height: "200px", overflow:"auto"}}>
                            {listDocs}
                            {listDocs.length === 0 && 
                                <ListItem key="item_first"  alignItems="flex-start">
                                    <ListItemText primary="Vous n'avez pas encore reçu de document !" />
                                </ListItem>
                            }
                        </List>
                    </MobileView>
                </Paper>
            </div>
        )
    }
}

export default  compose(withFirebase)(DocumentPanel);

//const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

const options = {  year: 'numeric', month: 'numeric', day: 'numeric'};

const MyPaper = styled(TableContainer)`
    height: 200px;
    min-width: 100%;
    font-family: 'Karla', sans-serif;
`

const MyCell = styled(TableCell)`
    font-family: 'Karla', sans-serif;
`

const MyLabel = styled.div`
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
  min-width: 50px;
  color: white;
  background: ${props => {
      return props.color ?? 'grey';
  }};
  font-family: 'Noto Sans', sans-serif;
`

export { MyLabel };