import React, { Component } from 'react'
import { DesktopView, MobileView } from '../../../../components/Theme';
import { InputBase, Paper } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import Fade from 'react-reveal/Fade';
import { withFirebase } from '../../../../helpers';
import Validator from '../../../../utils/Validator';

class ChangeEmailFacebook extends Component {  

    state = {
        email: "",
        confirm_email:"",

        errorMail:"",
        errorConfirmMail:"",
        error:"",
    }

    checkDoubleEmail = () => {

        const { email, confirm_email } = this.state;

        if (email !== confirm_email) {
            this.setState({errorConfirmMail : "Votre mail est différent"})
            return false;
        }
        else {
            this.setState({errorConfirmMail : ""})
            return true;
        }
    }

    confirm = () => {
        const { email } = this.state;

        // Specific checks
        var okEmail = Validator.checkEmail(email);
        this.setState({errorMail : !okEmail});

        var okConfirmEmail = this.checkDoubleEmail();

        if(okEmail && okConfirmEmail) {
            this.updateEmail();
        }
    }

    updateEmail = () => {
        const { email } = this.state;
        const { authUser } = this.props;
            this.props.firebase.doUpdateMail(email).then((result) => {
                const user = {
                    email : email
                }
                this.props.firebase.firestore.collection("users").doc(authUser.data.uid).update(user)
                .then((result) => {
                    this.props.setSuccess("Votre adresse mail a correctement été mise à jour");
                    this.setState({email:"", confirm_email:"", error:""})

                })
                .catch((error) => {
                    console.error(error)
                    this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
                });
              })
              .catch((error) => {
                console.error(error)
                if(error.code + "" === "auth/email-already-in-use") {
                    this.setState({ error: "l'email renseigné existe déjà."});
                }
                else {
                    this.setState({ error: "une erreur s'est produite - Vérifiez les informations et réessayez"});
                }
              });
    }

    render() {

        const { email, errorMail, error, confirm_email, errorConfirmMail } = this.state;
        const { authUser } = this.props;

        const body = (
            <div className="karla_text">
                <h3>Modifier votre adresse mail</h3>
                <p>Votre adresse mail actuelle est : <b>{authUser.data.email}</b></p>
                <form onSubmit={(event) => {event.preventDefault(); this.confirm()}}>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <EmailIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Saisissez votre nouvelle adresse mail"
                            onChange={(event) => {this.setState({email : event.target.value})}}
                            value={email}
                            type="email"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorMail}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                            Votre mail est vide ou non conforme
                        </div>
                    </Fade>
                    <Paper elevation={3} style={{ display:"flex", alignItems:"center", padding: '2px 4px', marginBottom:"15px"}}>
                        <EmailIcon style={{padding: "10px"}}  />
                        <InputBase
                            placeholder="Confirmez votre nouvelle adresse mail"
                            onChange={(event) => {this.setState({confirm_email : event.target.value})}}
                            value={confirm_email}
                            type="email"
                            className="karla_text"
                            style={{width:"100%"}}
                        />
                    </Paper>
                    <Fade bottom collapse when={errorConfirmMail}>
                        <div style={{ display: 'block', paddingBottom:"10px", color:"red", paddingLeft:"2px", fontSize:"12px" }}>
                            Votre mail de confirmation est différent de l'email saisi
                        </div>
                    </Fade>
                    <center style={{paddingBottom:"25px", paddingTop:"25px"}}>
                    <Fade bottom collapse when={error}>
                        <span style={{color:"red", fontSize:"14px",  paddingBottom:"10px",}}>Attention : {error}</span>
                    </Fade>
                    <button type="submit" className="circular-inscription-btn" style={{ paddingTop:"12px", paddingBottom:"12px", maxWidth:"300px"}}>Modifier</button>
                    </center>
                </form>
                        
            </div>
        );

        return (
            <div>
                 <DesktopView >
                        {body}
                </DesktopView>
                <MobileView >
                        {body}
                </MobileView>
            </div>
        )
    }
}


export default withFirebase(ChangeEmailFacebook);
