import React, { Component } from 'react'
import { Paper, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListSubheader, ListItemSecondaryAction, IconButton, Tooltip } from '@mui/material';
import MessengerPanel from '../../connected/Messenger/MessengerPanel';
import { withFirebase } from '../../../helpers';
import { compteStatut } from '../../connected/Profile/ProfilePanel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { compose } from 'recompose';
import algoliasearch from 'algoliasearch';
import { withRouter } from '../../../App';
import { collection, limit, query, orderBy, onSnapshot } from "firebase/firestore";
const searchClient = algoliasearch('5VLFOU1HSS', '53fab587d37f770223ccf8e28ed1d30a');
const index = searchClient.initIndex("Patients");

class MessengerPro extends Component {


    state = {
        users: [],
        targetUser: null,
        messages: {},
        conversations: []
    }

    componentDidMount() {
        const {  authUser } = this.props;

        if(!this.unsubConsultation) {
            this.unsubConsultation = onSnapshot(
                query(
                  collection(this.props.firebase.firestore, "users/"+ authUser.data.uid +"/conversations"), 
                  orderBy('last_message'),
                  limit(10)
                ),
                (querySnapshot) => {
                    //const data = querySnapshot.docs.map(docSnapshot => );
                    const data = querySnapshot.docs.map(docSnapshot => ({...docSnapshot.data(), id: docSnapshot.id}));
                    this.setState({ conversations:data })
                    data.forEach(element => {
                        index.search(element.id, {
                            attributesToRetrieve: ['firstname', 'name', 'imageURL', 'level'],
                        })
                        .then(({ hits }) => {
                            const users = this.state.users
                            hits.forEach(user => {
                                users.push(user)
                                
                            })
                            this.setState({users : users, targetUser: users.length ? users[0] : null})
                        })
                    });
                },
                (error) => {
                  console.error(error)
                  this.setState({  loadingUser : false });
                }
            );
        }
        
    }



    SelectUser = (i) => {
        this.setState({ targetUser: null }, () => {
            const user = this.state.users[i]
            this.setState({ targetUser: user })
        })
    }

    render() {

        const { users, targetUser } = this.state; 

        const listUsers = Object.keys(users).map((uid, i) => 
        (<ListItem button key={users[uid].objectID} onClick={() => this.SelectUser(uid)} style={{backgroundColor: users[uid] === targetUser ? "rgb(167, 198, 255)" : ""}}>
            <ListItemAvatar>
                <Avatar alt={users[uid].firstname} src={users[uid].imageURL} />
            </ListItemAvatar>
            <ListItemText primary={users[uid].name + " " + users[uid].firstname} secondary={compteStatut(users[uid].level)} />
            <ListItemSecondaryAction>
                <Tooltip title={"Voir profil de " + users[uid].firstname}  placement="top">
                    <IconButton edge="end" onClick={() => { this.props.navigate("/admin/profil/" + users[uid].objectID)}} >
                        <AccountCircleIcon />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
        ));
        return (
            <div>
                <Paper elevation={3}>
                    <Grid container>
                        <Grid item xs={4}>
                            <List style={{overflow: 'auto', height: '350px',}} className="documentWrapper" subheader={<li />}>
                                <ListSubheader style={{backgroundColor:"white"}}>Discuter avec un patient</ListSubheader>
                                   { listUsers }
                            </List>
                        </Grid>
                        <Grid item xs={8}>
                            { targetUser && <MessengerPanel authUser={this.props.authUser} to_user_uid={targetUser.objectID} targetUser={targetUser} isPro/> }
                        </Grid>
                    </Grid>
                   
                </Paper>
            </div>
        )
    }
}


export default compose(withRouter, withFirebase)(MessengerPro);