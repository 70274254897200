import React, { Component } from 'react'
import { Paper, Grid } from '@mui/material'
import { withFirebase } from '../../../../helpers';
import { compose } from 'recompose';
import styled from 'styled-components';
import { GreenButton } from '../../../../components/Theme';
import { getClosestConsultation } from '../../Consultation/ConsultationPanel';
import { getTimeConsultation } from '../../Consultation/ConsultationPage';
import { withRouter } from '../../../../App';

class ProfilePanel extends Component {

    state = {
        loading: true,
    }

    

    render() {
        if(this.props.authUser && this.props.authUser.data) {
            const {authUser} = this.props;
            const consultation = authUser.consultations ? getClosestConsultation(authUser.consultations) : null;

            return (
                <div className="karla_text">
                    <MyPaper elevation={3}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <WrapperImage><AvatarImage src={authUser.data.imageURL ?? "https://www.allsolutionslocksmiths.com.au/wp-content/uploads/2011/07/user.png"} alt={authUser.data.firstname} width="150" height="150" /></WrapperImage>
                            </Grid>
                            <Grid item xs container direction="column">
                                <Grid item>
                                    <h3>Bonjour <span>{authUser.data.firstname ?? "à vous"}</span>,</h3>
                                </Grid>
                                <Grid item>
                                    Prochaine Consultation : <span>{
                                        consultation ? getTimeConsultation(consultation.date) :
                                        "Pas de consultation prévue !"
                                    }</span>
                                </Grid>
                                <Grid item>
                                    Statut : {compteStatut(authUser.data.level)}
                                </Grid>
                               
                            </Grid>
                            
                        </Grid>
                        <WrapperThird>
                                <GreenButton onClick={() => this.props.navigate("/home/profil")}>Mon Compte</GreenButton>
                        </WrapperThird>
                    </MyPaper>
                </div>
            )
        }
        return <div></div>
    }
}

/*
 <Grid item>
    Abonnement valide jusqu'au : <i>{new Date(authUser.data.subscriptionExpirationDateDate).toLocaleDateString('fr-BE', optionsSub)}</i>
</Grid>
*/

export default  compose(withFirebase, withRouter)(ProfilePanel);

export function compteStatut(level) {
    switch(level) {
        case 1:
            return "Compte Gratuit";
        case 2:
            return "Membre abonné";
        case 3:
            return "Membre Elysium";
        case 4:
            return "Diététicienne";
        case 5:
            return "Administrateur";
        default:
            return "Compte Gratuit";
    }
}


export const optionsSub = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

const MyPaper = styled(Paper)`
    min-height: 200px;
    width: 100%;
    margin-top: 7px;
`

export const AvatarImage = styled.img`
    border-radius: 50%;
    padding: 1px;
    border: 2px solid transparent;
    background: #09371F;
    opacity:0.9
`;

const WrapperImage = styled.div`
    padding: 10px;
`;

const WrapperThird = styled.div`
    margin-left: auto;
    position: relative;
    bottom: 20px;
    width: 120px;
`