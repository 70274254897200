import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';

class Faq extends Component {
    render() {
        return (
            <div >
                <Helmet>
                    <title>Foire aux questions</title>
                    <meta name="description" content="Foire aux questions (FAQ). Comment prendre une consultation ? Comment payer ou annuler une consultation. Nous répondons à vos questions" />
                </Helmet>
                <Header/>
                <div style={{paddingLeft:"10px", paddingRight:"10px", backgroundColor:"rgb(242, 237, 219)", minHeight:"100vh"}}>
                    <h1 style={{margin:"0", paddingBottom:"15px", paddingTop:"30px"}}>FAQ</h1>
                    <div>
                        <h2  style={{margin:"0"}}>Dois-je préparer quelque chose avant ma consultation ?</h2>
                        <p style={{textAlign:"justify"}}>
                            Assurez-vous des points suivants : 
                            <ul>
                                <li>Vérifiez votre connexion internet</li>
                                <li>Soyez prêt ~10mins avant le début de votre consultation</li>
                                <li>Pour éviter tout problème, il est conseillé d'utiliser Google Chrome comme navigateur</li>
                            </ul>
                        </p>
                    </div>
                    <div>
                        <h2>Mes données sont-elles traitées confidentiellement ? </h2>
                        <p style={{textAlign:"justify"}}>Oui ! Vos données sur Food-eDiet sont privées et confidentielles. Elles ne sont consultables que par vous et votre diététicienne</p>
                    </div>
                    <div>
                        <h2>Quels sont les moyens de paiements autorisés ? </h2>
                        <p style={{textAlign:"justify"}}>Actuellement, 2 moyens de paiements sont autorisés : carte de crédit et Bancontact.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Faq;