import React, { Component } from 'react'
import { TableRow, TableCell, Table, TableHead, TableBody, TableContainer, Paper, IconButton, Tooltip, Collapse, Box, List, ListItem,  ListItemSecondaryAction, Divider, ListItemText, ListItemIcon } from '@mui/material';
import styled from 'styled-components';
//import CancelIcon from '@mui/icons-material/Cancel';
//import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { getTimeConsultation, labelStatus } from '.';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { withFirebase } from '../../../../helpers';
import { getVideoId } from '../../../../utils/Settings';
import { compose } from 'recompose';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Assistance from '../../Assistance';
import { DesktopView, MobileView } from '../../../../components/Theme';
import SimpleDialog from '../../../../components/SimpleDialog';
import { withRouter } from '../../../../App';
//import InfiniteScroll from 'react-infinite-scroll-component';
import {  doc, onSnapshot, query } from "firebase/firestore";

class HistoriquePanel extends Component {

    state = {
        slot: null,
        consultations: [],
        has_more: true,
    }

    componentDidMount() {
        this.getConsultations();
    }

    getConsultations = () => {
        const { firebase, authUser } = this.props;
        let set = this;

        //initiate first set
        var first = firebase.firestore.collection("users/"+authUser.data.uid+"/consultations").orderBy("date", "desc").limit(6);
        first.get().then(function (documentSnapshots) {
          var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
          const list = [];
          documentSnapshots.forEach(function(doc) {
              const articles = doc.data();
              list.push(articles);
          });
              set.setState({ consultations: list, last: lastVisible, has_more: (list.length > 0) });
          });
    }

    fetchMoreData = () => {
        //get last state we added from getUsers()
        let last = this.state.last;
        let set = this;
        const { firebase, authUser } = this.props;
        var next = firebase.firestore.collection("users/"+authUser.data.uid+"/consultations").orderBy("date", "desc").startAfter(last).limit(4);
        next.get().then(function (documentSnapshots) {
        var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        const list = [];
        documentSnapshots.forEach(function(doc) {
          const articles = doc.data();
          list.push(articles);
        });
        let updated_list = set.state.articles.concat(list);
        set.setState({ consultations: updated_list, last: lastVisible, has_more: (list.length > 0) });
        });
    };

    /// A changer avec les nouvelles méthodes
    cancelConsultation = (consultation) => {
        consultation.status = 2;
        this.props.firebase.firestore.collection("users/"+consultation.patientId +"/consultations").doc(consultation.id).update(consultation)
            .then((result) => {
                this.props.firebase.firestore.collection("users/"+consultation.dietId +"/consultations").doc(consultation.id).update(consultation)
                    .then((result) => {
                        this.unsubCustomer = onSnapshot(
                            query(
                              doc(this.props.firebase.firestore, "slots", consultation.slotId)
                            ),
                            (snap) => {
                                const slot = snap.data()
                                this.setState({ slot : slot})
                                const slotItem = slot.slots.findIndex(slotItem => slotItem.heure === consultation.date);
                                slot.slots[slotItem].statut = 1;
                                slot.slots[slotItem].consultationId = "";
                                this.props.firebase.firestore.collection("slots").doc(consultation.slotId).update(slot)
                            },
                            (error) => {
                              console.error(error)
                              this.setState({  loadingUser : false });
                            }
                          );
                    })
            })
            .catch(error => {
                this.setState({ error });
                console.error(error)
            });
    };

    terminateConsultation = (consultation) => {
        consultation.status = 1;
        this.props.firebase.firestore.collection("users").doc(consultation.patientId).collection("consultations").doc(consultation.id)
        .update(consultation)
        .then(() => {
            this.props.firebase.firestore.collection("users").doc(consultation.dietId).collection("consultations").doc(consultation.id).update(consultation)
        })
        .catch(error => {
            this.setState({ error });
            console.error(error)
        });
    };

    render() {

        const { authUser } = this.props;
        const { consultations} = this.state;

        const consultationRows = Object
            .keys(authUser.consultations ?? [])
            .map(key => {
                if(this.props.condition(authUser.consultations[key].status))
                return (<Row  consultation={authUser.consultations[key]} key={key} {...this.props} handleJoin={this.handleJoin} terminateConsultation={this.terminateConsultation} cancelConsultation={this.cancelConsultation} />);
                else return(<div></div>)
        });

        const listConsultations = Object.keys(authUser.consultations ?? []).map(key => {
            const consultation = authUser.consultations[key];
           
            if(this.props.condition(consultation.status))
            return (
                <Line consultation={consultation} key={key} {...this.props} handleJoin={this.handleJoin} terminateConsultation={this.terminateConsultation} cancelConsultation={this.cancelConsultation} />
            );
            else return(<div></div>)
    });

        const myHead = <MyTableHead>
            <MyTableRow>
                <TableCell />
                <MyCell><b>Date</b></MyCell>
                <MyCell align="left"><b>Motif</b></MyCell>
                <MyCell align="center"><b>Statut</b></MyCell>
                <MyCell align="center"><b>Actions</b></MyCell>
            </MyTableRow>                           
        </MyTableHead>

        return (
            <div className="karla_text">
                <DesktopView>
                    <MyContainer component={Paper} className="documentWrapper" id="test">
                        <Table stickyHeader size="small" >
                            {myHead}
                            <TableBody>
                                { consultationRows }   
                                { consultations.length === 0 && 
                                    <TableRow >
                                        <MyCell>Pas de consultation trouvée !</MyCell>
                                    </TableRow>
                                }  
                            </TableBody>
                        </Table>
                    </MyContainer>
                </DesktopView>
                <MobileView>
                    <List className="documentWrapper" style={{maxHeight: "350px", overflow:"auto"}} component={Paper}>
                            {listConsultations}
                            {consultations.length === 0 && 
                                <ListItem  alignItems="flex-start">
                                    <ListItemText primary="Pas de consultation trouvée !" />
                                </ListItem>
                            }
                    </List>
                </MobileView>
            </div>
        )
    }
}

export default compose(withRouter, withFirebase)(HistoriquePanel);


function Row(props) {

    const [open, setOpen] = React.useState(false);
    const [openDialogTerminate, setopenDialogTerminate] = React.useState(false);
    const key = props.key;
    const consultation = props.consultation;
    const terminateConsultation = props.terminateConsultation;
    //const cancelConsultation = props.cancelConsultation;

    return (
        <React.Fragment key={key} >
            <TableRow>
                <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                </TableCell>
                <MyCell>{getTimeConsultation(consultation.date)}</MyCell>
                <MyCell align="left">{consultation.motif}</MyCell>
                <MyCell align="center">{labelStatus(consultation.status)}</MyCell>
                <MyCell align="center">
                    <Espacement>
                        {props.isNext &&
                            <div>
                                
                                <Tooltip title="Rejoindre la consultation en ligne" placement="top">
                                    <IconButton href={"/meeting/" + getVideoId(consultation)}><OndemandVideoIcon htmlColor="coral"/></IconButton>
                                </Tooltip>
                                
                                {props.isPro && 
                                <div>
                                    <SimpleDialog open={openDialogTerminate} head="Terminer la consultation ?" body="voulez-vous confirmer la fin de la consultation ?" handleClose={() => setopenDialogTerminate(!openDialogTerminate)} handleConfirm={() => {setopenDialogTerminate(!openDialogTerminate); terminateConsultation(consultation)}}/>
                                    <Tooltip title="Terminer la consultation" placement="top">
                                        <IconButton onClick={() => setopenDialogTerminate(!openDialogTerminate)}><CheckCircleIcon htmlColor="green"/></IconButton>
                                    </Tooltip>
                                </div>
                                }
                                {
                                   //<Tooltip title="Annuler le rendez-vous" placement="top"><IconButton onClick={() => {cancelConsultation(consultation)}}><CancelIcon htmlColor="black" /></IconButton></Tooltip>
                                }
                            </div>
                        }
                        {props.isHistorique &&
                            <div>
                                <Assistance consultation={consultation}>
                                    <Tooltip title="Aide" placement="top"><IconButton><ContactSupportIcon htmlColor="#4169E1"/></IconButton></Tooltip>
                                </Assistance>
                            </div>
                        }
                    </Espacement>
                </MyCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1} className="karla_text">
                        <h3>Informations Complémentaires</h3> 
                        <span><b>Patient(e) :</b> {consultation.patientName} </span><br/>
                        <span><b>Diététicien(ne) :</b> {consultation.dietName} </span>
                        <br/>
                        <span><b>Commentaire :</b> {consultation.commentary} </span>
                    </Box>
                </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

function Line(props) {
    const [open, setOpen] = React.useState(false);
    const key = props.key;
    const consultation = props.consultation;
    const terminateConsultation = props.terminateConsultation;
    return(
    <div>
        <ListItem key={key} alignItems="flex-start">
            <ListItemIcon onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </ListItemIcon>
            <ListItemText secondary={consultation.motif} primary={getTimeConsultation(consultation.date)} />
            <ListItemSecondaryAction>
                {props.isNext &&
                    <div>
                        
                        <Tooltip title="Rejoindre la consultation en ligne" placement="top">
                            <IconButton href={"/meeting/" + getVideoId(consultation)}><OndemandVideoIcon htmlColor="coral"/></IconButton>
                        </Tooltip>
                        
                        {props.isPro && 
                        <Tooltip title="Terminer la consultation" placement="top">
                            <IconButton onClick={() => terminateConsultation(consultation)}><CheckCircleIcon htmlColor="green"/></IconButton>
                        </Tooltip>
                        }
                    </div>
                }
                {props.isHistorique &&
                    <div>
                        <Assistance consultation={consultation}>
                            <Tooltip title="Aide" placement="top"><IconButton><ContactSupportIcon htmlColor="#4169E1"/></IconButton></Tooltip>
                        </Assistance>
                    </div>
                }
            </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1} className="karla_text">
                        <h3>Informations Complémentaires</h3> 
                        <span><b>Patient(e) :</b> {consultation.patientName} </span><br/>
                        <span><b>Diététicien(ne) :</b> {consultation.dietName} </span>
                        <br/>
                        <span><b>Commentaire :</b> {consultation.commentary} </span>
                    </Box>
        </Collapse>
        <Divider component="li" />
    </div>
    )
}


// Styled components
const MyContainer = styled(TableContainer)`
    max-height: 350px;
    min-width: 100%;
    font-family: 'Karla', sans-serif;
`
const MyCell = styled(TableCell)`
    font-family: 'Karla', sans-serif;
    max-width: "35px";
    max-height: 50px;
`

const MyTableHead = styled(TableHead)`
    background: red;
    height: auto;
`

const MyTableRow = styled(TableRow)`
    background: orangered;
`

const Espacement = styled.div`
    min-width: 300px;
`

//<Tooltip title="Payer" placement="top"><IconButton><MonetizationOnIcon htmlColor="gold"/></IconButton></Tooltip>
//<Tooltip title="Aide - Problème" placement="top"><IconButton><ContactSupportIcon htmlColor="#4169E1"/></IconButton></Tooltip>
