import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';

class ConditionUtilisation extends Component {
    render() {
        return <div >
            <Helmet>
                <title>Conditions d'utilisations et politque de confidentialité</title>
                <meta name="description" content="Retrouvez nos conditions d'utilisations du site ainsi que notre politique de confidentialité. Vous devez les accepter afin de vous inscrire sur food-ediet" />
            </Helmet>
            <Header/>
            <div style={{paddingLeft:"10px", paddingRight:"10px", marginTop:"100px"}}>
                <h1 className="main_h2">Mentions légales et CGV</h1>
                <h3 id="politique-de-confidentialite">Politique de Confidentialité</h3>
                <p>
                    Date de prise d'effet: le 25 novembre, 2020
                </p>
                <p>
                    Food-eDiet ("nous", "notre", "nos") exploite le site web <a href="https://food-ediet.com">https://food-ediet.com/</a> (ci-après désigné par le terme "Service").
                </p>
                <p>
                    Cette page vous explique nos politiques en matière de collecte, d'utilisation et de communication des données à caractère personnel lorsque vous utilisez notre Service ainsi que les choix qui s'offrent à vous en ce qui concerne ces données.
                </p>
                <p>
                    Nous utilisons vos données pour fournir et améliorer le Service. En utilisant le Service, vous consentez à la collecte et à l'utilisation d'informations conformément à la présente politique. Sauf définition contraire dans la présente Politique de Confidentialité, les termes utilisés dans la présente Politique de Confidentialité ont la même signification que dans nos Conditions Générales qui sont disponibles sur <a href="https://food-ediet.com">https://food-ediet.com/</a> 
                </p>
                <h4>Définitions</h4>
                <ul>
                    <li>
                        Service - Par Service on entend le site web https://food-ediet.com/ exploité par Food-eDiet
                    </li>
                    <li>
                        Données à caractère personnel - Données à Caractère Personnel désigne des données concernant un individu vivant qui peut être identifié à partir de ces données (ou à partir de ces données et d'autres informations en notre possession ou susceptibles d'entrer en notre possession).
                    </li>
                    <li>
                        Données d'Utilisation - Les Données d'Utilisation sont recueillies automatiquement et sont générées soit par l'utilisation du Service, soit par l'infrastructure du Service proprement dite (par exemple, durée de consultation d'une page).
                    </li>
                    <li>
                        Cookies - Les cookies sont de petits fichiers enregistrés sur votre dispositif (ordinateur ou dispositif mobile).
                    </li>
                </ul>
                <h4>Collecte et utilisation des données</h4>
                <p>
                    Nous recueillons plusieurs types de données à différentes fins en vue de vous fournir notre Service et de l'améliorer.
                </p>
                <h5>Types de données recueillies</h5>
                <h6>Données à Caractère Personnel</h6>
                <p>
                    Lorsque vous utilisez notre Service, il est possible que nous vous demandions de nous fournir certaines données personnelles nominatives qui peuvent servir à vous contacter ou à vous identifier ("Données à Caractère Personnel"). Les données personnelles nominatives peuvent comprendre, mais de manière non limitative:
                </p>
                <ul>
                    <li>
                        Adresse e-mail
                    </li>
                    <li>
                        Prénom et nom de famille
                    </li>
                    <li>
                        Données anthropométriques 
                    </li>
                    <li>
                        Numéro de téléphone
                    </li>
                    <li>
                        Adresse, ville, province, état, code postal
                    </li>
                    <li>
                        Cookies et Données d'Utilisation
                    </li>
                </ul>
                <h6>Données anthropométriques</h6>
                <p>
                    Ces données sont recueillies pour servir Food-eDiet dans sa personnalisation de la solution ou produit à destination de l’utilisateur. Ces données ne seront pas communiquées à des tiers associés au profil de l’utilisateur.
                </p>
                <h6>Données d'Utilisation</h6>
                <p>
                    Nous pouvons également recueillir des informations relatives au mode d'accès et d'utilisation du Service ("Données d'Utilisation"). Ces Données d'Utilisation peuvent comprendre des informations telles que l'adresse de protocole Internet (c.-à-d. l'adresse IP) de votre ordinateur, le type de navigateur, la version du navigateur, les pages de notre Service que vous consultez, la date et l'heure de votre visite, le temps passé sur ces pages, les identifiants uniques de dispositifs ainsi que d'autres données de diagnostic.
                </p>
                <h6>Suivi et données de cookies</h6>
                <p>
                    Nous avons recours à des cookies et à d'autres technologies de suivi similaires pour effectuer un suivi des activités effectuées dans notre Service, et nous conservons certaines informations.
                </p>
                <p>
                    Les cookies sont des fichiers à faible volume de données pouvant comporter un identifiant unique anonyme. Les cookies sont envoyés à votre navigateur depuis un site web et sont stockés sur votre dispositif. D'autres technologies de suivi telles que les pixels, les balises et les scripts sont également utilisées pour recueillir et suivre des informations et afin d'améliorer et d'analyser notre Service.
                </p>
                <p>
                    Vous pouvez demander à votre navigateur de refuser tous les cookies ou de vous avertir lorsqu'un cookie est envoyé. Toutefois, si vous n'acceptez pas les cookies, il se peut que vous ne puissiez pas utiliser certains éléments de notre Service.
                </p>
                <p>
                    Exemples de cookies que nous utilisons :
                </p>
                <ul>
                    <li>
                        Cookies de Session. Nous utilisons des Cookies de Session pour faire fonctionner notre Service.
                    </li>
                    <li>
                        Cookies de Préférences. Nous utilisons des Cookies de Préférences pour mémoriser vos préférences et vos différents paramètres.
                    </li>
                    <li>
                        Cookies de Sécurité. Nous utilisons des Cookies de Sécurité pour des raisons de sécurité.
                    </li>
                </ul>
                <h4>Utilisation des données</h4>
                <p>
                    Food-eDiet utilise les données recueillies à des fins diverses:
                </p>
                <ul>
                    <li>
                        Pour fournir et assurer notre Service
                    </li>
                    <li>
                        Pour vous faire part des changements apportés à notre Service
                    </li>
                    <li>
                        Pour vous permettre d'utiliser les fonctions interactives de notre Service quand vous le souhaitez
                    </li>
                    <li>
                        Pour assurer l'assistance client
                    </li>
                    <li>
                        Pour recueillir des données précieuses ou d'analyses qui nous permettront d'améliorer notre Service
                    </li>
                    <li>
                        Pour contrôler l'utilisation de notre Service
                    </li>
                    <li>
                        Pour détecter, prévenir et régler les problèmes techniques
                    </li>
                </ul>
                <h4>Transfert des données</h4>
                <p>
                    Les informations vous concernant, notamment vos Données à Caractère Personnel dont vos Données anthropométriques, peuvent être transférées de votre région, province, pays, ou autre division territoriale vers des ordinateurs – et stockées sur ces derniers – situés à un endroit où la législation relative à la protection des données diffère de celle du territoire où vous résidez.
                </p>
                <p>
                    Si vous résidez hors du Royaume de Belgique et que vous choisissez de nous communiquer des informations, sachez que nous transférons les données, y compris les Données à Caractère Personnel, vers le/la Belgique et que nous les y traitons.
                </p>
                <p>
                    En acceptant la présente Politique de Confidentialité puis en soumettant ces informations, vous consentez à ce transfert.
                </p>
                <p>
                    Food-eDiet prendra toutes les mesures raisonnablement nécessaires pour faire en sorte que vos données soient traitées de manière sécurisée et conformément à la présente Politique de Confidentialité et vos Données à Caractère Personnel ne seront transférées vers aucune organisation ni aucun pays à moins que des contrôles adéquats ne soient en place, notamment en ce qui concerne la sécurité de vos données et d'autres données personnelles.
                </p>
                <h4>Communication de données</h4>
                <p>Exigences légales - Food-eDiet peut communiquer vos Données à Caractère Personnel si elle estime de bonne foi que cela est nécessaire pour :</p>
                <ul>
                    <li>
                        S'acquitter d'une obligation légale
                    </li>
                    <li>
                        Protéger et défendre les droits ou les biens de Food-eDiet
                    </li>
                    <li>
                        Prévenir d'éventuels actes répréhensibles ou enquêter sur de tels actes dans le cadre du Service
                    </li>
                    <li>
                        Assurer la sécurité personnelle des utilisateurs du Service ou du public
                    </li>
                    <li>
                        Se protéger contre la responsabilité civile
                    </li>
                </ul>
                <h4>Sécurité des données</h4>
                <p>
                    La sécurité de vos données nous tient à cœur. Toutefois, n'oubliez pas qu'aucune méthode de transmission de données par Internet ou méthode de stockage électronique n'est sûre à 100 %. Bien que nous nous efforçons d'utiliser des méthodes commercialement acceptables pour protéger vos Données à Caractère Personnel, nous ne pouvons pas leur garantir une sécurité absolue.
                </p>
                <h4>Prestataires de services</h4>
                <p>
                    Nous pouvons faire appel à des sociétés tierces et à des tierces personnes pour faciliter la prestation de notre Service ("Prestataires de Services"), assurer le Service en notre nom, assurer des services liés au Service ou nous aider à analyser la façon dont notre Service est utilisé.
                </p>
                <p>
                    Ces tiers n'ont accès à vos Données à Caractère Personnel que pour effectuer ces tâches en notre nom et il leur est interdit de les communiquer ou de les utiliser à quelque autre fin.
                </p>
                <h5>Analyses</h5>
                <p>
                    Nous pouvons faire appel à des Prestataires de Services tiers pour surveiller et analyser l'utilisation de notre Service.
                </p>
                <ul>
                    <li>
                        Google Analytics - Google Analytics est un service d'analyse web proposé par Google qui assure le suivi du trafic d'un site web et en rend compte. Google utilise les données recueillies pour suivre et surveiller l'utilisation de notre Service. Ces données sont partagées avec d'autres services Google. Google peut utiliser les données recueillies pour contextualiser et personnaliser les annonces de son propre réseau publicitaire.<br/>
                        Vous pouvez empêcher que vos activités dans le cadre du Service ne soient mises à la disposition de Google Analytics en installant le plug-in pour navigateur Analytics Opt out browser add-on de Google Analytics. Ce plug-in empêche le code JavaScript de Google Analytics JavaScript (ga.js, analytics.js et dc.js) de partager les informations concernant les activités liées aux visites avec Google Analytics.<br/>
                        Pour plus de précisions sur les pratiques de confidentialité de Google, merci de consulter la page web Protection de la vie privée et conditions de Google: https://policies.google.com/privacy?hl=en
                    </li>
                </ul>
                <h4>Liens pointant vers d'autres sites</h4>
                <p>
                    Il se peut que notre Service contient des liens pointant vers d'autres sites que nous n’exploitons pas. Si vous cliquez sur un lien de tiers, vous serez redirigé vers le site de ce tiers. Nous vous recommandons vivement d'examiner la politique de confidentialité de chacun des sites que vous consultez.
                </p>
                <p>
                    Nous n'avons aucun contrôle sur le contenu, les politiques ou pratiques de confidentialité des sites ou services de tiers et déclinons toute responsabilité en ce qui les concerne.
                </p>
                <h4>Vie privée des enfants</h4>
                <p>Notre Service ne s'adresse pas aux personnes de moins de 18 ans ("Enfants").</p>
                <p>
                    Nous ne recueillons pas sciemment de données personnelles nominatives auprès de personnes de moins de 18 ans. Si vous êtes un parent ou un tuteur et que vous savez que votre Enfant nous a communiqué des Données à Caractère Personnel, veuillez nous contacter. Si nous apprenons que nous avons recueilli des Données à Caractère Personnel auprès d'enfants sans vérifier s'il y a consentement parental, nous faisons le nécessaire pour supprimer ces informations de nos serveurs.
                </p>
                <h4>Modifications de la présente Politique de Confidentialité</h4>
                <p>
                    Nous nous réservons le droit d'actualiser notre Politique de Confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle Politique de Confidentialité sur cette page.
                </p>
                <p>
                    Avant que la modification ne prenne effet, nous vous en informerons par e-mail et/ ou en plaçant un avis bien en vue dans notre Service et nous actualiserons la "date de prise d'effet" qui figure en haut de la présente Politique de Confidentialité.
                </p>
                <p>
                    Nous vous conseillons de consulter la présente Politique de Confidentialité périodiquement pour prendre connaissance de toute modification. Les modifications apportées à la présente Politique de Confidentialité prennent effet lorsqu'elles sont publiées sur cette page.
                </p>
                <h4>Nous contacter</h4>
                <p>Pour toute question relative à la présente Politique de Confidentialité, veuillez nous contacter:</p>
                <ul>
                    <li>Par courrier électronique: demarchedynamics@gmail.com</li>
                </ul>
                <h3>Politique de retours</h3>
                <p>Nous n'acceptons pas des retours sur nos produits.</p>
                <h3 id="cgu">CONDITIONS GÉNÉRALES DE VENTE ET D’UTILISATION</h3>
                <h4>VUE D’ENSEMBLE</h4>
                <p>
                    Ce site web est exploité par Food-eDiet. Sur ce site, les termes "nous", "notre" et "nos" font référence à Food-eDiet. Food-eDiet propose ce site web, y compris toutes les informations, tous les outils et tous les services qui y sont disponibles pour vous, l’utilisateur, sous réserve de votre acceptation de l’ensemble des modalités, conditions, politiques et avis énoncés ici.
                </p>
                <p>
                    En visitant ce site et/ou en achetant un de nos produits, vous vous engagez dans notre "Service" et acceptez d’être lié par les modalités suivantes ("Conditions Générales de Vente", "Conditions Générales de Vente et d’Utilisation", "Conditions"), y compris les modalités, conditions et politiques additionnelles auxquelles il est fait référence ici et/ou accessibles par hyperlien. Ces Conditions Générales de Vente et d’Utilisation s’appliquent à tous les utilisateurs de ce site, incluant mais ne se limitant pas, aux utilisateurs qui naviguent sur le site, qui sont des vendeurs, des clients, des marchands, et/ou des contributeurs de contenu.
                </p>
                <p>
                    Veuillez lire attentivement ces Conditions Générales de Vente et d’Utilisation avant d’accéder à ou d’utiliser notre site web. En accédant à ou en utilisant une quelconque partie de ce site, vous acceptez d’être lié par ces Conditions Générales de Vente et d’Utilisation. Si vous n’acceptez pas toutes les modalités et toutes les Conditions de cet accord, alors vous ne devez pas accéder au site web ni utiliser les services qui y sont proposés. Si ces Conditions Générales de Vente et d’Utilisation sont considérées comme une offre, l’acceptation se limite expressément à ces Conditions Générales de Vente et d’Utilisation.
                </p>
                <p>
                    Toutes les nouvelles fonctionnalités et tous les nouveaux outils qui seront ajoutés ultérieurement à cette boutique seront également assujettis à ces Conditions Générales de Vente et d’Utilisation. Vous pouvez consulter la version la plus récente des Conditions Générales de Vente et d’Utilisation à tout moment sur cette page. Nous nous réservons le droit de mettre à jour, de changer ou de remplacer toute partie de ces Conditions Générales de Vente et d’Utilisation en publiant les mises à jour et/ou les modifications sur notre site web. Il vous incombe de consulter cette page régulièrement pour vérifier si des modifications ont été apportées. Votre utilisation continue du site web ou votre accès à celui-ci après la publication de toute modification constitue une acceptation de votre part de ces modifications.
                </p>
                <h4>ARTICLE 1 – CONDITIONS D’UTILISATION DE NOTRE BOUTIQUE EN LIGNE</h4>
                <p>Lorsque vous utilisez les services proposés ou lorsque vous vous inscrivez sur le présent site, vous acceptez et confirmez que :</p>
                <ul>
                    <li><span>vous êtes majeur conformément à la loi en vigueur dans votre pays de résidence ou de connexion ;</span></li>
                    <li><span>vous avez la capacité de contracter ;</span></li>
                    <li><span>vous utilisez les services pour votre seule activité personnelle, sans lien avec une quelconque activité professionnelle ;</span></li>
                    <li><span>vous accédez à un site de suivi diététique, susceptible de vous donner accès à des contenus destinés à un public adulte ;</span></li>
                    <li><span>la loi de votre pays de résidence vous autorise à accéder au site internet ;</span></li>
                    <li><span>vous avez lu, compris et accepté les Conditions Générales ;</span></li>
                    <li><span>l'ensemble des stipulations énoncées ci-après vous sont applicables jusqu'à la cl&ocirc;ture de votre Compte dans les conditions prévues à l'Article 16 des Conditions Générales.</span></li>
                </ul>
                <p><span  >L'utilisation de nos produits à toute fin illégale ou non autorisée est interdite, et vous ne devez pas non plus, dans le cadre de l'utilisation du Service, violer les lois de votre juridiction (incluant mais ne se limitant pas aux lois relatives aux droits d'auteur).</span></p>
                <p><span  >Nous nous réservons le droit de refuser à tout moment l'accès aux services à toute personne, et ce, pour quelque raison que ce soit.</span></p>
                <p><span  >Vous comprenez que votre contenu (à l'exclusion de vos informations de carte de crédit) pourrait être transféré de manière non chiffrée, et cela sous-entend (a) des transmissions sur divers réseaux; et (b) des changements pour se conformer et s'adapter aux exigences techniques pour la connexion des réseaux ou appareils. Les informations de carte de crédit sont toujours chiffrées pendant la transmission sur les réseaux.</span></p>
                <p><span  >Vous acceptez de ne pas reproduire, dupliquer, copier, vendre, revendre ou exploiter une quelconque partie du Service ou utilisation du Service, ou un quelconque accès au Service ou contact sur le site web, par le biais duquel le Service est fourni, sans autorisation écrite expresse préalable de notre part.</span></p>
                <p><span  >Les titres utilisés dans cet accord sont inclus pour votre commodité, et ne vont ni limiter ni affecter ces Conditions.</span></p>
                <p><span  >Vous ne devez pas transmettre de vers, de virus ou tout autre code de nature destructive.</span></p>
                <p><span  >Toute infraction ou violation des présentes Conditions Générales de Vente et d'Utilisation entra&icirc;nera la résiliation immédiate de vos Services.</span></p>
                <h4>ARTICLE 2 - Définitions</h4>
                <p><span >Utilisateur : personne physique qui utilise le service Foode-Diet.</span></p>
                <p><span >Diététicien.ne : désigne les professionnel(le)s dipl&ocirc;mé(e)s d'Etat pratiquant les consultations diététiques des programmes. A titre d'information, Foode-Diet veille à ce que les Diététiciens pratiquant via le présent site justifient de leur dipl&ocirc;me et soient enregistrés à l'UPDLF, le répertoire belge des professionnels de santé, attestant ainsi de l'autorisation légale de l'exercice de la profession de Diététicien.</span></p>
                <p><span >Compte : l'Utilisateur crée un compte personnel dès lors qu'il s'inscrit et rentre son email. Les paramètres du Compte sont modifiables dans l'espace personnel accessible après avoir rempli le questionnaire nutritionnel.</span></p>
                <p><span >Un Compte est unique, personnel et les informations qu'il comporte doivent être mises à jour. A cet effet, l'Utilisateur veille à actualiser ses données et à en tenir Foode-Diet informé. Pour pouvoir utiliser la totalité des services proposés par Foode-Diet, une inscription est nécessaire. Le Compte pourra être suspendu ou cl&ocirc;turé notamment en cas de :</span></p>
                <p><span >- violation de l'une des présentes Conditions Générales</span></p>
                <p><span >- authentification ou vérification impossible de l'identité de l'Utilisateur</span></p>
                <p><span >- fourniture de données inexactes lors de l'inscription</span></p>
                <p><span >- comportement incompatible avec la loi ou les bonnes moeurs, notamment à l'occasion d'une Consultation diététique.</span></p>
                <p><span >Espace Personnel : interface du site internet food-ediet.com permettant l'accès et la gestion des données fournies par l'Utilisateur et des prestations délivrées par FoodeDiet.</span></p>
                <p><span >Site : le présent site internet et ses extensions, édité sous la responsabilité de Foode-Diet, accessible sous le nom de domaine food-ediet.com.</span></p>
                <p><span >Programme : accompagnement et suivi diététique (coaching) choisi par l'Utilisateur et le Diététicien à la suite du bilan de profil nutritionnel.</span></p>
                <p><span >Services : ensemble des prestations proposées par Foode-Diet sur le Site.</span></p>
                <p><span >Consultation : prestation dispensée par un Diététicien à l'Utilisateur grâce à un système de téléphonie ou de visio-conférence distribuée par un service et un logiciel de messagerie tiers et rendue possible grâce à une caméra reliée aux appareils utilisés par le Diététicien et l'Utilisateur (ordinateur, tablette, smartphone).</span></p>
                <h4>ARTICLE 3 – Accès et inscription aux Services Foode-Diet</h4>
                <h5>3.1 Conditions d’accès</h5>
                <p>
                    La création d’un Compte sur le Site est nécessaire pour accéder aux Services. Cette création de Compte est gratuite et se fait via l’Espace Personnel. Elle donne accès à différents outils tels que mails d'explications et de conseils, vidéos, quiz, newsletters, questions et réponses personnalisées, blog....
                </p>
                <p>Pour être Utilisateur, il convient de respecter les conditions suivantes :</p>
                <ul>
                    <li ><span >l'Utilisateur certifie avoir plus de 18 ans</span></li>
                    <li ><span >l'Utilisateur certifie fournir des informations véridiques, à jour et complètes au regard de la loi</span></li>
                    <li ><span >l'Utilisateur s'engage à n'utiliser le Compte qu'à des fins d'inscription, de participation à une Consultation et de règlement du Prix, sans porter atteinte aux intérêts de Foode-Diet ou de tiers</span></li>
                </ul>
                <p><span >L'Utilisateur garantit donner une adresse électronique valide et des coordonnées complètes et exactes permettant de procéder à son identification précise. Toute information personnelle fantaisiste ou calomnieuse est susceptible d'entra&icirc;ner la fermeture du Compte de l'Utilisateur. Une fois le Compte créé, l'Utilisateur informe Foode-Diet de toutes les modifications de ses informations personnelles. Tout courriel adressé par Foode-Diet à l'adresse électronique indiquée par l'Utilisateur sera réputé avoir été re&ccedil;u par ce dernier.</span></p>
                <p><span >Pour créer un Compte, l'Utilisateur doit renseigner le formulaire d'inscription avec les informations requises et choisir un identifiant et un mot de passe. Un courrier électronique de confirmation sera envoyé à l'Utilisateur à l'adresse électronique déclarée dans le formulaire d'inscription. L'Utilisateur sera seul responsable de l'utilisation qu'il fera de son identifiant et mot de passe et il s'engage à préserver la confidentialité de son identifiant et de son mot de passe. La responsabilité de Foode-Diet ne pourra pas être engagée pour la perte, le piratage ou l'utilisation frauduleuse des données personnelles de l'Utilisateur par un tiers.</span></p>
                <p><span >En cas de perte des identifiants donnant accès à son Compte, l'Utilisateur doit se rendre dans l'Espace Personnel et cliquer sur l'onglet &laquo; Mot de passe oublié &raquo;. Il devra ensuite entrer de nouveau son adresse mail afin de réactualiser son mot de passe. L'Utilisateur pourra le cas échéant contacter le support à l'adresse demarchedynamics@gmail.com pour la mise à jour de ses données de connexion. L'Utilisateur prend la responsabilité de l'ensemble des actions effectuées au moyen de ses identifiant et mot de passe.</span></p>
                <p><span >L'Utilisateur ne peut avoir qu'un seul Compte, qui lui est personnel et qui ne peut être cédé ou transféré de quelque manière que ce soit.</span></p>
                <p><span >En créant un Compte sur le Site, l'Utilisateur pourra être redirigé vers une autre adresse URL permettant de sécuriser l'accès aux informations présentes dans son Espace Personnel. Pour accéder à cet espace, l'Utilisateur devra de nouveau fournir ses identifiants.</span></p>
                <p><span >L'Utilisateur pourra aussi accéder à des sites tiers via des liens mis en place par Foode-Diet. La responsabilité de Foode-Diet ne peut être mise en cause en raison de la navigation de l'Utilisateur sur ses sites tiers.</span></p>
                <h5>3.2 Droit de rétractation</h5>
                <p><span >Conformément à la Loi du 28 août 2011 relative à la protection des consommateurs en matière de contrats d'utilisation de biens à temps partagé, de produits de vacances à long terme, de revente et d'échange et la Section 3. du droit de rétractation insérée par L 2014-04-19/39, art. 3, 021; En vigueur : 01-04-2015 (voir AR 2014-04-19/40, art. 2) Art. VII.83 du code de la consommation, l'Utilisateur est informé qu'il dispose d'un délai de quatorze jours à compter de la souscription aux Services pour exercer son droit de rétractation sans motif ni pénalité. L'Utilisateur peut mettre en oeuvre son droit de rétractation en envoyant un courriel à Food-eDiet à l'adresse suivante : demarchedynamics@gmail.com .</span></p>
                <p><span >Si l'Utilisateur décide d'exercer son droit de rétractation, Food-eDiet le remboursera de la totalité des sommes versées dans un délai de quatorze jours à compter de la réception par Food-eDiet du courriel l'informant de la rétractation et par le même moyen de règlement que celui utilisé par l'Utilisateur.</span></p>
                <h4>ARTICLE 4 - Description des Services et produits</h4>
                <p><span >Le Site propose un service d'accompagnement nutritionnel par consultation et suivi diététique en ligne.</span></p>
                <p><span >Les Programmes proposés par Food-eDiet sont exclusivement réservés :</span></p>
                <ul>
                <li ><span >aux personnes physiques âgées de plus de 18 ans, qui souhaitent bénéficier des services proposés à titre personnel et individuel (c'est à dire en dehors de toute utilisation professionnelle ou collective)</span></li>
                <li ><span >aux personnes qui ne présentent pas de contre-indication d'ordre médical à l'utilisation d'un régime diététique.</span></li>
                </ul>
                <p><span >Le Site propose aux Utilisateurs des Services leur permettant notamment de :</span></p>
                <ul>
                <li ><span >Remplir un questionnaire-bilan</span></li>
                <li ><span >Choisir un Diététicien</span></li>
                <li ><span >Créer un Compte via l'Espace Personnel</span></li>
                <li ><span >Obtenir un bilan gratuit de profil nutritionnel&nbsp;</span></li>
                <li ><span >Bénéficier de Consultations avec un Diététicien en visio-conférence</span></li>
                <li ><span >Noter et commenter la prestation délivrée par le Diététicien à l'occasion du Programme</span></li>
                <li ><span >Obtenir des informations et contenus sur la diététique et l'alimentation grâce au blog et aux newsletters</span></li>
                <li ><span >Accéder à un programme de suivi de ses connaissances et de son évolution diététique grâce aux fiches conseils et aux quiz nutritionnels de l'Espace Personnel</span></li>
                </ul>
                <p><span >Le contenu du Site, de même que les Consultations effectuées par les Diététiciens, ne peuvent en aucun cas être considérés comme équivalant à une consultation médicale et ne doivent pas être utilisés pour démarrer, modifier ou arrêter un traitement médical en cours.</span></p>
                <p><span >Certains produits ou services peuvent être exclusivement disponibles en ligne sur notre site web. Ces produits ou services peuvent être disponibles en quantités limitées et peuvent uniquement faire l'objet de retours ou d'échanges conformément à notre Politique de Retour.</span></p>
                <p><span >Nous avons fait de notre mieux pour afficher aussi clairement que possible les couleurs et images de nos produits qui apparaissent sur notre boutique. Nous ne pouvons pas garantir que l'affichage des couleurs par l'écran de votre ordinateur sera précis.</span></p>
                <p><span >Nous nous réservons le droit, sans toutefois être obligés de le faire, de limiter les ventes de nos produits ou services à toute personne, et dans toute région géographique ou juridiction. Nous pourrions exercer ce droit au cas par cas. Nous nous réservons le droit de limiter les quantités de tout produit ou service que nous offrons. Toutes les descriptions de produits et tous les prix des produits peuvent être modifiés en tout temps sans avis préalable, à notre seule discrétion. Nous nous réservons le droit d'arrêter d'offrir un produit à tout moment. Toute offre de service ou de produit présentée sur ce site est nulle là o&ugrave; la loi l'interdit.</span></p>
                <p><span >Nous ne garantissons pas que la qualité de tous les produits, services, informations, ou toute autre marchandise que vous avez obtenue ou achetée répondra à vos attentes, ni que toute erreur dans le Service sera corrigée.</span></p>
                <h4>ARTICLE 5 - Utilisation des Services</h4>
                <h5>4.1 Acceptation des Conditions Générales</h5>
                <p>
                    L'accès aux Services proposés sur le Site est strictement subordonné à l'acceptation pleine et entière par l'Utilisateur des présentes Conditions Générales. En s'inscrivant, l'Utilisateur reconnaît être pleinement informé des dispositions des présentes Conditions Générales et s’engage à les respecter. Tout Utilisateur qui n’accepte pas les Conditions Générales doit s’abstenir d’utiliser les Services.
                </p>
                <h5>4.2 Conditions d’utilisation des Services</h5>
                <p>
                    Une fois inscrit, l’Utilisateur bénéficiera d’un accès aux Services Food-eDiet. L’utilisation des Services doit se faire en conformité avec les lois et règlements et avec les obligations définies à l’Article 7. Les prix et modalités de paiement de ces Services sont accessibles à tout moment dans la rubrique « Programmes et tarifs » du Site et à l’Article 11 des présentes Conditions Générales.
                </p>
                <h5>4.2.1 Configuration technique</h5>
                <p><span >L'Utilisateur reconna&icirc;t disposer des moyens nécessaires pour accéder au Site et l'utiliser dans des conditions normales de navigation. Pour cela, l'Utilisateur reconna&icirc;t avoir vérifié que la configuration informatique qu'il utilise ne met en oeuvre aucun logiciel défaillant, aucun virus ou élément nuisible au système déployé par le Site.</span></p>
                <p><span >L'utilisation du Service met en oeuvre un système de visio-consultations et nécessite l'utilisation d'une application de messagerie externe au service proposé par Food-eDiet. Les Services proposés étant indissociablement liés à l'utilisation d'une connexion internet, d'une webcam et du matériel informatique nécessaire à la réception d'un service presté à distance, l'Utilisateur fait siennes les obligations liées aux équipements et connexions nécessaires à l'utilisation du présent Site et de ses Services.</span></p>
                <p><span >L'Utilisateur est seul responsable de l'installation, du maintien et du contr&ocirc;le de la configuration technique requise pour se connecter au Site. Food-eDiet ne saurait en aucun cas être tenue responsable de l'incompatibilité du Site avec la configuration technique de l'ordinateur, de la tablette ou du téléphone portable de l'Utilisateur.</span></p>
                <h5>4.2.2 Services payants</h5>
                <p><span >Les Services proposés par Food-eDiet sont des services paramédicaux dispensés par des professionnels de santé et, à ce titre, correspondent à un service sur mesure en fonction du profil de l'Utilisateur. Les Services ne peuvent être mis en oeuvre qu'au profit de l'Utilisateur, les Consultations, les bilans et conseils, et, d'une manière générale, toutes les prestations effectuées par les Diététiciens sur le Site sont dispensées à titre individuel.</span></p>
                <p><span >Le Programme mis en place par Food-eDiet prévoit un suivi nutritionnel d'une durée variable allant de un à six mois, comprenant entre deux et sept consultations, des fiches-conseil et des quiz nutritionnels. Ce Programme peut être payé en plusieurs fois ou comptant à la souscription.</span></p>
                <p><span >A la suite du Programme, l'Utilisateur, s'il le souhaite, peut bénéficier de Consultations supplémentaires. Ces Consultations sont animées par le Diététicien de son choix (cf Article 11 pour le prix de cette prestation). Les Consultations supplémentaires ne seront dispensées qu'à la condition que l'Utilisateur ait d'abord souscrit au Programme.</span></p>
                <h5>4.3 Confirmation de la commande</h5>
                <p><span >La commande concerne la souscription aux Services payants. L'Utilisateur devra confirmer sa commande en :</span></p>
                <ul>
                <li ><span >Complétant l'ensemble des informations demandées ;</span></li>
                <li ><span >Déclarant accepter pleinement et sans réserve l'intégralité des Conditions Générales ;</span></li>
                <li ><span >Validant sa commande par le paiement, celle-ci ne deviendra définitive qu'à compter du paiement du prix correspondant et lorsque l'Utilisateur recevra une confirmation de commande à l'adresse électronique qu'il aura communiquée.</span></li>
                </ul>
                <p><span >Le prix applicable à la commande de l'Utilisateur est celui indiqué sur la page récapitulant le contenu des Services commandés avant la confirmation de la commande.</span></p>
                <h4>ARTICLE 6 – ERREURS, INEXACTITUDES ET OMISSIONS</h4>
                <p><span >Il se pourrait qu'il y ait parfois des informations sur notre site ou dans le Service qui pourraient contenir des erreurs typographiques, des inexactitudes ou des omissions qui pourraient être relatives aux descriptions de produits, aux prix, aux promotions, aux offres, aux frais d'expédition des produits, aux délais de livraison et à la disponibilité. Nous nous réservons le droit de corriger toute erreur, inexactitude, omission, et de changer ou de mettre à jour des informations ou d'annuler des commandes, si une quelconque information dans le Service ou sur tout autre site web associé est inexacte, et ce, en tout temps et sans préavis (y compris après que vous ayez passé votre commande).</span></p>
                <p><span >Nous ne sommes pas tenus de mettre à jour, de modifier ou de clarifier les informations dans le Service ou sur tout autre site web associé, incluant mais ne se limitant pas aux informations sur les prix, sauf si requis par la loi. Aucune date définie de mise à jour ou d'actualisation dans le Service ou sur tout autre site web associé ne devrait être prise en compte pour conclure que les informations dans le Service ou sur tout autre site web associé ont été modifiées ou mises à jour.</span></p>
                <h4>ARTICLE 7 – UTILISATIONS INTERDITES</h4>
                <p><span >En plus des interdictions énoncées dans les Conditions Générales de Vente et d'Utilisation, il vous est interdit d'utiliser le site ou son contenu: (a) à des fins illégales; (b) pour inciter des tiers à réaliser des actes illégaux ou à y prendre part; (c) pour enfreindre toute ordonnance régionale ou toute loi, règle ou régulation internationale, fédérale, provinciale ou étatique; (d) pour porter atteinte à ou violer nos droits de propriété intellectuelle ou ceux de tierces parties; (e) pour harceler, maltraiter, insulter, blesser, diffamer, calomnier, dénigrer, intimider ou discriminer quiconque en fonction du sexe, de l'orientation sexuelle, de la religion, de l'origine ethnique, de la race, de l'âge, de l'origine nationale, ou d'un handicap; (f) pour soumettre des renseignements faux ou trompeurs; (g) pour téléverser ou transmettre des virus ou tout autre type de code malveillant qui sera ou pourrait être utilisé de manière à compromettre la fonctionnalité ou le fonctionnement du Service ou de tout autre site web associé, indépendant, ou d'Internet; (h) pour recueillir ou suivre les renseignements personnels d'autrui; (i) pour polluposter, hame&ccedil;onner, détourner un domaine, extorquer des informations, parcourir, explorer ou balayer le web (ou toute autre ressource); (j) à des fins obscènes ou immorales; ou (k) pour porter atteinte ou contourner les mesures de sécurité de notre Service, de tout autre site web, ou d'Internet. Nous nous réservons le droit de résilier votre utilisation du Service ou de tout site web connexe pour avoir enfreint les utilisations interdites.</span></p>
                <h4>ARTICLE 8 – EXCLUSION DE GARANTIES ET LIMITATION DE RESPONSABILITÉ</h4>
                <p><span >Nous ne garantissons ni ne prétendons en aucun cas que votre utilisation de notre Service sera ininterrompue, rapide, sécurisée ou sans erreur.</span></p>
                <p><span >Nous ne garantissons pas que les résultats qui pourraient être obtenus par le biais de l'utilisation du Service seront exacts ou fiables.</span></p>
                <p><span >Vous acceptez que de temps à autre, nous puissions supprimer le Service pour des périodes de temps indéfinies ou annuler le Service à tout moment, sans vous avertir au préalable.</span></p>
                <p><span >Vous convenez expressément que votre utilisation du Service, ou votre incapacité à utiliser celui-ci, est à votre seul risque. Le Service ainsi que tous les produits et services qui vous sont fournis par le biais du Service sont (sauf mention expresse du contraire de notre part) fournis "tels quels" et "selon la disponibilité" pour votre utilisation, et ce sans représentation, sans garanties et sans conditions d'aucune sorte, expresses ou implicites, y compris toutes les garanties implicites de commercialisation ou de qualité marchande, d'adaptation à un usage particulier, de durabilité, de titre et d'absence de contrefa&ccedil;on.</span></p>
                <p><span >Foode-Diet, nos directeurs, responsables, employés, sociétés affiliées, agents, contractants, stagiaires, fournisseurs, prestataires de services et concédants ne peuvent en aucun cas être tenus responsables de toute blessure, perte, réclamation, ou de dommages directs, indirects, accessoires, punitifs, spéciaux, ou dommages consécutifs de quelque nature qu'ils soient, incluant mais ne se limitant pas à la perte de profits, de revenus, d'économies, de données, aux coûts de remplacement ou tous dommages similaires, qu'ils soient contractuels, délictuels (même en cas de négligence), de responsabilité stricte ou autre, résultant de votre utilisation de tout service ou produit provenant de ce Service, ou quant à toute autre réclamation liée de quelque manière que ce soit à votre utilisation du Service ou de tout produit, incluant mais ne se limitant à toute erreur ou omission dans tout contenu, ou à toute perte ou tout dommage de toute sorte découlant de l'utilisation du Service ou de tout contenu (ou produit) publié, transmis, ou autrement rendu disponible par le biais du Service, même si vous avez été avertis de la possibilité qu'ils surviennent. Parce que certains états ou certaines juridictions ne permettent pas d'exclure ou de limiter la responsabilité quant aux dommages consécutifs ou accessoires, notre responsabilité sera limitée dans la mesure maximale permise par la loi.</span></p>
                <h4>ARTICLE 9 – EXACTITUDE, EXHAUSTIVITÉ ET ACTUALITÉ DES INFORMATIONS</h4>
                <p><span >Nous ne sommes pas responsables si les informations disponibles sur ce site ne sont pas exactes, complètes ou à jour. Le contenu de ce site est fourni à titre indicatif uniquement et ne devrait pas constituer votre seule source d'information pour prendre des décisions, sans consulter au préalable des sources d'information plus exactes, plus complètes et actualisées. Si vous décidez de vous fier au contenu présenté sur ce site, vous le faites à votre propre risque.</span></p>
                <p><span >Ce site pourrait contenir certaines informations antérieures. Ces informations antérieures, par nature, ne sont pas à jour et sont fournies à titre indicatif seulement. Nous nous réservons le droit de modifier le contenu de ce site à tout moment, mais nous n'avons aucune obligation de mettre à jour les informations sur notre site. Vous acceptez qu'il vous incombe de surveiller les modifications apportées à notre site.</span></p>
                <p><span >Nous nous réservons le droit de refuser toute commande que vous passez auprès de nous. Nous pourrions, à notre seule discrétion, réduire ou annuler les quantités achetées par personne, par foyer ou par commande. Ces restrictions pourraient inclure des commandes passées par ou depuis le même compte client, la même carte de crédit, et/ou des commandes qui utilisent la même adresse de facturation et/ou d'expédition. Dans le cas o&ugrave; nous modifierions une commande ou si nous venions à l'annuler, nous pourrions tenter de vous avertir en vous contactant à l'e-mail et/ou à l'adresse de facturation/au numéro de téléphone fourni au moment o&ugrave; la commande a été passée. Nous nous réservons le droit de limiter ou d'interdire les commandes qui, à notre seul jugement, pourraient sembler provenir de marchands, de revendeurs ou de distributeurs.</span></p>
                <p><span >Vous acceptez de fournir des informations de commande et de compte à jour, complètes et exactes pour toutes les commandes passées sur notre boutique. Vous vous engagez à mettre à jour rapidement votre compte et vos autres informations, y compris votre adresse e-mail, vos numéros de cartes de crédit et dates d'expiration, pour que nous poussions compléter vos transactions et vous contacter si nécessaire.</span></p>
                <p><span >Pour plus de détails, veuillez consulter notre Politique de Retour.</span></p>
                <h4>ARTICLE 10 – MODIFICATIONS APPORTÉES AU SERVICE ET AUX PRIX</h4>
                <p><span >Les prix de nos produits peuvent être modifiés sans préavis.</span></p>
                <p><span >Nous nous réservons le droit à tout moment de modifier ou d'interrompre le Service (ainsi que toute partie ou tout contenu du Service) sans préavis et en tout temps.</span></p>
                <p><span >Nous ne serons pas responsables envers vous ou toute autre tierce partie de toute modification de prix, suspension ou interruption du Service</span></p>
                <h4>ARTICLE 11 – Prix des Services et modalités de paiement</h4>
                <h5>11.1 Prix des Services</h5>
                <p><span >Food-eDiet propose un Programme d'accompagnement diététique sur un bilan unique ou une durée allant de un à six mois avec une possibilité de prolongation de l'accompagnement. Avant la mise en oeuvre du Programme, l'Utilisateur répond à un questionnaire, choisit son Diététicien et bénéficie d'un bilan diététique gratuit sous la forme d'un document. La Souscription aux Services payants donne droit à deux à sept séances d'accompagnement par un Diététicien réparties sur une durée allant de un à six mois avec une possibilité de prolongation de l'accompagnement. Le prix du bilan unique est de 59&euro;, Programme un mois est de 99 &euro;, le prix du Programme trois mois est de 189 &euro; et le prix du Programme six mois est de 319 &euro;. L'utilisateur peut choisir le paiement comptant ou en plusieurs fois s'il souscrit au Programme trois ou six mois, comme décrit à l'Article 11.2.</span></p>
                <p><span >Des consultations supplémentaires peuvent être ajoutées au tarif de 49 &euro; TTC la séance.</span></p>
                <h5>11.2 Modalités de paiement</h5>
                <p><span >Deux options de paiement s'offrent à l'Utilisateur :</span></p>
                <ul>
                <li ><span >En intégralité au moment de la souscription aux Programmes</span></li>
                <li ><span >Mensuellement pour les Programmes de trous ou six mois.</span></li>
                </ul>
                <p><span >Le Prix des Services Food-eDiet s'entend toutes taxes comprises.</span></p>
                <p><span >Food-eDiet propose un service de paiement sécurisé via le système STRIPE.</span></p>
                <p><span >Voir les </span><a href="https://stripe.com/fr-be/privacy"><span >Conditions Générales d'Utilisation des services de paiement STRIPE</span></a></p>
                <p><span >Le paiement s'effectue par carte bancaire quelle que soit la modalité de paiement choisie (mensuelle ou comptant).</span></p>
                <p><span >L'Utilisateur garantit qu'il est habilité à utiliser la carte de paiement fournie pour le paiement de sa commande. L'Utilisateur peut garder une trace de son paiement en demandant à Food-eDiet l'établissement d'un ticket récapitulatif des Services.</span></p>
                <p><span >La date d'échéance du paiement des Services est celle de la première Consultation. Lorsque l'Utilisateur choisit la mensualisation, il est débité tous les mois à cette date pendant la durée de son Programme.</span></p>
                <p><span >En cas de défaut de paiement ou de retard de paiement par l'Utilisateur, Food-eDiet se réserve le droit de recourir à une agence de recouvrement de créances, les coûts correspondants étant alors à la charge de l'Utilisateur, sans préjudice de l'application de l'Article 26 menant à la cl&ocirc;ture du Compte de l'Utilisateur.</span></p>
                <p><span >En cas d'annulation non justifiée d'un paiement par carte bancaire ou d'une opposition injustifiée à un paiement par carte de crédit, l'Utilisateur s'engage à rembourser à Food-eDiet les frais générés par l'annulation ou l'opposition illégitimes sur présentation du justificatif desdits frais.</span></p>
                <h4>ARTICLE 12 - Parrainage</h4>
                <p><span >Tout Utilisateur des Services payants à la faculté de parrainer un proche. Un parrainage s'entend comme la présentation d'un Utilisateur par un Utilisateur des Services payants en vue de la souscription aux Services payants. L'attribution des avantages ci-dessous décrits au parrain est conditionnée au paiement des Services par le filleul.</span></p>
                <p><span >Le cadeau offert au parrain et au filleul, sous la forme d'une code promotionnel, est le suivant :</span></p>
                <ul>
                <li ><span >10% de remise pour la.le filleul.e après l'achat d'un programme bilan unique, 1, 3 ou 6 mois et une consultation supplémentaire offerte pour le parrain (utilisateur).</span></li>
                </ul>
                <h4>ARTICLE 13 – Obligations de l’Utilisateur</h4>
                <p><span >Dans le cadre de l'utilisation du Site, l'Utilisateur s'engage à se conformer aux lois en vigueur et à respecter les dispositions des présentes Conditions Générales et notamment :</span></p>
                <ul>
                <li ><span >L'Utilisateur s'engage à fournir à Food-eDiet des informations personnelles exactes, au moment de l'inscription sur le Site comme lors des mises à jour de ses informations personnelles. La responsabilité de Food-eDiet ne pourra être engagée en raison des fausses déclaration fournies par les Utilisateurs.</span></li>
                <li ><span >L'Utilisateur ne doit pas :</span></li>
                <ul>
                <li ><span >utiliser les services proposés par le Site à des fins professionnelles, commerciales ou collectives ;</span></li>
                <li ><span >nuire au bon fonctionnement du Site en postant ou en diffusant, sous quelque forme que ce soit, des informations ou des contenus (tels que logiciels, virus, bombes logiques, spams...) ayant pour effet de désorganiser ou d'empêcher l'utilisation normale du Site et l'exécution des prestations incombant à Food-eDiet .</span></li>
                </ul>
                </ul>
                <p><span >Les Services sont proposés par Food-eDiet dans le cadre d'une utilisation personnelle, individuelle et privée.</span></p>
                <p><span >L'Utilisateur s'interdit toute communication ou diffusion du Programme ou des Services à d'autres personnes, quel que soit le mode de communication utilisé.</span></p>
                <p><span >Lorsqu'il est connecté à Food-eDiet, l'Utilisateur s'engage à ne pas tenir de propos injurieux, ne pas demander ou proposer des Consultations contraires aux bonnes moeurs. Food-eDiet demande à tout Utilisateur qui constate des propos préjudiciables aux autres Utilisateurs de le lui signaler le plus rapidement possible via l'adresse mail ci-après : demarchedynamics@gmail.com</span></p>
                <p><span >A tout moment, Food-eDiet se réserve le droit d'exclure un Utilisateur pour faute grave, usurpation d'identité ou d'identifiants, comportement irrespectueux ou tout comportement qui nuirait au bon fonctionnement des Services et à la réputation ou à l'image du Site ou de Food-eDiet , conformément aux dispositions de l'Article 26.</span></p>
                <p><span >La responsabilité de Food-eDiet ne pourra pas être engagée en raison du comportement illégal ou préjudiciable d'un Utilisateur envers d'autres Utilisateurs ni en raison de la cl&ocirc;ture du Compte de l'Utilisateur par Food-eDiet .</span></p>
                <p><span >L'Utilisateur est responsable des dommages et préjudices (directs ou indirects, matériels ou immatériels), causés à Food-eDiet et/ou à tout tiers et résultant de tout comportement illégal ou contrevenant aux présentes dispositions.</span></p>
                <h4>ARTICLE 14 – Prise en charge des consultations</h4>
                <p><span >A titre d'information, certaines mutuelles peuvent prendre en charge un certain nombre de Consultations effectuées par un Diététicien. Le Diététicien peut, à la demande de l'Utilisateur, éditer une facture afin que l'Utilisateur entreprenne les démarches de prise en charge auprès de l'organisme de complémentaire santé auquel il est affilié.</span></p>
                <p><span >La responsabilité de Food-eDiet ne pourra pas être mise en oeuvre si l'organisme auquel l'Utilisateur est affilié refuse tout remboursement des prestations effectuées par Food-eDiet .</span></p>
                <p><span >L'Utilisateur est donc invité à se reporter aux conditions de prise en charge des consultations diététiques de sa mutuelle.</span></p>
                <h4>ARTICLE 15 - Responsabilité</h4>
                <h5>15.1 Objectif minceur de l'Utilisateur</h5>
                <p><span >L'Utilisateur portera une attention particulière au fait que l'efficacité des Programmes proposés dépend totalement de son implication personnelle et du respect avec lequel il applique les principes d'alimentation et les conseils dispensés dans le cadre des Consultations et des suivis. Food-eDiet ne saurait donc supporter aucune obligation de résultat si l'Utilisateur n'atteint pas l'objectif personnel de perte de poids qu'il s'est fixé. FoodeDiet fournit, au moyen du Site, un ensemble de services et d'outils destinés à l'apprentissage de la méthode Food-eDiet . Food-eDiet n'est pas garant de la qualité de la prestation des Diététiciens nutritionnistes. Le contenu des Consultations est déterminé librement par le Diététicien. En revanche, Food-eDiet s'engage à vérifier les dipl&ocirc;mes et inscriptions au répertoire des professionnels de santé UPDLF de chaque Diététicien qui propose des Consultations à travers le Site.</span></p>
                <p><span >Food-eDiet ne sera pas tenue d'apporter des réponses aux questions des Utilisateurs si ces questions ne sont pas liées :</span></p>
                <ul>
                <li ><span >à la compréhension des Programmes</span></li>
                <li ><span >à la mise en application des Programmes</span></li>
                <li ><span >à l'accès technique au Site</span></li>
                </ul>
                <h5>15.2 Maintenance</h5>
                <p><span >Food-eDiet s'engage à proposer aux Utilisateurs une accessibilité continue au Site. Toutefois, afin d'offrir aux Utilisateurs des Services de qualité, Food-eDiet se réserve la possibilité de procéder à des opérations de maintenance, de mise à jour ou de réparation sur le Site. Dans la mesure du possible, l'Utilisateur sera informé des suspensions temporaires du Site dues à ces opérations.</span></p>
                <p><span >Cependant, Food-eDiet ne pourra être tenue responsable en cas d'indisponibilité temporaire du Site, notamment pour des raisons de maintenance ou de réparation et dans toute hypothèse de défaillance des systèmes de communication échappant ou non au contr&ocirc;le de Food-eDiet. De la même manière, Food-eDiet ne pourra voir sa responsabilité engagée en cas d'atteinte à la sécurité de ses propres systèmes dès lors qu'elle aura accompli toutes les obligations de sécurité nécessaires à l'exécution de ses obligations en tant que prestataire de services.</span></p>
                <p><span >Food-eDiet fait son possible pour garantir la sécurité des données collectées par le biais du Site. Toutefois, si une faille de sécurité venait à être révélée, Food-eDiet s'engage à agir dans les plus brefs délais pour remédier au problème et à en informer les Utilisateurs concernés.</span></p>
                <p><span >Vous acceptez d'indemniser, de défendre et de protéger Foode-Diet, notre société-mère, nos filiales, sociétés affiliées, partenaires, responsables, directeurs, agents, contractants, concédants, prestataires de services, sous-traitants, fournisseurs, stagiaires et employés, quant à toute réclamation ou demande, incluant les honoraires raisonnables d'avocat, faite par toute tierce partie à cause de ou découlant de votre violation de ces Conditions Générales de Vente et d'Utilisation ou des documents auxquels ils font référence, ou de votre violation de toute loi ou des droits d'un tiers.</span></p>
                <h4>ARTICLE 16 – Données à caractère personnel</h4>
                <p><span >Conformément à la loi 30 JUILLET 2018. - Loi relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel</span> <span >relative à l'informatique, aux fichiers et aux libertés, l'Utilisateur est informé que Food-eDiet , en tant que responsable du traitement, met en oeuvre un traitement de données à caractère personnel ayant notamment pour principales finalités :</span></p>
                <ul>
                <li ><span >l'établissement d'un bilan sur la qualité de l'équilibre nutritionnel, suite au questionnaire rempli par l'Utilisateur ;</span></li>
                <li ><span >la gestion de l'accès au Site et du fonctionnement des Services ;</span></li>
                <li ><span >l'exécution des obligations contractuelles suite à la souscription aux Services ;</span></li>
                <li ><span >la gestion et le suivi de la relation clients utilisateurs et prospects ;</span></li>
                <li ><span >la gestion des contenus ;</span></li>
                <li ><span >l'amélioration et la sécurisation des Services ;</span></li>
                <li ><span >la prospection commerciale ;</span></li>
                <li ><span >l'établissement de statistiques concernant les comportements nutritionnels et la méthode Food-eDiet&nbsp;</span></li>
                </ul>
                <h5>16.1 Traitement des données et droits de l’Utilisateur</h5>
                <p><span >Les informations recueillies auprès des Utilisateurs au moment de leur inscription sont tenues pour confidentielles. Food-eDiet ne recueille, traite ou utilise les données des Utilisateurs que dans la mesure o&ugrave; cela est nécessaire à l'exécution et à l'amélioration des Services.</span></p>
                <p><span >Le destinataire des données collectées est Food-eDiet . Food-eDiet respecte la confidentialité, l'intégrité et la sécurité des données que l'Utilisateur lui communique par le biais du Site. Les informations collectées par Food-eDiet sont destinées à son usage exclusif. Food-eDiet s'engage à ne pas divulguer, céder, vendre, louer, commercialiser, donner, ou prêter à des tiers toute information personnelle, sans l'accord préalable exprès de l'Utilisateur.</span></p>
                <p><span >L'Utilisateur est informé qu'il bénéficie d'un droit d'accès, de modification, de rectification et de suppression de ses données qui lui permet, le cas échéant, de faire rectifier, compléter, mettre à jour ou effacer les données personnelles le concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont la collecte, l'utilisation, la communication ou la conservation n'est plus pertinente.</span></p>
                <p><span >Conformément à la loi RGPD belge, l'Utilisateur est informé qu'il peut transmettre à Food-eDiet ses directives concernant le sort de ses données personnelles après son décès. Il peut notamment gérer les modalités de conservation, d'effacement ou de communication de ses données.</span></p>
                <p><span >L'Utilisateur dispose également d'un droit d'opposition au traitement de ses données pour des motifs légitimes ainsi que d'un droit d'opposition au traitement de ses données à des fins de prospection commerciale.</span></p>
                <p><span >Ces droits s'exercent par courriel à Food-eDiet , en précisant l'objet de sa demande ainsi que ses nom, prénom, adresse mail de souscription et identifiant à l'adresse demarchedynamics@gmail.com, soit par courrier postal à l'adresse Avenue de la Ramée 30, 1180 Uccle, Bruxelles, Belgique. Quelle que soit la forme de la demande, elle doit être accompagnée de la copie d'un titre d'identité signée de l'Utilisateur.</span></p>
                <h5>16.2 Sécurité bancaire des données</h5>
                <p><span >Afin de procéder au paiement de sa commande, il sera demandé à l'Utilisateur de communiquer le numéro et la date d'expiration de sa carte bancaire ainsi que le cryptogramme de sécurité. Les paiements en ligne sont sécurisés par le protocole HTTPS afin de garantir le chiffrage des données de l'Utilisateur lors de la transmission de ses informations bancaires.</span></p>
                <p><span >La sécurité des transactions ainsi que celle de la collecte et du traitement des données bancaires de l'Utilisateur est assurée par STRIPE dont le système est certifié et reconnu comme fournissant un service fiable et sécurisé.</span></p>
                <h4>ARTICLE 17 – Conservation et archivage</h4>
                <p><span >Les données fournies par l'Utilisateur ne sont conservées par Food-eDiet que le temps nécessaire à l'accomplissement des finalités de leur collecte définies à l'article précédent.</span></p>
                <p><span >Les données bancaires fournies par l'Utilisateur ne sont pas stockées par Food-eDiet mais par STRIPE. Elles ne seront conservées que le temps de la réalisation du paiement dans son intégralité.</span></p>
                <p><span >Les données relatives au bilan diététique et celles collectées à l'occasion des Consultations et de la navigation de l'Utilisateur sur son Espace Personnel sont conservées un an à compter de la fin de la prestation accomplie pour le compte de l'Utilisateur. Les données relatives à la relation contractuelle entre Food-eDiet et l'Utilisateur sont conservées trois ans à compter de la fin de la relation contractuelle.</span></p>
                <h4>ARTICLE 18 – OUTILS FACULTATIFS</h4>
                <p><span >Nous pourrions vous fournir l'accès à des outils de tierces parties sur lesquels nous n'exer&ccedil;ons ni suivi, ni contr&ocirc;le, ni influence.</span></p>
                <p><span >Vous reconnaissez et acceptez le fait que nous fournissons l'accès à de tels outils "tels quels" et "selon la disponibilité", sans aucune garantie, représentation ou condition d'aucune sorte et sans aucune approbation. Nous n'aurons aucune responsabilité légale résultant de ou liée à l'utilisation de ces outils facultatifs de tiers.</span></p>
                <p><span >Si vous utilisez les outils facultatifs offerts sur le site, vous le faites à votre propre risque et à votre propre discrétion, et vous devriez consulter les conditions auxquelles ces outils sont offerts par le ou les fournisseurs tiers concerné(s).</span></p>
                <p><span >Nous pourrions aussi, à l'avenir, offrir de nouveaux services et/ou de nouvelles fonctionnalités sur notre site (incluant de nouveaux outils et de nouvelles ressources). Ces nouvelles fonctionnalités et ces nouveaux services seront également assujettis à ces Conditions Générales de Vente et d'Utilisation.</span></p>
                <h4>ARTICLE 19 – LIENS DE TIERS</h4>
                <p><span >Certains contenus, produits et services disponibles par le biais de notre Service pourraient inclure des éléments provenant de tierces parties.</span></p>
                <p><span >Les liens provenant de tierces parties sur ce site pourraient vous rediriger vers des sites web de tiers qui ne sont pas affiliés à nous. Nous ne sommes pas tenus d'examiner ou d'évaluer le contenu ou l'exactitude de ces sites, et nous ne garantissons pas et n'assumons aucune responsabilité quant à tout contenu, site web, produit, service ou autre élément accessible sur ou depuis ces sites tiers.</span></p>
                <p><span >Nous ne sommes pas responsables des préjudices ou dommages liés à l'achat ou à l'utilisation de biens, de services, de ressources, de contenu, ou de toute autre transaction effectuée en rapport avec ces sites web de tiers. Veuillez lire attentivement les politiques et pratiques des tierces parties et assurez-vous de bien les comprendre avant de vous engager dans toute transaction. Les plaintes, réclamations, préoccupations, ou questions concernant les produits de ces tiers doivent être soumises à ces mêmes tiers.</span></p>
                <h4>ARTICLE 20 – COMMENTAIRES, SUGGESTIONS ET AUTRES PROPOSITIONS D’UTILISATEURS</h4>
                <p><span >Si, à notre demande, vous soumettez des contenus spécifiques (par exemple, pour participer à des concours), ou si sans demande de notre part, vous envoyez des idées créatives, des suggestions, des propositions, des plans ou d'autres éléments, que ce soit en ligne, par e-mail, par courrier, ou autrement (collectivement, "commentaires"), vous nous accordez le droit, en tout temps, et sans restriction, d'éditer, de copier, de publier, de distribuer, de traduire et d'utiliser autrement et dans tout média tout commentaire que vous nous envoyez. Nous ne sommes pas et ne devrons pas être tenus (1) de maintenir la confidentialité des commentaires; (2) de payer une compensation à quiconque pour tout commentaire fourni; (3) de répondre aux commentaires.</span></p>
                <p><span >Nous pourrions, mais n'avons aucune obligation de le faire, surveiller, modifier ou supprimer le contenu que nous estimons, à notre seule discrétion, être illégal, offensant, mena&ccedil;ant, injurieux, diffamatoire, pornographique, obscène ou autrement répréhensible, ou qui enfreint toute propriété intellectuelle ou ces Conditions Générales de Vente et d'Utilisation.</span></p>
                <p><span >Vous vous engagez à écrire des commentaires qui ne violent pas les droits de tierces parties, y compris les droits d'auteur, les marques déposées, la confidentialité, la personnalité, ou d'autres droits personnels ou de propriété. Vous convenez également que vos commentaires ne contiendront pas de contenu illégal, diffamatoire, offensif ou obscène, et qu'ils ne contiendront non plus pas de virus informatique ou d'autres logiciels malveillants qui pourraient affecter de quelque manière que ce soit le fonctionnement du Service ou tout autre site web associé. Vous ne pouvez pas utiliser de fausse adresse e-mail, prétendre être quelqu'un que vous n'êtes pas, ou essayer de nous induire nous et/ou les tierces parties en erreur quant à l'origine de vos commentaires. Vous êtes entièrement responsable de tous les commentaires que vous publiez ainsi que de leur exactitude. Nous n'assumons aucune responsabilité et déclinons tout engagement quant à tout commentaire que vous publiez ou que toute autre tierce partie publie.</span></p>
                <h4>ARTICLE 21 - Cookies</h4>
                <h5>21.1 Information</h5>
                <p><span >Les cookies sont des fichiers textes placés sur le terminal des Utilisateurs. Food-eDiet utilise les cookies suivants :</span></p>
                <ul>
                <li ><span >cookies d'authentification</span></li>
                <li ><span >cookies d'identifiants de session</span></li>
                <li ><span >cookies de panier d'achat</span></li>
                <li ><span >cookies de mesure d'audience</span></li>
                </ul>
                <p><span >L'Utilisateur doit consentir au recueil d'informations permettant les mesures d'audience du Site via le dép&ocirc;t de cookies sur son terminal. Dans ce cadre, l'Utilisateur est invité à accepter la politique de gestion des cookies sur le bandeau prévu à cet effet en bas de l'écran, ou à en configurer les modalités à partir de son navigateur.</span></p>
                <h5>21.2 Gestion</h5>
                <p><span >Vous pouvez configurer votre logiciel de navigation en conformité avec vos attentes. Vous pouvez :</span></p>
                <p><span >1) Accepter l'enregistrement des cookies sur votre terminal ; ou</span></p>
                <p><span >2) Rejeter l'enregistrement des cookies soit systématiquement ou soit en fonction de l'émetteur. Dans cette hypothèse, l'Utilisateur est informé qu'il ne pourra plus bénéficier d'une partie des fonctionnalités nécessaires à sa navigation dans certains espaces du Site ; ou</span></p>
                <p><span >3) Choisir en temps réel de refuser ou accepter l'enregistrement de cookies, une notification vous étant envoyée avant qu'un cookie ne soit enregistré sur votre terminal.</span></p>
                <p><span >Ces réglages de configuration se font directement dans le menu d'aide de votre navigateur. Cette configuration est différente selon chaque navigateur et l'Utilisateur est invité à consulter les rubriques d'aide de son navigateur (exemple : Préférences &ndash; Confidentialité pour SAFARI).</span></p>
                <p><span >Vous pouvez vous opposer au dép&ocirc;t de cookies en nous formulant votre demande par email à l'adresse : demarchedynamics@gmail.com</span></p>
                <h4>ARTICLE 22 - Propriété Intellectuelle</h4>
                <p><span >Le Site pris dans sa globalité, ainsi que chaque élément le composant (logiciels, textes, logos, graphismes, marques, noms de domaine, titres, sons, images...), sont la propriété de Food-eDiet ou ont fait l'objet d'une autorisation préalable d'utilisation de la part de tiers.</span></p>
                <p><span >Toute reproduction, utilisation ou représentation totale ou partielle d'un élément composant le Site et/ou sa structure sans l'autorisation préalable et écrite de Food-eDiet est constitutive d'une contrefa&ccedil;on au sens du Code de la Propriété Intellectuelle et pourra faire l'objet de poursuites judiciaires.</span></p>
                <h4>ARTICLE 23 – RÉSILIATION</h4>
                <p><span >Les obligations et responsabilités engagées par les parties avant la date de résiliation resteront en vigueur après la résiliation de cet accord et ce à toutes les fins.</span></p>
                <p><span >Ces Conditions Générales de Vente et d'Utilisation sont effectives à moins et jusqu'à ce qu'elles soient résiliées par ou bien vous ou non. Vous pouvez résilier ces Conditions Générales de Vente et d'Utilisation à tout moment en nous avisant que vous ne souhaitez plus utiliser nos Services, ou lorsque vous cessez d'utiliser notre site.</span></p>
                <p><span >Si nous jugeons, à notre seule discrétion, que vous échouez, ou si nous soup&ccedil;onnons que vous avez été incapable de vous conformer aux modalités de ces Conditions Générales de Vente et d'Utilisation, nous pourrions aussi résilier cet accord à tout moment sans vous prévenir à l'avance et vous resterez responsable de toutes les sommes redevables jusqu'à la date de résiliation (celle-ci étant incluse), et/ou nous pourrions vous refuser l'accès à nos Services (ou à toute partie de ceux-ci).</span></p>
                <h4>ARTICLE 24 – INTÉGRALITÉ DE L’ACCORD</h4>
                <p><span >Tout manquement de notre part à l'exercice ou à l'application de tout droit ou de toute disposition des présentes Conditions Générales de Vente et d'Utilisation ne devrait pas constituer une renonciation à ce droit ou à cette disposition.</span></p>
                <p><span >Ces Conditions Générales de Vente et d'Utilisation ou toute autre politique ou règle d'exploitation que nous publions sur ce site ou relativement au Service constituent l'intégralité de l'entente et de l'accord entre vous et nous et régissent votre utilisation du Service, et remplacent toutes les communications, propositions et tous les accords, antérieurs et contemporains, oraux ou écrits, entre vous et nous (incluant, mais ne se limitant pas à toute version antérieure des Conditions Générales de Vente et d'Utilisation).</span></p>
                <p><span >Toute ambigu&iuml;té quant à l'interprétation de ces Conditions Générales de Vente et d'Utilisation ne doit pas être interprétée en défaveur de la partie rédactrice.</span></p>
                <h4>ARTICLE 25 – DISSOCIABILITÉ</h4>
                <p>
                    Dans le cas où une disposition des présentes Conditions Générales de Vente et d’Utilisation serait jugée comme étant illégale, nulle ou inapplicable, cette disposition pourra néanmoins être appliquée dans la pleine mesure permise par la loi, et la partie non applicable devra être considérée comme étant dissociée de ces Conditions Générales de Vente et d’Utilisation, cette dissociation ne devra pas affecter la validité et l’applicabilité de toutes les autres dispositions restantes.
                </p>
                <h4 id="cloture">ARTICLE 26 – Clôture du Compte</h4>
                <h5>26.1 Par l’Utilisateur</h5>
                <p>La clôture du compte se fait par demande à demarchedynamics@gmail.com</p>
                <h5>26.2 Par Foode-Diet</h5>
                <p><span >A compter de l'expiration du délai légal de rétractation prévu à l'Article 23, l'Utilisateur n'aura plus la possibilité de revenir sur la souscription au Programme choisi. Celui-ci sera exécuté conformément à la description des Services payants de l'Article 11. A compter de la souscription aux Services payants, l'Utilisateur doit payer le montant des prestations selon la formule choisie.</span></p>
                <p><span >Food-eDiet se réserve le droit de cl&ocirc;turer le Compte de l'Utilisateur de plein droit, sans préavis, ni remboursement des prestations payées au jour de la cl&ocirc;ture ni indemnité, par courrier électronique ou par simple courrier postal dans les cas suivants :</span></p>
                <ul>
                <li ><span >utilisation des Services contraire à l'ordre public et aux bonnes moeurs ;</span></li>
                <li ><span >atteinte aux droits de propriété intellectuelle de Food-eDietet à ceux d'un tiers ;</span></li>
                <li ><span >actes affectant le fonctionnement des Services ;</span></li>
                <li ><span >perte, détournement, utilisation non autorisée ou frauduleuse des identifiant et mot de passe de l'Utilisateur par un tiers ;</span></li>
                <li ><span >tout manquement substantiel de la part de l'Utilisateur à ses obligations au titre des Conditions Générales, et en particulier l'envoi de messages intempestifs et répétés aux autres Utilisateurs via le blog, la création d'une fausse identité (liste non exhaustive...)</span></li>
                </ul>
                <p><span >La responsabilité de Food-eDiet ne pourra en aucun cas être engagée suite à la fermeture du Compte de l'Utilisateur dans les conditions prévues au présent article.</span></p>
                <h4>ARTICLE 27 – Modification du Site et des Conditions Générales</h4>
                <p><span >La société Food-eDiet a la possibilité de modifier tout ou partie des Services et du Site. Toutefois, les nouvelles Conditions définies par Food-eDiet ne seront opposables à l'Utilisateur ayant déjà souscrit aux Services et accepté les présentes Conditions Générales qu'après notification de ces modifications à l'Utilisateur.</span></p>
                <p><span >La poursuite de l'utilisation du Site par les Utilisateurs après la date d'entrée en vigueur de telles modifications, et après réception de la notification, vaudra acceptation par les Utilisateurs des modifications apportées.</span></p>
                <h4>ARTICLE 28 – LOI APPLICABLE</h4>
                <p><span >Ces Conditions Générales de Vente et d'Utilisation, ainsi que tout autre accord séparé par le biais duquel nous vous fournissons des Services seront régis et interprétés en vertu des lois en vigueur à Liège.</span></p>
                <h4>ARTICLE 29 – COORDONNÉES</h4>
                <p>Les questions concernant les Conditions Générales de Vente et d’Utilisation devraient nous être envoyées à demarchedynamics@gmail.com</p>
            </div>
        </div>
    }
}

export default ConditionUtilisation;
