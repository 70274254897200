import React, { Component } from 'react';
import { MyEditor } from '../ArticleManagement'
import { GreenButton } from '../../../components/Theme';
import { compose } from 'recompose';
import { withFirebase } from '../../../helpers';
import withSnackBar from '../../../components/Theme/MySnackBar/withSnackBar';

class CommentairePrive extends Component {

    state = {
        commentaire: "",
    }

    componentDidMount() {
        this.setState({commentaire: this.props.targetUser.commentaire})
    }

    handleConfirm = () => {
        const { targetUser } = this.props;
        const newValue = {
            commentaire: this.state.commentaire
        }
        //todo: ne pas remplir le champs dans le user patient, mais dans une collection de la diet privée 
        this.props.firebase.firestore.collection("users").doc(targetUser.uid).update(newValue)
        .then((result) => {
            this.props.openSnackMessage("Commentaire mis à jour !");
        })
        .catch(error => {
            this.setState({ error });
        });
    }


    render() {
        return (
            <div>
                <MyEditor 
                    onChange={(val) => {this.setState({commentaire: val})}}
                    content={this.state.commentaire}
                />
                <GreenButton onClick={this.handleConfirm}>
                    Modifier
                </GreenButton>
            </div>
        )
    }
}

export default compose(withFirebase, withSnackBar)(CommentairePrive)
