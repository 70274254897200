import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {  red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { MyColor } from '..';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: "8px",
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: MyColor.SECONDARY_GREEN,
    '&:hover': {
      backgroundColor: MyColor.SECONDARY_GREEN,
    },
  },
  buttonFail: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  fabProgress: {
    color: MyColor.SECONDARY_GREEN,
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: MyColor.SECONDARY_GREEN,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CircularIntegration(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [fail, setFail] = React.useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
    [classes.buttonFail]: fail,
  });

  React.useEffect(() => {
    return () => {
    };
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      props.onClick(() => {setSuccess(true); setLoading(false);} , () => { setFail(true); setLoading(false);} );
     
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          className={buttonClassname}
          disabled={loading || props.disabled}
          onClick={handleButtonClick}
          type={props.type}
        >
          {props.text}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}