import React, { Component } from 'react'
import { withAuthorization } from '../../components/Session';
import { Grid, Hidden } from '@mui/material';
import LoadingPanel from '../robustness/LoadingPanel';
import MessengerPro from './MessengerPro';
import Horaire from './Horaire';
import { BackgroundDesktopPage, BackgroundMobilePage } from '../connected/Home';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import AgendaPanel from './Agenda/AgendaPanel';

class DietPanel extends Component {

    state = {
        text: "",
        article: {},
        loading: true,
    }

    componentDidMount() {
        const {  authUser } = this.props;

        if(!authUser || !authUser.data || !authUser.data.level >= 4) {
            this.props.navigate("/")
        }
    }


    render() {

        const body =  <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Fade bottom>
                    <AgendaPanel authUser={this.props.authUser} /> 
                </Fade>
            </Grid>
            <Grid item xs={12}  md={12}>
                {!this.props.authUser.data  && <LoadingPanel />}
                {this.props.authUser.data && <MessengerPro authUser={this.props.authUser} />}
            </Grid>
            <Grid item xs={12} md={6}>
                {!this.props.authUser.data  && <LoadingPanel />}
                {this.props.authUser.data  && <Horaire authUser={this.props.authUser} />}
            </Grid>
        </Grid>

        return (
            <div>
                <Helmet>
                    <title>Accès pro</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                <Hidden smUp implementation="css">
                    <BackgroundMobilePage {...this.props}>
                    {body}
                    </BackgroundMobilePage>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <BackgroundDesktopPage {...this.props}>
                        {body}
                    </BackgroundDesktopPage>
                </Hidden>


               
           </div>
        )
    }
}

/*
<Grid item xs={12} md={8}>
                {!this.props.authUser.data  && <LoadingPanel />}
                {this.props.authUser.data  && 
                    <Paper style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <h2 style={{paddingLeft: "10px", paddingTop: "10px" }} >Mon Agenda</h2>
                        <h5 style={{paddingLeft: "10px" }} >Retrouvez vos prochains rendez-vous et finalisez vos dernières consultations.</h5>
                        <HistoriquePanel authUser={this.props.authUser} condition={(statut) => true} isPro isNext/>
                    </Paper>
                }
            </Grid>
            */
/*
<Grid item xs={6}>
    {!this.props.authUser.data  && <LoadingPanel />}
    {this.props.authUser.data && <NewArticlePanel authUser={this.props.authUser} />}
</Grid>
<Grid item xs={6}>
    {!this.props.authUser.data  && <LoadingPanel />}
    {this.props.authUser.data && <NewArticlePanel authUser={this.props.authUser} />}
</Grid>
*/

const condition = authUser => !!authUser;

export default withAuthorization(condition)(DietPanel);
