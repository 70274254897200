import {  Hidden } from '@mui/material';
import React, { Component } from 'react'

import { withAuthorization } from '../../../components/Session';
import { Background } from '../../../components/Theme';
import MyAppBar from '../../connected/Menu/MyAppBar';
import LoadingPanel from '../../robustness/LoadingPanel';
import MesArticlesPanel from './MesArticlesPanel';
import { BackgroundDesktopPage } from '../../connected/Home';
import { Helmet } from 'react-helmet';

class MesArticles extends Component {

    state = {
    }

    componentDidMount() {
        const {  authUser } = this.props;

        if(!authUser || !authUser.data || !authUser.data.level >= 4) {
            this.props.navigate("/")
        }
    }

    render() {

        const body = <div>{!this.props.authUser.data  && <LoadingPanel />}
        {this.props.authUser.data && <MesArticlesPanel authUser={this.props.authUser} />}</div>

        return (
            <div>
                <Helmet>
                    <title>Accès pro - Mes articles</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                <Hidden smUp implementation="css">
                    <MyAppBar authUser={this.props.authUser} title={"Mes Articles"} paddingLeft="0px"/>
                    <Background marginLeft="0px" paddingLeft="35px" minHeight="835px">
                    {body}
                    </Background>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <BackgroundDesktopPage {...this.props}>
                    {body}
                    </BackgroundDesktopPage>
                </Hidden>


               
           </div>
        )
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(MesArticles);