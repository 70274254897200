import React, { Component } from 'react'
import { withAuthorization } from '../../../../components/Session';
import Alert from '@mui/material/Alert';
import { BackgroundDesktopPage, BackgroundMobilePage } from '../../Home';
import { DesktopView, MobileView, MyColor } from '../../../../components/Theme';
import { Container, Paper} from '@mui/material';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import MesPaiements from './MesPaiements';
import ChangeEmailFacebook from './ChangeEmailFacebook';
import { Helmet } from 'react-helmet';

export const PROVIDER = {
    PASSWORD:"password",
    FACEBOOK:"facebook.com"
}

class ComptePage extends Component {  

    state = {
        showSuccess: "",
        provider:"",
    }

    componentDidMount() {
        // Get provider 
        const providers = this.props.firebase.getProviderId() 
        const provider = providers[providers.length - 1]
        this.setState({provider : provider.providerId ?? "password"})
        //id : password
        //id : facebook.com
    }

    render() {

        const { showSuccess, provider } = this.state;

        const body = (
            <div className="karla_text">
                <Container maxWidth="md" style={{marginLeft:"0"}} disableGutters>
                    {showSuccess &&
                        <Alert severity="success">
                            {showSuccess}
                        </Alert>
                    }
                    <h2 style={{color:MyColor.PRIMARY_GREEN}}>Mon compte</h2>
                    <h5>Modifiez vos paramètres de compte et votre mot de passe.</h5>
                    <Paper style={{padding:"15px"}} elevation={2}>
                        {provider === PROVIDER.PASSWORD ?
                        <div>
                            <ChangeEmail {...this.props} setSuccess={(msg) => {this.setState({showSuccess:msg}) }} />
                            <div style={{width:"100%", height:"1px", borderRadius:"25px", backgroundColor:"gainsboro", marginBottom:"15px"}} />
                            <ChangePassword {...this.props} setSuccess={(msg) => {this.setState({showSuccess:msg}) }} />
                        </div>
                        :
                        <div>
                            <ChangeEmailFacebook {...this.props} setSuccess={(msg) => {this.setState({showSuccess:msg}) }} />
                        </div>
                        }
                    </Paper>
                    <h3>Mes Paiements</h3>
                    <h5>Visualisez votre historique de paiements.</h5>
                    {process.env.NODE_ENV === 'production' &&
                        <MesPaiements authUser={this.props.authUser} />
                    }
                </Container>
            </div>
        );

        return (
            <div>
                <Helmet>
                    <title>Mon compte</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                 <DesktopView >
                    <BackgroundDesktopPage {...this.props}>
                        {body}
                    </BackgroundDesktopPage>
                </DesktopView>
                <MobileView >
                    <BackgroundMobilePage {...this.props}>
                        {body}
                    </BackgroundMobilePage>
                </MobileView>
            </div>
        )
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ComptePage);
