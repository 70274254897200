import { Divider, Grid } from '@mui/material';
import React, {  } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { DesktopView, MobileView, MyColor } from '../../../../components/Theme';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';


export default function ArticleEntry(props) {

    const article = props.article;
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

    return (
        <div>
            <DesktopView >
                <div style={{marginBottom:"20px"}}>
                    <Divider orientation="horizontal" variant="fullWidth"/>
                    <Grid container>
                        <Grid item xs={5}>
                            <Link to={"/blog/" + article.uid} className="nolink">
                                <div style={{height:"250px", backgroundImage:"url("+article.miniatureURL+")",  backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"  }}   />
                            </Link>
                        </Grid>
                        <Grid item xs={7}>
                            <div style={{padding:"15px", height:"200px"}}>
                                <span ><i>{article.type}</i></span>
                                <Link to={"/blog/" + article.uid} className="nolink">
                                    <h2 style={{marginBottom:"5px", marginTop:"5px", textDecoration:"none", color:MyColor.PRIMARY_GREEN}}>{article.title}</h2>
                                </Link>
                                <p>Par <i><a style={{textDecoration:"none", color:MyColor.PRIMARY_GREEN}} href="/dieteticienne/eloise-feryr">Eloïse</a>, le {new Date(article.date).toLocaleDateString('fr-BE', options)}</i> </p>
                                <LinesEllipsis text={article.subtitle}
                                    maxLine='4'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters' />
                            </div>
                            <div style={{float:"right"}}>
                                <Link to={"/blog/" + article.uid} className="nolink"><button type="button" className="circular-inscription-btn" style={{fontSize:"11px", width:"130px", padding:"5px"}}>→ Lire la suite</button></Link>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </DesktopView>
            <MobileView>
                <div style={{marginBottom:"5px"}}>
                    <Divider orientation="horizontal" variant="fullWidth"/>
                    <Grid container>
                        <Grid item xs={5}>
                            <Link to={"/blog/" + article.uid} className="nolink">
                                <div style={{height:"90px", backgroundImage:"url("+article.miniatureURL+")",  backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"  }}   />
                            </Link>
                        </Grid>
                        <Grid item xs={7}>
                            <div style={{padding:"15px", height:"90px"}}>
                                <Link to={"/blog/" + article.uid} className="nolink">
                                    <h4 style={{marginBottom:"5px", marginTop:"5px", textDecoration:"none", color:MyColor.PRIMARY_GREEN}}>{article.title}</h4>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </MobileView>
        </div>
        
    )
}

export function ArticleEntrySkeleton() {


    return (
        <div>
            <DesktopView >
                <div style={{marginBottom:"20px"}}>
                    <Divider orientation="horizontal" variant="fullWidth"/>
                    <Grid container>
                        <Grid item xs={5}>
                            <Skeleton animation="wave" variant="rect" width="100%" height={250} />
                        </Grid>
                        <Grid item xs={7}>
                            <div style={{padding:"15px", height:"190px"}}>
                                <Skeleton animation="wave" variant="text" width="40%" />
                                <Skeleton animation="wave" variant="text"  />  
                                <Skeleton animation="wave" variant="text"  />  
                                <Skeleton animation="wave" variant="text"  />  
                                <Skeleton animation="wave" variant="text"  />  
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </DesktopView>
            <MobileView>
                <div style={{marginBottom:"5px"}}>
                    <Divider orientation="horizontal" variant="fullWidth"/>
                    <Grid container>
                        <Grid item xs={5}>
                            <Skeleton animation="wave" variant="rect" width="100%" height={90} />
                        </Grid>
                        <Grid item xs={7}>
                            <div style={{padding:"15px", height:"90px"}}>
                                <Skeleton animation="wave" variant="text"  />  
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </MobileView>
        </div>
        
    )
}


export function ArticleEntryUne(props) {

    const article = props.article;
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

    return (
        <div>
            <DesktopView >
                <div style={{marginBottom:"20px", paddingTop:"15px"}}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Link to={"/blog/" + article.uid} className="nolink">
                                <center>
                                    <div style={{  height:"500px", backgroundImage:"url("+article.miniatureURL+")",  backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"  }}   />
                                </center>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={12} justifyContent="center">
                            <center>
                            <div style={{padding:"15px", marginBottom:"10px"}}>
                                <span ><i>{article.type}</i></span>
                                <Link to={"/blog/" + article.uid} className="nolink">
                                    <h2 style={{marginBottom:"5px", marginTop:"5px", textDecoration:"none", color:MyColor.PRIMARY_GREEN, fontSize:"2em"}}>{article.title}</h2>
                                </Link>
                                <p>Par <i><a style={{textDecoration:"none", color:MyColor.PRIMARY_GREEN}} href="/dieteticienne/eloise-feryr">Eloïse</a>, le {new Date(article.date).toLocaleDateString('fr-BE', options)}</i> </p>
                                <LinesEllipsis 
                                    text={article.subtitle}
                                    maxLine='4'
                                    style={{fontSize:"20px"}}
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters' />
                            </div>
                            <div>
                                <Link to={"/blog/" + article.uid} className="nolink"><button type="button" className="circular-inscription-btn" style={{fontSize:"11px", width:"130px", padding:"5px", marginBottom:"15px"}}>→ Lire la suite</button></Link>
                            </div>
                            </center>
                        </Grid>
                    </Grid>
                </div>
            </DesktopView>
            <MobileView>
                <div style={{marginBottom:"20px"}}>
                    <Divider orientation="horizontal" variant="fullWidth"/>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Link to={"/blog/" + article.uid} className="nolink">
                                <center>
                                    <div style={{  height:"240px", backgroundImage:"url("+article.miniatureURL+")",  backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"  }}   />
                                </center>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <center>
                                <div style={{padding:"15px"}}>
                                    <span ><i>{article.type}</i></span>
                                    <Link to={"/blog/" + article.uid} className="nolink">
                                        <h2 style={{marginBottom:"5px", marginTop:"5px", textDecoration:"none", color:MyColor.PRIMARY_GREEN}}>{article.title}</h2>
                                    </Link>
                                    <p>Par <i><a style={{textDecoration:"none", color:MyColor.PRIMARY_GREEN}} href="/dieteticienne/eloise-feryr">Eloïse</a>, le {new Date(article.date).toLocaleDateString('fr-BE', options)}</i> </p>
                                    <LinesEllipsis 
                                        text={article.subtitle}
                                        maxLine='4'
                                        style={{fontSize:"14px"}}
                                        ellipsis='...'
                                        trimRight
                                        basedOn='letters' />
                                </div>
                                <div>
                                    <Link to={"/blog/" + article.uid} className="nolink"><button type="button" className="circular-inscription-btn" style={{fontSize:"11px", width:"130px", padding:"5px"}}>→ Lire la suite</button></Link>
                                </div>
                            </center>
                        </Grid>
                    </Grid>
                </div>
            </MobileView>
        </div>
        
    )
}

export function ArticleEntryUneSkeleton(props) {

    return (
        <div>
            <DesktopView >
                <div style={{marginBottom:"20px", paddingTop:"15px"}}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Skeleton animation="wave" variant="rect" width="100%" height={500} />
                        </Grid>
                        <Grid container item xs={12} md={12} justifyContent="center">
                            <center>
                            <div style={{padding:"15px", marginBottom:"10px"}}>
                                <Skeleton animation="wave" variant="text" width="40%" />
                                <Skeleton animation="wave" variant="text" width="90%" />  
                                <Skeleton animation="wave" variant="text" width="60%" />  
                                <Skeleton animation="wave" variant="text"  />  
                                <Skeleton animation="wave" variant="text"  />  
                            </div>
                            </center>
                        </Grid>
                    </Grid>
                </div>
            </DesktopView>
            <MobileView>
                <div style={{marginBottom:"20px"}}>
                    <Divider orientation="horizontal" variant="fullWidth"/>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Skeleton animation="wave" variant="rectange" width="100%" height={240} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <center>
                                <div style={{padding:"15px"}}>
                                <Skeleton animation="wave" variant="text" width="40%" />
                                <Skeleton animation="wave" variant="text"  />  
                                <Skeleton animation="wave" variant="text"  />  
                                <Skeleton animation="wave" variant="text"  />  
                                <Skeleton animation="wave" variant="text"  />  
                                </div>
                            </center>
                        </Grid>
                    </Grid>
                </div>
            </MobileView>
        </div>
        
    )
}
