import {  Grid, IconButton, Paper, TextField, Tooltip, Button } from '@mui/material';
import React, { Component } from 'react'
import { withFirebase } from '../../../helpers';
import { compose } from 'recompose';
import InfiniteScroll from 'react-infinite-scroll-component';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { compteStatut } from '../../connected/Profile/ProfilePanel';
import SearchIcon from '@mui/icons-material/Search';
import algoliasearch from 'algoliasearch';
import AddIcon from '@mui/icons-material/Add';
import { withRouter } from '../../../App';
const searchClient = algoliasearch('5VLFOU1HSS', '53fab587d37f770223ccf8e28ed1d30a');
const index = searchClient.initIndex("Patients");

class MesArticlesPanel extends Component {

    state = {
        patients: [],
        has_more: true,
        searchText: "",
        typing: false,
        typingTimeout: 0
    }

    componentDidMount() {
        this.getPatients();
    }

    changeName = (event) => {
        const self = this;
    
        if (self.state.typingTimeout) {
           clearTimeout(self.state.typingTimeout);
        }
    
        self.setState({
           name: event.target.value,
           typing: false,
           typingTimeout: setTimeout(function () {
               self.searchText(self.state.name);
             }, 300)
        });
    }

    searchText = (name) => {

        index.search(name, {
            filters: 'dietId:PuXdV6hqDjaYfJTyYa5RMPv2N3F2'
          })
        .then(({ hits }) => {
            this.setState({patients: hits})
        })
        .catch(err => {
            console.error(err);
        });
       
    }

    getPatients = (title) => {
        const { firebase, authUser } = this.props;
        let set = this

        //initiate first set
        var first;
        first  = firebase.firestore.collection("users").where("dietId", "==", authUser.data.id).limit(8);
        first.get().then(function (documentSnapshots) {
          var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
          const list = [];
          documentSnapshots.forEach(function(doc) {
              const patients = doc.data();
              if(patients.id !== authUser.data.id)
                list.push(patients);
          });
              set.setState({ patients: list, last: lastVisible, has_more: (list.length > 0) });
          });
    }

    fetchMoreData = () => {
        //get last state we added from getUsers()
        let last = this.state.last;
        let set = this;
        if(last == null)
            return
        const { firebase, authUser } = this.props;
        var next = firebase.firestore.collection("users").where("dietId", "==", authUser.data.uid).startAfter(last).limit(4);
        next.get().then(function (documentSnapshots) {
            var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
            const list = [];
            documentSnapshots.forEach(function(doc) {
            const patients = doc.data();
            if(patients.uid !== authUser.data.uid)
                list.push(patients);
            });
            let updated_list = set.state.patients.concat(list);
            set.setState({ patients: updated_list, last: lastVisible, has_more: (list.length > 0) });
        });
    };

    visualizePatient = (key) => {
        const url = window.location.origin + "/admin/profil/" + this.state.patients[key].uid
        var win = window.open(url, '_blank');
        win.focus();
    }
 
    render() {

        const { patients, has_more } = this.state;

        const articlesList = 
        <InfiniteScroll 
            dataLength={patients.length}
            next={this.fetchMoreData}
            hasMore={has_more}
            /*endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }*/
            //loader={<span className="text-secondary">loading</span>}>
        >
            {patients.map((single, key) => (
            <Paper key={key} style={{padding:"5px 10px 5px 10px", marginBottom:"10px",}}>
            <Grid container spacing={1} >
                <Grid item>
                    <img style={{cursor: "pointer"}} src={patients[key].imageURL ??  "https://www.allsolutionslocksmiths.com.au/wp-content/uploads/2011/07/user.png"} alt="" width="150" height="100" onClick={() => this.visualizePatient(key)}/>
                </Grid>
                <Grid item xs={8} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs direction="column" container spacing={0}>
                            <Grid item xs>
                                <h4 style={{marginBottom:"4px"}}>{patients[key].firstname + " " + patients[key].name}</h4>
                            </Grid>
                            <Grid item xs>
                                <i>{compteStatut(patients[key].level)}</i>
                            </Grid>
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs={6}>
                                Dernière consultation : <i>{patients[key].lastConsultation ? new Date(parseInt(patients[key].lastConsultation)).toLocaleDateString('fr-BE', options) : "Non disponible"}</i>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} container alignContent="center">
                        <Grid item >
                            <Tooltip title="Voir le profil" placement="top">
                                <IconButton component="span" onClick={() => this.visualizePatient(key)}>
                                    <AccountBoxIcon htmlColor="#4169E1" fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            ))}
        </InfiniteScroll>

        const filter = 
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
                <SearchIcon />
            </Grid>
            <Grid item md>
                <TextField  onChange={this.changeName} placeholder="Rechercher un patient..." fullWidth/>
            </Grid>
        </Grid>


        return (
            <div>
                <Grid container style={{marginBottom:"10px"}}>
                    <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {this.props.navigate(({
                            pathname: "/admin/patients/nouveau",
                            state: { return_url: this.props.location.pathname }
                            }))}}
                    >
                        Encoder un nouveau patient
                    </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        
                        <Paper style={{padding:"10px 10px 10px", marginBottom:"10px"}}>
                            {filter}
                        </Paper>
                        {articlesList}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper style={{padding:"10px 10px 10px", marginBottom:"10px"}}>
                            <h2>Statistiques</h2>
                            Nombre de patients : {this.props.authUser.data.numberOfPatients ?? 0}
                        </Paper>
                    </Grid>
                </Grid>
                
            </div>
        )
    }
}

export default compose(withFirebase, withRouter)(MesArticlesPanel);

const options = { year: 'numeric', month: 'numeric', day: 'numeric' };